import styled from 'styled-components'
import { Tag, TagProps } from 'antd'
import { PluginType } from '../../../models/graphql'

type TagSCProps = TagProps & {
  type: PluginType
}

export const TagSC = styled(Tag)<TagSCProps>`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  background: ${props => (props.type === PluginType.Executor ? '#F9F0FF' : '#E6F4FF')};
  border: 1px solid ${props => (props.type === PluginType.Executor ? '#D3ADF7' : '#91CAFF')};
  padding: 2px 5px 2px 0;

  svg {
    height: 16px;
  }
`
