import styled from 'styled-components'
import { Alert, Button, Card, Table } from 'antd'
import { MachineEntry } from './ConnectedMachines'
import { breakpoints } from '../../styles/globalStyles'
import { ReactComponent as Phoenix } from '../../assets/phoenix.svg'

const iconStyles = `
 width: 94px;
  height: 94px;

  @media (max-width: ${breakpoints.mdMin}px) {
    width: 64px;
    height: 64px;
  }

  @media (max-width: ${breakpoints.xsMin}px) {
    width: 48px;
    height: 48px;
  }
`

export const IconWrapperSC = styled.div`
  margin: 0 auto;
  ${iconStyles}

  span.anticon, svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`

export const CenteredContentSC = styled.div`
  margin: 0;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`

export const MachineTableSC = styled(Table<MachineEntry>)`
  .ant-empty-normal {
    margin: 6px 0 5px;
  }
`

export const ButtonSC = styled(Button)`
  border-width: 0;
  transition: all 0.3s;
  > span {
    position: relative;
  }

  &::before {
    transition: all 0.3s;
    content: '';
    background: linear-gradient(135deg, rgb(23 101 243), rgb(255 85 0));
    position: absolute;
    inset: 0;
    opacity: 1;
    border-radius: inherit;
  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }
`

export const FuseAlertWrapperSC = styled.div`
  background: linear-gradient(135deg, rgb(31 102 234), rgb(239 142 93));
  width: 100%;
  height: 100%;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 3px;
`

export const FuseAlertSC = styled(Alert)`
  margin: 0;
  padding: 40px 0 40px 40px;
  background: transparent;
  border: 0;

  .ant-alert-message {
    font-weight: bold;
    font-size: 18px;
  }

  p {
    margin-top: 0;
    font-size: 15px;
  }

  @media (max-width: ${breakpoints.mdMin}px) {
    padding: 15px;
  }
`

export const IllustrationSC = styled(Phoenix)`
  position: absolute;
  top: 10px;
  height: 100% !important;
  right: 0;
`

export const FuseAlertBgSC = styled.div`
  border-radius: 6px;
  padding: 0;
  background: #fff;
  display: block;
  overflow: hidden;
`

export const CardSC = styled(Card)`
  height: 100%;

  p {
    margin-top: 0;
  }

  .ant-card-head-title {
    display: flex;
    align-items: center;
  }
`
