import React, { FC } from 'react'
import { CodeBlock } from '../../components/codeBlock/CodeBlock'
import styled from 'styled-components'
import { SolutionOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { CardWithTextSC } from './styles'

export const InstructionCodeBlockSC = styled(CodeBlock)`
  text-align: center;
  padding: 0 30px 0 0;
  margin: 0 10px -3px 10px;
  min-width: 100px;
  display: inline-block;
  border-width: 0;
`

export const FuseCustomInstructions: FC = () => {
  return (
    <CardWithTextSC
      title={
        <>
          <SolutionOutlined /> Tailored Instructions
        </>
      }
    >
      <Row>
        <Col lg={14}>
          <p>
            Fuse categorizes your queries and applies tailored AI instructions to provide accurate, relevant responses
            in predefined areas like GitHub Actions secret management, pipeline analysis, GKE troubleshooting, and local
            environment operations.
          </p>
          <p>
            In addition to the built-in instructions, you can create your own to match your specific workflows and daily
            tasks — ensuring Fuse assists you exactly the way you need. Your instructions are shared between all Fuse
            installations within your organization.
          </p>
          <p>
            Start by creating your own instructions with the command{' '}
            <InstructionCodeBlockSC light={true}>fuse instructions new</InstructionCodeBlockSC>
          </p>
        </Col>
      </Row>
    </CardWithTextSC>
  )
}
