import { FC, Fragment } from 'react'
import { CodeBlock } from '../../../../../../components/codeBlock/CodeBlock'
import { Typography } from 'antd'
import { DeploymentPage } from '../../../../utils'

type Props = {
  instruction: NonNullable<DeploymentPage['installUpgradeInstructions']>[number]
}

export const ConnectTabContent: FC<Props> = ({ instruction }) => {
  let titleCounter = 0
  return (
    <div>
      {instruction.prerequisites?.map((prerequisiteItem, idx) => (
        <Fragment key={idx}>
          {prerequisiteItem.title && (
            <Typography.Title level={3}>
              {++titleCounter}. {prerequisiteItem.title}
            </Typography.Title>
          )}
          {prerequisiteItem.description && <p dangerouslySetInnerHTML={{ __html: prerequisiteItem.description }} />}
          {prerequisiteItem.command && <CodeBlock className={'mb-20'}>{prerequisiteItem.command}</CodeBlock>}
        </Fragment>
      ))}
      <CodeBlock id={'install-upgrade-cmd'}>{instruction.installUpgradeCommand}</CodeBlock>
      <Typography.Title level={3}>{++titleCounter}. Wait for cluster to connect</Typography.Title>
      <Typography.Paragraph>
        Usually, it takes less than a minute to install the agent and establish a connection to Botkube Cloud.
      </Typography.Paragraph>
    </div>
  )
}
