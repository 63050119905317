import { FC, useContext } from 'react'
import { AlertBarSC, AlertSC } from './styles'
import { Container } from '../../layouts/container/Container'
import { AppCtx } from '../../providers/appCtx'
import { paths } from '../../router/paths'
import { Link } from 'react-router-dom'
import { CommonSkeleton, ContentType } from '../../services/contentful/types'
import { useContent } from '../../utils/customeHooks/useContent'
import { useAppSelector } from '../../store/store'

export const UpdateBlockedAlert: FC = () => {
  const appCtx = useContext(AppCtx)
  const updateBlock = useAppSelector(state => state.deployment.updateBlock)
  const content = useContent<CommonSkeleton>(ContentType.COMMON)

  if (!updateBlock.org.isBlocked) {
    return null
  }

  const orgPage = paths.ORGANIZATION.replace(':id', appCtx.orgID)
  const message = (
    <>
      {content?.fields.updateBlockedAlert}{' '}
      <Link to={orgPage}>
        <b>See the plan usage</b>
      </Link>
    </>
  )

  return (
    <AlertBarSC>
      <Container>
        <AlertSC
          description={
            <>
              <b>Reason:</b> {updateBlock.org.reason}
            </>
          }
          message={message}
          type='error'
          showIcon
        />
      </Container>
    </AlertBarSC>
  )
}
