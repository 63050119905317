import { FC, memo } from 'react'
import { Row } from 'antd'
import { SocketSlackForm } from '../../../../../../forms/deploymentWizard/platforms/socketSlack'
import { SocketSlackFormValues } from '../utils'
import { FormikErrors, FormikHandlers, FormikHelpers, FormikTouched } from 'formik'
import { PlatformContentInstruction } from './PlatformContentInstruction'
import { ContentType } from '../../../../../../services/contentful/types'
import { PlatformEmbeddedDocs } from './PlatformEmbeddedDocs'
import { ContentColumnLeft } from './columns/ContentColumnLeft'
import { ContentColumnRight } from './columns/ContentColumnRight'

export type SlackContentProps = {
  values: SocketSlackFormValues
  errors?: FormikErrors<SocketSlackFormValues>
  touched?: FormikTouched<SocketSlackFormValues>
  handleChange: FormikHandlers['handleChange']
  setFieldValue: FormikHelpers<SocketSlackFormValues>['setFieldValue']
  idx: number
  isSubmitted: boolean
}

const SlackContentInner: FC<SlackContentProps> = props => {
  const { values, errors, touched, handleChange, setFieldValue, idx, isSubmitted } = props
  const platformInstructionType = ContentType.EMBEDDED_SLACK_PLATFORM_INSTRUCTION

  return (
    <section>
      <Row>
        <ContentColumnLeft>
          <PlatformContentInstruction platformInstructionType={platformInstructionType} />
          <SocketSlackForm
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            idx={idx}
            isSubmitted={isSubmitted}
          />
        </ContentColumnLeft>
        <ContentColumnRight>
          <PlatformEmbeddedDocs platformInstructionType={platformInstructionType} />
        </ContentColumnRight>
      </Row>
    </section>
  )
}

export const SlackContent = memo(SlackContentInner)
