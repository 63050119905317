import styled from 'styled-components'
import { Form, Table } from 'antd'

export const BotJoinedSpanSC = styled.span`
  line-height: 32px;
`

export const FormItemSC = styled(Form.Item)`
  .ant-form-item-explain-error {
    margin-bottom: 10px;
  }
  .ant-table-pagination.ant-pagination {
    margin-bottom: 0;
  }

  &.form-item-channels {
    .ant-form-item-row {
      display: block;
    }
    .ant-form-item-label {
      text-align: left;
    }
  }
`

export const UlSC = styled.ul`
  list-style: inside;
  margin-bottom: 20px;
`

export const TableChannelsSC = styled(Table)`
  thead {
    th {
      span.helper-icon svg {
        margin-left: 5px;
        color: rgb(191, 191, 191);
      }
    }
    th:nth-child(2) {
      padding-left: 10px;

      &:hover,
      &.ant-table-column-sort {
        background: #fafafa;
      }

      &. .ant-table-column-sorter {
        margin-left: 0;
      }
    }
  }

  tbody {
    tr {
      &.ant-table-row-selected {
        &:hover {
          td.column-sort-without-bg {
            background: #dcf4ff;
          }
        }

        td.column-sort-without-bg {
          background: #e6f7ff;
        }
      }

      td.column-sort-without-bg {
        background: none;
      }
    }
  }
`

export const InvitationInstructionsSC = styled.div`
  text-align: center;

  .slack-botkube-name {
    min-width: 100px;
    display: inline-block;
  }

  svg {
    max-width: 100%;
  }
`
