export interface HubSpotExtWindow extends Window {
  _hsq: unknown[] | undefined
  hsConversationsSettings: {
    identificationEmail?: string
    identificationToken?: string
    loadImmediately?: boolean
  }
  HubSpotConversations?: {
    resetAndReloadWidget: () => void
    widget: {
      load: () => void
    }
  }
}

const getHubSpotDataLayer = () => {
  return ((window as unknown as HubSpotExtWindow)._hsq = (window as unknown as HubSpotExtWindow)._hsq ?? [])
}

export const setHubSpotIdentify = (email: string) => {
  const _hsq = getHubSpotDataLayer()
  _hsq.push([
    'identify',
    {
      email,
    },
  ])
}

let hubSpotWidgetInit = false
export const setHubSpotConversationSettings = (email: string, token: string) => {
  ;(window as unknown as HubSpotExtWindow).hsConversationsSettings = {
    identificationEmail: email,
    identificationToken: token,
  }
  if (!hubSpotWidgetInit && (window as unknown as HubSpotExtWindow).HubSpotConversations) {
    ;(window as unknown as HubSpotExtWindow).HubSpotConversations?.widget.load()
    hubSpotWidgetInit = true
  }
}

let hubSpotEnabledAsync = false
export const enableHubspot = (hubSpotEnabled: boolean, callback: () => void) => {
  if (!hubSpotEnabled && !hubSpotEnabledAsync) {
    ;(window as unknown as HubSpotExtWindow).hsConversationsSettings = { loadImmediately: false }
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = '//js-na1.hs-scripts.com/24158269.js'
    script.id = 'hs-script-loader'
    script.defer = true
    script.async = true
    script.onload = function () {
      callback()
    }
    document.head.appendChild(script)
    hubSpotEnabledAsync = true
  }
}
