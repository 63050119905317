import { FC, useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { usePageTitle } from '../../utils/customeHooks/usePageTitle'

export const Unauthenticated: FC = () => {
  usePageTitle('Sign In')
  const { loginWithRedirect } = useAuth0()

  useEffect(() => {
    const returnTo = window.location.pathname + window.location.search
    void loginWithRedirect({
      appState: { returnTo },
    })
  }, [loginWithRedirect])

  return null
}
