import styled from 'styled-components'
import { Alert, theme } from 'antd'
import { HealthStatus } from './PlatformHealthBadge'
import { breakpoints } from '../../../styles/globalStyles'

export const PlatformHealthBadgeSC = styled.div`
  position: relative;
`

const getBadgeIconColor = (healthStatus: HealthStatus) => {
  switch (healthStatus.toLowerCase()) {
    case 'healthy':
      return theme.getDesignToken().colorSuccess
    case 'unhealthy':
      return theme.getDesignToken().colorError
    case 'unknown':
    default:
      return 'gray'
  }
}
type BadgeIconWrapperProps = {
  healthStatus: HealthStatus
}
export const BadgeIconWrapperSC = styled.span<BadgeIconWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${theme.getDesignToken().colorBgBase};
  position: absolute;
  bottom: 0;
  right: 0;
  width: 14px;
  height: 14px;

  span.anticon {
    svg {
      height: 10px;
      margin: 0;
      color: ${props => getBadgeIconColor(props.healthStatus)};
    }
  }

  @media (min-width: ${breakpoints.lgMin}px) {
    bottom: 0;
    right: 10px;
    width: 20px;
    height: 20px;

    span.anticon {
      svg {
        height: 16px;
      }
    }
  }
`

export const PlatformHealthAlertSC = styled(Alert)`
  border-bottom: 1px solid ${theme.getDesignToken().colorErrorBorder} !important;
`
