import { User } from '@auth0/auth0-react'
import { AppQuery, Organization } from '../models/graphql'
import { browserStoreService, StorageKey } from '../services/storage'

type AppOrg = Exclude<AppQuery['organizations'], null | undefined>[number]

export const getUserOrganization = (organizations: AppOrg[], user: User) => {
  return organizations.find(orgItem => orgItem.owner.email === user.email)
}

export const getAppUserId = (organization: Partial<Organization>, user: User) => {
  return organization.members?.find(memberItem => memberItem.email === user.email)?.id
}

export const getCurrentSelectedOrganization = (organizations: AppOrg[], user: User) => {
  const orgId = browserStoreService.get(StorageKey.ORG_ID)
  if (orgId) {
    return organizations.find(orgItem => orgItem.id === orgId)
  }

  const userOrg = getUserOrganization(organizations, user)
  if (userOrg) {
    return userOrg
  }

  if (organizations.length >= 0) {
    return organizations[0]
  }

  throw new Error('No organization found')
}

export const isOrganizationIdBelongsUserOrganizations = (organizations: AppOrg[], id?: string | null) => {
  return organizations.some(org => org.id === id)
}
