import styled from 'styled-components'
import { red } from '@ant-design/colors'
import { Button, Space, Tabs } from 'antd'
import { ReactComponent as EmptyContent } from '../../../../../assets/empty-content.svg'
import { breakpoints } from '../../../../../styles/globalStyles'

type TabWrapperProps = {
  error: boolean
}

export const TabWrapperSC = styled.div<TabWrapperProps>`
  color: ${props => (props.error ? red.primary : undefined)};

  svg {
    margin-right: 5px;
  }
`

export const TabsSC = styled(Tabs)`
  .ant-tabs-nav-add {
    height: 40px;
  }
`

export const PlatformButtonSC = styled(Button)`
  width: 100%;
  text-align: left;
  position: static;
  display: flex;
  align-items: center;

  span {
    display: inline-flex;
    align-items: center;
    width: 25px;
  }

  :after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`

export const EmptyContentBotSC = styled(EmptyContent)`
  width: 80%;
  display: block;
`

export const EmbedDocsSC = styled.embed`
  width: 100%;
  min-height: 500px;
  height: calc(100% - 38px);
  background: #fff;
  position: relative;

  @media (max-width: ${breakpoints.lgMin}px) {
    height: 600px;
  }
`

export const EmbedButtonWrapperSC = styled(Space)`
  width: 100%;
  margin-bottom: 6px;
  justify-content: flex-end;
`

export const IconWrapperSC = styled.span`
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 6px;
  position: relative;
  top: 1px;
`
