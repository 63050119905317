import React, { FC, useContext, useMemo, useState } from 'react'
import { Alert, Breadcrumb, Button, Col, message, Modal, Row, Spin, Table, Typography } from 'antd'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { MainLayout } from '../../layouts/main/MainLayout'
import {
  Organization,
  useAddMemberMutation,
  useDisconnectSlackPlatformMutation,
  useDisconnectTeamsPlatformMutation,
  useOrganizationPageQuery,
  useRemoveMemberMutation,
  useUpdateOrganizationMutation,
} from '../../models/graphql'
import { getMembersTableColumns, getMembersTableData, getPlatformsTableColumns, getPlatformsTableData } from './utils'
import { AddMemberForm, AddMemberFormValues } from '../../forms/organization/addMember/AddMember'
import {
  OrganizationSettingsForm,
  OrganizationSettingsFormValues,
} from '../../forms/organization/settings/OrganizationSettings'
import { useAuth0 } from '@auth0/auth0-react'
import { getAppUserId } from '../../utils/organizations'
import { paths } from '../../router/paths'
import { browserStoreService, StorageKey } from '../../services/storage'
import { GlobalStyle } from '../../styles/globalStyles'
import { CurrentBilling } from './CurrentBilling'
import { usePageTitle } from '../../utils/customeHooks/usePageTitle'
import { TableSC } from './styles'
import { useContent } from '../../utils/customeHooks/useContent'
import { ContentType, OrganizationSkeleton } from '../../services/contentful/types'
import { PlatformsType } from '../../models/platform/platform'
import { useAppSelector } from '../../store/store'
import { AppCtx } from '../../providers/appCtx'

const CHECKOUT_STATUS_SEARCH_PARAM = 'checkoutStatus'
const CHECKOUT_STATUS_SUCCESS = 'success'

export const OrganizationPage: FC = () => {
  usePageTitle('Organization')
  const { id = '' } = useParams()
  const appCtx = useContext(AppCtx)
  const [searchParams, setSearchParams] = useSearchParams()
  const checkoutStatus = searchParams.get(CHECKOUT_STATUS_SEARCH_PARAM)
  const navigate = useNavigate()
  const [openAddMember, setOpenAddMember] = useState(false)
  const content = useContent<OrganizationSkeleton>(ContentType.ORGANIZATION_PAGE)
  const deploymentUpdateBlock = useAppSelector(state => state.deployment.updateBlock)

  const { data, isLoading, refetch } = useOrganizationPageQuery({ id })
  const { mutate: addMemberMutation } = useAddMemberMutation({
    onSuccess: () => {
      setOpenAddMember(false)
      void message.success('Member successfully added to the organization')
      void refetch()
    },
  })
  const { mutate: removeMemberMutation } = useRemoveMemberMutation({
    onSuccess: (_, { input }) => {
      if (input.userId === appUserId) {
        browserStoreService.removeItem(StorageKey.ORG_ID)
        navigate(paths.HOME)
        return void message.success('You have been removed from organization')
      }
      void message.success('Member successfully removed from the organization')
      void refetch()
    },
  })
  const { mutateAsync: updateOrgMutation, isLoading: updateOrgLoading } = useUpdateOrganizationMutation({
    onSuccess: () => {
      void message.success('Organization name successfully updated')
      void refetch()
    },
  })

  const { mutateAsync: disconnectSlackPlatformMutation } = useDisconnectSlackPlatformMutation({
    onSuccess: () => {
      void message.success('Slack workspace successfully disconnected')
      void refetch()
    },
  })
  const { mutateAsync: disconnectTeamsPlatformMutation } = useDisconnectTeamsPlatformMutation({
    onSuccess: () => {
      void message.success('Teams team successfully disconnected')
      void refetch()
    },
  })

  const { user } = useAuth0()

  const appUserId = useMemo(() => {
    if (!data?.organization || !user) {
      return
    }
    return getAppUserId(data.organization as Partial<Organization>, user)
  }, [data?.organization, user])

  const handleRemoveMember = (memberId: string) => {
    if (!data?.organization) {
      return
    }
    removeMemberMutation({
      input: {
        orgId: data.organization.id,
        userId: memberId,
      },
    })
  }

  const handleAddMember = async (values: AddMemberFormValues) => {
    if (!data?.organization) {
      return
    }
    const orgId = data.organization.id

    try {
      await Promise.all(
        values.emails.map(email =>
          addMemberMutation({
            input: {
              orgId: orgId,
              userEmail: email,
            },
          }),
        ),
      )
    } catch (err) {
      void message.error('Error while adding members to organization')
      console.error('while adding members to organization', err)
    }
  }

  const handleDisconnectOrgPlatform = async (platformType: PlatformsType, id: string) => {
    switch (platformType) {
      case PlatformsType.CLOUD_SLACK:
        await disconnectSlackPlatformMutation({
          orgId: data?.organization?.id ?? '',
          slackId: id,
        })
        break
      case PlatformsType.CLOUD_TEAMS:
        await disconnectTeamsPlatformMutation({
          orgId: data?.organization?.id ?? '',
          teamsOrgId: id,
        })
        break
      default:
        console.error('Unknown platform type', platformType)
    }
  }

  const handleOrganizationSettingsUpdate = async (values: OrganizationSettingsFormValues) => {
    if (!data?.organization) {
      return
    }
    await updateOrgMutation({ id: data.organization.id, input: { ...values } })

    if (appCtx.refetchAppQuery) {
      await appCtx.refetchAppQuery()
    }
  }

  const closeSuccessAlert = () => {
    setSearchParams({})
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (isLoading || !data) {
    return (
      <>
        <GlobalStyle />
        <Spin
          tip={'Loading organization...'}
          fullscreen={true}
        />
      </>
    )
  }

  if (!data.organization) {
    if (data.organization === null) {
      navigate(paths.NOT_FOUND)
    }

    return null
  }

  return (
    <MainLayout>
      {renderBreadcrumb(data.organization.displayName)}
      {checkoutStatus === CHECKOUT_STATUS_SUCCESS && (
        <Alert
          className={'mb-20'}
          showIcon
          message={content?.fields.checkoutSuccessAlertTitle}
          description={content?.fields.checkoutSuccessAlertDescription}
          type='success'
          closable
          afterClose={closeSuccessAlert}
        />
      )}
      <h1>{data.organization.displayName}</h1>

      <CurrentBilling organization={data.organization} />

      <h2>Organization Members</h2>
      <Button
        type={'primary'}
        onClick={() => setOpenAddMember(true)}
        className={'mb-20'}
        disabled={deploymentUpdateBlock.org.isBlocked}
      >
        Add member
      </Button>
      <Table
        columns={getMembersTableColumns({
          onRemove: handleRemoveMember,
          ownerId: data.organization.ownerId,
          appUserId,
        })}
        dataSource={getMembersTableData(data.organization.members)}
        rowKey={'id'}
      />
      <>
        <h2>Connected Chat Platforms</h2>
        <p>You can connect new chat platforms during Instance creation or update.</p>
        <TableSC
          columns={getPlatformsTableColumns(handleDisconnectOrgPlatform)}
          dataSource={getPlatformsTableData(data.organization.connectedPlatforms)}
          rowKey={'id'}
          className={'mb-40'}
        />
      </>

      <h2>Organization Settings</h2>
      <Row className={'mb-30'}>
        <Col
          xxl={8}
          xl={12}
          lg={14}
          xs={24}
        >
          <OrganizationSettingsForm
            organization={data.organization}
            loading={updateOrgLoading}
            disabled={deploymentUpdateBlock.org.isBlocked}
            onSubmit={values => handleOrganizationSettingsUpdate(values)}
          />
        </Col>
      </Row>
      <Modal
        open={openAddMember}
        title={
          <>
            <Typography.Text
              style={{ width: 300 }}
              ellipsis={{ tooltip: data.organization.displayName }}
            >
              Add member to {data.organization.displayName}
            </Typography.Text>
          </>
        }
        footer={false}
        destroyOnClose={true}
      >
        <AddMemberForm
          onCancel={() => setOpenAddMember(false)}
          onSubmit={values => void handleAddMember(values)}
        />
      </Modal>
    </MainLayout>
  )
}

function renderBreadcrumb(orgName?: string) {
  return (
    <Breadcrumb
      className={'mb-40'}
      items={[{ title: <Link to={paths.HOME}>Home</Link> }, { title: orgName ?? 'Organization' }]}
    />
  )
}
