import styled, { css } from 'styled-components'

function makeContainer(minWidth: string, breakpoint: string, padding: string) {
  return css`
    @media (min-width: ${minWidth}) {
      max-width: ${breakpoint};
      padding: ${padding};
    }
  `
}

export const ContainerSC = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto;
  padding: 0 15px;

  &.center {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }

  ${makeContainer('0', '575px', '0 5px')}
  ${makeContainer('576px', '576px', '0 5px')}
  ${makeContainer('768px', '768px', '0 15px')}
  ${makeContainer('992px', '992px', '0 15px')}
  ${makeContainer('1200px', '1200px', '0 15px')}
  ${makeContainer('1600px', '1600px', '0 15px')}
`
