import { FC } from 'react'
import { Checkbox, Divider, Form, Input, Modal, ModalProps, Space } from 'antd'
import { FormikConfig, useFormik } from 'formik'
import * as yup from 'yup'
import { useCheckAll } from '../../../utils/customeHooks/useCheckAll'
import {
  CreateAliasMutation,
  CreateAliasMutationVariables,
  UpdateAliasMutation,
  UpdateAliasMutationVariables,
} from '../../../models/graphql'
import { UseMutateFunction } from '@tanstack/react-query'
import { getValidationError, getValidationStatus } from '../../../utils/formik/formik'
import { AliasPage } from '../../../pages/aliases/utils'

interface Props extends ModalProps {
  alias?: AliasPage
  deployments: AliasPage['deployments']
  isLoading: boolean
  handleCreate: UseMutateFunction<CreateAliasMutation, unknown, CreateAliasMutationVariables>
  handleEdit: UseMutateFunction<UpdateAliasMutation, unknown, UpdateAliasMutationVariables>
}

type FormValues = {
  name: string
  displayName: string
  command: string
  deploymentIds: string[]
}

const initFormik = (props: Props): FormikConfig<FormValues> => ({
  enableReinitialize: true,
  initialValues: {
    name: props.alias?.name ?? '',
    displayName: props.alias?.displayName ?? '',
    command: props.alias?.command ?? '',
    deploymentIds: props.alias?.deployments.map(dep => dep.id) ?? [],
  },
  onSubmit: (values, { resetForm }) => {
    if (props.alias) {
      return props.handleEdit({ id: props.alias.id, input: values })
    }
    props.handleCreate({ input: values }, { onSuccess: () => resetForm() })
  },
  validationSchema: yup.object().shape({
    name: yup.string().required(),
    command: yup.string().required(),
    deployments: yup.array().min(1),
  }),
})

export const AddEditAlias: FC<Props> = props => {
  const { alias, deployments, isLoading } = props
  const formik = useFormik<FormValues>(initFormik(props))

  const [indeterminate, checkAll, onCheckAllChange, onChange] = useCheckAll(
    deployments.length,
    formik.values.deploymentIds.length,
    checked => {
      void formik.setFieldValue('deploymentIds', checked ? deployments.map(deployment => deployment.id) : [])
    },
    list => {
      void formik.setFieldValue('deploymentIds', list)
    },
  )

  return (
    <Modal
      title={alias ? `Edit alias: ${alias.name}` : 'Add alias'}
      {...props}
      confirmLoading={isLoading}
      onOk={() => void formik.submitForm()}
    >
      <Form layout={'vertical'}>
        <Form.Item
          label={'Display Name'}
          required={true}
          validateStatus={getValidationStatus(formik.errors.displayName, formik.touched.displayName)}
          help={getValidationError(formik.errors.displayName, formik.touched.displayName)}
        >
          <Input
            name={'displayName'}
            value={formik.values.displayName}
            onChange={formik.handleChange}
          />
        </Form.Item>
        <Form.Item
          label={'Alias'}
          required={true}
          validateStatus={getValidationStatus(formik.errors.name, formik.touched.name)}
          help={getValidationError(formik.errors.name, formik.touched.name)}
        >
          <Input
            name={'name'}
            value={formik.values.name}
            onChange={formik.handleChange}
          />
        </Form.Item>
        <Form.Item
          label={'Command'}
          required={true}
          validateStatus={getValidationStatus(formik.errors.command, formik.touched.command)}
          help={getValidationError(formik.errors.command, formik.touched.command)}
        >
          <Input
            name={'command'}
            value={formik.values.command}
            onChange={formik.handleChange}
          />
        </Form.Item>
        <Form.Item
          label={'Instances'}
          required={true}
          validateStatus={getValidationStatus(formik.errors.deploymentIds as string, formik.touched.deploymentIds)}
          help={getValidationError(formik.errors.deploymentIds as string, formik.touched.deploymentIds)}
        >
          <Space direction={'vertical'}>
            <Checkbox
              indeterminate={indeterminate}
              onChange={onCheckAllChange}
              checked={checkAll}
            >
              Select all
            </Checkbox>
            <Divider />
            <Checkbox.Group
              name={'instanceIds'}
              value={formik.values.deploymentIds}
              onChange={onChange}
            >
              <Space direction={'vertical'}>
                {deployments.map(deployment => (
                  <Checkbox
                    key={deployment.id}
                    value={deployment.id}
                  >
                    {deployment.name}
                  </Checkbox>
                ))}
              </Space>
            </Checkbox.Group>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  )
}
