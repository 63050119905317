import { FC, useEffect, useState } from 'react'
import { WizardSteps } from './steps/WizardSteps'
import { usePageTitle } from '../../../utils/customeHooks/usePageTitle'
import { ContentSC, StepsSC } from './styles'
import { InstanceNameForm, InstanceNameFormValues } from '../../../forms/deploymentWizard/instanceName/InstanceName'
import { useDeploymentPageQuery, useUpdateDeploymentMutation } from '../../../models/graphql'
import { useParams } from 'react-router-dom'
import { Spin } from 'antd'
import { PlatformsFormValues } from './steps/platformsConfiguration/utils'
import { PluginsFormValues } from './steps/plugins/PluginsStep'
import { setWizardValuesFromDeployment } from './utils'
import { WizardLayout } from '../../../layouts/wizard/WizardLayout'
import { ContainerContentSC, ContainerStepWrapperSC } from '../../../layouts/wizard/styles'
import { useViewport } from '../../../utils/customeHooks/useViewport'
import { breakpoints } from '../../../styles/globalStyles'
import { createDeploymentUpdateInput } from '../../../utils/deployment'
import { Container } from '../../../layouts/container/Container'
import { useAppDispatch } from '../../../store/store'
import { setDeploymentOrigin } from '../../../store/slices/deployment/deployment'

export enum DeploymentAddStep {
  CONNECTION = 0,
  PLATFORMS_CONFIG = 1,
  PLUGINS = 2,
  CONFIG = 3,
}

export type WizardFormValues = {
  connectionStep?: InstanceNameFormValues
  platformsStep?: PlatformsFormValues
  pluginsStep?: PluginsFormValues
}

export const DeploymentAddPage: FC = () => {
  usePageTitle('Add Deployment')
  const dispatch = useAppDispatch()
  const [step, setStep] = useState(DeploymentAddStep.CONNECTION)
  const [wizardValues, setWizardValues] = useState<WizardFormValues>({})

  const { id = '' } = useParams()
  const { width } = useViewport()
  const { data, refetch } = useDeploymentPageQuery(
    { id },
    {
      refetchOnWindowFocus: false,
      onSuccess: data => {
        if (data.deployment) {
          dispatch(setDeploymentOrigin({ deployment: data.deployment, slackWorkspaces: [] }))
        }
      },
    },
  )
  const { mutate: updateDeployment, isLoading: deploymentMutationLoading } = useUpdateDeploymentMutation({
    onSuccess: () => {
      void refetch()
    },
  })

  useEffect(() => {
    if (data?.deployment) {
      setWizardValues(setWizardValuesFromDeployment(data.deployment))
    }
  }, [data?.deployment])

  const handleNameChange = (values: InstanceNameFormValues) => {
    setWizardValues({
      ...wizardValues,
      connectionStep: values,
    })
    if (data?.deployment) {
      updateDeployment({
        id: data.deployment.id,
        input: {
          ...createDeploymentUpdateInput(data.deployment),
          name: values.name,
        },
      })
    }
  }

  if (!data || !data.deployment) {
    return (
      <WizardLayout>
        <Spin
          tip={'Creating a new instance ...'}
          fullscreen={true}
        />
      </WizardLayout>
    )
  }

  return (
    <WizardLayout>
      <ContainerStepWrapperSC>
        <Container>
          <InstanceNameForm
            name={data.deployment.name}
            isFetching={deploymentMutationLoading}
            onSubmit={handleNameChange}
          />
          <StepsSC
            current={step}
            direction={width > breakpoints.lgMin ? 'horizontal' : 'vertical'}
            className={'mb-20 mt-20'}
            items={[
              { title: 'Connect your cluster', description: 'Install the Botkube agent' },
              { title: 'Connect your platforms', description: 'Setup your chat platforms, and more' },
              { title: 'Add Plugins', description: 'Add event sources like Kubernetes and executors like Kubectl' },
              { title: 'Configuration', description: 'Set aliases and create workflows' },
            ]}
          />
        </Container>
      </ContainerStepWrapperSC>
      <ContentSC>
        <ContainerContentSC>
          <WizardSteps
            wizardValues={wizardValues}
            setWizardValues={setWizardValues}
            deployment={data.deployment}
            refetchDeployment={refetch}
            step={step}
            setStep={setStep}
          />
        </ContainerContentSC>
      </ContentSC>
    </WizardLayout>
  )
}
