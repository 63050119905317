import {
  DiscordCreateInput,
  MattermostCreateInput,
  SocketSlackCreateInput,
  SocketSlack,
  Discord,
  Mattermost,
  CloudSlackCreateInput,
  CloudSlack,
  Webhook,
  Elasticsearch,
  CloudMsTeams,
  PagerDuty,
} from '../graphql'
import {
  DiscordFormValues,
  ElasticSearchFormValues,
  MattermostFormValues,
  PagerDutyFormValues,
  SocketSlackFormValues,
  WebhookFormValues,
} from '../../pages/clusters/add/steps/platformsConfiguration/utils'

export enum PlatformsType {
  CLOUD_SLACK = 'cloudSlacks',
  SOCKET_SLACK = 'socketSlacks',
  DISCORD = 'discords',
  MATTERMOST = 'mattermosts',
  CLOUD_TEAMS = 'cloudMsTeams',
  WEBHOOK = 'webhooks',
  ELASTIC_SEARCH = 'elasticsearches',
  PAGER_DUTY = 'pagerDuty',
}

export const isSinkPlatform = (platform: PlatformsType) => {
  return [PlatformsType.ELASTIC_SEARCH, PlatformsType.WEBHOOK, PlatformsType.PAGER_DUTY].includes(platform)
}

export enum PlatformType {
  CLOUD_SLACK = 'cloudSlack',
  SOCKET_SLACK = 'socketSlack',
  DISCORD = 'discord',
  MATTERMOST = 'mattermost',
  CLOUD_TEAMS = 'cloudMsTeams',
  WEBHOOK = 'webhook',
  ELASTIC_SEARCH = 'elasticsearch',
  PAGER_DUTY = 'pagerDuty',
}

export const PlatformsTypeNames = {
  [PlatformsType.CLOUD_SLACK]: 'Slack',
  [PlatformsType.SOCKET_SLACK]: 'Slack',
  [PlatformsType.DISCORD]: 'Discord',
  [PlatformsType.MATTERMOST]: 'Mattermost',
  [PlatformsType.CLOUD_TEAMS]: 'Teams',
  [PlatformsType.WEBHOOK]: 'Webhook',
  [PlatformsType.ELASTIC_SEARCH]: 'Elasticsearch',
  [PlatformsType.PAGER_DUTY]: 'PagerDuty',
}

export const Platforms2PlatformType = {
  [PlatformsType.CLOUD_SLACK]: PlatformType.CLOUD_SLACK,
  [PlatformsType.SOCKET_SLACK]: PlatformType.SOCKET_SLACK,
  [PlatformsType.DISCORD]: PlatformType.DISCORD,
  [PlatformsType.MATTERMOST]: PlatformType.MATTERMOST,
  [PlatformsType.CLOUD_TEAMS]: PlatformType.CLOUD_TEAMS,
  [PlatformsType.WEBHOOK]: PlatformType.WEBHOOK,
  [PlatformsType.ELASTIC_SEARCH]: PlatformType.ELASTIC_SEARCH,
  [PlatformsType.PAGER_DUTY]: PlatformType.PAGER_DUTY,
}

export type PlatformsCreateInput =
  | CloudSlackCreateInput
  | SocketSlackCreateInput
  | DiscordCreateInput
  | MattermostCreateInput

export type PlatformsValues =
  | CloudSlack
  | SocketSlack
  | Discord
  | Mattermost
  | CloudMsTeams
  | Webhook
  | Elasticsearch
  | PagerDuty
export type PlatformsFormValuesWithoutChannels =
  | Omit<SocketSlackFormValues, 'channelNames'>
  | Omit<DiscordFormValues, 'channelNames'>
  | Omit<MattermostFormValues, 'channelNames'>
  | WebhookFormValues
  | PagerDutyFormValues
  | ElasticSearchFormValues
