import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { FileTextOutlined, HomeOutlined, MailOutlined, SlackOutlined } from '@ant-design/icons'
import { Typography } from 'antd'

import { FooterWrapperSC, FooterSC } from './styles'
import { paths } from '../../router/paths'
import { Container } from '../../layouts/container/Container'

const { Link } = Typography

export const Footer: React.FC = () => {
  return (
    <FooterSC>
      <Container>
        <FooterWrapperSC>
          <RouterLink to={paths.HOME}>
            <HomeOutlined /> Home
          </RouterLink>
          <Link
            href={'https://docs.botkube.io/'}
            target={'_blank'}
          >
            <FileTextOutlined /> Documentation
          </Link>
          <Link
            href={'https://join.botkube.io/'}
            target={'_blank'}
          >
            <SlackOutlined /> Slack Community
          </Link>
          <Link href={'mailto:support@botkube.io'}>
            <MailOutlined /> support@botkube.io
          </Link>
        </FooterWrapperSC>
      </Container>
    </FooterSC>
  )
}
