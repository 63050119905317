import { Form, Input } from 'antd'
import { FC, useState } from 'react'
import { FormikErrors, FormikHandlers, FormikHelpers, FormikTouched } from 'formik'
import { PlatformsType } from '../../../models/platform/platform'
import { getValidationError, getValidationStatus } from '../../../utils/formik/formik'
import { SocketSlackFormValues } from '../../../pages/clusters/add/steps/platformsConfiguration/utils'
import { InputTag } from '../../../components/inputTag/InputTag'

type Props = {
  values: SocketSlackFormValues
  handleChange: FormikHandlers['handleChange']
  setFieldValue: FormikHelpers<SocketSlackFormValues>['setFieldValue']
  errors?: FormikErrors<SocketSlackFormValues>
  touched?: FormikTouched<SocketSlackFormValues>
  idx: number
  isSubmitted: boolean
}

export const SocketSlackForm: FC<Props> = props => {
  const { errors, touched, values, idx, handleChange, setFieldValue, isSubmitted } = props
  const [appTokenVisible, setAppTokenVisible] = useState(false)
  const [botTokenVisible, setBotTokenVisible] = useState(false)
  const handleChannels = (channels: string[]) => {
    setFieldValue(`${PlatformsType.SOCKET_SLACK}.[${idx}].channelNames`, channels)
  }

  return (
    <>
      <Form.Item
        label={'Display Name'}
        required={true}
        validateStatus={getValidationStatus(errors?.name, touched?.name ?? isSubmitted)}
        help={getValidationError(errors?.name, touched?.name ?? isSubmitted)}
      >
        <Input
          name={`${PlatformsType.SOCKET_SLACK}.[${idx}].name`}
          onChange={handleChange}
          value={values.name}
          placeholder={'My Slack Name'}
        />
      </Form.Item>
      <Form.Item
        label={'App Token'}
        required={true}
        validateStatus={getValidationStatus(errors?.appToken, touched?.appToken ?? isSubmitted)}
        help={getValidationError(errors?.appToken, touched?.appToken ?? isSubmitted)}
      >
        <Input.Password
          visibilityToggle={{ visible: appTokenVisible, onVisibleChange: setAppTokenVisible }}
          name={`${PlatformsType.SOCKET_SLACK}.[${idx}].appToken`}
          onChange={handleChange}
          value={values.appToken}
          placeholder={'xapp-MTAxNDU1NzA0MTAwNTY5OTIGcPZFv.FjwqnoIAi7BqJcVTj7vRmY99JdlgBeYrN-iPE'}
        />
      </Form.Item>
      <Form.Item
        label={'Bot Token'}
        required={true}
        validateStatus={getValidationStatus(errors?.botToken, touched?.botToken ?? isSubmitted)}
        help={getValidationError(errors?.botToken, touched?.botToken ?? isSubmitted)}
      >
        <Input.Password
          visibilityToggle={{ visible: botTokenVisible, onVisibleChange: setBotTokenVisible }}
          name={`${PlatformsType.SOCKET_SLACK}.[${idx}].botToken`}
          onChange={handleChange}
          value={values.botToken}
          placeholder={'xoxb-MTAxNDU1NzA0MTAwNTY5OTIGcPZFv.FjwqnoIAi7BqJcVTj7vRmY99JdlgBeYrN-iPE'}
        />
      </Form.Item>
      <Form.Item
        label={'Channel Name'}
        required={true}
        validateStatus={getValidationStatus(errors?.channelNames as string, touched?.channelNames ?? isSubmitted)}
        help={getValidationError(errors?.channelNames as string, touched?.channelNames ?? isSubmitted)}
      >
        <InputTag
          prefix={'#'}
          values={values.channelNames}
          placeholder={'New channel'}
          onChange={handleChannels}
        />
      </Form.Item>
    </>
  )
}
