import { FC } from 'react'
import { Button, Col, Form, Input, Row, Select, Switch } from 'antd'
import CodeEditor from '@uiw/react-textarea-code-editor'
import { useFormik } from 'formik'
import { DeploymentPageQuery } from '../../../models/graphql'
import { initFormik } from './utils'
import { colProps, gutter } from '../../../styles/globalStyles'
import { ButtonWrapper } from '../channels/styles'
import { getValidationError, getValidationStatus } from '../../../utils/formik/formik'

type Action = NonNullable<DeploymentPageQuery['deployment']>['actions'][number]

export type AddEditActionFormValues = {
  id: string
  name: string
  displayName: string
  enabled: boolean
  command: string
  sources: string[]
  executors: string[]
}

export type AddEditActionFormProps = {
  action?: Action
  sources: {
    name: string
    displayName: string
  }[]
  executors: {
    name: string
    displayName: string
  }[]
  isLoading?: boolean
  onCancel: () => void
  onSubmit: (values: AddEditActionFormValues) => void
  otherActionNames: string[]
}

export const AddEditActionForm: FC<AddEditActionFormProps> = props => {
  const { action, sources, executors, onCancel, isLoading } = props
  const formik = useFormik(initFormik(props))
  return (
    <Form layout={'vertical'}>
      <Row gutter={gutter}>
        <Col
          {...colProps}
          lg={8}
          xl={8}
        >
          <Form.Item
            label={'Name'}
            extra={
              'A unique name for the Action. This name will be used to refer to the Action in other places, such as Botkube bot Action commands.'
            }
            required={true}
            validateStatus={getValidationStatus(formik.errors.name, formik.touched.name)}
            help={getValidationError(formik.errors.name, formik.touched.name)}
          >
            <Input
              name={'name'}
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </Form.Item>
          <Form.Item
            label={'Display Name'}
            required={true}
            validateStatus={getValidationStatus(formik.errors.displayName, formik.touched.displayName)}
            help={getValidationError(formik.errors.displayName, formik.touched.displayName)}
          >
            <Input
              name={'displayName'}
              value={formik.values.displayName}
              onChange={formik.handleChange}
            />
          </Form.Item>
          <Form.Item
            label={'Enabled'}
            validateStatus={getValidationStatus(formik.errors.enabled, formik.touched.enabled)}
            help={getValidationError(formik.errors.enabled, formik.touched.enabled)}
          >
            <Switch
              id={'enabled'}
              checked={formik.values.enabled}
              onChange={checked => void formik.setFieldValue('enabled', checked)}
            />
          </Form.Item>
          <Form.Item
            label={'Trigger Source'}
            extra={'Every time a new event is received from the selected source(s), the Action will be executed.'}
            required={true}
            validateStatus={getValidationStatus(formik.errors.sources, formik.touched.sources)}
            help={getValidationError(formik.errors.sources, formik.touched.sources)}
          >
            <Select
              mode={'multiple'}
              value={formik.values.sources}
              onChange={values => void formik.setFieldValue('sources', values)}
              options={sources.map(source => ({
                label: source.displayName,
                value: source.name,
              }))}
            />
          </Form.Item>
          <Form.Item
            label={'Trigger Executor'}
            extra={'The Action will use the selected executor configuration(s) to execute the command.'}
            required={true}
            validateStatus={getValidationStatus(formik.errors.executors, formik.touched.executors)}
            help={getValidationError(formik.errors.executors, formik.touched.executors)}
          >
            <Select
              mode={'multiple'}
              value={formik.values.executors}
              onChange={values => void formik.setFieldValue('executors', values)}
              options={executors.map(executor => ({
                label: executor.displayName,
                value: executor.name,
              }))}
            />
          </Form.Item>
        </Col>
        <Col
          {...colProps}
          lg={16}
          xl={16}
        >
          <Form.Item
            label={'Command'}
            extra={
              <>
                Command to execute when the action is triggered. You can use{' '}
                <a
                  href={'https://pkg.go.dev/text/template'}
                  target={'_blank'}
                  rel='noreferrer'
                >
                  Go template
                </a>{' '}
                together with all helper functions defined by{' '}
                <a
                  href={'https://go-task.github.io/slim-sprig'}
                  target={'_blank'}
                  rel='noreferrer'
                >
                  Slim-Sprig library
                </a>
                . The <code>{`{{ .Event }}`}</code> variable contains the event object that triggered the action. Learn
                more about Command Templates on{' '}
                <a
                  href={'https://docs.botkube.io/configuration/action#command-templates'}
                  target={'_blank'}
                  rel='noreferrer'
                >
                  docs.botkube.io
                </a>
                .
              </>
            }
            required={true}
            validateStatus={getValidationStatus(formik.errors.command, formik.touched.command)}
            help={getValidationError(formik.errors.command, formik.touched.command)}
          >
            <CodeEditor
              name={'command'}
              value={formik.values.command}
              language={'go'}
              minHeight={200}
              onChange={formik.handleChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <ButtonWrapper>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          onClick={() => void formik.submitForm()}
          type={'primary'}
          loading={isLoading}
        >
          {action ? 'Save' : 'Add'}
        </Button>
      </ButtonWrapper>
    </Form>
  )
}
