import { Form, Input } from 'antd'
import { FC, useState } from 'react'
import { PlatformsType } from '../../../models/platform/platform'
import { FormikErrors, FormikHandlers, FormikTouched } from 'formik'
import { getValidationError, getValidationStatus } from '../../../utils/formik/formik'
import { PagerDutyFormValues } from '../../../pages/clusters/add/steps/platformsConfiguration/utils'

type Props = {
  values: PagerDutyFormValues
  handleChange: FormikHandlers['handleChange']
  errors?: FormikErrors<PagerDutyFormValues>
  touched?: FormikTouched<PagerDutyFormValues>
  idx: number
  isSubmitted: boolean
}

export const PagerDutyForm: FC<Props> = props => {
  const { errors, touched, values, idx, handleChange, isSubmitted } = props
  const [integrationKeyVisible, setIntegrationKeyVisible] = useState(false)

  return (
    <>
      <Form.Item
        label={'Display Name'}
        required={true}
        validateStatus={getValidationStatus(errors?.name, touched?.name ?? isSubmitted)}
        help={getValidationError(errors?.name, touched?.name ?? isSubmitted)}
      >
        <Input
          name={`${PlatformsType.PAGER_DUTY}.[${idx}].name`}
          onChange={handleChange}
          value={values.name}
          placeholder={'My PagerDuty integration'}
        />
      </Form.Item>
      <Form.Item
        label={'Integration Key'}
        required={true}
        validateStatus={getValidationStatus(errors?.integrationKey, touched?.integrationKey ?? isSubmitted)}
        help={getValidationError(errors?.integrationKey, touched?.integrationKey ?? isSubmitted)}
      >
        <Input.Password
          visibilityToggle={{ visible: integrationKeyVisible, onVisibleChange: setIntegrationKeyVisible }}
          name={`${PlatformsType.PAGER_DUTY}.[${idx}].integrationKey`}
          onChange={handleChange}
          value={values.integrationKey}
        />
      </Form.Item>
    </>
  )
}
