import { FC, ReactNode } from 'react'
import { Typography } from 'antd'
import { ReactComponent as InviteBot } from '../../../../assets/invite-bot-slack.svg'
import { InvitationInstructionsSC } from './styles'
import { CodeBlock } from '../../../../components/codeBlock/CodeBlock'

type Props = {
  instruction?: ReactNode
}

export const InvitationInstructions: FC<Props> = ({ instruction }) => {
  return (
    <InvitationInstructionsSC>
      <InviteBot />
      <div>
        <CodeBlock
          className={'slack-botkube-name'}
          light={true}
        >
          @Botkube
        </CodeBlock>
        {instruction && <Typography.Paragraph className={'mt-20'}>{instruction}</Typography.Paragraph>}
      </div>
    </InvitationInstructionsSC>
  )
}
