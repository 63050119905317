import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PluginTemplate } from './model'

type PluginState = {
  pluginTemplates: PluginTemplate[] | null
}
const initialState: PluginState = {
  pluginTemplates: null,
}
export const pluginSlice = createSlice({
  name: 'plugin',
  initialState,
  reducers: {
    setPluginTemplates: (state, action: PayloadAction<PluginTemplate[]>) => {
      state.pluginTemplates = action.payload
    },
  },
})

export const { setPluginTemplates } = pluginSlice.actions
