import { CodeBlock } from '../../codeBlock/CodeBlock'
import { FC } from 'react'
import { Tabs, TabsProps } from 'antd'
import { PrerequisiteSC } from './styles'
import { DeploymentPage } from '../../../pages/clusters/utils'

export type ConnectionInstructionsProps = {
  data: NonNullable<DeploymentPage['installUpgradeInstructions']>
  action: 'install' | 'install or upgrade'
}

export const InstallUpgradeInstructions: FC<ConnectionInstructionsProps> = ({ data, action }) => {
  const actionCapitalized = action.charAt(0).toUpperCase() + action.slice(1)

  const tabItems: TabsProps['items'] = []

  for (const item of data) {
    const tabContent = (
      <>
        {item.prerequisites?.length && (
          <>
            <h3>Prerequisites</h3>
            {item.prerequisites.map(prerequisite => {
              const result = []

              if (prerequisite.description) {
                result.push(
                  <PrerequisiteSC
                    key={prerequisite.description}
                    dangerouslySetInnerHTML={{ __html: prerequisite.description }}
                  />,
                )
              }

              if (prerequisite.command) {
                result.push(<CodeBlock key={prerequisite.command}>{prerequisite.command}</CodeBlock>)
              }

              return result
            })}
          </>
        )}
        <h3 className={'mt-20'}>{actionCapitalized} Botkube and connect it to Botkube Cloud:</h3>
        <CodeBlock className={'mb-20'}>{item.installUpgradeCommand}</CodeBlock>
      </>
    )
    tabItems.push({
      key: item.platformName,
      label: item.platformName,
      children: tabContent,
    })
  }

  return (
    <>
      <p>Select your platform and follow the instructions to {action} Botkube and connect it to Botkube Cloud.</p>
      <Tabs items={tabItems} />
    </>
  )
}
