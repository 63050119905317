import { createGlobalStyle } from 'styled-components'
import { Gutter } from 'antd/lib/grid/row'

export const breakpoints = {
  xsMin: 480,
  smMin: 576,
  mdMin: 768,
  lgMin: 992,
  xlMin: 1200,
  xxlMin: 1600,
}

export const gutter: [Gutter, Gutter] = [24, 24]

export const colProps = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 8,
  xl: 6,
}

const headerHeight = '64px'
const footerHeight = '50px'

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
      margin: 0;
      padding: 0;
  }

  h1 {
      font-weight: 600;
      font-size: 1.875rem;
  }

  h2 {
      font-weight: 400;
      font-size: 1.25rem;
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  fieldset {
    border: none;
  }
  
  .mb-5 {
    margin-bottom: 5px;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .mb-40 {
    margin-bottom: 40px;
  }
  .mb-60 {
    margin-bottom: 60px;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .ml-10 {
    margin-left: 10px;
  }
  .mr-20 {
    margin-right: 20px;
  }
  
  .align-height {
    height: 100%;
  }

  // The following styles were moved from antd LESS files. They can be refactored later.
  //CARD -----------------------------------------------------------------------
  .ant-card-head-title {
    svg {
      margin-right: 5px;
    }
  }
  //FORM -----------------------------------------------------------------------
  .ant-col {
      .ant-form-item.align-height {
          margin-bottom: 0;
      }
  }
  .ant-form-item.align-height {
      height: 100%;

      .ant-form-item-row {
          height: 100%;

          .ant-form-item-control {
              height: 100%;

              .ant-form-item-control-input {
                  height: 100%;

                  .ant-form-item-control-input-content {
                      height: 100%;
                  }
              }
          }
      }
  }
  //MENU -----------------------------------------------------------------------
  .ant-menu-horizontal {
      border-bottom: none;
  }
  //TABS -----------------------------------------------------------------------
  .hide-active-state {
      .ant-tabs-tab.ant-tabs-tab-active.ant-tabs-tab-disabled .ant-tabs-tab-btn {
          color: rgba(0, 0, 0, 0.25);
          text-shadow: none;
      }

      .ant-tabs-tab-disabled + .ant-tabs-ink-bar {
          display: none;
      }
  }
  .ant-tabs-nav {
      &::before {
          display: none;
      }

      .ant-tabs-nav-list {
          .ant-tabs-tab {
              background: transparent;
              border: 0;
              border-bottom: 2px solid transparent;
              &.ant-tabs-tab-active {
                  border-bottom: 2px solid #1890ff;
              }
          }

          .ant-tabs-nav-add {
              background: transparent;
              border: none;
          }
      }
  }
  //TABLE ----------------------------------------------------------------------
  .ant-table-content {
      max-width: 100%;
      overflow-x: auto;
  }
  //BUTTONS --------------------------------------------------------------------
  .ant-btn {
      &.ant-btn-link {
          padding: 0;
          border: none;
      }
  }
  //LAYOUT ---------------------------------------------------------------------
  .ant-layout {
      height: 100%;
  }
  .ant-layout-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: sticky;
      top: 0;
      z-index: 200; // can't be higher than 1000 because of antd modal
      min-height: ${headerHeight};
      height: auto;
      padding-left: 0;
      padding-right: 0;
      border-bottom: 1px solid #ccc;
      background: #fff;
  }
  .ant-layout-content {
      padding: 25px 0;
      background: #f5f5f5;
      position: relative;
      min-height: calc(100vh - ${headerHeight} - ${footerHeight});

      &.without-header {
          background: #f5f5f5;
          min-height: calc(100vh - ${footerHeight});
          position: relative;
      }
  }
`
