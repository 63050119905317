import { FormikConfig } from 'formik'
import * as yup from 'yup'
import { AnySchema } from 'yup'
import { v4 } from 'uuid'
import { AddEditActionFormProps, AddEditActionFormValues } from './AddEditAction'
import { generateConfigName } from '../../../utils/plugins'
import { ValidationMsg } from '../../../utils/validation'

export const initFormik = (props: AddEditActionFormProps): FormikConfig<AddEditActionFormValues> => {
  return {
    enableReinitialize: false,
    initialValues: {
      id: props.action ? props.action.id : v4(),
      name: props.action ? props.action.name : generateConfigName('action'),
      displayName: props.action ? props.action.displayName : '',
      enabled: props.action ? props.action.enabled : true,
      command: props.action ? props.action.command : '',
      sources: props.action ? props.action.bindings.sources : [],
      executors: props.action ? props.action.bindings.executors : [],
    },
    validationSchema: yup.object().shape<Record<keyof AddEditActionFormValues, AnySchema>>({
      id: yup.string(),
      name: yup
        .string()
        .test('unique-action-name-test', 'Action name must be unique', value => {
          if (!value) return true

          return !props.otherActionNames.includes(value)
        })
        .required(ValidationMsg.REQUIRED),
      displayName: yup.string().required(ValidationMsg.REQUIRED),
      enabled: yup.boolean(),
      command: yup.string().required(ValidationMsg.REQUIRED),
      sources: yup.array().of(yup.string()).min(1, ValidationMsg.MIN_ONE),
      executors: yup.array().of(yup.string()).min(1, ValidationMsg.MIN_ONE),
    }),
    onSubmit: values => {
      props.onSubmit(values)
    },
  }
}
