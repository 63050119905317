import { Switch } from 'antd'

export const getData = () => [
  {
    key: 1,
    resource: 'pods',
    error: false,
    create: true,
    update: true,
    delete: true,
  },
  {
    key: 2,
    resource: 'deployments',
    error: true,
    create: true,
    update: true,
    delete: true,
  },
  {
    key: 3,
    resource: 'configmaps',
    error: true,
    create: true,
    update: false,
    delete: true,
  },
  {
    key: 4,
    resource: 'secrets',
    error: true,
    create: true,
    update: true,
    delete: true,
  },
]

export const setColumns = () => [
  {
    title: 'Resource',
    dataIndex: 'resource',
    key: 'resource',
  },
  {
    title: 'Error',
    dataIndex: 'error',
    key: 'error',
    render: (status: boolean) => <Switch checked={status} />,
  },
  {
    title: 'Create',
    dataIndex: 'create',
    key: 'create',
    render: (status: boolean) => <Switch checked={status} />,
  },
  {
    title: 'Update',
    dataIndex: 'update',
    key: 'update',
    render: (status: boolean) => <Switch checked={status} />,
  },
  {
    title: 'Delete',
    dataIndex: 'delete',
    key: 'delete',
    render: (status: boolean) => <Switch checked={status} />,
  },
]
