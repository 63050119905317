import { FC, ReactNode } from 'react'
import { ContentColumnRightSC } from './styles'

type Props = {
  children: ReactNode
}
export const ContentColumnRight: FC<Props> = ({ children }) => {
  return (
    <ContentColumnRightSC
      xl={{ span: 12, offset: 3 }}
      lg={{ span: 11, offset: 1 }}
      md={24}
      sm={24}
    >
      {children}
    </ContentColumnRightSC>
  )
}
