import styled from 'styled-components'

export const CookieBannerSC = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 2;
  left: 50%;
  width: 60vw;
  transform: translateX(-50%);
  padding: 40px;
  background: #fff;
  box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.5);
`

export const ButtonsWrapperSC = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`
