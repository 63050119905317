import { FC } from 'react'
import { Col, Row } from 'antd'
import { DeploymentCard } from '../single/DeploymentCard'
import { colProps, gutter } from '../../../styles/globalStyles'
import { SkeletonGroup } from '../../skeleton/group/SkeletonGroup'
import { HomePageDeployment } from '../../../pages/home/utils'
import { AddCardDeployment } from '../../addCard/AddCardDeployment'

type Props = {
  className: string
  deployments: HomePageDeployment[]
  loading: boolean
  addButtonEnabled: boolean
}
export const DeploymentsGroup: FC<Props> = props => {
  const { className, deployments, loading, addButtonEnabled } = props

  return (
    <Row
      gutter={gutter}
      className={className}
    >
      <SkeletonGroup
        loading={loading}
        size={3}
        horizontal
        horizontalSize={6}
      />
      {deployments.map(deployment => (
        <Col
          key={deployment.id}
          {...colProps}
        >
          <DeploymentCard
            deployment={deployment}
            displayManagementActions
          />
        </Col>
      ))}

      {addButtonEnabled && (
        <>
          <Col {...colProps}>
            <AddCardDeployment />
          </Col>
        </>
      )}
    </Row>
  )
}
