import React, { FC, useContext, useState } from 'react'
import { Spin, Tabs } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { useDeploymentPageQuery, useDeploymentSlackWorkspacesQuery } from '../../models/graphql'
import { AppCtx } from '../../providers/appCtx'
import { paths } from '../../router/paths'
import { usePageTitle } from '../../utils/customeHooks/usePageTitle'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { setDeploymentOrigin } from '../../store/slices/deployment/deployment'
import { DeploymentDetailsHeader } from '../../components/deployment/detailsHeader/DeploymentDetailsHeader'
import { setPluginTemplates } from '../../store/slices/plugin/plugin'
import { hasDeploymentCloudSlack } from '../../utils/deployment'
import { setWorkspaces } from '../../store/slices/slackWorkspace/slackWorkspace'
import { FlexibleLayout } from '../../layouts/flexible/FlexibleLayout'
import { Container } from '../../layouts/container/Container'
import { PlatformsList } from '../../components/platform/list/PlatformsList'
import { ActionsList } from '../../components/action/list/ActionsList'
import { ExistingPluginsList } from '../../components/plugin/existingList/ExistingPluginsList'

export const DeploymentPage: FC = () => {
  usePageTitle('Deployment')
  const { id = '' } = useParams()
  const [activeTab, setActiveTab] = useState<string | undefined>(undefined)
  const deployment = useAppSelector(state => state.deployment.data)
  const appCtx = useContext(AppCtx)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { isLoading, refetch } = useDeploymentPageQuery(
    { id },
    {
      refetchOnWindowFocus: false,
      onSuccess: data => {
        dispatch((dispatch, getState) => {
          if (!data.deployment) {
            navigate(paths.NOT_FOUND)
            return
          }
          const { slackWorkspace } = getState()
          dispatch(setDeploymentOrigin({ deployment: data.deployment, slackWorkspaces: slackWorkspace.workspaces }))
        })
        dispatch(setPluginTemplates(data.pluginTemplates.data))
      },
    },
  )
  useDeploymentSlackWorkspacesQuery(
    { id: appCtx.orgID },
    {
      enabled: hasDeploymentCloudSlack(deployment),
      refetchOnWindowFocus: false,
      onSuccess: data => {
        dispatch(setWorkspaces(data.organization?.connectedPlatforms?.slacks ?? []))
      },
    },
  )

  if (isLoading || !deployment) {
    return (
      <Spin
        tip={'Fetching instance...'}
        fullscreen={true}
      />
    )
  }

  return (
    <FlexibleLayout>
      <DeploymentDetailsHeader fetchDeployment={refetch} />
      <Container>
        <Tabs
          items={[
            {
              key: 'platforms',
              label: 'Platforms',
              children: <PlatformsList />,
            },
            {
              key: 'plugins',
              label: 'Plugins',
              children: <ExistingPluginsList disableAdd={true} />,
            },
            {
              key: 'actions',
              label: 'Actions',
              children: <ActionsList />,
            },
          ]}
          activeKey={activeTab}
          onChange={activeKey => setActiveTab(activeKey)}
        />
      </Container>
    </FlexibleLayout>
  )
}
