import React, { FC } from 'react'
import { LoadMoreWrapperSC, TableSC } from './styles'
import { Button, Table, TableColumnsType, Tooltip } from 'antd'
import { filesize } from 'filesize'
import moment from 'moment/moment'
import { TableRowSelection } from 'antd/lib/table/interface'

type DocsTableProps = {
  isLoading: boolean
  rowSelection: TableRowSelection<DataType>
  data: DataType[]
  totalCount?: number
  canFetchMore?: boolean
  fetchMore: () => void
}

export interface DataType {
  key: React.Key
  fileName: string
  bytes: number
  createdAt: string
}

const columns: TableColumnsType<DataType> = [
  {
    title: 'File name',
    dataIndex: 'fileName',
  },
  {
    title: 'Size',
    dataIndex: 'bytes',
    render: (bytes: number) => {
      return filesize(bytes)
    },
  },
  {
    title: 'Uploaded',
    dataIndex: 'createdAt',
    render: (createdAt: string) => {
      return <Tooltip title={moment(createdAt).toLocaleString()}>{moment(createdAt).fromNow()}</Tooltip>
    },
  },
]

export const CustomDocsTable: FC<DocsTableProps> = ({
  isLoading,
  data,
  rowSelection,
  totalCount = 0,
  canFetchMore,
  fetchMore,
}) => {
  return (
    <>
      {/* @ts-expect-error */}
      <TableSC
        loading={isLoading}
        style={{ marginTop: '1px' }}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ scrollToFirstRowOnChange: false, y: 'calc(100vh - 200px)' }}
        summary={() => {
          if (data.length === 0) return null

          return (
            <Table.Summary.Row>
              <Table.Summary.Cell
                index={0}
                colSpan={4}
              >
                <LoadMoreWrapperSC>
                  <p>
                    Displaying {data.length} of {totalCount} items.
                  </p>
                  <Button
                    type='link'
                    onClick={() => fetchMore()}
                    disabled={!canFetchMore}
                  >
                    Load more
                  </Button>
                </LoadMoreWrapperSC>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )
        }}
      />
    </>
  )
}
