import React, { FC, ReactNode } from 'react'
import { Tooltip } from 'antd'

type Props = {
  tooltip?: string | null
  children: ReactNode
}

export const WrapWithOptionalTooltip: FC<Props> = ({ tooltip, children }) => {
  if (tooltip) {
    return <Tooltip title={tooltip}>{children}</Tooltip>
  }
  return <>{children}</>
}
