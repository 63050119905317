import { FC, useState } from 'react'
import { Form, Input, Radio, Tabs, Typography } from 'antd'
import { FormItemSC, PlatformTabLabelSC } from './styles'
import { initFormik, initStateSelectedConnections } from './utils'
import { PluginConfigurationProps } from '../../PluginConfiguration'
import { FormikErrors, useFormik } from 'formik'
import { getValidationError, getValidationStatus } from '../../../../../utils/formik/formik'
import { useAppSelector } from '../../../../../store/store'
import { getPlatformFromDeployment, mapPlatformTypeToIcon } from '../../../../../utils/platforms'
import { isSinkPlatform, PlatformsType } from '../../../../../models/platform/platform'
import { BoundIndicator } from '../../../boundIndicator/BoundIndicator'
import { PlatformSettings } from '../../../../platform/settings/PlatformSettings'
import { PluginGeneralFormChannels } from './PluginGeneralFormChannels'

export type PluginOverviewFormProps = {
  pluginConfig: PluginConfigurationProps
  onChange: (values: PluginGeneralFormValues, errors: FormikErrors<PluginGeneralFormValues>) => void
  selectedPlatformId?: string
  blockPlatformSettings?: boolean
}
export type PluginGeneralFormValues = {
  enabled: boolean
  displayName: string
  connections: {
    platformId: string
    channels: string[]
    bound?: boolean
  }[]
}

export const PluginGeneralForm: FC<PluginOverviewFormProps> = props => {
  const { blockPlatformSettings, pluginConfig, onChange, selectedPlatformId } = props
  const deployment = useAppSelector(state => state.deployment.data)
  const formik = useFormik(initFormik(props))
  const [selectedConnections, setSelectedConnections] = useState<
    { platformType: PlatformsType; platformId: string } | undefined
  >(() => initStateSelectedConnections(pluginConfig, selectedPlatformId))
  const [isPlatformSettingsOpened, setPlatformSettingsOpened] = useState(false)

  const sendDataToPluginConfig = async () => {
    onChange(formik.values, await formik.validateForm())
  }

  if (!deployment) {
    return null
  }

  return (
    <>
      <Form layout={'vertical'}>
        <FormItemSC
          label={'Status'}
          required={true}
          validateStatus={getValidationStatus(formik.errors.enabled, formik.touched.enabled)}
          help={getValidationError(formik.errors.enabled, formik.touched.enabled)}
        >
          <Radio.Group
            name={'enabled'}
            onChange={evt => {
              if (!evt.target.name) {
                return console.error('PluginOverviewForm missing name in element')
              }
              void formik.setFieldValue(evt.target.name, evt.target.value)
              void formik.setFieldTouched(evt.target.name)
            }}
            onBlur={() => void sendDataToPluginConfig()}
            value={formik.values.enabled}
          >
            <Radio value={true}>Enabled</Radio>
            <Radio value={false}>Disabled</Radio>
          </Radio.Group>
        </FormItemSC>
        <FormItemSC
          label={'Display Name'}
          required={true}
          validateStatus={getValidationStatus(formik.errors.displayName, formik.touched.displayName)}
          help={getValidationError(
            formik.errors.displayName,
            formik.touched.displayName,
            'Set your own name for the plugin.',
          )}
          style={{ marginBottom: '44px' }}
        >
          <Input
            name={'displayName'}
            value={formik.values.displayName}
            onChange={evt => {
              if (!evt.target.name) {
                return console.error('PluginOverviewForm missing name in element')
              }
              void formik.setFieldValue(evt.target.name, evt.target.value)
            }}
            onBlur={evt => {
              formik.handleBlur(evt)
              void sendDataToPluginConfig()
            }}
          />
        </FormItemSC>
        <FormItemSC
          label={'Binding'}
          required={true}
          validateStatus={getValidationStatus(
            formik.errors.connections as string,
            formik.touched.connections as unknown as boolean,
          )}
          help={getValidationError(
            formik.errors.connections as string,
            formik.touched.connections as unknown as boolean,
            <>
              {`Select platform to which this plugin should be bound. `}
              {!blockPlatformSettings && selectedConnections && !isSinkPlatform(selectedConnections.platformType) ? (
                <>
                  {`Can't find channel?`}{' '}
                  <Typography.Link onClick={() => setPlatformSettingsOpened(true)}>
                    Check platform settings
                  </Typography.Link>
                </>
              ) : (
                ''
              )}
            </>,
          )}
        >
          <Tabs
            defaultActiveKey={selectedPlatformId}
            onChange={platformId => {
              const connection = pluginConfig.connections.find(conn => conn.platformId === platformId)
              setSelectedConnections(
                connection && {
                  platformId: connection.platformId,
                  platformType: connection.platformType,
                },
              )
            }}
            items={pluginConfig.connections.map(connection => {
              const formikConnection = formik.values.connections.find(con => con.platformId === connection.platformId)
              const formikConnectionIdx = formik.values.connections.findIndex(
                con => con.platformId === connection.platformId,
              )
              return {
                key: connection.platformId,
                label: (
                  <PlatformTabLabelSC>
                    {mapPlatformTypeToIcon(connection.platformType, { height: '12px' })}{' '}
                    {getPlatformFromDeployment(deployment, connection.platformId)?.name ?? ''}
                    <BoundIndicator
                      connectedChannels={formikConnection?.channels.length ?? 0}
                      availableChannels={connection.availableChannels.length}
                      isBound={formikConnection?.bound}
                      platformType={connection.platformType}
                    />
                  </PlatformTabLabelSC>
                ),
                children: (
                  <PluginGeneralFormChannels
                    channels={formikConnection?.channels ?? []}
                    isBound={formikConnection?.bound}
                    connection={connection}
                    onBlur={sendDataToPluginConfig}
                    onChange={(platformType, channels, bound) => {
                      if (!isSinkPlatform(platformType)) {
                        if (formikConnectionIdx < 0) {
                          formik.values.connections.push({
                            platformId: connection.platformId,
                            channels: channels,
                          })
                          void formik.setFieldValue(`connections`, formik.values.connections)
                        } else {
                          void formik.setFieldValue(`connections[${formikConnectionIdx}].channels`, channels)
                        }
                      } else {
                        if (formikConnectionIdx < 0) {
                          formik.values.connections.push({
                            platformId: connection.platformId,
                            channels: [],
                            bound: bound,
                          })
                          void formik.setFieldValue(`connections`, formik.values.connections)
                        } else {
                          void formik.setFieldValue(`connections[${formikConnectionIdx}].bound`, bound)
                        }
                      }
                      void formik.setFieldTouched(`connections`)
                    }}
                  />
                ),
              }
            })}
          />
        </FormItemSC>
      </Form>
      {selectedConnections && (
        <PlatformSettings
          platformType={selectedConnections.platformType}
          values={getPlatformFromDeployment(deployment, selectedConnections.platformId)}
          isOpened={isPlatformSettingsOpened}
          onClose={() => setPlatformSettingsOpened(false)}
        />
      )}
    </>
  )
}
