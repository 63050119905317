import { ConnectedTeamsOrganizations } from './CloudTeamsContent'
import { TeamChannel } from './channels/CloudTeamsChannelsTableChannels'
import { getTeamsFromOrganization } from '../../../../utils/platforms'
import { FormikConfig } from 'formik'
import {
  CloudTeamsFormValues,
  platformDefaultValue,
} from '../../../../pages/clusters/add/steps/platformsConfiguration/utils'
import { PlatformsType } from '../../../../models/platform/platform'
import * as yup from 'yup'
import { AnySchema } from 'yup'
import { ValidationMsg } from '../../../../utils/validation'
import { CloudMsTeams } from '../../../../models/graphql'
import { HandlePlatformFormSubmit } from '../../../../components/platform/settings/utils'

type TeamsInConnectedTeams = NonNullable<ConnectedTeamsOrganizations>[number]['teams']
export type TeamInConnectedTeams = NonNullable<TeamsInConnectedTeams>[number]
export type TeamInConnectedTeamsOption = ReturnType<typeof getTeamsOptionsForSelector>[number]

export const getTeamsOptionsForSelector = (connectedTeams: ConnectedTeamsOrganizations) => {
  if (!connectedTeams) {
    return []
  }
  return getTeamsFromOrganization(connectedTeams).map(team => ({
    label: team.name,
    value: team.aadGroupId,
  }))
}

export const getChannelsFromTeam = (teams: TeamInConnectedTeams[], aadGroupId: string): TeamChannel[] => {
  const team = teams.find(team => team.aadGroupId === aadGroupId)
  if (!team?.channels) {
    return []
  }

  return team.channels
}

type Props = {
  values?: CloudMsTeams
  onSubmit: HandlePlatformFormSubmit
}
export const initFormik = ({ values, onSubmit }: Props): FormikConfig<CloudTeamsFormValues> => {
  const initialValues: CloudTeamsFormValues = values
    ? {
        id: values.id,
        name: values.name,
        aadGroupId: values.aadGroupId,
        attachmentStorage: {
          sharePointSiteName: values.attachmentStorage.sharePointSiteName,
          useDefaultLocation: values.attachmentStorage.sharePointSiteName === '',
        },
        channelNames: values.channels.map(channel => channel.id),
      }
    : { ...(platformDefaultValue[PlatformsType.CLOUD_TEAMS] as CloudTeamsFormValues), id: '' }

  return {
    enableReinitialize: true,
    initialValues,
    validationSchema: yup.object().shape<Record<keyof CloudTeamsFormValues, AnySchema>>({
      id: yup.string(),
      name: yup.string().required(ValidationMsg.REQUIRED),
      aadGroupId: yup.string().required(ValidationMsg.REQUIRED),
      attachmentStorage: yup.object().shape({
        useDefaultLocation: yup.boolean(),
        sharePointSiteName: yup.string().when('useDefaultLocation', {
          is: false,
          then: yup.string().required(ValidationMsg.REQUIRED),
        }),
      }),
      channelNames: yup
        .array()
        .of(yup.string())
        .min(1, 'At least one team must be selected')
        .required(ValidationMsg.REQUIRED),
    }),
    onSubmit: values => {
      onSubmit(PlatformsType.CLOUD_TEAMS, values)
    },
  }
}
