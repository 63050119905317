import styled from 'styled-components'
import { Input, Tag } from 'antd'

export const InputTagSC = styled(Input)`
  width: 200px;
  height: 32px;
  vertical-align: top;
`

export const AddTagSC = styled(Tag)`
  display: flex;
  align-items: center;
  background: #eee;
  height: 32px;
  border-style: dashed;
`

export const TagSC = styled(Tag)`
  display: flex;
  align-items: center;
  height: 32px;
`
