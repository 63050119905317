import { Form, Input, Row } from 'antd'
import { FC, useState } from 'react'
import { FormikProps } from 'formik'
import { getValidationError, getValidationStatus } from '../../../../utils/formik/formik'
import { DiscordFormValues } from '../../../../pages/clusters/add/steps/platformsConfiguration/utils'
import { ContentType } from '../../../../services/contentful/types'
import { PlatformContentInstruction } from '../../../../pages/clusters/add/steps/platformsConfiguration/contents/PlatformContentInstruction'
import { ContentColumnRight } from '../../../../pages/clusters/add/steps/platformsConfiguration/contents/columns/ContentColumnRight'
import { PlatformEmbeddedDocs } from '../../../../pages/clusters/add/steps/platformsConfiguration/contents/PlatformEmbeddedDocs'
import { ContentColumnLeft } from '../../../../pages/clusters/add/steps/platformsConfiguration/contents/columns/ContentColumnLeft'
import { InputTag } from '../../../../components/inputTag/InputTag'

export type DiscordInstanceFormValues = Omit<DiscordFormValues, 'channelNames'>

export type DiscordInstanceFormProps = {
  formik: FormikProps<DiscordFormValues>
}

export const DiscordInstanceForm: FC<DiscordInstanceFormProps> = ({ formik }) => {
  const [tokenVisible, setTokenVisible] = useState(false)

  return (
    <Row>
      <ContentColumnLeft>
        <PlatformContentInstruction platformInstructionType={ContentType.DISCORD_PLATFORM_INSTRUCTION} />
        <Form layout={'vertical'}>
          <Form.Item
            label={'Display Name'}
            required={true}
            validateStatus={getValidationStatus(formik.errors.name, formik.touched.name)}
            help={getValidationError(formik.errors.name, formik.touched.name)}
          >
            <Input
              name={`name`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              placeholder={'My Discord Name'}
            />
          </Form.Item>
          <Form.Item
            label={'BotID'}
            required={true}
            validateStatus={getValidationStatus(formik.errors.botId, formik.touched.botId)}
            help={getValidationError(formik.errors.botId, formik.touched.botId)}
          >
            <Input
              name={`botId`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.botId}
              placeholder={'1004652664149254188'}
            />
          </Form.Item>
          <Form.Item
            label={'Token'}
            required={true}
            validateStatus={getValidationStatus(formik.errors.token, formik.touched.token)}
            help={getValidationError(formik.errors.token, formik.touched.token)}
          >
            <Input.Password
              visibilityToggle={{ visible: tokenVisible, onVisibleChange: setTokenVisible }}
              name={`token`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.token}
              placeholder={'MTAwNDY1MjY2NDE0OTI1NDE3NQ.G6CfuQ.rLKeSGQxGBzVEMsrclA6MHgLy9uuujdjdye'}
            />
          </Form.Item>
          <Form.Item
            label={'Channel Name'}
            required={true}
            validateStatus={getValidationStatus(formik.errors.channelNames as string, formik.touched.channelNames)}
            help={getValidationError(formik.errors.channelNames as string, formik.touched.channelNames)}
          >
            <InputTag
              prefix={undefined}
              values={formik.values.channelNames}
              placeholder={'New channel'}
              onChange={channels => formik.setFieldValue(`channelNames`, channels)}
            />
          </Form.Item>
        </Form>
      </ContentColumnLeft>
      <ContentColumnRight>
        <PlatformEmbeddedDocs platformInstructionType={ContentType.DISCORD_PLATFORM_INSTRUCTION} />
      </ContentColumnRight>
    </Row>
  )
}
