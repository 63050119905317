import styled from 'styled-components'
import { breakpoints } from '../../styles/globalStyles'

export const HeaderSC = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;

  .logo-link {
    display: flex;
  }

  .logo {
    width: 142px;
    flex-shrink: 0;
  }

  h4 {
    margin: 0;
  }

  @media (min-width: ${breakpoints.lgMin}px) {
    padding: 0;
  }
`
