import { flatten } from 'lodash'
import { PlatformsType } from '../../../../../models/platform/platform'
import { PluginType } from '../../../../../models/graphql'
import { RJSFSchema } from '@rjsf/utils'
import { generateConfigName, getDefaultsFromSchema, isSchemaValid } from '../../../../../utils/plugins'
import { PlatformsInPluginStep, PluginTemplateInPluginStep } from './PluginsStep'
import { PlatformsChannels } from '../../../../../utils/platforms'
import { getBindingChannels } from '../../../../../components/plugin/wizardCard/utils'

export const createEnabledDefaultPlugins = (
  platforms: PlatformsInPluginStep[],
  pluginsRecommended: PluginTemplateInPluginStep[],
  platformsChannels: PlatformsChannels,
) => {
  return flatten(
    platforms.map(platform => {
      return pluginsRecommended
        .filter(pluginItem =>
          [PlatformsType.WEBHOOK, PlatformsType.ELASTIC_SEARCH].includes(platform.platform)
            ? pluginItem.type === PluginType.Source
            : true,
        )
        .map(plugin => {
          const schema = plugin.schema as RJSFSchema
          const defaultVal = getDefaultsFromSchema(schema) ?? {}

          return {
            configurationName: generateConfigName(plugin.name),
            configuration: JSON.stringify(defaultVal),
            enabled: true,
            name: plugin.name,
            type: plugin.type,
            bindings: {
              [platform.platformId]: {
                channels: getBindingChannels(platform.platform, platformsChannels[platform.platform]?.[platform.idx]),
                platform: platform.platform,
                platformIdx: platform.idx,
                platformId: platform.platformId,
              },
            },
            displayName: plugin.title,
            invalid: !isSchemaValid(schema, defaultVal),
          }
        })
    }),
  )
}
