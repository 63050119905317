import styled from 'styled-components'
import { Avatar, Button, Menu, MenuProps as MenuPropsAntd, Spin, Tag } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import { MenuOutlined } from '@ant-design/icons'
import { breakpoints } from '../../styles/globalStyles'

export const LogoutButtonSC = styled(Button)`
  width: 100%;
  color: inherit;
  text-align: left;
  position: static;

  :after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`

export const MenuNavSC = styled.nav`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;

  .ant-menu {
    li:nth-child(5) {
      padding-right: 0;

      &::after {
        right: 0;
      }
    }
  }
`

interface MenuProps extends MenuPropsAntd {
  isMobileOpen: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const MenuMainSC = styled(({ isMobileOpen, ...rest }: MenuProps) => <Menu {...rest} />)<MenuProps>`
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  display: ${({ isMobileOpen }: MenuProps) => (isMobileOpen ? 'block' : 'none')};
  ${({ isMobileOpen }: MenuProps) =>
    isMobileOpen
      ? `
        position: fixed;
        top: 64px;
        left: 0;
        width: 100vw;
        z-index: 100;
      `
      : ''};

  @media (min-width: ${breakpoints.lgMin}px) {
    display: flex;
  }
`

export const MobileMenuButtonSC = styled(MenuOutlined)`
  font-size: 2rem;
  display: block;

  @media (min-width: ${breakpoints.lgMin}px) {
    display: none;
  }
`

export const SpinnerSC = styled(Spin)`
  display: block;
  line-height: 40px;
  margin: 0 auto;
`

export const OrgNameSC = styled.span`
  padding: 0 5px;
`

export const CaretDownOutlinedSC = styled(CaretDownOutlined)`
  position: relative;
  top: 1px;
`

const borderColor = '#ccc'
const menuHeight = '64px'
const dropdownHeight = '42px'

export const OrgDropdownWrapperSC = styled.div`
  width: 100%;
  display: block;
  height: ${menuHeight};
  line-height: ${menuHeight};

  @media (max-width: ${breakpoints.lgMin}px) {
    height: auto;
    line-height: inherit;
  }
`

export const OrgDropdownSC = styled.div`
  border: 1px solid ${borderColor};
  padding: 0 8px;
  height: ${dropdownHeight};
  line-height: calc(${dropdownHeight} - 2px);
  position: relative;
  top: calc((${menuHeight} - ${dropdownHeight}) / 2);
  border-radius: 8px;

  @media (max-width: ${breakpoints.lgMin}px) {
    top: 0;
    position: static;
    border: 0;
    height: 40px;
    line-height: 40px;
    padding: 0;
  }
`

export const AvatarSC = styled(Avatar)`
  position: relative;
  top: -1px;
  display: inline-block;
`

export const TagSC = styled(Tag)`
  margin-right: 0;
  margin-left: 5px;
  border-radius: 5px;
  border: 0;
  color: #fff;
  background: linear-gradient(135deg, rgb(23 101 243), rgb(255 85 0));
`
