import { createBrowserRouter } from 'react-router-dom'

import { HomePage } from '../pages/home/HomePage'
import { paths } from './paths'
import { AuditLogsPage } from '../pages/auditLogs/AuditLogsPage'
import { DeploymentPage } from '../pages/clusters/DeploymentPage'
import { AliasesPage } from '../pages/aliases/AliasesPage'
import { ClusterEventsPage } from '../pages/clusters/events/ClusterEventsPage'
import { DeploymentAddPage } from '../pages/clusters/add/DeploymentAddPage'
import { LoginPage } from '../pages/login/LoginPage'
import { OrganizationPage } from '../pages/organization/OrganizationPage'
import { SuccessEmailVerification } from '../pages/login/SuccessEmailVerification'
import type { Router as RemixRouter } from '@remix-run/router'
import { SubscriptionPlansPage } from '../pages/subscriptionplans/SubscriptionPlansPage'
import { Unauthenticated } from '../pages/login/Unauthenticated'
import { SlackConnectionPage } from '../pages/organization/platforms/SlackConnection'
import { Page404 } from '../pages/404/404Page'
import LoginCLIPage from '../pages/login/cli/LoginPage'
import { CloudSlackReinstallPage } from '../pages/platforms/cloudSlack/CloudSlackReinstalled'
import { TeamsConnectionPage } from '../pages/organization/platforms/TeamsConnection'
import { TeamsConsentPage } from '../pages/organization/platforms/TeamsConsent'
import { SlackConnectionError } from '../pages/organization/platforms/SlackConnectionError'
import { AIAssistantPage } from '../pages/ai-assistant/AIAssistantPage'
import { SlackConnectionSuccess } from '../pages/organization/platforms/SlackConnectionSuccess'
import { MachineConnectionPage } from '../pages/fuse/MachineConnection'
import { FusePage } from '../pages/fuse/FusePage'

export const getRoutesForAuthorizedUser = function (): RemixRouter {
  const routes = [
    {
      path: paths.LOGIN,
      element: <LoginPage />,
    },
    {
      path: paths.CLI_CLOUD_LOGIN,
      element: <LoginCLIPage />,
    },
    {
      path: paths.EMAIL_VERIFIED,
      element: <SuccessEmailVerification />,
    },
    {
      path: paths.HOME,
      element: <HomePage />,
    },
    {
      path: paths.DEPLOYMENT,
      element: <DeploymentPage />,
    },
    {
      path: paths.DEPLOYMENT_ADD,
      element: <DeploymentAddPage />,
    },
    {
      path: paths.DEPLOYMENT_ADD_RETURN,
      element: <DeploymentAddPage />,
    },
    // DEPLOYMENT_ADD_BUILDER is disabled, it's too much effort to keep typescript updated if we don't use it.
    // {
    //   path: paths.DEPLOYMENT_ADD_BUILDER,
    //   element: <DeploymentAddBuilderPage />,
    // },
    {
      path: paths.DEPLOYMENT_EVENTS,
      element: <ClusterEventsPage />,
    },
    {
      path: paths.AI_ASSISTANT,
      element: <AIAssistantPage />,
    },
    {
      path: paths.FUSE,
      element: <FusePage />,
    },
    {
      path: paths.ALIASES,
      element: <AliasesPage />,
    },
    {
      path: paths.AUDIT_LOGS,
      element: <AuditLogsPage />,
    },
    {
      path: paths.ORGANIZATION,
      element: <OrganizationPage />,
    },
    {
      path: paths.SUBSCRIPTION_PLANS,
      element: <SubscriptionPlansPage />,
    },
    {
      path: paths.CONNECT_SLACK_WORKSPACE,
      element: <SlackConnectionPage />,
    },
    {
      path: paths.REINSTALLED_SLACK_WORKSPACE,
      element: <CloudSlackReinstallPage />,
    },
    {
      path: paths.CONNECT_TEAMS_TEAM,
      element: <TeamsConnectionPage />,
    },
    {
      path: paths.GIVE_CONSENT_TEAMS_TEAM,
      element: <TeamsConsentPage authenticated={true} />,
    },
    {
      path: paths.SUCCESSFUL_SLACK_WORKSPACE_CONNECTION,
      element: <SlackConnectionSuccess />,
    },
    {
      path: paths.FAILED_SLACK_WORKSPACE_CONNECTION,
      element: <SlackConnectionError />,
    },
    {
      path: paths.CONNECT_MACHINE,
      element: <MachineConnectionPage />,
    },
    {
      path: '*',
      element: <Page404 />,
    },
  ]

  return createBrowserRouter(routes)
}

export const getRoutesForUnauthorizedUser = createBrowserRouter([
  {
    path: '/*',
    element: <Unauthenticated />,
  },
  {
    path: paths.GIVE_CONSENT_TEAMS_TEAM,
    element: <TeamsConsentPage authenticated={false} />,
  },
  {
    path: paths.SUCCESSFUL_SLACK_WORKSPACE_CONNECTION,
    element: <SlackConnectionSuccess />,
  },
  {
    path: paths.FAILED_SLACK_WORKSPACE_CONNECTION,
    element: <SlackConnectionError />,
  },
])
