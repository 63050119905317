import { FC } from 'react'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, Space } from 'antd'
import { useNavigate } from 'react-router-dom'
import { animals, colors, uniqueNamesGenerator } from 'unique-names-generator'
import { AddCardDeploymentSC, VariantsWrapper } from './styles'
import { paths } from '../../router/paths'
import { useCreateDraftDeploymentMutation } from '../../models/graphql'

type Props = {
  disabled?: boolean
  init?: boolean
}
export const AddCardDeployment: FC<Props> = ({ disabled, init }) => {
  const navigate = useNavigate()
  const { mutate: createDraftDeployment, isLoading } = useCreateDraftDeploymentMutation({
    onSuccess: data => {
      navigate(paths.DEPLOYMENT_ADD.replace(':id', data.createDeployment.id))
    },
  })

  const handleCreateEmptyDeployment = () => {
    if (disabled) {
      return
    }

    createDraftDeployment({
      input: {
        name: uniqueNamesGenerator({
          dictionaries: [colors, animals],
        }),
        draft: true,
      },
    })
  }

  if (init) {
    return (
      <Button
        type={'primary'}
        disabled={disabled}
        onClick={handleCreateEmptyDeployment}
      >
        Create an Instance
      </Button>
    )
  }

  return (
    <AddCardDeploymentSC
      onClick={handleCreateEmptyDeployment}
      disabled={disabled}
    >
      <Space style={{ height: '100%' }}>
        {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
        <VariantsWrapper>
          <div>
            <h6 id={'create-instance'}>Create Instance</h6>
            <p>Create a new instance using step wizard</p>
          </div>
        </VariantsWrapper>
      </Space>
    </AddCardDeploymentSC>
  )
}
