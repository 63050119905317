import { FormikConfig } from 'formik'
import * as yup from 'yup'
import { AnySchema } from 'yup'
import { InstanceNameFormValues, Props } from './InstanceName'
import { ValidationMsg } from '../../../utils/validation'

export const initFormik = (props: Props): FormikConfig<InstanceNameFormValues> => ({
  enableReinitialize: true,
  initialValues: {
    name: props.name,
  },
  validationSchema: yup.object().shape<Record<keyof InstanceNameFormValues, AnySchema>>({
    name: yup.string().required(ValidationMsg.REQUIRED),
  }),
  onSubmit: values => {
    props.onSubmit(values)
  },
})
