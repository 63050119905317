import { CheckCircleFilled, CloseCircleFilled, StopOutlined } from '@ant-design/icons'
import { theme } from 'antd'
import React, { FC } from 'react'
import { useAppSelector } from '../../../store/store'
import { selectPluginHealth } from '../../../store/slices/deployment/selectors'
import { WrapWithOptionalTooltip } from '../../tooltip/WrapWithOptionalTooltip'

type Props = {
  enabled: boolean
  name: string
  configName: string
}
export const PluginHealthIndicator: FC<Props> = ({ configName, enabled, name }) => {
  const pluginHealth = useAppSelector(state => selectPluginHealth(state, configName))

  const renderIndicator = (enabled: boolean) => {
    if (pluginHealth && pluginHealth.status?.toLowerCase() !== 'running') {
      return (
        <WrapWithOptionalTooltip
          tooltip={`Status: ${pluginHealth.status ? pluginHealth.status : 'Unknown'}, Restarts: ${
            pluginHealth.restarts ? pluginHealth.restarts : 'Unknown'
          }`}
        >
          <CloseCircleFilled style={{ color: theme.getDesignToken().colorError }} />
        </WrapWithOptionalTooltip>
      )
    }
    if (enabled) {
      return <CheckCircleFilled style={{ color: theme.defaultSeed.colorSuccess }} />
    }

    return <StopOutlined />
  }

  return (
    <>
      {renderIndicator(enabled)} {name}
    </>
  )
}
