import { FC, useContext, useState } from 'react'
import { Alert, Breadcrumb, message, Spin, Table } from 'antd'
import { GlobalStyle } from '../../styles/globalStyles'
import {
  useGenerateBillingPortalUrlMutation,
  useGenerateCheckoutUrlMutation,
  usePlansQuery,
} from '../../models/graphql'
import { ReasonsToUpgrade } from './ReasonsToUpgrade'
import { MainLayout } from '../../layouts/main/MainLayout'
import { addCustomPlanToSubscriptionPlans, getColumns, getTableSummary, PlanColumns, planData } from './planData'
import { Link } from 'react-router-dom'
import { paths } from '../../router/paths'
import moment from 'moment'
import { AppCtx } from '../../providers/appCtx'
import { usePageTitle } from '../../utils/customeHooks/usePageTitle'
import { useContent } from '../../utils/customeHooks/useContent'
import { ContentType, SubscriptionFeatureSkeleton } from '../../services/contentful/types'
import { BillingPortalLink } from '../../components/billingPortalLink/BillingPortalLink'

export const SubscriptionPlansPage: FC = () => {
  usePageTitle('Subscription Plans')
  const { orgID } = useContext(AppCtx)
  const content = useContent<SubscriptionFeatureSkeleton[]>(ContentType.SUBSCRIPTION_FEATURE, true) ?? []
  const { data, isLoading } = usePlansQuery({ orgID: orgID })
  const { mutateAsync: checkoutMutateAsync, isLoading: checkoutIsLoading } = useGenerateCheckoutUrlMutation()
  const { mutateAsync: billingMutateAsync, isLoading: billingIsLoading } = useGenerateBillingPortalUrlMutation()
  const redirectToBillingPortal = async () => {
    try {
      const result = await billingMutateAsync({})
      setRedirectInProgress(true)
      window.location.replace(result.generateBillingPortalURL)
    } catch (err: unknown) {
      void message.error((err as Error).toString())
    }
  }

  const [redirectInProgress, setRedirectInProgress] = useState(false)

  const isOnTrial = data?.organization?.subscription.invoice?.isOnTrial
  const endOfTrialDate = moment(data?.organization?.subscription.invoice?.endOfTrialDate)
  const trialConsumed = data?.organization?.subscription.trialConsumed ?? isOnTrial ?? true
  const currentPlanName = data?.organization?.subscription.planName
  const isFreePlan = data?.organization?.subscription.isDefaultPlan

  if (redirectInProgress) {
    return (
      <>
        <GlobalStyle />
        <Spin
          tip={'Redirecting to Stripe...'}
          fullscreen={true}
        />
      </>
    )
  }

  if (isLoading) {
    return (
      <>
        <GlobalStyle />
        <Spin
          tip={'Loading plans...'}
          fullscreen={true}
        />
      </>
    )
  }

  const checkout = async (planName: string) => {
    try {
      const result = await checkoutMutateAsync({ planName })
      setRedirectInProgress(true)
      window.location.replace(result.generateCheckoutURL)
    } catch (err: unknown) {
      void message.error((err as Error).toString())
    }
  }

  const plans = addCustomPlanToSubscriptionPlans(data?.subscriptionPlans)

  const tableSummary = getTableSummary(
    plans as PlanColumns,
    checkout,
    redirectToBillingPortal,
    checkoutIsLoading || billingIsLoading,
    trialConsumed,
    currentPlanName,
  )

  return (
    <MainLayout>
      {renderBreadcrumb(orgID, data?.organization?.displayName)}
      {isOnTrial && (
        <Alert
          message={`You are on trial that expires ${endOfTrialDate.fromNow()}.`}
          type={trialInfoType(endOfTrialDate)}
        />
      )}
      <h1>Manage Subscription</h1>
      {isFreePlan ? (
        <>
          <h2>Upgrade your plan</h2>
          <p>Upgrade your plan to get access to the premium features:</p>
        </>
      ) : (
        <>
          <h2>Thank you for being a subscriber.</h2>
          <p>You have access to all premium features:</p>
        </>
      )}
      <ReasonsToUpgrade content={content} />
      <h2 className={'mt-20'}>Compare plans</h2>
      <Table
        columns={getColumns(plans as PlanColumns)}
        dataSource={planData(content)}
        pagination={false}
        bordered
        summary={() => tableSummary}
        className={'mb-40'}
      />
      <p>Manage payment, current subscriptions and see the billing history in the Billing Portal:</p>
      <BillingPortalLink billingHistoryAvailable={data?.organization?.billingHistoryAvailable ?? false} />
    </MainLayout>
  )
}

function renderBreadcrumb(orgID: string, orgName?: string) {
  return (
    <Breadcrumb
      className={'mb-40'}
      items={[
        { title: <Link to={paths.HOME}>Home</Link> },
        { title: <Link to={paths.ORGANIZATION.replace(':id', orgID)}>{orgName ?? 'Organization'}</Link> },
        { title: 'Manage Subscription' },
      ]}
    />
  )
}

function trialInfoType(trial: moment.Moment) {
  const diffDays = trial.diff(new Date(), 'days')

  if (diffDays < 3) {
    return 'error'
  }
  if (diffDays < 7) {
    return 'warning'
  }
  return 'success'
}
