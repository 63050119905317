import styled from 'styled-components'
import { theme } from 'antd'

type PlatformChannelsBadgeSCProps = {
  bound: boolean
}
export const PlatformChannelsBadgeSC = styled.div<PlatformChannelsBadgeSCProps>`
  background: ${props => (props.bound ? '#F6FFED' : '#F5F5F5')};
  border: 1px solid ${props => (props.bound ? '#52C41A' : '#D9D9D9')};
  border-radius: 14px;
  padding: 0 6px;
  color: ${theme.getDesignToken().colorTextDescription};
`
