import { PriceSC, TagSC } from './styles'
import { ReactNode } from 'react'
import { ColumnsType } from 'antd/lib/table'
import { Button, Table, Tooltip } from 'antd'
import { PlansQuery } from '../../models/graphql'
import { SubscriptionFeatureSkeleton } from '../../services/contentful/types'

export function addCustomPlanToSubscriptionPlans(subscriptionPlans?: PlansQuery['subscriptionPlans']) {
  return [...(subscriptionPlans ?? []), CUSTOM_PLAN]
}

export const CUSTOM_PLAN = {
  name: 'ENTERPRISE',
  displayName: 'Enterprise',
  isDefault: 0,
}

export interface DataType {
  key: string
  feature: string
  comingSoon: boolean

  [key: string]: boolean | string | number | ReactNode
}

export const planData = (content: SubscriptionFeatureSkeleton[]): DataType[] =>
  content
    .sort((itemA, itemB) => itemA.fields.order - itemB.fields.order)
    .map(contentItem => ({
      key: contentItem.fields.planData?.key ?? contentItem.fields.title,
      feature: contentItem.fields.title,
      comingSoon: contentItem.fields.comingSoon,
      FREE: contentItem.fields.planData?.FREE,
      TEAM: contentItem.fields.planData?.TEAM,
      TEAM_PLUS: contentItem.fields.planData?.TEAM_PLUS,
      ENTERPRISE: contentItem.fields.planData?.ENTERPRISE,
    }))

export type PlanColumns = [
  {
    displayName: string
    name: string
    isDefault: boolean
    trialPeriodDays?: number
    displayUnitPrice?: number
  },
]

export function getColumns(plans: PlanColumns): ColumnsType<DataType> {
  const columns: ColumnsType<DataType> = [
    {
      title: 'Feature',
      dataIndex: 'feature',
      fixed: true,
      width: 450,
      render: (value: string | ReactNode, row) => {
        return (
          <>
            {value} {row.comingSoon && <TagSC color={'processing'}>Coming soon</TagSC>}
          </>
        )
      },
    },
  ]

  for (const plan of plans) {
    columns.push({
      title: plan.displayName,
      dataIndex: plan.name,
      fixed: false,
      render: (value: string | number | boolean) => {
        if (typeof value === 'boolean') {
          return value ? '✅' : ''
        }

        return value
      },
    })
  }

  return columns
}

export function getTableSummary(
  plans: PlanColumns,
  checkout: (planName: string) => Promise<void>,
  redirectToBillingPortal: () => Promise<void>,
  isLoading: boolean,
  trialConsumed: boolean,
  currentPlanName?: string,
): ReactNode {
  const activePlanIdx = plans.findIndex(plan => plan.name === currentPlanName)
  const getPlanSummaryCell = (currentIndex: number, priceDisplay: string, actionButton: ReactNode) => {
    const idx = currentIndex + 1
    return (
      <Table.Summary.Cell
        key={idx}
        index={idx}
      >
        <PriceSC>{priceDisplay}</PriceSC>
        {actionButton}
      </Table.Summary.Cell>
    )
  }

  const cells = plans.map((plan, idx) => {
    const defaultPriceDisplay = `${plan.displayUnitPrice ?? 0}$ / node / month`

    // Case 1: Custom plan - open live chat on click
    if (plan.name === CUSTOM_PLAN.name) {
      const customPricing = 'Custom pricing'
      const actionBtn = (
        <Button
          type={'primary'}
          disabled={isLoading}
          block
          size={'large'}
          href={'#hs-chat-open'}
        >
          Enquire
        </Button>
      )
      return getPlanSummaryCell(idx, customPricing, actionBtn)
    }

    // Case 2: Active plan - disable button
    if (activePlanIdx === idx) {
      const actionBtn = (
        <Button
          type={'default'}
          disabled={true}
          block
          size={'large'}
        >
          Active plan
        </Button>
      )
      return getPlanSummaryCell(idx, defaultPriceDisplay, actionBtn)
    }

    // Case 3: This is default (free) plan, but currently not active. Show downgrade button with link to Billing portal
    if (plan.isDefault) {
      const actionBtn = (
        <Tooltip title={'To downgrade, navigate to the Billing Portal and cancel your current subscription.'}>
          <Button
            type={'default'}
            block
            size={'large'}
            disabled={isLoading}
            onClick={() => void redirectToBillingPortal()}
          >
            Downgrade
          </Button>
        </Tooltip>
      )
      return getPlanSummaryCell(idx, defaultPriceDisplay, actionBtn)
    }

    // Case 4: Paid plan, but currently rendering tier below currently selected - show "Downgrade" button with checkout mutation
    if (activePlanIdx > idx) {
      const actionBtn = (
        <Button
          type={'default'}
          block
          size={'large'}
          disabled={isLoading}
          onClick={() => void checkout(plan.name)}
        >
          Downgrade
        </Button>
      )
      return getPlanSummaryCell(idx, defaultPriceDisplay, actionBtn)
    }

    // Case 5: Paid plan, but tier above currently selected and trial not yet consumed
    if (!trialConsumed && plan.trialPeriodDays) {
      const actionBtn = (
        <Button
          type={'primary'}
          block
          size={'large'}
          disabled={isLoading}
          onClick={() => void checkout(plan.name)}
        >
          Start {plan.trialPeriodDays}-day Free Trial
        </Button>
      )
      return getPlanSummaryCell(idx, defaultPriceDisplay, actionBtn)
    }

    // Case 6: Paid plan, but tier above currently selected and trial consumed
    const actionBtn = (
      <Button
        type={'primary'}
        block
        size={'large'}
        disabled={isLoading}
        onClick={() => void checkout(plan.name)}
      >
        Subscribe
      </Button>
    )
    return getPlanSummaryCell(idx, defaultPriceDisplay, actionBtn)
  })

  return (
    <Table.Summary>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} />
        {cells}
      </Table.Summary.Row>
    </Table.Summary>
  )
}
