import styled from 'styled-components'
import { Card } from 'antd'
import { breakpoints } from '../../../styles/globalStyles'

export const PlatformSC = styled(Card)`
  .ant-card-head {
    padding: 0 14px;

    @media (min-width: ${breakpoints.lgMin}px) {
      padding: 0 18px;
    }
  }
  .ant-card-head-title {
    display: flex;
    align-items: center;

    svg {
      height: 16px;
      margin-right: 4px;
      flex-shrink: 0;

      @media (min-width: ${breakpoints.lgMin}px) {
        height: 32px;
        margin-right: 16px;
      }
    }
  }
`
