import React, { FC } from 'react'
import { Button, message, Space } from 'antd'
import { discardDeploymentChanges } from '../../../store/slices/deployment/deployment'
import { ReasonForBlockUpdateTooltip } from '../../tooltip/ReasonForBlockUpdateTooltip'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { createDeploymentUpdateInput } from '../../../utils/deployment'
import { useUpdateDeploymentMutation } from '../../../models/graphql'

type Props = {
  isDeploymentClean: boolean
  isOutdated: boolean
  needsUpgrade?: boolean
  fetchDeployment: () => Promise<unknown>
  onUpgradeDeployment: () => void
}
export const ExtraActions: FC<Props> = ({
  onUpgradeDeployment,
  isDeploymentClean,
  isOutdated,
  needsUpgrade,
  fetchDeployment,
}) => {
  const dispatch = useAppDispatch()
  const deployment = useAppSelector(state => state.deployment.data)
  const deploymentUpdateBlock = useAppSelector(state => state.deployment.updateBlock)
  const isDeploymentUpdateValid = useAppSelector(state => state.deployment.isUpdateValid)

  const { mutate: updateDeployment, isLoading: isUpdateLoading } = useUpdateDeploymentMutation({
    onSuccess: () => {
      void fetchDeployment()
      void message.success('Changes successfully saved!')
    },
  })

  const handleUpdateDeployment = () => {
    if (deployment) {
      updateDeployment({ id: deployment.id, input: createDeploymentUpdateInput(deployment) })
    }
  }

  if (!isDeploymentClean || isOutdated) {
    return (
      <Space>
        <Button
          onClick={() => dispatch(discardDeploymentChanges())}
          disabled={isDeploymentClean}
          danger={true}
        >
          Discard changes
        </Button>
        <ReasonForBlockUpdateTooltip>
          <Button
            type={'primary'}
            onClick={handleUpdateDeployment}
            disabled={!isDeploymentUpdateValid || deploymentUpdateBlock.isBlocked}
            loading={isUpdateLoading}
          >
            Deploy changes
          </Button>
        </ReasonForBlockUpdateTooltip>
      </Space>
    )
  }

  if (needsUpgrade) {
    return (
      <ReasonForBlockUpdateTooltip>
        <Button
          type={'primary'}
          onClick={onUpgradeDeployment}
          disabled={!isDeploymentUpdateValid || deploymentUpdateBlock.org.isBlocked}
        >
          Upgrade
        </Button>
      </ReasonForBlockUpdateTooltip>
    )
  }

  return null
}
