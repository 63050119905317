import { FC } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { WithoutHeaderLayout } from '../../layouts/withoutHeader/WithoutHeaderLayout'
import { BotkubeSignInIllustration, SignInButton, UnauthenticatedContent, WelcomeTitle } from './styles'
import { paths } from '../../router/paths'
import { useSearchParams } from 'react-router-dom'
import { Button, Space } from 'antd'
import { usePageTitle } from '../../utils/customeHooks/usePageTitle'

export const SuccessEmailVerification: FC = () => {
  usePageTitle('Email Verification')
  const { loginWithRedirect } = useAuth0()
  const onAuthBtnClick: React.MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault()
    e.stopPropagation()
    void loginWithRedirect({
      appState: { targetUrl: paths.HOME },
    })
  }

  const [searchParams] = useSearchParams()

  const success = searchParams.has('success') && searchParams.get('success') === 'true'

  if (!success) {
    const message = searchParams.get('message') ?? 'A general error occurred. Contact support to get help'
    return (
      <WithoutHeaderLayout contentClass={'center'}>
        <UnauthenticatedContent>
          <BotkubeSignInIllustration />
          <WelcomeTitle>An error occurred</WelcomeTitle>
          <p>{message}.</p>
          <Space align='center'>
            <Button
              type={'primary'}
              onClick={onAuthBtnClick}
            >
              Sign in
            </Button>
            <Button href='mailto:frontdesk@kubeshop.io'>Contact Support</Button>
          </Space>
        </UnauthenticatedContent>
      </WithoutHeaderLayout>
    )
  }

  return (
    <WithoutHeaderLayout contentClass={'center'}>
      <UnauthenticatedContent>
        <BotkubeSignInIllustration />
        <WelcomeTitle>Email Verified</WelcomeTitle>
        <p>And yeah, we are sorry but you need to sign in again…</p>
        <SignInButton
          type={'primary'}
          onClick={onAuthBtnClick}
        >
          Sign in
        </SignInButton>
      </UnauthenticatedContent>
    </WithoutHeaderLayout>
  )
}
