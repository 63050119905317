import { FC } from 'react'
import { Button, Divider, Drawer, Space, Typography } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

import { PlatformsType, PlatformsTypeNames, PlatformsValues } from '../../../models/platform/platform'
import { initFormikForPlatform, mapPlatformTypeToDocs, renderPlatformSettingsForm } from './utils'
import { PlatformsFormValuesWithChannels } from '../../../pages/clusters/add/steps/platformsConfiguration/utils'
import { useAppDispatch } from '../../../store/store'
import { addPlatformToDeployment, updatePlatformInDeployment } from '../../../store/slices/deployment/deployment'
import { mapPlatformTypeToIcon } from '../../../utils/platforms'
import { FormikConfig, FormikValues, useFormik } from 'formik'
import { DeletePlatform } from '../delete/DeletePlatform'
import { v4 } from 'uuid'
import { useViewport } from '../../../utils/customeHooks/useViewport'
import { breakpoints } from '../../../styles/globalStyles'

type Props = {
  platformType: PlatformsType
  values?: PlatformsValues
  isOpened: boolean
  onClose: () => void
}

export const PlatformSettings: FC<Props> = ({ isOpened, onClose, platformType, values }) => {
  const dispatch = useAppDispatch()
  const { width } = useViewport()

  const handleSubmitPlatformForm = (type: PlatformsType, formValues: PlatformsFormValuesWithChannels) => {
    if (!values?.id) {
      dispatch(
        addPlatformToDeployment({
          platformType,
          values: {
            ...formValues,
            id: v4(),
          },
        }),
      )

      return onClose()
    }

    dispatch(
      updatePlatformInDeployment({
        type,
        values: {
          ...formValues,
          id: values.id,
        },
      }),
    )
    onClose()
  }

  const formik = useFormik(
    initFormikForPlatform({
      type: platformType,
      values,
      onSubmit: handleSubmitPlatformForm,
    }) as unknown as FormikConfig<FormikValues>,
  )

  return (
    <Drawer
      title={
        <Space>
          {mapPlatformTypeToIcon(platformType, { height: '16px' })} {values?.name ?? PlatformsTypeNames[platformType]}
        </Space>
      }
      width={width < breakpoints.lgMin ? '100%' : '85%'}
      closeIcon={false}
      maskClosable={false}
      open={isOpened}
      destroyOnClose={true}
      onClose={onClose}
      extra={
        <Space>
          <Typography.Link
            href={mapPlatformTypeToDocs[platformType]}
            target={'_blank'}
          >
            <QuestionCircleOutlined /> {width < breakpoints.lgMin ? '' : 'Documentation'}
          </Typography.Link>
          <Button
            onClick={() => {
              onClose()
              formik.resetForm()
            }}
          >
            Cancel
          </Button>
          <Button
            type={'primary'}
            onClick={() => void formik.submitForm()}
          >
            Save
          </Button>
        </Space>
      }
    >
      <>
        {renderPlatformSettingsForm({
          formik,
          platformType,
        })}
        {values?.id && (
          <>
            <Divider />
            <DeletePlatform
              platformId={values.id}
              platformType={platformType}
              onDelete={onClose}
            />
          </>
        )}
      </>
    </Drawer>
  )
}
