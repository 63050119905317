import { FC, useEffect, useState } from 'react'
import Editor, { Monaco, OnMount, OnValidate } from '@monaco-editor/react'
import { RJSFSchema } from '@rjsf/utils'
import { stringify, parse } from 'yaml'
import { configureMonacoYaml, MonacoYaml } from 'monaco-yaml'
import { PluginConfigData } from '../../utils'
import { Alert } from 'antd'

type Props = {
  schema: RJSFSchema
  configuration: RJSFSchema
  onChange: (data?: PluginConfigData) => void
  onValidate: (errors: string[], data: PluginConfigData) => void
}

let monacoYamlInstance: MonacoYaml | null = null

export const YAMLForm: FC<Props> = ({ onValidate, onChange, configuration, schema }) => {
  const [yamlStr, setYamlStr] = useState<string | undefined>('')
  const [errors, setErrors] = useState<string[]>([])

  useEffect(() => {
    setYamlStr(stringify(configuration))
  }, [configuration])

  const handleSaveChange = (value: string) => {
    onChange(parse(value) as PluginConfigData)
  }

  const handleValidation: OnValidate = markers => {
    const validationErrors: string[] = []
    markers.forEach(marker => {
      validationErrors.push(`Line: ${marker.startLineNumber} - ${marker.message}`)
    })
    setErrors(validationErrors)
    onValidate(validationErrors, parse(yamlStr ?? ''))
  }

  const handleMount: OnMount = (editor, monaco) => {
    configMonaco(monaco, schema)

    editor.onDidBlurEditorWidget(() => {
      handleSaveChange(editor.getValue())
    })
  }

  return (
    <>
      {errors.map((error, idx) => (
        <Alert
          key={idx}
          type={'error'}
          message={error}
          className={'mb-10'}
        />
      ))}
      <Editor
        height='60vh'
        value={yamlStr}
        defaultLanguage='yaml'
        onMount={handleMount}
        onChange={setYamlStr}
        onValidate={handleValidation}
      />
    </>
  )
}

const configMonaco = (monaco: Monaco, schema: RJSFSchema) => {
  if (!monacoYamlInstance) {
    monacoYamlInstance = configureMonacoYaml(monaco)
  }
  monacoYamlInstance.update({
    enableSchemaRequest: false,
    validate: true,
    format: true,
    hover: true,
    completion: true,
    isKubernetes: true,
    schemas: [
      {
        uri: `inmemory://inmemory/schema.json`,
        fileMatch: ['*'],
        schema: schema,
      },
    ],
  })
}
