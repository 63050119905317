import styled from 'styled-components'
import { Form } from 'antd'

export const DivSC = styled.div<{ highlighted: boolean }>`
  padding: 6px 8px;
  ${props => props.highlighted && `background-color: #FAFAFA;`}
  margin-top: 6px;
`

export const FormItemSC = styled(Form.Item)`
  margin-bottom: 6px;
`

export const FormPartWrapperSC = styled.div`
  padding: 10px;
`

export const FormItemWithExtraSC = styled(Form.Item)`
  .ant-form-item-extra {
    margin-top: 4px;
  }
`
