import { FC } from 'react'
import { Tabs, Typography } from 'antd'
import { InstallationType } from '../../../../../../models/graphql'
import { DeploymentPage } from '../../../../utils'
import { OSIcon } from '../../../../../../components/osIcon/OSIcon'
import { ConnectTabContent } from './ConnectTabContent'
import { ConnectTabLabelSC } from './styles'

type Props = {
  deployment: DeploymentPage
  installationType: InstallationType
}
export const ConnectTabs: FC<Props> = ({ installationType, deployment }) => {
  return (
    <>
      <Typography.Title level={2}>
        {installationType === InstallationType.Cli ? 'Install using Botkube CLI' : 'Install using GitOps'}
      </Typography.Title>
      {installationType === InstallationType.Cli && (
        <Typography.Paragraph>
          Homebrew is the easiest way to install the CLI tool. Alternatively you can download the binary directly for
          your system.
        </Typography.Paragraph>
      )}
      <Tabs
        items={deployment.installUpgradeInstructions
          ?.filter(instruction => instruction.installationType === installationType)
          ?.map(instructItem => ({
            label: (
              <ConnectTabLabelSC>
                <OSIcon platformType={instructItem.platformType} />
                {instructItem.platformName}
              </ConnectTabLabelSC>
            ),
            key: instructItem.platformName,
            children: <ConnectTabContent instruction={instructItem} />,
          }))}
      />
    </>
  )
}
