import { FC, ReactNode } from 'react'
import { QueryClient, QueryClientProvider as ReactQueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()

type Props = {
  children: ReactNode
}
export const QueryClientProvider: FC<Props> = props => (
  <ReactQueryClientProvider client={queryClient}>{props.children}</ReactQueryClientProvider>
)
