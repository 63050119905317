import { GetPluginTemplatesQuery } from '../../../models/graphql'
import { PlatformChannelsConfig } from '../../../utils/platforms'
import { PlatformsType } from '../../../models/platform/platform'

export type PluginTemplate = GetPluginTemplatesQuery['pluginTemplates']['data'][number]

export const getBindingChannels = (
  platformType: PlatformsType,
  platformChannelsConfig?: PlatformChannelsConfig,
): string[] => {
  if (!platformChannelsConfig) {
    return []
  }
  if (platformType === PlatformsType.CLOUD_SLACK) {
    return platformChannelsConfig.meta?.slackChannels.map(slCh => slCh.id) ?? []
  }

  return platformChannelsConfig.channels
}
