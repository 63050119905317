import styled from 'styled-components'

type Props = {
  selected: boolean
}
export const ConnectLabelSC = styled.div<Props>`
  cursor: pointer;
  padding: 12px 20px;
  background: ${props => (props.selected ? '#E6F4FF' : 'transparent')};
  border-right: ${props => (props.selected ? '2px solid #1890ff' : 'none')};

  h2,
  h3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

export const ConnectTabLabelSC = styled.div`
  display: flex;
  gap: 0;
  justify-content: flex-start;
  align-items: center;

  svg {
    font-size: 20px;

    &.custom-icon {
      height: 20px;
    }
  }
`
