import { v4 } from 'uuid'
import validator from '@rjsf/validator-ajv8'
import randomString from 'random-string'
import { isEmpty } from 'lodash'

import { Maybe, PluginsUpdateInput, PluginType, PolicySubjectType, Rbac } from '../models/graphql'
import {
  instanceOfChannelBindingsById,
  instanceOfChannelBindingsByName,
  instanceOfChannelBindingsByNameAndId,
  instanceOfCloudSlack,
  instanceOfElasticsearch,
  instanceOfPlatformWithChannels,
  instanceOfWebhook,
} from './ts-guards'
import {
  PlatformsInPluginStep,
  PluginBindings,
  PluginsFormValues,
  PluginValue,
} from '../pages/clusters/add/steps/plugins/PluginsStep'
import { isSinkPlatform, PlatformsType } from '../models/platform/platform'
import { createSchemaUtils, RJSFSchema } from '@rjsf/utils'
import { DeploymentPage } from '../pages/clusters/utils'
import { getChannelsFromPlatformValues, MSTeamsCloudChannel } from './platforms'
import { Deployment } from '../store/slices/deployment/model'
import { ChannelNameWithId } from '../pages/clusters/add/steps/platformsConfiguration/utils'

import { ReactComponent as AIIcon } from '../assets/icons/plugins/ai.svg'
import { ReactComponent as ArgoCDIcon } from '../assets/icons/plugins/argocd.svg'
import { ReactComponent as FluxIcon } from '../assets/icons/plugins/flux.svg'
import { ReactComponent as GitHubIcon } from '../assets/icons/plugins/github.svg'
import { ReactComponent as HelmIcon } from '../assets/icons/plugins/helm.svg'
import { ReactComponent as K8sIcon } from '../assets/icons/plugins/k8s.svg'
import { ReactComponent as KeptnIcon } from '../assets/icons/plugins/keptn.svg'
import { ReactComponent as CLIIcon } from '../assets/icons/plugins/kubectl-exec.svg'
import { ReactComponent as PrometheusIcon } from '../assets/icons/plugins/prometheus.svg'
import { CSSProperties } from 'react'
import {
  PluginConfigurationAvailableChannel,
  PluginConfigurationConnection,
} from '../components/plugin/configuration/PluginConfiguration'
import { PluginTemplate } from '../store/slices/plugin/model'

export type UnboundPluginData = Deployment['plugins'][number] & { boundActions?: string[]; invalid?: boolean }

export const getBoundActionsForPlugin = (pluginConfigurationName: string, actions?: Deployment['actions']) => {
  return actions
    ?.filter(
      action =>
        action.bindings.sources.some(source => source === pluginConfigurationName) ||
        action.bindings.executors.some(exec => exec === pluginConfigurationName),
    )
    .map(action => action.name)
}

// TODO make channels as {id: string, name: string}[], always for all platforms
export type PluginBindingsDeploymentPage = {
  platformId: string
  platformType: PlatformsType
  channels: string[]
  meta?: {
    slackChannels: ChannelNameWithId[]
  }
}

export type PluginValueDeploymentPage = {
  id: string
  name: string
  enabled: boolean
  displayName: string
  description: string
  type: PluginType
  configuration: string
  configurationName: string
  bindings: PluginBindingsDeploymentPage[]
  boundActions?: string[]
  rbac?: Maybe<Rbac>
}

type BindPluginWithPlatformsArguments = {
  pluginData: PluginValue
  currentPlugin?: PluginValue
  isChecked: boolean
}
export const bindPluginWithPlatforms = (arg: BindPluginWithPlatformsArguments): PluginValue => {
  const { pluginData, isChecked, currentPlugin } = arg

  if (currentPlugin) {
    return {
      ...currentPlugin,
      enabled: isChecked,
    }
  } else {
    return {
      ...pluginData,
      enabled: isChecked,
    }
  }
}

export const getBindingsPluginToPlatforms = (
  pluginConfig: DeploymentPage['plugins'][number],
  platforms: DeploymentPage['platforms'],
): Record<string, PluginBindings> | undefined => {
  const pluginBindings = {} as Record<string, PluginBindings>
  let pluginBinding = {} as PluginBindings

  for (const [platformName, platformValues] of Object.entries(platforms)) {
    for (let idx = 0; idx < (platformValues ?? []).length; idx++) {
      const platformVal = platformValues?.[idx]
      if (!platformVal) {
        continue
      }
      pluginBinding = {
        platform: platformName as PlatformsType,
        platformId: platformVal.id,
        platformIdx: idx,
        channels: [],
      }

      if (instanceOfPlatformWithChannels(platformVal)) {
        platformVal.channels.forEach(channel => {
          let bindings = []
          switch (pluginConfig.type) {
            case PluginType.Source:
              bindings = channel.bindings.sources ?? []
              break
            case PluginType.Executor:
              bindings = channel.bindings.executors ?? []
              break
          }

          if (!bindings.includes(pluginConfig.configurationName)) {
            return
          }

          let channelIdentifier
          if (instanceOfChannelBindingsByNameAndId(channel)) {
            channelIdentifier = channel.channelId
          } else if (instanceOfChannelBindingsByName(channel)) {
            channelIdentifier = channel.name
          } else if (instanceOfChannelBindingsById(channel)) {
            channelIdentifier = channel.id
          } else {
            throw new Error(`getBindingsPluginToPlatforms::can't find channel for platform: ${platformName}`)
          }

          pluginBinding.channels.push(channelIdentifier)
        })

        if (pluginBinding.channels.length == 0) {
          continue
        }
        pluginBindings[platformVal.id] = pluginBinding
      } else if (
        instanceOfElasticsearch(platformVal) &&
        platformVal.indices.some(index =>
          index.bindings?.sources?.some(sourceName => sourceName === pluginConfig.configurationName),
        )
      ) {
        pluginBindings[platformVal.id] = pluginBinding
      } else if (
        instanceOfWebhook(platformVal) &&
        platformVal.bindings.sources?.some(sourceName => sourceName === pluginConfig.configurationName)
      ) {
        pluginBindings[platformVal.id] = pluginBinding
      }
    }
  }

  return !isEmpty(pluginBindings) ? pluginBindings : undefined
}

export const getBindingsPluginToPlatformsForDeploymentPage = (
  pluginConfigName: string,
  platforms: Record<
    string,
    | {
        id: string
      }[]
    | null
  >,
): PluginBindingsDeploymentPage[] => {
  const bindings: PluginBindingsDeploymentPage[] = []

  Object.entries(platforms).forEach(([platformName, platforms]) => {
    if (Array.isArray(platforms)) {
      platforms.forEach(platformVal => {
        let isBound = false
        const pluginBinding: PluginBindingsDeploymentPage = {
          platformId: platformVal.id,
          platformType: platformName as PlatformsType,
          channels: [],
        }
        if (instanceOfCloudSlack(platformVal)) {
          pluginBinding.meta = { slackChannels: [] }
          platformVal.channels.forEach(channel => {
            pluginBinding.meta?.slackChannels.push({
              id: channel.channelId,
              name: channel.name,
            })
            if (
              channel.bindings.executors?.includes(pluginConfigName) ||
              channel.bindings.sources?.includes(pluginConfigName)
            ) {
              isBound = true
              pluginBinding.channels.push(channel.channelId)
            }
          })
        } else if (instanceOfPlatformWithChannels(platformVal)) {
          platformVal.channels.forEach(channel => {
            if (instanceOfChannelBindingsByName(channel)) {
              if (
                channel.bindings.executors?.includes(pluginConfigName) ||
                channel.bindings.sources?.includes(pluginConfigName)
              ) {
                isBound = true
                pluginBinding.channels.push(channel.name)
              }
            } else if (instanceOfChannelBindingsById(channel)) {
              if (
                channel.bindings.executors?.includes(pluginConfigName) ||
                channel.bindings.sources?.includes(pluginConfigName)
              ) {
                isBound = true
                pluginBinding.channels.push(channel.id)
              }
            } else {
              throw new Error(`getBindingsPluginToPlatforms::can't find channel for platform: ${platformName}`)
            }
          })
        } else if (instanceOfWebhook(platformVal)) {
          if (platformVal.bindings.sources?.includes(pluginConfigName)) {
            isBound = true
          }
        } else if (instanceOfElasticsearch(platformVal)) {
          if (platformVal.indices.some(index => index.bindings?.sources?.includes(pluginConfigName))) {
            isBound = true
          }
        }

        if (isBound) {
          bindings.push(pluginBinding)
        }
      })
    }
  })

  return bindings
}

export const getPluginsFromDeploymentForDeploymentPage = (
  deployment: Deployment,
  pluginTemplates: { name: string; description: string }[],
): PluginValueDeploymentPage[] => {
  const { plugins, platforms } = deployment

  return plugins.map(plugin => {
    const bindings = getBindingsPluginToPlatformsForDeploymentPage(plugin.configurationName, platforms)
    const description = pluginTemplates.find(template => template.name === plugin.name)?.description ?? ''

    const boundActions = getBoundActionsForPlugin(plugin.configurationName, deployment.actions)

    return {
      id: plugin.id,
      name: plugin.name,
      type: plugin.type,
      enabled: plugin.enabled,
      displayName: plugin.displayName,
      configuration: plugin.configuration,
      configurationName: plugin.configurationName,
      rbac: plugin.rbac,
      description,
      bindings,
      boundActions,
    }
  })
}

export const getPluginsConfigurationNamesByPluginType = (
  plugins: PluginValue[],
  pluginType: PluginType,
  channel?: string,
): string[] => {
  let filterPlugins = plugins.filter(plugin => plugin.type === pluginType)
  if (channel) {
    filterPlugins = filterPlugins.filter(plugin =>
      Object.values(plugin.bindings ?? {}).some(binding => binding.channels.includes(channel)),
    )
  }

  return filterPlugins.map(plugin => plugin.configurationName)
}

export const getPluginsFormValuesFromDeployment = (deployment?: DeploymentPage): PluginsFormValues | undefined => {
  if (!deployment) {
    return
  }
  const { plugins, platforms } = deployment
  const pluginValues: PluginValue[] = plugins.map(pluginItem => {
    const bindings = getBindingsPluginToPlatforms(pluginItem, platforms)
    return {
      id: pluginItem.id,
      name: pluginItem.name,
      displayName: pluginItem.displayName,
      type: pluginItem.type,
      configuration: pluginItem.configuration,
      configurationName: pluginItem.configurationName,
      bindings,
      enabled: true,
    }
  })

  return { plugins: pluginValues }
}

export const getPluginsInPlatform = (plugins: PluginValue[], platform: Omit<PlatformsInPluginStep, 'name'>) => {
  return plugins.filter(plugin =>
    Object.values(plugin.bindings ?? {}).some(binding => binding.platformId === platform.platformId),
  )
}

export const setPluginsUpdateInput = (values: PluginsFormValues): PluginsUpdateInput => {
  return {
    groups: values.plugins
      .filter(plugin => plugin.enabled)
      .map(plugin => ({
        id: plugin.id,
        name: plugin.name,
        displayName: plugin.displayName,
        type: plugin.type,
        enabled: plugin.enabled,
        configurations: [
          {
            name: plugin.configurationName,
            configuration: plugin.configuration,
            rbac: {
              id: plugin.rbac?.id ?? v4(),
              group: {
                type: plugin.rbac?.group?.type ?? PolicySubjectType.Empty,
                static: plugin.rbac?.group?.static ?? null,
                prefix: plugin.rbac?.group?.prefix ?? null,
              },
              user: {
                type: plugin.rbac?.user?.type ?? PolicySubjectType.Empty,
                prefix: plugin.rbac?.user?.prefix ?? null,
                static: plugin.rbac?.user?.static ?? null,
              },
            },
          },
        ],
      })),
  }
}

export const getDefaultsFromSchema = (schema: RJSFSchema): unknown => {
  const schemaUtils = createSchemaUtils(validator, schema)
  return schemaUtils.getDefaultFormState(schema) as unknown
}

export const isSchemaValid = (schema: RJSFSchema, values: unknown): boolean => {
  return validator.isValid(schema, values, schema)
}

export const generateConfigName = (prefix: string) => `${prefix}_${randomString({ length: 5 })}`

export const getAvailableChannels = (
  channelsConfig: {
    channels: string[]
    meta?: {
      slackChannels: ChannelNameWithId[]
    }
  },
  platformType: PlatformsType,
  msTeamsChannels: MSTeamsCloudChannel[],
): PluginConfigurationAvailableChannel[] => {
  if (platformType === PlatformsType.CLOUD_TEAMS) {
    return channelsConfig.channels.map(channelId => {
      const msTeamsChannel = msTeamsChannels.find(channel => channel.id === channelId)
      if (!msTeamsChannel && msTeamsChannels.length) {
        console.warn(`while getting available channels, cannot find channel with id ${channelId}`)
      }
      return {
        label: msTeamsChannel?.name ?? '',
        value: msTeamsChannel?.id ?? '',
      }
    })
  }
  if (platformType === PlatformsType.CLOUD_SLACK) {
    return channelsConfig.channels.map(channel => ({
      label: channel,
      value: channelsConfig.meta?.slackChannels.find(slCh => slCh.name === channel)?.id ?? '',
    }))
  }

  return channelsConfig.channels.map(channel => ({
    label: channel,
    value: channel,
  }))
}

export function findUniquePluginConfigDisplayName(
  initialDisplayName: string,
  plugins: DeploymentPage['plugins'],
): string {
  let uniqueDisplayName = initialDisplayName
  let count = 1
  for (;;) {
    if (!plugins.some(plugin => plugin.displayName == uniqueDisplayName)) {
      break
    }
    uniqueDisplayName = `${initialDisplayName} (${count})`
    count++
  }
  return uniqueDisplayName
}

export const mapPluginNameToIcon = (name: string, style?: CSSProperties) => {
  switch (true) {
    case name.includes('flux'):
      return <FluxIcon style={style} />
    case name.includes('gh'):
    case name.includes('github-events'):
      return <GitHubIcon style={style} />
    case name.includes('helm'):
      return <HelmIcon style={style} />
    case name.includes('keptn'):
      return <KeptnIcon style={style} />
    case name.includes('prometheus'):
      return <PrometheusIcon style={style} />
    case name.includes('argocd'):
      return <ArgoCDIcon style={style} />
    case name.includes('ai'):
      return <AIIcon style={style} />
    case name.includes('kubernetes'):
      return <K8sIcon style={style} />
    case name.includes('kubectl'):
    case name.includes('exec'):
    default:
      return <CLIIcon style={style} />
  }
}

export const getPluginConfigurationConnections = (
  deployment: Deployment,
  pluginType: PluginType,
  msTeamsChannels?: MSTeamsCloudChannel[],
): PluginConfigurationConnection[] => {
  return Object.entries(deployment.platforms).reduce<PluginConfigurationConnection[]>(
    (connections, [platformTypeName, platformsValues]) => {
      const platformType = platformTypeName as PlatformsType
      if (pluginType === PluginType.Executor && isSinkPlatform(platformType)) {
        return connections
      }
      platformsValues?.forEach(values => {
        connections.push({
          platformId: values.id,
          platformType,
          availableChannels: getChannelsFromPlatformValues(values, msTeamsChannels),
        })
      })
      return connections
    },
    [],
  )
}

const OS_PLUGIN_PREFIX = 'botkube/'
const PRIVATE_PLUGIN_PREFIX = 'botkubeCloud/'
export const getPluginTemplate = (pluginTemplates: PluginTemplate[], pluginName: string) => {
  let out = pluginTemplates.find(p => p.name === pluginName)
  // This is a special case when someone has old `botkube` plugins configured, but we already moved them into `botkubeCloud` repo.
  // This avoids problems on UI and display old plugin config with new JSON schemas etc.
  // Once user upgrades Botkube instance, all plugins will be properly migrated by backend.
  if (!out && pluginName.startsWith('botkube/')) {
    out = pluginTemplates.find(p => p.name === pluginName.replace(OS_PLUGIN_PREFIX, PRIVATE_PLUGIN_PREFIX))
  }

  return out
}
