import { FC, useEffect, useState } from 'react'
import { MainLayout } from '../../layouts/main/MainLayout'
import { Button, Space, Table } from 'antd'
import { AliasPage, getData, setColumns } from './utils'
import { AddEditAlias } from '../../components/modal/addEditAlias/AddEditAlias'
import {
  useAliasPageQuery,
  useCreateAliasMutation,
  useDeleteAliasMutation,
  useUpdateAliasMutation,
} from '../../models/graphql'
import { usePageTitle } from '../../utils/customeHooks/usePageTitle'
import { useContent } from '../../utils/customeHooks/useContent'
import { AliasesSkeleton, ContentType } from '../../services/contentful/types'
import { usePagination } from '../../utils/customeHooks/usePagination'
import { defaultPagination } from '../../utils/table/pagination'

export const AliasesPage: FC = () => {
  usePageTitle('Aliases')
  const { limit, offset, setOffset, setLimit } = usePagination()
  const [isAddAliasOpen, setAddAliasOpen] = useState(false)
  const [selectedAlias, setSelectedAlias] = useState<AliasPage>()
  const content = useContent<AliasesSkeleton>(ContentType.ALIASES_PAGE)
  const { data, isFetching, refetch } = useAliasPageQuery({
    offset,
    limit,
  })
  const {
    mutate: createAlias,
    isLoading: isLoadingCreate,
    isSuccess: isSuccessCreate,
  } = useCreateAliasMutation({
    onSuccess: () => refetch(),
  })
  const {
    mutate: updateAlias,
    isLoading: isLoadingUpdate,
    isSuccess: isSuccessUpdate,
  } = useUpdateAliasMutation({ onSuccess: () => refetch() })
  const { mutate: deleteAlias, isLoading: isLoadingDelete } = useDeleteAliasMutation({
    onSuccess: () => refetch(),
  })

  useEffect(() => {
    if (isSuccessCreate || isSuccessUpdate) {
      setAddAliasOpen(false)
    }
  }, [isSuccessCreate, isSuccessUpdate])

  return (
    <MainLayout>
      <h1>{content?.fields.pageTitle}</h1>
      <p>Command aliases used by Botkube Chat Platform instances.</p>
      <Space className={'mb-20'}>
        <Button
          type={'primary'}
          onClick={() => {
            setAddAliasOpen(true)
            setSelectedAlias(undefined)
          }}
        >
          Create new
        </Button>
      </Space>
      <Table
        loading={isFetching || isLoadingDelete || isLoadingUpdate}
        rowKey={'id'}
        dataSource={getData(data?.aliases.data ?? [])}
        columns={setColumns({
          onEdit: id => {
            setSelectedAlias(data?.aliases.data.find(alias => alias.id === id))
            setAddAliasOpen(true)
          },
          // eslint-disable-next-line no-console
          onDelete: id => deleteAlias({ id }),
        })}
        pagination={defaultPagination({
          total: data?.aliases.totalCount,
          limit: data?.aliases.pageInfo.limit,
          setOffset,
          setLimit,
          hideOnSinglePage: true,
        })}
      />
      <AddEditAlias
        open={isAddAliasOpen}
        okText={'Apply'}
        onCancel={() => setAddAliasOpen(false)}
        alias={selectedAlias}
        deployments={data?.deployments.data ?? []}
        handleEdit={updateAlias}
        handleCreate={createAlias}
        isLoading={isLoadingCreate}
      />
    </MainLayout>
  )
}
