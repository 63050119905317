import styled from 'styled-components'

export const WIZARD_STEP_FOOTER_HEIGHT = 115

export const WizardStepFooterSC = styled.div`
  position: fixed;
  z-index: 2;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: ${WIZARD_STEP_FOOTER_HEIGHT}px;
  box-sizing: border-box;
  padding: 0 24px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
`

export const ActionsSC = styled.div``

export const HelpTextSC = styled.div`
  margin-top: 10px;
`
