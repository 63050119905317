import styled from 'styled-components'
import { red } from '@ant-design/colors'
import { HTMLAttributes } from 'react'

interface SpanSCProps extends HTMLAttributes<HTMLSpanElement> {
  invalid?: boolean
}

export const SpanSC = styled.span<SpanSCProps>`
  color: ${props => (props.invalid ? red.primary : undefined)};
`
