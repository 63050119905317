import { WizardFormValues } from './DeploymentAddPage'
import { getPlatformsFormValuesFromDeployment, setPlatformsUpdateInput } from '../../../utils/platforms'
import { getPluginsFormValuesFromDeployment, setPluginsUpdateInput } from '../../../utils/plugins'
import { DeploymentUpdateInput, Exact, UpdateDeploymentMutation } from '../../../models/graphql'
import { PluginsFormValues } from './steps/plugins/PluginsStep'
import { PlatformsFormValues } from './steps/platformsConfiguration/utils'
import { UseMutateFunction } from '@tanstack/react-query'
import { Deployment } from '../../../store/slices/deployment/model'

export const setWizardValuesFromDeployment = (deployment: Deployment): WizardFormValues => {
  return {
    connectionStep: {
      name: deployment.name,
    },
    platformsStep: getPlatformsFormValuesFromDeployment(deployment),
    pluginsStep: getPluginsFormValuesFromDeployment(deployment),
  }
}

type UpdateDeploymentInsideWizardArgs = {
  deployment: Deployment
  wizardValues: WizardFormValues
  platforms?: PlatformsFormValues
  plugins?: PluginsFormValues
  draft?: boolean
  defaultAliases?: boolean
  defaultActions?: boolean
  updateDeployment: UseMutateFunction<
    UpdateDeploymentMutation,
    unknown,
    Exact<{ id: string; input: DeploymentUpdateInput }>
  >
  onSuccess: () => void
}
export const updateDeploymentInsideWizard = ({
  onSuccess,
  updateDeployment,
  wizardValues,
  platforms,
  plugins,
  deployment,
  draft = true,
  defaultAliases = false,
  defaultActions = false,
}: UpdateDeploymentInsideWizardArgs) => {
  const platformsInput = (platforms ?? {}) as PlatformsFormValues
  const pluginsInput = (plugins ?? []) as PluginsFormValues
  const deploymentInput: DeploymentUpdateInput = {
    name: wizardValues.connectionStep?.name ?? '',
    platforms: setPlatformsUpdateInput(pluginsInput, platformsInput),
    plugins: [setPluginsUpdateInput(pluginsInput)],
    resourceVersion: deployment.resourceVersion,
    draft,
  }

  updateDeployment(
    {
      id: deployment.id,
      input: {
        ...deploymentInput,
        attachDefaultAliases: defaultAliases,
        attachDefaultActions: defaultActions,
      },
    },
    {
      onSuccess,
    },
  )
}
