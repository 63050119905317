import { FC, ReactNode, useContext, useState } from 'react'
import { MainLayout } from '../../layouts/main/MainLayout'
import { Alert, DatePicker, Modal, Segmented, Select, Space, Table, Tooltip } from 'antd'
import { populateTableData, setColumns } from './utils'
import { useAuditEventQuotaQuery, useAuditPageQuery, useBasicDeploymentListQuery } from '../../models/graphql'
import { defaultPagination } from '../../utils/table/pagination'
import { usePagination } from '../../utils/customeHooks/usePagination'
import { useAuditPageFilters } from './hooks'
import { CodeBlock } from '../../components/codeBlock/CodeBlock'
import { Spinner } from '../login/styles'
import moment from 'moment'
import { AppCtx } from '../../providers/appCtx'
import { usePageTitle } from '../../utils/customeHooks/usePageTitle'
import { useContent } from '../../utils/customeHooks/useContent'
import { AuditLogsSkeleton, ContentType } from '../../services/contentful/types'
import { PlanUpgradeMessage } from '../../components/subscription/PlanUpgradeMessage'

const ALL_DEPLOYMENTS = 'ALL'

export const AuditLogsPage: FC = () => {
  usePageTitle('Audit Logs')
  const { limit, offset, setOffset, setLimit } = usePagination()
  const content = useContent<AuditLogsSkeleton>(ContentType.AUDIT_LOGS_PAGE)
  const { dateFrom, dateTo, deploymentId, setDateFrom, setDateTo, setDeploymentId } = useAuditPageFilters()
  const { data, isLoading } = useAuditPageQuery({
    offset,
    limit,
    filter: { startDate: dateFrom, endDate: dateTo, deploymentId: deploymentId },
  })

  const { orgID } = useContext(AppCtx)
  const { data: quotaData, isLoading: quotaIsLoading } = useAuditEventQuotaQuery({ orgID })
  const [detailsModalState, setDetailsModalState] = useState({ open: false, data: '' })
  const unlimitedRetentionPeriod = quotaData?.organization?.quota?.auditRetentionPeriod === -1

  const onDeploymentChange = (val: string) => {
    if (val === ALL_DEPLOYMENTS) {
      setDeploymentId(undefined)
      return
    }
    setDeploymentId(val)
  }

  const { data: deploymentsQueryData = { deployments: { data: [] } }, isLoading: deploymentsQueryIsLoading } =
    useBasicDeploymentListQuery()
  const deploymentSelectOptions = [
    { value: ALL_DEPLOYMENTS, label: 'All instances' },
    ...deploymentsQueryData.deployments.data.map(deploy => ({
      value: deploy.id,
      label: `${deploy.name} (${deploy.id})`,
    })),
  ]

  const showDetailsModal = (value: string) => {
    setDetailsModalState({ open: true, data: value })
  }
  const closeDetailsModal = () => {
    setDetailsModalState({ open: false, data: detailsModalState.data })
  }

  const quotaMessage = getQuotaMessage(
    orgID,
    quotaData?.organization?.quota?.auditRetentionPeriod,
    quotaData?.organization?.subscription.isDefaultPlan,
    quotaData?.organization?.subscription.trialConsumed,
  )

  return (
    <MainLayout>
      <h1>{content?.fields.pageTitle}</h1>
      <Spinner spinning={quotaIsLoading}>
        {!unlimitedRetentionPeriod && (
          <Alert
            className={'mb-20'}
            message={quotaMessage}
            type='info'
            showIcon
          />
        )}
      </Spinner>
      <div className={'mb-20'}>
        <Tooltip
          title={'Timeline view coming soon!'}
          placement='right'
        >
          <Segmented options={['Datagrid', { label: 'Timeline', value: '', disabled: true }]} />
        </Tooltip>
      </div>
      <Space className={'mb-20'}>
        <Select
          placeholder={'Select instance'}
          style={{ width: '250px' }}
          loading={deploymentsQueryIsLoading}
          defaultValue={ALL_DEPLOYMENTS}
          onChange={onDeploymentChange}
          showSearch
          options={deploymentSelectOptions}
          filterOption={(input, option) =>
            [(option?.label ?? '').toLowerCase(), (option?.value ?? '').toLowerCase()].some(val =>
              val.includes(input.toLowerCase()),
            )
          }
        />
        <DatePicker.RangePicker
          showTime
          onChange={dates => {
            setDateFrom(dates?.[0]?.toISOString())
            setDateTo(dates?.[1]?.toISOString())
          }}
        />
      </Space>
      <Table
        rowKey={'id'}
        loading={isLoading}
        dataSource={populateTableData(data?.auditEvents.data ?? [])}
        columns={setColumns(showDetailsModal)}
        pagination={defaultPagination({
          total: data?.auditEvents.totalCount,
          limit: data?.auditEvents.pageInfo.limit,
          setOffset,
          setLimit,
        })}
      />
      <Modal
        width={700}
        title={'Audit event details'}
        open={detailsModalState.open}
        cancelButtonProps={{ hidden: true }}
        onOk={closeDetailsModal}
        onCancel={closeDetailsModal}
      >
        <CodeBlock>{detailsModalState.data}</CodeBlock>
      </Modal>
    </MainLayout>
  )
}

function getQuotaMessage(
  orgID: string,
  auditEventRetentionInHours?: number | null,
  isDefaultPlan?: boolean | null,
  trialConsumed?: boolean,
): ReactNode {
  if (auditEventRetentionInHours === null || auditEventRetentionInHours === undefined) {
    return ''
  }

  const duration = moment.duration(auditEventRetentionInHours, 'hours')
  const quotaMessage = `Data retention limited to ${duration.humanize()}.`

  if (!isDefaultPlan) {
    return quotaMessage
  }

  return (
    <>
      {quotaMessage}{' '}
      <PlanUpgradeMessage
        orgID={orgID}
        trialConsumed={trialConsumed}
      />
    </>
  )
}
