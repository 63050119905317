import styled from 'styled-components'
import { Tag } from 'antd'
import { TagProps, TagType } from './Tag'

type ColorSchema = {
  colorTxt?: string
  colorBorder?: string
  colorBg?: string
}
const getColorSchema = (type?: TagType): ColorSchema => {
  switch (type) {
    case TagType.PRIMARY:
      return {
        colorTxt: '#FFF',
        colorBg: '#096DD9',
        colorBorder: '#096DD9',
      }
    case TagType.SECONDARY:
      return {
        colorTxt: '#2F54EB',
        colorBg: '#F0F5FF',
        colorBorder: '#ADC6FF',
      }
    case TagType.THIRD:
      return {
        colorTxt: '#FAAD14',
        colorBg: '#FFFBE6',
        colorBorder: '#FFE58F',
      }
    case TagType.ENABLE:
      return {
        colorTxt: '#52C41A',
        colorBg: '#F6FFED',
        colorBorder: '#B7EB8F',
      }
    case TagType.FORTH:
      return {
        colorTxt: '#722ED1',
        colorBg: '#F9F0FF',
        colorBorder: '#D3ADF7',
      }
    case TagType.DANGER:
      return {
        colorTxt: '#EB2F96',
        colorBg: '#FFF0F6',
        colorBorder: '#FFADD2',
      }
    case TagType.DEFAULT:
      return {}
    default:
      return {}
  }
}

export const TagSC = styled(Tag)<TagProps>`
  ${props => {
    const colorSchema = getColorSchema(props.type)

    return `
      .ant-typography {
        ${colorSchema.colorTxt ? `color: ${colorSchema.colorTxt};` : ''}    
      }
      
      ${colorSchema.colorBorder ? `border: 1px solid ${colorSchema.colorBorder};` : ''}
      ${colorSchema.colorBg ? `background: ${colorSchema.colorBg};` : ''}

      svg {
        ${colorSchema.colorTxt ? `fill: ${colorSchema.colorTxt};` : ''}
      }      
    `
  }}
`
