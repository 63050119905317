import styled from 'styled-components'
import { Descriptions, Table } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { ConnectedPlatformEntry } from './utils'

export const DescriptionsSC = styled(Descriptions)`
  background-color: #fff;
`

export const ProgressValueSC = styled.div<{ exceeded?: boolean }>`
  padding-left: 15px;
  display: inline-block;
  position: relative;
  top: 1px;
  ${props =>
    props.exceeded &&
    `
    font-weight: bold;
    color: #ff4d4f;
`}
`
export const ProgressIndicatorSC = styled.div`
  display: inline-block;
  width: 170px;
`

export const InfoCircleOutlinedSC = styled(InfoCircleOutlined)`
  margin-left: 5px;
  position: relative;
  top: 1px;
`

export const TableSC = styled(Table<ConnectedPlatformEntry>)`
  .ant-empty-normal {
    margin: 6px 0 5px;
  }
`
