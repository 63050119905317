import styled from 'styled-components'
import { Layout } from 'antd'
import { breakpoints } from '../../styles/globalStyles'

export const FooterSC = styled(Layout.Footer)`
  padding: 15px 0;
  color: #fff;
  background: #373737;
  height: auto;

  @media (min-width: ${breakpoints.lgMin}px) {
    height: 50px;
  }
`

export const FooterWrapperSC = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 32px;

  a {
    color: #fff;
  }
`
