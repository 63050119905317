import React, { ReactNode } from 'react'
import { HeaderSC } from './styles'
import { ReactComponent as BotkubeLogo } from '../../assets/botkube-logo.svg'
import { MenuMain } from '../menu/MenuMain'
import { Link } from 'react-router-dom'
import { paths } from '../../router/paths'

type Props = {
  children?: ReactNode
  disableHomePageLink?: boolean
}
export const Header: React.FC<Props> = ({ children, disableHomePageLink }) => {
  const logo = <BotkubeLogo className={'logo'} />
  return (
    <HeaderSC>
      {disableHomePageLink ? (
        logo
      ) : (
        <Link
          to={paths.HOME}
          className={'logo-link'}
        >
          {logo}
        </Link>
      )}
      {children ? children : <MenuMain />}
    </HeaderSC>
  )
}
