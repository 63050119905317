import { createSelector } from 'reselect'

import { RootState } from '../../store'
import { getPlatformFromDeployment } from '../../../utils/platforms'
import { PlatformsType } from '../../../models/platform/platform'
import { DEPLOYMENT_HEALTH_CLOUD_TEAMS_KEY } from './model'

export const selectPlatform = createSelector(
  [(state: RootState) => state.deployment, (_, platformId: string) => platformId],
  (deployment, platformId) => {
    if (!deployment.data) {
      return null
    }
    return getPlatformFromDeployment(deployment.data, platformId)
  },
)

export const selectPlatformHealth = createSelector(
  [(state: RootState) => state.deployment, (_, id: string, type: PlatformsType) => ({ id, type })],
  (deployment, platform) => {
    if (!deployment.health) {
      return null
    }
    return deployment.health.platforms.find(platHealth =>
      platform.type !== PlatformsType.CLOUD_TEAMS
        ? platHealth?.key === platform.id
        : platHealth?.key === DEPLOYMENT_HEALTH_CLOUD_TEAMS_KEY,
    )?.value
  },
)

export const selectPluginHealth = createSelector(
  [(state: RootState) => state.deployment, (_, pluginConfigName: string) => pluginConfigName],
  (deployment, pluginConfigName) => {
    if (!deployment.health) {
      return null
    }
    return deployment.health.plugins.find(plugHealth => plugHealth?.key?.includes(pluginConfigName))?.value
  },
)
