import { ReactNode } from 'react'

export const getValidationStatus = (fieldError?: string | string[], fieldTouched?: boolean): 'error' | '' => {
  if (fieldTouched && fieldError) {
    return 'error'
  }

  return ''
}

export const getValidationError = (
  fieldError?: string | string[],
  fieldTouched?: boolean,
  msg?: ReactNode,
): ReactNode => {
  if (fieldTouched && fieldError) {
    return fieldError.toString()
  }

  return msg ?? ''
}

export const isError = (generalError: boolean, generalTouched: boolean): boolean => {
  return generalError && generalTouched
}
