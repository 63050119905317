import { FC } from 'react'
import { AppleFilled, AppleOutlined, WindowsFilled } from '@ant-design/icons'
import { ReactComponent as Linux } from '../../assets/icons/linux.svg'
import { ReactComponent as FluxCD } from '../../assets/icons/fluxcd.svg'
import { ReactComponent as ArgoCD } from '../../assets/icons/argocd.svg'
import { InstallationPlatformType } from '../../models/graphql'
import { OSIconSC } from './styles'

type Props = {
  platformType: InstallationPlatformType
}
export const OSIcon: FC<Props> = ({ platformType }) => {
  const renderIcon = (platformType: InstallationPlatformType) => {
    switch (platformType) {
      case InstallationPlatformType.MacSilicon:
        return <AppleFilled />
      case InstallationPlatformType.MacIntel:
        return <AppleOutlined />
      case InstallationPlatformType.Windows:
        return <WindowsFilled />
      case InstallationPlatformType.Linux:
        return <Linux className={'linux custom-icon'} />
      case InstallationPlatformType.Flux:
        return <FluxCD className={'flux custom-icon'} />
      case InstallationPlatformType.Argo:
        return <ArgoCD className={'argo custom-icon'} />
      default:
        return null
    }
  }

  return <OSIconSC>{renderIcon(platformType)}</OSIconSC>
}
