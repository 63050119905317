import { FormikConfig } from 'formik'
import * as yup from 'yup'
import { AnySchema } from 'yup'
import { ValidationMsg } from '../../../../utils/validation'
import { PlatformsType } from '../../../../models/platform/platform'
import { PagerDuty } from '../../../../models/graphql'
import { HandlePlatformFormSubmit } from '../../../../components/platform/settings/utils'
import { PagerDutyFormValues } from '../../../../pages/clusters/add/steps/platformsConfiguration/utils'

type Props = {
  values?: PagerDuty
  onSubmit: HandlePlatformFormSubmit
}

export const initFormik = (props: Props): FormikConfig<PagerDutyFormValues> => {
  return {
    enableReinitialize: true,
    initialValues: {
      id: props.values?.id ?? '',
      name: props.values?.name ?? '',
      integrationKey: props.values?.integrationKey ?? '',
    },
    validationSchema: yup.object().shape<Record<keyof PagerDutyFormValues, AnySchema>>({
      id: yup.string(),
      name: yup.string().required(ValidationMsg.REQUIRED),
      integrationKey: yup.string().required(ValidationMsg.REQUIRED),
    }),
    onSubmit: values => {
      props.onSubmit(PlatformsType.PAGER_DUTY, values)
    },
  }
}
