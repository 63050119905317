import { CodeOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { ButtonSC, FuseAlertBgSC, FuseAlertSC, FuseAlertWrapperSC, IllustrationSC } from './styles'
import { gutter } from '../../styles/globalStyles'
import { FUSE_URL } from './config'
import { usePostHog } from 'posthog-js/react'
import { FeatureFlag } from '../../utils/posthog'

export const FuseAlert = () => {
  const posthog = usePostHog()
  if (!posthog.isFeatureEnabled(FeatureFlag.DISPLAY_FUSE)) {
    return null
  }

  return (
    <FuseAlertWrapperSC>
      <FuseAlertBgSC>
        <Row gutter={gutter}>
          <Col
            md={17}
            lg={14}
          >
            <FuseAlertSC
              icon={<CodeOutlined />}
              message={'Meet Fuse, Your All-in-One Platform Engineering Copilot.'}
              description={
                <>
                  <p>
                    Botkube Fuse brings together information from all your essential tools into single terminal
                    interface,
                    <br />
                    offering real-time updates and expert insights with the help of AI.
                  </p>

                  <ButtonSC
                    size={'large'}
                    type='primary'
                    href={FUSE_URL}
                  >
                    Check it out
                  </ButtonSC>
                </>
              }
              type='info'
              showIcon
            />
          </Col>
          <Col
            xs={0}
            md={7}
            lg={10}
            style={{ textAlign: 'right' }}
          >
            <IllustrationSC />
          </Col>
        </Row>
      </FuseAlertBgSC>
    </FuseAlertWrapperSC>
  )
}
