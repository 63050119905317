import React, { FC, useState } from 'react'
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Modal, Typography } from 'antd'
import { useAppDispatch } from '../../../store/store'
import { removePluginFromDeployment } from '../../../store/slices/deployment/deployment'

type Props = {
  pluginId: string
  onDelete: () => void
}
export const DeletePlugin: FC<Props> = ({ pluginId, onDelete }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const dispatch = useAppDispatch()

  const onRemovePlugin = () => {
    dispatch(removePluginFromDeployment({ pluginId }))
    onDelete()
  }

  return (
    <>
      <Typography.Title level={4}>Delete plugin</Typography.Title>
      <Button
        ghost={true}
        danger={true}
        icon={<DeleteOutlined />}
        onClick={() => setIsDeleteModalOpen(true)}
      >
        Delete plugin
      </Button>
      <Modal
        width={700}
        title={
          <>
            <ExclamationCircleOutlined /> Delete plugin
          </>
        }
        onOk={() => {
          onRemovePlugin()
          setIsDeleteModalOpen(false)
        }}
        open={isDeleteModalOpen}
        onCancel={() => setIsDeleteModalOpen(false)}
        okText={'Delete plugin'}
        okButtonProps={{ danger: true }}
      >
        <p>Are you sure you want to delete this plugin?</p>
      </Modal>
    </>
  )
}
