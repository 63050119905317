import * as Sentry from '@sentry/react'
import { CaptureConsole } from '@sentry/integrations'

export const initSentry = (dsn?: string) => {
  if (!dsn) {
    return
  }

  Sentry.init({
    dsn,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost', /^https:\/\/app\.botkube.io\.io/, /^https:\/\/app-dev\.botkube.io\.io/],
      }),
      new Sentry.Replay(),
      new CaptureConsole({
        levels: ['warn', 'error'],
      }),
    ],
    tracesSampleRate: 0.3,
    replaysSessionSampleRate: 0.3,
    replaysOnErrorSampleRate: 1.0,
  })
}
