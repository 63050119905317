import React, { FC } from 'react'

import { TableChannelsSC } from './styles'
import { ChannelData, populateRowSelection } from './channels'
import { breakpoints } from '../../../../styles/globalStyles'
import { Key } from 'antd/lib/table/interface'
import { ChannelNameWithId } from '../../../../pages/clusters/add/steps/platformsConfiguration/utils'
import { useViewport } from '../../../../utils/customeHooks/useViewport'
import { ColumnsType } from 'antd/lib/table'
import { GlobalOutlined, LockOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { shouldEnablePrivateChannelAlias } from '../../../../config/runtime'
import { Tooltip } from 'antd'
import { useFilterAntTable } from '../../../../utils/customeHooks/useFilterAntTable'

type Props = {
  data: ChannelData[]
  selectedChannels: ChannelNameWithId[]
  onChannelSelect: (channels: ChannelNameWithId[]) => void
  disabled?: boolean
}
export const CloudSlackChannelsTable: FC<Props> = ({ data, disabled, selectedChannels, onChannelSelect }) => {
  const { width } = useViewport()
  const { getColumnSearchProps, getColumnFilterProps } = useFilterAntTable()

  const onChannelSelectHandler = (_selectedRowKeys: Key[], selectedRows: ChannelData[]) => {
    const selectedChannelsNames: ChannelNameWithId[] = selectedRows.map(row => ({
      name: row.name,
      id: row.key,
    }))
    onChannelSelect(selectedChannelsNames)
  }

  const channelColumns: ColumnsType<ChannelData> = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: '130px',
      sorter: (a, b) => (a.type ?? '').localeCompare(b.type ?? ''),
      render: (_, record) => {
        const iconStyle = { marginRight: 5 }
        if (record.type === 'public') {
          return (
            <>
              <GlobalOutlined style={iconStyle} /> Public
            </>
          )
        }

        if (record.type == 'private') {
          return (
            <>
              <LockOutlined style={iconStyle} /> Private
            </>
          )
        }

        return (
          <>
            <QuestionCircleOutlined style={iconStyle} /> Unknown
          </>
        )
      },
      ...getColumnFilterProps<ChannelData>('type', [
        { text: 'Public', value: 'public' },
        { text: 'Private', value: 'private' },
      ]),
    },
    {
      title: (
        <>
          Name {shouldEnablePrivateChannelAlias() && 'or public alias '}
          {shouldEnablePrivateChannelAlias() && (
            <Tooltip title={'Private channel can be recognized by a public alias set from within that channel.'}>
              <QuestionCircleOutlined className={'helper-icon'} />
            </Tooltip>
          )}
        </>
      ),
      dataIndex: 'name',
      showSorterTooltip: false,
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...getColumnSearchProps<ChannelData>('name', 'channel'),
    },
    {
      title: 'Purpose',
      dataIndex: 'purpose',
      key: 'purpose',
      ellipsis: true,
      ...getColumnSearchProps<ChannelData>('purpose', 'purpose'),
    },
  ]

  return (
    // @ts-expect-error
    <TableChannelsSC
      rowSelection={populateRowSelection(onChannelSelectHandler, selectedChannels, disabled)}
      columns={channelColumns}
      dataSource={data}
      tableLayout={width < breakpoints.lgMin ? 'auto' : 'fixed'}
    />
  )
}
