import { Table, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import {
  ChannelNameWithId,
  CloudSlackFormValues,
} from '../../../../pages/clusters/add/steps/platformsConfiguration/utils'
import { Key, RowSelectMethod, TableRowSelection } from 'antd/lib/table/interface'
import { ConnectedSlackPlatformsQuery } from '../../../../models/graphql'

export type ChannelData = {
  key: string
  name: string
  purpose?: string | null
  type?: 'public' | 'private'
  isAlias: boolean
  isBoundToChannel: boolean
}

export type ChannelIndex = keyof ChannelData

export type ConnectedPlatform = Exclude<
  Exclude<NonNullable<ConnectedSlackPlatformsQuery['organization']>['connectedPlatforms'], null | undefined>['slacks'],
  null | undefined
>[number]
export function populateChannels(values: CloudSlackFormValues, connectedPlatform?: ConnectedPlatform) {
  const validChannels: ChannelData[] = (connectedPlatform?.channels ?? [])
    .filter(channel => Boolean(channel))
    .map(channel => {
      return {
        key: channel.id,
        name: channel.name,
        purpose: channel.purpose,
        type: channel.isPrivate ? 'private' : 'public',
        isAlias: channel.isAlias,
        isBoundToChannel: values.channelNamesWithId.some(channelItem => channelItem.id === channel.id),
      }
    })

  const invalidChannels = values.channelNamesWithId.filter(channelItem => {
    return !validChannels.find(ch =>
      channelItem.id ? ch.key === channelItem.id : !ch.isAlias && ch.name === channelItem.name,
    )
  })

  return {
    channels: validChannels,
    invalidChannels,
  }
}

export function populateRowSelection(
  onChannelSelect: (selectedRowKeys: Key[], selectedRows: ChannelData[], info: { type: RowSelectMethod }) => void,
  channels: ChannelNameWithId[],
  disabled?: boolean,
): TableRowSelection<ChannelData> {
  return {
    columnTitle: (
      <>
        Enabled
        <Tooltip title={'Enable the channel to use it with the Botkube instance.'}>
          <QuestionCircleOutlined className={'helper-icon'} />
        </Tooltip>
      </>
    ),
    columnWidth: '96px',
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
    selectedRowKeys: channels.map(ch => ch.id),
    onChange: onChannelSelect,
    getCheckboxProps: (record: ChannelData) => ({
      disabled: disabled,
      name: record.name,
    }),
  }
}

export function getChannelInviteURL(teamId: string, channelId: string) {
  return `https://slack.com/app_redirect?channel=${channelId}&team=${teamId}`
}
