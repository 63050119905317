import React, { FC, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { WithoutMenuLayout } from '../../../layouts/withoutMenu/withoutMenuLayout'
import { Button, Result } from 'antd'

const SLACK_APP_ID = 'app_id'
const SLACK_TEAM_ID = 'team_id'
const REDIRECT_MILLIS = 3000

export const SlackConnectionSuccess: FC = () => {
  const [searchParams] = useSearchParams()
  const appID = searchParams.get(SLACK_APP_ID) ?? ''
  const teamID = searchParams.get(SLACK_TEAM_ID) ?? ''
  const slackAppURL = `https://slack.com/app_redirect?app=${appID}&team=${teamID}`

  useEffect(() => {
    setTimeout(() => {
      window.location.href = slackAppURL
    }, REDIRECT_MILLIS)
  }, [slackAppURL])
  return (
    <WithoutMenuLayout>
      <Result
        style={{ margin: '34px 0 0' }}
        status='success'
        title={'Success! Redirecting to the Botkube app for Slack...'}
        subTitle="If that doesn't happen automatically, use the button below:"
        extra={
          <Button
            type='primary'
            key='Slack'
            href={slackAppURL}
          >
            Navigate to Botkube app for Slack
          </Button>
        }
      />
    </WithoutMenuLayout>
  )
}
