import { Button, Col, Form, Input, Modal, Select, Space, Typography } from 'antd'
import React, { FC, useMemo, useState } from 'react'
import { FormikErrors, FormikHandlers, FormikHelpers, FormikTouched } from 'formik'
import { getValidationError, getValidationStatus } from '../../../../utils/formik/formik'
import {
  ChannelNameWithId,
  CloudSlackFormValues,
} from '../../../../pages/clusters/add/steps/platformsConfiguration/utils'
import { ConnectedSlackPlatformsQuery } from '../../../../models/graphql'
import { FormItemSC, UlSC } from './styles'
import { CloudSlackChannelModal } from './CloudSlackChannelModal'
import { getChannelInviteURL, populateChannels } from './channels'
import { InvitationInstructions } from './InvitationInstructions'
import { CloudSlackChannelsTable } from './CloudSlackChannelsTable'

const { Text } = Typography

type Props = {
  values: CloudSlackFormValues
  handleChange: FormikHandlers['handleChange']
  setFieldValue: FormikHelpers<CloudSlackFormValues>['setFieldValue']
  errors?: FormikErrors<CloudSlackFormValues>
  touched?: FormikTouched<CloudSlackFormValues>
  isSubmitted: boolean
  connectedPlatforms?: Exclude<
    NonNullable<ConnectedSlackPlatformsQuery['organization']>['connectedPlatforms'],
    null | undefined
  >['slacks']
  fieldNamePrefix?: string
  onBlur?: () => void
  disabled?: boolean
}

export const CloudSlackForm: FC<Props> = props => {
  const {
    errors,
    touched,
    values,
    handleChange,
    setFieldValue,
    isSubmitted,
    connectedPlatforms,
    fieldNamePrefix = '',
    onBlur,
    disabled,
  } = props

  const onChannelSelect = (channels: ChannelNameWithId[]) => {
    setFieldValue(`${fieldNamePrefix}channelNamesWithId`, channels)

    // workaround for https://github.com/jaredpalmer/formik/issues/529
    if (onBlur) {
      setTimeout(() => {
        // eslint-disable-next-line
        if (onBlur) {
          // check if it's still there
          onBlur()
        }
      }, 50)
    }

    if (onBlur) {
      onBlur()
    }
  }

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isInvalidChannelModalForceClosed, setIsInvalidChannelModalForceClosed] = useState(false)
  const selectedConnectedPlatform = useMemo(() => {
    const connectedPlatform = connectedPlatforms?.find(slack => slack.teamId === values.teamId)
    if (connectedPlatform?.channels && Array.isArray(connectedPlatform.channels)) {
      return {
        ...connectedPlatform,
        channels: connectedPlatform.channels
          .slice()
          .sort(
            (channelA, channelB) =>
              (values.channelNamesWithId.some(chan => chan.name === channelB.name) ? 1 : 0) -
              (values.channelNamesWithId.some(chan => chan.name === channelA.name) ? 1 : 0),
          ),
      }
    }
    return connectedPlatform
    // eslint-disable-next-line
  }, [connectedPlatforms, values.teamId])

  if (!connectedPlatforms || !connectedPlatforms.length) {
    return (
      <p>
        <Text
          strong={true}
          style={{ color: '#f5222d' }}
        >
          No connected Slack workspace found. Please connect your Slack workspace first, and return to the page.
        </Text>
      </p>
    )
  }

  const onConnectedPlatformSelect = (val: string) => {
    setFieldValue(`${fieldNamePrefix}teamId`, val)

    if (!values.name) {
      setFieldValue(`${fieldNamePrefix}name`, connectedPlatforms.find(slack => slack.teamId === val)?.name)
    }
  }

  const { channels, invalidChannels } = populateChannels(values, selectedConnectedPlatform)

  return (
    <>
      <Col xl={9}>
        <Form.Item
          label={'Select workspace'}
          required={true}
          validateStatus={getValidationStatus(errors?.teamId, touched?.teamId ?? isSubmitted)}
          help={getValidationError(errors?.teamId, touched?.teamId ?? isSubmitted)}
        >
          <Select
            value={values.teamId !== '' ? values.teamId : undefined}
            placeholder={'Select workspace'}
            disabled={disabled}
            onChange={onConnectedPlatformSelect}
            showSearch
            onBlur={onBlur}
            options={populateConnectedSlacksDropdown(connectedPlatforms)}
            filterOption={(input, option) =>
              [(option?.label ?? '').toLowerCase(), (option?.value ?? '').toLowerCase()].some(val =>
                val.includes(input.toLowerCase()),
              )
            }
          />
        </Form.Item>
        <Form.Item
          label={'Display Name'}
          required={true}
          validateStatus={getValidationStatus(errors?.name, touched?.name ?? isSubmitted)}
          help={getValidationError(errors?.name, touched?.name ?? isSubmitted)}
        >
          <Input
            name={`${fieldNamePrefix}name`}
            disabled={disabled}
            onChange={handleChange}
            onBlur={onBlur}
            value={values.name}
            placeholder={'My Slack Name'}
          />
        </Form.Item>
      </Col>
      <Col xl={24}>
        <FormItemSC
          label={'Channels'}
          required={true}
          validateStatus={getValidationStatus(errors?.channelNamesWithId as string | string[], isSubmitted)}
          help={getValidationError(errors?.channelNamesWithId as string | string[], isSubmitted)}
          className={'form-item-channels'}
        >
          <Space className={'mb-20'}>
            <Text>
              All channels where the Botkube bot is invited will show up here. To invite the bot just mention @Botkube
              in the channel. Enable the channel to use it with the Botkube instance.
            </Text>
          </Space>
          {channels.length ? (
            <>
              <CloudSlackChannelsTable
                data={channels}
                onChannelSelect={onChannelSelect}
                selectedChannels={values.channelNamesWithId}
                disabled={disabled}
              />
              <InvitationInstructions
                instruction={
                  <>
                    Just mention @Botkube and invite the bot to add the channel you want to connect to.
                    <br /> Alternatively you can add the bot under “Channel details &gt; Integrations &gt; Add Apps”.
                  </>
                }
              />
            </>
          ) : (
            <InvitationInstructions />
          )}
        </FormItemSC>
      </Col>
      <CloudSlackChannelModal
        teamId={values.teamId}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <Modal
        title={'Invalid channel configuration detected'}
        open={invalidChannels.length > 0 && !isInvalidChannelModalForceClosed}
        onCancel={() => setIsInvalidChannelModalForceClosed(true)}
        width={600}
        footer={<Button onClick={() => setIsInvalidChannelModalForceClosed(true)}>OK</Button>}
      >
        {getInvalidChannelsWarningContent(invalidChannels, values.teamId, invalidChannels.length > 1)}
      </Modal>
    </>
  )
}

function populateConnectedSlacksDropdown(connectedPlatforms: Props['connectedPlatforms']) {
  if (!connectedPlatforms || !connectedPlatforms.length) {
    return []
  }

  return connectedPlatforms.map(slack => ({
    value: slack.teamId,
    label: `${slack.name} (${slack.url})`,
  }))
}

function getInvalidChannelsWarningContent(
  invalidChannels: ChannelNameWithId[],
  teamId: string,
  multipleChannels: boolean,
) {
  return (
    <>
      <p>
        Some of the plugins are using invalid {multipleChannels ? 'channels' : 'channel'}. Before continuing please
        unbind invalid {multipleChannels ? 'channels' : 'channel'} from the plugins or invite Botkube Cloud bot to{' '}
        {multipleChannels ? 'these channels' : 'the channel'}. Use the link{multipleChannels && 's'} below to navigate
        directly to the channel{multipleChannels && 's'} and invite the bot:
      </p>
      <UlSC>
        {invalidChannels.map(channel => (
          <li key={channel.id}>
            <Button
              type={'link'}
              href={getChannelInviteURL(teamId, channel.id)}
              target={'_blank'}
              rel='noreferrer'
            >
              #{channel.name}
            </Button>
          </li>
        ))}
      </UlSC>
      <p>After inviting the Botkube bot, close the modal and continue configuring the channels.</p>
    </>
  )
}
