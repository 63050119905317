import { FormikConfig } from 'formik'
import * as yup from 'yup'
import { AnySchema } from 'yup'
import { ValidationMsg } from '../../../../utils/validation'
import { PlatformsType } from '../../../../models/platform/platform'
import { Webhook } from '../../../../models/graphql'
import { HandlePlatformFormSubmit } from '../../../../components/platform/settings/utils'
import { WebhookFormValues } from '../../../../pages/clusters/add/steps/platformsConfiguration/utils'

type Props = {
  values?: Webhook
  onSubmit: HandlePlatformFormSubmit
}
export const initFormik = (props: Props): FormikConfig<WebhookFormValues> => {
  return {
    enableReinitialize: true,
    initialValues: {
      id: props.values?.id ?? '',
      name: props.values?.name ?? '',
      url: props.values?.url ?? '',
    },
    validationSchema: yup.object().shape<Record<keyof WebhookFormValues, AnySchema>>({
      id: yup.string(),
      name: yup.string().required(ValidationMsg.REQUIRED),
      url: yup.string().required(ValidationMsg.REQUIRED),
    }),
    onSubmit: values => {
      props.onSubmit(PlatformsType.WEBHOOK, values)
    },
  }
}
