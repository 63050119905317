import { FC, useEffect, useState } from 'react'
import { v4 } from 'uuid'
import { useFormik } from 'formik'
import { Checkbox, Form, Input, Radio } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio/interface'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

import { Maybe, PolicySubjectType, Rbac } from '../../../../../models/graphql'
import { initFormik } from './rbacForm'
import { InputTag } from '../../../../inputTag/InputTag'
import { DivSC, FormItemSC, FormItemWithExtraSC, FormPartWrapperSC } from './styles'

export const getEmptyRBACConfig = (id?: string): Rbac => ({
  id: id ?? v4(),
  user: {
    type: PolicySubjectType.Empty,
    static: {
      value: '',
    },
  },
  group: {
    type: PolicySubjectType.Empty,
    static: {
      values: [],
    },
  },
})

export type RBACFormValues = {
  id: string
  group?: {
    prefix?: string | null
    static?: {
      values: string[]
    }
    type: PolicySubjectType
  }
  user?: {
    prefix?: string | null
    type: PolicySubjectType
    static?: {
      value: string
    }
  }
}

export type PluginRBACProps = {
  value?: Maybe<Rbac>
  onSubmit: (values: RBACFormValues) => void
  onChange: (isValid: boolean) => void
}

export const PluginRBAC: FC<PluginRBACProps> = props => {
  const { value, onChange } = props

  const customPermissions =
    (value?.group?.type ?? PolicySubjectType.Empty) !== PolicySubjectType.Empty ||
    (value?.user?.type ?? PolicySubjectType.Empty) !== PolicySubjectType.Empty

  const [radioGroupValue, setRadioGroupValue] = useState(customPermissions ? 'custom' : 'default')
  const formik = useFormik<RBACFormValues>(initFormik(props))

  const { isValid } = formik
  // automatically submit form
  useEffect(() => {
    onChange(isValid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  const onCustomRBACToggle = (e: RadioChangeEvent) => {
    const selectedValue = e.target.value as string

    if (selectedValue === 'default') {
      void formik.setValues(getEmptyRBACConfig(value?.id) as RBACFormValues)
      submitFormAfterFieldChange()
    }

    setRadioGroupValue(selectedValue)
  }

  const onImpersonationTypeChange = (e: CheckboxChangeEvent, type: 'user' | 'group') => {
    const checked = e.target.checked
    void formik.setFieldValue(`${type}.type`, checked ? PolicySubjectType.Static : PolicySubjectType.Empty)
    submitFormAfterFieldChange()
  }

  const onGroupValuesChange = (values: string[]) => {
    void formik.setFieldValue(`group.static.values`, values)
    submitFormAfterFieldChange()
  }

  const submitFormAfterFieldChange = () => {
    // workaround for https://github.com/jaredpalmer/formik/issues/529
    setTimeout(() => {
      // eslint-disable-next-line
      if (handleAutoSubmit) {
        // check if it's still there
        handleAutoSubmit()
      }
    }, 50)
  }

  const handleAutoSubmit = () => {
    void formik.submitForm()
  }

  const getCheckboxValueForType = (type?: PolicySubjectType) => type !== PolicySubjectType.Empty

  return (
    <>
      <p>
        Set the permissions of plugins by defining RBAC rules.{' '}
        <a
          href='https://docs.botkube.io/configuration/rbac'
          target={'_blank'}
          rel='noreferrer'
        >
          Learn more
        </a>
      </p>

      <Radio.Group
        buttonStyle='solid'
        value={radioGroupValue}
        onChange={onCustomRBACToggle}
        className={'mb-20'}
      >
        <Radio.Button value='default'>Default</Radio.Button>
        <Radio.Button value='custom'>Custom</Radio.Button>
      </Radio.Group>

      {radioGroupValue === 'default' && (
        <p>
          Applies the default permissions specified for the <b>botkube-plugins-default</b> group.{' '}
          <a
            href='https://docs.botkube.io/configuration/rbac'
            target={'_blank'}
            rel='noreferrer'
          >
            Learn more
          </a>
        </p>
      )}

      {radioGroupValue === 'custom' && (
        <>
          <p>Configure Kubernetes RBAC impersonation</p>
          <Form layout={'vertical'}>
            <DivSC highlighted={formik.values.group?.type !== PolicySubjectType.Empty}>
              <FormItemSC>
                <Checkbox
                  onChange={e => onImpersonationTypeChange(e, 'group')}
                  checked={getCheckboxValueForType(formik.values.group?.type)}
                >
                  Group
                </Checkbox>
              </FormItemSC>

              {formik.values.group?.type !== PolicySubjectType.Empty && (
                <FormPartWrapperSC>
                  <Form.Item label={'Select group mapping type'}>
                    <Radio.Group
                      name={'group.type'}
                      onChange={formik.handleChange}
                      onBlur={handleAutoSubmit}
                      value={formik.values.group?.type}
                    >
                      <Radio value={PolicySubjectType.Static}>Static value</Radio>
                      <Radio value={PolicySubjectType.ChannelName}>Channel Name</Radio>
                    </Radio.Group>
                  </Form.Item>

                  {formik.values.group?.type === PolicySubjectType.Static && (
                    <FormItemWithExtraSC
                      label={'Static value'}
                      required={true}
                      extra={
                        <>
                          At least one group must be provided. If disabled, <b>botkube-plugins-default</b> value will be
                          used.
                        </>
                      }
                    >
                      <InputTag
                        values={formik.values.group.static?.values ?? []}
                        placeholder={'Group name'}
                        onChange={onGroupValuesChange}
                      />
                    </FormItemWithExtraSC>
                  )}

                  {formik.values.group?.type === PolicySubjectType.ChannelName && (
                    <FormItemWithExtraSC
                      label={'Prefix'}
                      extra={`Optional prefix and the channel name combined together are used as the group name for impersonation.`}
                    >
                      <Input
                        onBlur={handleAutoSubmit}
                        name={`group.prefix`}
                        onChange={formik.handleChange}
                        value={formik.values.group.prefix ?? undefined}
                      />
                    </FormItemWithExtraSC>
                  )}
                </FormPartWrapperSC>
              )}
            </DivSC>

            <DivSC highlighted={formik.values.user?.type !== PolicySubjectType.Empty}>
              <FormItemSC>
                <Checkbox
                  onChange={e => onImpersonationTypeChange(e, 'user')}
                  checked={getCheckboxValueForType(formik.values.user?.type)}
                >
                  User
                </Checkbox>
              </FormItemSC>

              {formik.values.user?.type !== PolicySubjectType.Empty && (
                <FormPartWrapperSC>
                  <FormItemWithExtraSC
                    label={'Static value'}
                    required={true}
                    extra={
                      <>
                        If disabled, <b>botkube-internal-static-user</b> value will be used.
                      </>
                    }
                  >
                    <Input
                      onBlur={handleAutoSubmit}
                      name={`user.static.value`}
                      onChange={formik.handleChange}
                      value={formik.values.user?.static?.value ?? undefined}
                    />
                  </FormItemWithExtraSC>
                </FormPartWrapperSC>
              )}
            </DivSC>
          </Form>
        </>
      )}
    </>
  )
}
