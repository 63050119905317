import { FC, useState } from 'react'
import { Button, Typography } from 'antd'
import Cookie from 'js-cookie'

import { ButtonsWrapperSC, CookieBannerSC } from './styles'
import { CookieName } from '../../models/cookie'
import { externalPaths } from '../../router/paths'

const { Paragraph, Link } = Typography

type Props = {
  onAccept: () => void
}

export const CookieBanner: FC<Props> = props => {
  const { onAccept } = props
  const [hide, setHide] = useState(false)

  if (Cookie.get(CookieName.CONSENT) || hide) {
    return null
  }

  const handleAccept = () => {
    Cookie.set(CookieName.CONSENT, '1', { expires: 365 })
    setHide(true)
    onAccept()
  }

  const handleDecline = () => {
    Cookie.set(CookieName.CONSENT, '0', { expires: 365 })
    setHide(true)
  }

  return (
    <CookieBannerSC>
      <Paragraph>
        We use cookies and third-party analytics to provide and continually improve our services.
        <br />
        You can find more information in:{' '}
        <Link
          href={externalPaths.PRIVACY_POLICY}
          target={'_blank'}
        >
          Privacy Policy
        </Link>
        ,{' '}
        <Link
          href={externalPaths.TERMS_OF_SERVICE}
          target={'_blank'}
        >
          Terms of Service
        </Link>{' '}
        and{' '}
        <Link
          href={externalPaths.EULA}
          target={'_blank'}
        >
          EULA
        </Link>
        .
      </Paragraph>
      <ButtonsWrapperSC>
        <Button
          type={'default'}
          onClick={handleDecline}
        >
          Decline
        </Button>
        <Button
          type={'primary'}
          onClick={handleAccept}
        >
          Accept Cookies
        </Button>
      </ButtonsWrapperSC>
    </CookieBannerSC>
  )
}
