import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SlackWorkspace } from './model'

export type SlackWorkspaceState = {
  workspaces: SlackWorkspace[]
}

const initialState: SlackWorkspaceState = {
  workspaces: [],
}

export const slackWorkspaceSlice = createSlice({
  name: 'slackWorkspace',
  initialState,
  reducers: {
    setWorkspaces: (state, action: PayloadAction<SlackWorkspace[]>) => {
      state.workspaces = action.payload
    },
  },
})

export const { setWorkspaces } = slackWorkspaceSlice.actions
