import React, { FC, useCallback, useMemo, useState } from 'react'
import { Button, Flex, Table, Tag } from 'antd'

import {
  getPluginConfigurationConnections,
  getPluginsFromDeploymentForDeploymentPage,
  getPluginTemplate,
  PluginBindingsDeploymentPage,
  PluginValueDeploymentPage,
} from '../../../utils/plugins'
import { PlatformsType } from '../../../models/platform/platform'
import { PluginConfiguration, PluginConfigurationAvailableChannel } from '../configuration/PluginConfiguration'
import { PluginConfigData } from '../configuration/utils'
import { RBACFormValues } from '../configuration/content/rbac/PluginRBAC'
import { PluginGeneralFormValues } from '../configuration/content/overview/PluginGeneralForm'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { setDeploymentPluginsValidReport, updatePluginConfiguration } from '../../../store/slices/deployment/deployment'
import { ColumnsType } from 'antd/lib/table'
import { SettingOutlined } from '@ant-design/icons'
import { PluginTag } from '../tag/PluginTag'
import { PluginBindings } from '../../../pages/clusters/add/steps/plugins/PluginsStep'
import { useMSTeamsChannels } from '../../../utils/customeHooks/useMSTeamsChannels'
import { ReasonForBlockUpdateTooltip } from '../../tooltip/ReasonForBlockUpdateTooltip'
import { PluginHealthIndicator } from '../health/PluginHealthIndicator'

type Props = {
  platformType: PlatformsType
  platformId: string
  availableChannels: PluginConfigurationAvailableChannel[]
}

export const PluginDeploymentGroup: FC<Props> = props => {
  const { platformType, platformId, availableChannels } = props
  const deployment = useAppSelector(state => state.deployment.data)
  const isDeploymentUpdateBlocked = useAppSelector(state => state.deployment.updateBlock.isBlocked)
  const pluginTemplates = useAppSelector(state => state.plugin.pluginTemplates)
  const { msTeamsChannels } = useMSTeamsChannels()

  const [pluginSelected, setPluginSelected] = useState<PluginValueDeploymentPage | undefined>()

  const dispatch = useAppDispatch()

  const pluginsInPlatform = useMemo<PluginValueDeploymentPage[]>(() => {
    if (!deployment || !pluginTemplates) {
      return []
    }
    const plugins = getPluginsFromDeploymentForDeploymentPage(deployment, pluginTemplates)
    return plugins.filter(plugin => plugin.bindings.some(bind => bind.platformId === platformId))
  }, [deployment, pluginTemplates, platformId])

  const findPluginTemplate = useCallback(
    (plugin: PluginValueDeploymentPage) => getPluginTemplate(pluginTemplates ?? [], plugin.name),
    [pluginTemplates],
  )

  const pluginTemplate = useMemo(() => {
    return pluginSelected ? findPluginTemplate(pluginSelected) : undefined
  }, [pluginSelected, findPluginTemplate])

  const handleConfigurationChange = (
    pluginId: string,
    config: PluginConfigData,
    generalSettings: PluginGeneralFormValues,
    rbac?: RBACFormValues,
  ) => {
    dispatch(setDeploymentPluginsValidReport({ pluginId, isValid: true })) // there's no way to submit invalid config
    dispatch(updatePluginConfiguration({ pluginId, config, generalSettings, rbac }))
    setPluginSelected(undefined)
  }

  if (!deployment) {
    return null
  }

  const columns: ColumnsType<PluginValueDeploymentPage> = [
    {
      title: 'Plugin Name',
      dataIndex: 'displayName',
      render: (name: string, record) => (
        <PluginHealthIndicator
          enabled={record.enabled}
          name={name}
          configName={record.configurationName}
        />
      ),
    },
    {
      title: 'Type',
      dataIndex: 'name',
      render: (name: string, record) => (
        <PluginTag
          type={record.type}
          internalName={name}
        >
          {name}
        </PluginTag>
      ),
    },
    {
      title: 'Bound channels',
      dataIndex: 'bindings',
      render: (bindings: PluginBindingsDeploymentPage[]) =>
        bindings
          .filter(bind => bind.platformId === platformId)
          .map(bind =>
            bind.channels.map(ch => (
              <Tag key={ch}>
                {[
                  PlatformsType.CLOUD_SLACK,
                  PlatformsType.CLOUD_TEAMS,
                  PlatformsType.SOCKET_SLACK,
                  PlatformsType.MATTERMOST,
                ].includes(platformType)
                  ? '#'
                  : ''}
                {availableChannels.find(avCh => avCh.value === ch)?.label}
              </Tag>
            )),
          ),
    },
    {
      title: '',
      key: 'actions',
      render: (_, record) => (
        <Flex justify={'end'}>
          <ReasonForBlockUpdateTooltip>
            <Button
              onClick={() => setPluginSelected(record)}
              icon={<SettingOutlined />}
              disabled={isDeploymentUpdateBlocked}
            />
          </ReasonForBlockUpdateTooltip>
        </Flex>
      ),
    },
  ]

  return (
    <>
      <Table
        columns={columns}
        dataSource={pluginsInPlatform}
        rowKey={'id'}
        pagination={false}
      />
      {pluginSelected && (
        <PluginConfiguration
          okText={'Update'}
          selectedPlatformId={platformId}
          pluginConfig={{
            pluginTemplate: pluginTemplate,
            pluginValue: {
              ...pluginSelected,
              bindings: pluginSelected.bindings.reduce<Record<string, PluginBindings>>((bindingsRecord, binding) => {
                bindingsRecord[binding.platformId] = {
                  channels: binding.channels,
                  platform: binding.platformType,
                  platformId: binding.platformId,
                  platformIdx: binding.platformId,
                }
                return bindingsRecord
              }, {}),
              enabled: pluginSelected.enabled,
            },
            connections: getPluginConfigurationConnections(deployment, pluginSelected.type, msTeamsChannels),
          }}
          onCancel={() => setPluginSelected(undefined)}
          onSubmit={(config, generalSettings, _, rbacData) =>
            handleConfigurationChange(pluginSelected.id, config, generalSettings, rbacData)
          }
        />
      )}
    </>
  )
}
