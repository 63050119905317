import { CSSProperties, FC, ReactNode } from 'react'
import { TagProps as TagPropsAntd, Typography } from 'antd'
import { TagSC } from './styles'

export enum TagType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  THIRD = 'third',
  FORTH = 'forth',
  DEFAULT = 'default',
  ENABLE = 'enable',
  DANGER = 'danger',
}
export interface TagProps extends TagPropsAntd {
  children: ReactNode
  ellipsis?: {
    enable: boolean
    width: string
  }
  type?: TagType
  style?: CSSProperties
}

const { Text } = Typography

export const Tag: FC<TagProps> = props => {
  const { children, ellipsis, type = TagType.DEFAULT, ...rest } = props

  return (
    <TagSC
      type={type}
      {...rest}
    >
      <Text
        style={ellipsis ? { maxWidth: ellipsis.width } : {}}
        ellipsis={ellipsis?.enable ? { tooltip: children } : false}
      >
        {children}
      </Text>
    </TagSC>
  )
}
