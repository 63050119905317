import { FC, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { Button, Flex, Table, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { v4 } from 'uuid'
import { PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { RJSFSchema } from '@rjsf/utils'

import { PluginTemplate } from '../../../store/slices/plugin/model'
import { PluginConfiguration, PluginConfigurationProps } from '../configuration/PluginConfiguration'
import { PluginConfigData } from '../configuration/utils'
import { PluginGeneralFormValues } from '../configuration/content/overview/PluginGeneralForm'
import { RBACFormValues } from '../configuration/content/rbac/PluginRBAC'
import { setDeploymentPluginsValidReport, addPluginToDeployment } from '../../../store/slices/deployment/deployment'
import { isSinkPlatform, PlatformsType } from '../../../models/platform/platform'
import { PluginValue } from '../../../pages/clusters/add/steps/plugins/PluginsStep'
import {
  findUniquePluginConfigDisplayName,
  generateConfigName,
  getDefaultsFromSchema,
  getPluginConfigurationConnections,
  isSchemaValid,
  mapPluginNameToIcon,
} from '../../../utils/plugins'
import { getChannelsFromPlatformValues, getPlatformFromDeployment } from '../../../utils/platforms'
import { PluginType } from '../../../models/graphql'
import { PluginTitleSC } from './styles'
import { useMSTeamsChannels } from '../../../utils/customeHooks/useMSTeamsChannels'

type Props = {
  platformType?: PlatformsType
  platformId?: string
}
export const PluginsTemplatesList: FC<Props> = ({ platformId, platformType }) => {
  const deployment = useAppSelector(state => state.deployment.data)
  const pluginTemplates = useAppSelector(state => state.plugin.pluginTemplates)
  const dispatch = useAppDispatch()
  const { msTeamsChannels } = useMSTeamsChannels()
  const [pluginConfig, setPluginConfig] = useState<PluginConfigurationProps | undefined>()

  useEffect(() => {
    if (deployment && pluginConfig) {
      setPluginConfig({
        ...pluginConfig,
        connections: getPluginConfigurationConnections(deployment, pluginConfig.pluginValue.type, msTeamsChannels),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deployment, msTeamsChannels])

  const handleAddPlugin = (template: PluginTemplate) => {
    const schema = template.schema as RJSFSchema
    const defaultVal = getDefaultsFromSchema(schema) ?? {}
    setPluginConfig({
      connections: deployment ? getPluginConfigurationConnections(deployment, template.type, msTeamsChannels) : [],
      pluginTemplate: template,
      pluginValue: {
        name: template.name,
        displayName: findUniquePluginConfigDisplayName(template.title, deployment?.plugins ?? []),
        type: template.type,
        configuration: JSON.stringify(defaultVal),
        configurationName: generateConfigName(template.name),
        enabled: true,
        invalid: !isSchemaValid(schema, defaultVal),
        bindings:
          platformId && platformType
            ? {
                [platformId]: {
                  platform: platformType,
                  platformId: platformId,
                  platformIdx: platformId,
                  channels:
                    (deployment &&
                      getChannelsFromPlatformValues(getPlatformFromDeployment(deployment, platformId)).map(
                        ch => ch.value,
                      )) ??
                    [],
                },
              }
            : undefined,
        rbac: undefined,
      },
    })
  }

  const handleConfigurationChange = (
    data: PluginConfigData,
    generalSettings: PluginGeneralFormValues,
    plugin: PluginValue,
    rbac?: RBACFormValues,
  ) => {
    const pluginId = plugin.id ? plugin.id : v4()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { bindings, rbac: pluginValueRbac, ...pluginValue } = plugin
    dispatch(setDeploymentPluginsValidReport({ pluginId: pluginId, isValid: true })) // there's no way to submit invalid config
    dispatch(
      addPluginToDeployment({
        values: {
          id: pluginId,
          invalid: pluginValue.invalid ?? false,
          ...pluginValue,
        },
        platformId,
        additionalValues: {
          pluginId,
          config: data,
          generalSettings: generalSettings,
          rbac: rbac,
        },
      }),
    )
    setPluginConfig(undefined)
  }

  const columns: ColumnsType<PluginTemplate> = [
    {
      title: 'Name',
      dataIndex: 'title',
      render: (title: string, record) => (
        <PluginTitleSC>
          {mapPluginNameToIcon(record.name, { height: '32px' })} {title}
        </PluginTitleSC>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (item: string) => <div dangerouslySetInnerHTML={{ __html: item }} />,
    },
    {
      key: 'actions',
      title: '',
      render: (_, record) => {
        return (
          <Flex
            gap={'middle'}
            align={'center'}
            justify={'end'}
          >
            {record.documentationUrl && (
              <Typography.Link
                href={record.documentationUrl}
                target={'_blank'}
              >
                <Button icon={<QuestionCircleOutlined />} />
              </Typography.Link>
            )}
            <Button
              icon={<PlusOutlined />}
              onClick={() => handleAddPlugin(record)}
            >
              Add plugin
            </Button>
          </Flex>
        )
      },
    },
  ]

  const data =
    pluginTemplates?.map(pt => {
      return {
        ...pt,
        key: pt.name,
      }
    }) ?? []

  return (
    <>
      <Table
        columns={columns}
        dataSource={
          platformType && isSinkPlatform(platformType) ? data.filter(pt => pt.type === PluginType.Source) : data
        }
        pagination={false}
      />
      {pluginConfig && (
        <PluginConfiguration
          selectedPlatformId={platformId}
          pluginConfig={pluginConfig}
          onCancel={() => setPluginConfig(undefined)}
          onSubmit={handleConfigurationChange}
        />
      )}
    </>
  )
}
