import { useEffect, useState } from 'react'

type LocationSearchParams = {
  organizationId?: string
}
export const useLocationSearchParams = (): LocationSearchParams => {
  const searchParams = Object.fromEntries(new URLSearchParams(window.location.search))
  const [search, setSearch] = useState(searchParams)

  const listenToPopstate = () => {
    const searchParams = Object.fromEntries(new URLSearchParams(window.location.search))
    setSearch(searchParams)
  }

  useEffect(() => {
    window.addEventListener('popstate', listenToPopstate)

    return () => {
      window.removeEventListener('popstate', listenToPopstate)
    }
  }, [])

  return search
}
