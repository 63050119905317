import React, { FC, useContext, useState } from 'react'

import { Button, Col, Flex, Row, Tag, Tooltip } from 'antd'
import { BarChartOutlined, BulbOutlined, UploadOutlined } from '@ant-design/icons'
import { AiCustomDocument, useAiCustomDocsQuery } from '../../models/graphql'
import { AppCtx } from '../../providers/appCtx'
import { CardSC, ColSC, TooltipWrapperSC } from './styles'
import { TableRowSelection } from 'antd/lib/table/interface'
import { Link } from 'react-router-dom'
import { paths } from '../../router/paths'
import { UploadModal } from './UploadModal'
import { DeleteDocsButton } from './DeleteDocs'
import { CustomDocsTable, DataType } from './DocsTable'

const PAGE_LIMIT = 100

export const CustomDocsUpload: FC = () => {
  const { orgID } = useContext(AppCtx)

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [isUploadModalOpen, setUploadModalOpen] = useState(false)

  const [docsList, setDocsList] = useState<AiCustomDocument[]>([])
  const [after, setAfter] = useState<string | null>(null)
  const [isTableLoading, setTableLoading] = useState(false)
  const { data, isLoading, refetch } = useAiCustomDocsQuery(
    {
      limit: PAGE_LIMIT,
      after,
      orgID,
    },
    {
      onSuccess: data => {
        const items = [...docsList, ...(data.organization?.aiAssistant.uploadedAIDocs?.data ?? [])]
        setDocsList(Array.from(new Set(items).values()))
        setTableLoading(false)
      },
      refetchOnWindowFocus: false,
    },
  )
  const fetchMore = () => {
    setAfter(data?.organization?.aiAssistant.uploadedAIDocs?.pageInfo.lastID ?? null)
  }
  const resetTable = () => {
    setAfter(null)
    setDocsList([])
    setSelectedRowKeys([])
    setTableLoading(true)
    void refetch()
  }

  const aiDocsLimit = data?.organization?.quota?.aiCustomDocsCount ?? 0
  const isLimitExceeded = docsList.length >= aiDocsLimit

  const tableData = docsList.map(doc => {
    return {
      key: doc.fileID,
      fileName: doc.fileName,
      bytes: doc.bytes,
      createdAt: doc.createdAt as string,
    }
  })
  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys)
    },
  }

  const uploadButton = (
    <Button
      type='primary'
      icon={<UploadOutlined />}
      disabled={isLimitExceeded}
      onClick={() => setUploadModalOpen(true)}
    >
      Upload file(s)
    </Button>
  )

  return (
    <>
      <CardSC
        className={'mt-20'}
        title={
          <>
            <BulbOutlined /> Knowledge Documents
            <TooltipWrapperSC>
              <Tooltip
                title={`You can upload up to ${aiDocsLimit} documents. Upgrade your Botkube Cloud plan to upload more documents.`}
              >
                <Link to={paths.SUBSCRIPTION_PLANS}>
                  <Tag icon={<BarChartOutlined />}>
                    {docsList.length} of {aiDocsLimit} docs uploaded
                  </Tag>
                </Link>
              </Tooltip>
            </TooltipWrapperSC>
          </>
        }
        extra={
          <Flex
            gap='small'
            wrap
          >
            <DeleteDocsButton
              selectedRowKeys={selectedRowKeys}
              onDeleteFinish={resetTable}
              onUploadStart={() => setTableLoading(true)}
            />

            {isLimitExceeded ? (
              <Tooltip title={'Upgrade your Botkube Cloud plan to upload more documents.'}>{uploadButton}</Tooltip>
            ) : (
              uploadButton
            )}
          </Flex>
        }
      >
        <Row>
          <ColSC lg={8}>
            <p>
              Fuse can utilize the supplied documentation to provide better answers tailored to your architecture and
              processes. For example, this may include architectural documents, onboarding materials, runbooks,
              post-mortems, and more.
            </p>
            <p>
              You retain all ownership rights and your data is never used for training. See our{' '}
              <a
                href='https://botkube.io/privacy-policy'
                target='_blank'
                rel='noreferrer'
              >
                Privacy Policy
              </a>{' '}
              for more details. Supported file formats include: .doc, .docx, .md, .pdf, .txt. See the{' '}
              <a
                href={'https://platform.openai.com/docs/assistants/tools/file-search/supported-files'}
                target='_blank'
                rel='noreferrer'
              >
                full list of supported files
              </a>
              .
            </p>
            Your knowledge documents are shared between all Fuse installations within your organization.
          </ColSC>
          <Col lg={16}>
            <CustomDocsTable
              isLoading={isTableLoading || isLoading}
              data={tableData}
              rowSelection={rowSelection}
              totalCount={data?.organization?.aiAssistant.uploadedAIDocs?.totalCount}
              fetchMore={fetchMore}
              canFetchMore={data?.organization?.aiAssistant.uploadedAIDocs?.pageInfo.hasNextPage}
            />
          </Col>
        </Row>
      </CardSC>
      <UploadModal
        isOpen={isUploadModalOpen}
        customDocsReadyToUpload={data?.organization?.aiAssistant.customDocsReadyToUpload}
        onEnableDocsSuccess={() => void refetch()}
        onUploadFinish={resetTable}
        closeModal={() => setUploadModalOpen(false)}
        totalUploadedDocsCount={docsList.length}
        totalDocsLimit={aiDocsLimit}
      />
    </>
  )
}
