import { Alert } from 'antd'
import styled from 'styled-components'

export const AlertBarSC = styled.div`
  border-bottom: 1px solid #ffccc7;
  background-color: #fff2f0;
  display: block;
  width: 100%;
  padding: 18px 16px;
`
export const AlertSC = styled(Alert)`
  border: 0;
  background: transparent;
  padding: 0;
`
