import { FC } from 'react'
import { ConnectLabelSC } from './styles'
import { Tag, TagType } from '../../../../../../components/tag/Tag'
import { Typography } from 'antd'

type Props = {
  title: string
  description: string
  selected: boolean
  recommended?: boolean
  onClick: () => void
}
export const ConnectLabel: FC<Props> = ({ selected, onClick, title, description, recommended }) => {
  return (
    <ConnectLabelSC
      onClick={onClick}
      selected={selected}
    >
      <Typography.Title level={3}>
        {title} {recommended && <Tag type={TagType.THIRD}>Recommended</Tag>}
      </Typography.Title>
      <Typography.Paragraph>{description}</Typography.Paragraph>
    </ConnectLabelSC>
  )
}
