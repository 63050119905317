import styled from 'styled-components'
import { Alert, theme } from 'antd'

type DeploymentInfoSCProps = {
  iconType?: 'error' | 'warning'
}
export const DeploymentInfoSC = styled.div<DeploymentInfoSCProps>`
  background: ${props =>
    props.iconType === 'error' ? theme.getDesignToken().colorErrorBg : theme.getDesignToken().colorWarningBg};
  border-bottom: 1px solid
    ${props =>
      props.iconType === 'error' ? theme.getDesignToken().colorErrorBorder : theme.getDesignToken().colorWarningBorder};
`

export const AlertSC = styled(Alert)`
  padding-right: 0;
  padding-left: 0;
`
