import { FormikConfig } from 'formik'
import {
  CloudSlackFormValues,
  platformDefaultValue,
} from '../../../../pages/clusters/add/steps/platformsConfiguration/utils'
import { PlatformsType } from '../../../../models/platform/platform'
import * as yup from 'yup'
import { AnySchema } from 'yup'
import { ValidationMsg } from '../../../../utils/validation'
import { CloudSlack } from '../../../../models/graphql'
import { HandlePlatformFormSubmit } from '../../../../components/platform/settings/utils'

type Props = {
  values?: CloudSlack
  onSubmit: HandlePlatformFormSubmit
}
export const initFormik = ({ values, onSubmit }: Props): FormikConfig<CloudSlackFormValues> => {
  const initialValues: CloudSlackFormValues = values
    ? {
        id: values.id,
        name: values.name,
        teamId: values.teamId,
        channelNamesWithId: values.channels.map(channel => {
          return {
            id: channel.channelId,
            name: channel.name,
          }
        }),
      }
    : { ...(platformDefaultValue[PlatformsType.CLOUD_SLACK] as CloudSlackFormValues), id: '' }

  return {
    enableReinitialize: true,
    initialValues,
    validationSchema: yup.object().shape<Record<keyof CloudSlackFormValues, AnySchema>>({
      id: yup.string(),
      name: yup.string().required(ValidationMsg.REQUIRED),
      teamId: yup.string().required(ValidationMsg.REQUIRED),
      channelNamesWithId: yup
        .array()
        .of(
          yup.object().shape({
            id: yup.string().required(ValidationMsg.REQUIRED),
            name: yup.string().required(ValidationMsg.REQUIRED),
          }),
        )
        .min(1, 'At least one channel must be selected')
        .required(ValidationMsg.REQUIRED),
    }),
    onSubmit: values => {
      onSubmit(PlatformsType.CLOUD_SLACK, values)
    },
  }
}
