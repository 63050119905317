import { Document } from '@contentful/rich-text-types'

export enum ContentType {
  COMMON = 'common',
  HOME_PAGE = 'homePage',
  INSTANCE_PAGE = 'instancePage',
  AI_ASSISTANT_PAGE = 'aiAssistantPage',
  ALIASES_PAGE = 'aliasesPage',
  AUDIT_LOGS_PAGE = 'auditLogsPage',
  PRIVACY_PAGE = 'privacyPage',
  TERMS_OF_SERVICE_PAGE = 'termsOfServicePage',
  EULA_PAGE = 'endUserLicenseAgreementPage',
  ORGANIZATION_PAGE = 'organizationPage',
  SUBSCRIPTION_FEATURE = 'manageSubscriptionFeature',
  SLACK_PLATFORM_TYPE = 'slackPlatformType',
  SLACK_CLOUD_PLATFORM_INSTRUCTION = 'slackCloudPlatformInstruction',
  SLACK_PLATFORM_INSTRUCTION = 'slackPlatformInstruction',
  TEAMS_CLOUD_PLATFORM_INSTRUCTION = 'teamsCloudPlatformInstruction',
  EMBEDDED_SLACK_PLATFORM_INSTRUCTION = 'embeddedSlackPlatformInstruction',
  MATTERMOST_PLATFORM_INSTRUCTION = 'mattermostPlatformInstruction',
  DISCORD_PLATFORM_INSTRUCTION = 'discordPlatformInstruction',
  WEBHOOK_PLATFORM_INSTRUCTION = 'webhookPlatformInstruction',
  PAGER_DUTY_PLATFORM_INSTRUCTION = 'pagerDutyPlatformInstruction',
  ELASTICSEARCH_PLATFORM_INSTRUCTION = 'elasticsearchPlatformInstruction',
}

export type FieldContentful = {
  sys?: {
    id: string
    type: 'Asset'
  }
  url?: string
}

export type AssetContentful = {
  fields: {
    file: {
      url: string
    }
  }
  sys: {
    id: string
  }
}

export type CommonSkeleton = {
  contentTypeId: ContentType.COMMON
  fields: {
    updateBlockedAlert: string
  }
}

export type HomeSkeleton = {
  contentTypeId: ContentType.HOME_PAGE
  fields: {
    pageTitle: string
    pageDescription: string
    pageTitleWithoutInstances: string
    pageDescriptionWithoutInstances: string
  }
}

export type InstanceSkeleton = {
  contentTypeId: ContentType.INSTANCE_PAGE
  fields: {
    pluginsSectionTitle: string
    pluginsUnboundSectionTitle: string
    channelsSectionTitle: string
    actionsSectionTitle: string
    platformSettingsSectionTitle: string
    apiKeySectionTitle: string
    apiKeySectionDescription: string
  }
}

export type AIAssistantSkeleton = {
  contentTypeId: ContentType.AI_ASSISTANT_PAGE
  fields: {
    pageTitle: string
    aiAssistantDescription: string
    documentsTitle: string
    documentsDescription: string
  }
}

export type AliasesSkeleton = {
  contentTypeId: ContentType.ALIASES_PAGE
  fields: {
    pageTitle: string
  }
}

export type AuditLogsSkeleton = {
  contentTypeId: ContentType.AUDIT_LOGS_PAGE
  fields: {
    pageTitle: string
  }
}

export type StaticContentSkeleton = {
  contentTypeId: ContentType.AUDIT_LOGS_PAGE
  fields: {
    content: Document
  }
}

export type SubscriptionFeatureSkeleton = {
  contentTypeId: ContentType.SUBSCRIPTION_FEATURE
  fields: {
    title: string
    description: string
    icon: {
      sys: {
        id: string
      }
      url?: string
    }
    showInReasonToUpgrade: boolean
    comingSoon: boolean
    order: number
    planData?: {
      key?: string
      FREE?: string | number
      TEAM?: string | boolean
      TEAM_PLUS?: string | boolean
      ENTERPRISE?: string | boolean
    }
  }
}

export type OrganizationSkeleton = {
  contentTypeId: ContentType.ORGANIZATION_PAGE
  fields: {
    checkoutSuccessAlertTitle: string
    checkoutSuccessAlertDescription: string
  }
}

export type SlackPlatformTypeSkeleton = {
  contentTypeId: ContentType.SLACK_PLATFORM_TYPE
  fields: {
    title: string
    features: string[]
    subscriptionRequired: boolean
  }
}

export type SlackCloudPlatformInstructionSkeleton = {
  contentTypeId: ContentType.SLACK_CLOUD_PLATFORM_INSTRUCTION
  fields: {
    title: string
    description: string
    attachAddToSlackButton: boolean
    order: number
  }
}

export type TeamsCloudPlatformInstructionSkeleton = {
  contentTypeId: ContentType.TEAMS_CLOUD_PLATFORM_INSTRUCTION
  fields: {
    title: string
    description: string
    warningDescription: string
    attachDownloadMsTeamsApp: boolean
    order: number
  }
}

export type PlatformInstructionTypes =
  | ContentType.SLACK_PLATFORM_INSTRUCTION
  | ContentType.EMBEDDED_SLACK_PLATFORM_INSTRUCTION
  | ContentType.DISCORD_PLATFORM_INSTRUCTION
  | ContentType.MATTERMOST_PLATFORM_INSTRUCTION
  | ContentType.PAGER_DUTY_PLATFORM_INSTRUCTION
  | ContentType.WEBHOOK_PLATFORM_INSTRUCTION
  | ContentType.ELASTICSEARCH_PLATFORM_INSTRUCTION
  | ContentType.TEAMS_CLOUD_PLATFORM_INSTRUCTION

export type PlatformInstructionSkeleton = {
  contentTypeId: PlatformInstructionTypes
  fields: {
    title: string
    description: string
    buttonLabel: string
    buttonLink: string
    order: number
    documentationUrl?: string
  }
}

export type ContentSkeleton =
  | HomeSkeleton
  | CommonSkeleton
  | InstanceSkeleton
  | AliasesSkeleton
  | AuditLogsSkeleton
  | StaticContentSkeleton
  | SubscriptionFeatureSkeleton
  | OrganizationSkeleton
  | SlackPlatformTypeSkeleton
  | SlackCloudPlatformInstructionSkeleton
