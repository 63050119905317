import { FC, ReactNode, useContext, useState } from 'react'
import { Button, Layout, Space } from 'antd'
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons'
import { useNavigate, useParams } from 'react-router-dom'
import { Container } from '../container/Container'
import { Header } from '../../components/header/Header'
import { UpdateBlockedAlert } from '../../components/orgUpdateBlocked/UpdateBlockedAlert'
import { SlackReinstallRequiredAlert } from '../../components/slackReinstallRequired/SlackReinstallRequiredAlert'
import { AppCtx } from '../../providers/appCtx'
import { paths } from '../../router/paths'
import { useViewport } from '../../utils/customeHooks/useViewport'
import { breakpoints } from '../../styles/globalStyles'
import { ContentSC, TitleSC } from './styles'
import { DeleteDeployment } from '../../pages/clusters/delete/DeleteDeployment'
import { useDeploymentPageQuery } from '../../models/graphql'

const { Header: ADHeader } = Layout

type Props = {
  children?: ReactNode
}
export const WizardLayout: FC<Props> = props => {
  const appCtx = useContext(AppCtx)
  const navigate = useNavigate()
  const { width } = useViewport()
  const { id = '' } = useParams()
  const { data } = useDeploymentPageQuery({ id }, { refetchOnWindowFocus: false })
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  return (
    <Layout>
      <ADHeader ref={appCtx.headerRef}>
        <UpdateBlockedAlert />
        <SlackReinstallRequiredAlert />
        <Container>
          <Header>
            <TitleSC
              level={4}
              hidden={width < breakpoints.lgMin}
            >
              Create Instance
            </TitleSC>
            <Space>
              {data?.deployment && (
                <DeleteDeployment
                  deployment={data.deployment}
                  isOpened={isDeleteModalOpen}
                  setOpened={setIsDeleteModalOpen}
                />
              )}
              <Button
                danger={true}
                onClick={() => setIsDeleteModalOpen(true)}
                icon={<DeleteOutlined />}
              >
                {width < breakpoints.mdMin ? 'Discard' : 'Discard Instance'}
              </Button>
              <Button
                onClick={() => {
                  navigate(paths.HOME)
                }}
                icon={<CloseOutlined style={{ lineHeight: '25px' }} />}
              >
                {width < breakpoints.mdMin ? 'Return' : 'Return to dashboard'}
              </Button>
            </Space>
          </Header>
        </Container>
      </ADHeader>
      <ContentSC>{props.children}</ContentSC>
    </Layout>
  )
}
