import { FC } from 'react'
import { Col, Form, Input, Row } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { getValidationError, getValidationStatus } from '../../../utils/formik/formik'
import { gutter } from '../../../styles/globalStyles'
import { useFormik } from 'formik'
import { initFormik } from './utils'

const colProps = {
  xs: 24,
  sm: 24,
  md: 18,
  lg: 12,
  xl: 8,
}

export type Props = {
  name: string
  isFetching?: boolean
  onSubmit: (values: InstanceNameFormValues) => void
}

export type InstanceNameFormValues = {
  name: string
}

export const InstanceNameForm: FC<Props> = props => {
  const { isFetching } = props
  const formik = useFormik(initFormik(props))

  return (
    <Form
      layout={'vertical'}
      size={'large'}
    >
      <Row gutter={gutter}>
        <Col {...colProps}>
          <Form.Item
            label={'Instance Name'}
            required={true}
            validateStatus={getValidationStatus(formik.errors.name, formik.touched.name)}
            help={getValidationError(
              formik.errors.name,
              formik.touched.name,
              'Choose a name to easily identify your Botkube instance later.',
            )}
          >
            <Input
              name={'name'}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={() => void formik.submitForm()}
              suffix={isFetching && <LoadingOutlined />}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
