import { FC, useMemo } from 'react'
import { Button, Tabs } from 'antd'
import { useAppSelector } from '../../../store/store'
import { PluginsTemplatesList } from '../templateList/PluginsTemplatesList'
import { ExistingPluginsList } from '../existingList/ExistingPluginsList'
import { isSinkPlatform, PlatformsType } from '../../../models/platform/platform'
import { PluginType } from '../../../models/graphql'
import { getPlatformFromDeployment, mapPlatformTypeToIcon } from '../../../utils/platforms'
import { DrawerSC } from './styles'
import { useViewport } from '../../../utils/customeHooks/useViewport'
import { breakpoints } from '../../../styles/globalStyles'

type Props = {
  isOpened: boolean
  onClose: () => void
  hideExisting?: boolean
  platformType?: PlatformsType
  platformId?: string
}

export const AddPlugin: FC<Props> = ({ hideExisting, platformId, platformType, isOpened, onClose }) => {
  const { width } = useViewport()
  const deployment = useAppSelector(state => state.deployment.data)
  const platform = useMemo(() => {
    if (deployment && platformId) {
      return getPlatformFromDeployment(deployment, platformId)
    }
    return null
  }, [deployment, platformId])
  const pluginsAmount =
    platformType && isSinkPlatform(platformType)
      ? deployment?.plugins.filter(plug => plug.type === PluginType.Source).length
      : deployment?.plugins.length

  return (
    <DrawerSC
      title={
        platformType ? (
          <>
            {mapPlatformTypeToIcon(platformType, { height: '16px' })} {platform?.name}
          </>
        ) : (
          'Add new plugin'
        )
      }
      width={width < breakpoints.lgMin ? '100%' : '85%'}
      open={isOpened}
      closeIcon={false}
      onClose={onClose}
      extra={<Button onClick={onClose}>Close</Button>}
    >
      <Tabs
        items={[
          {
            key: 'new',
            label: 'New plugins',
            children: (
              <PluginsTemplatesList
                platformType={platformType}
                platformId={platformId}
              />
            ),
          },
          {
            key: 'existing',
            label: `Existing plugins (${pluginsAmount ?? 0})`,
            disabled: hideExisting,
            children: (
              <ExistingPluginsList
                platformType={platformType}
                platformId={platformId}
              />
            ),
          },
        ]}
      />
    </DrawerSC>
  )
}
