import { ReactNode } from 'react'
import { FormikProps, FormikValues } from 'formik'

import { PlatformsType, PlatformsValues } from '../../../models/platform/platform'
import {
  CloudMsTeams,
  CloudSlack,
  Discord,
  Elasticsearch,
  Mattermost,
  PagerDuty,
  SocketSlack,
  Webhook,
} from '../../../models/graphql'
import { SocketSlackInstanceForm } from '../../../forms/instance/platforms/socketSlack/socketSlackInstance'
import { DiscordInstanceForm } from '../../../forms/instance/platforms/discord/discordInstance'
import { MattermostInstanceForm } from '../../../forms/instance/platforms/mattermost/mattermostInstance'
import { CloudTeamsUpdateContent } from '../../../forms/instance/platforms/cloudTeams/CloudTeamsUpdateContent'
import { WebhookInstanceForm } from '../../../forms/instance/platforms/webhook/webhookInstance'
import { ElasticsearchInstanceForm } from '../../../forms/instance/platforms/elasticsearch/elasticsearchInstance'
import { CloudSlackUpdateContent } from '../../../forms/instance/platforms/cloudSlack/CloudSlackUpdateContent'
import {
  CloudSlackFormValues,
  CloudTeamsFormValues,
  DiscordFormValues,
  ElasticSearchFormValues,
  MattermostFormValues,
  PagerDutyFormValues,
  PlatformsFormValuesWithChannels,
  SocketSlackFormValues,
  WebhookFormValues,
} from '../../../pages/clusters/add/steps/platformsConfiguration/utils'
import { initFormik as initFormikCloudSlack } from '../../../forms/instance/platforms/cloudSlack/utils'
import { initFormik as initFormikSocketSlack } from '../../../forms/instance/platforms/socketSlack/utils'
import { initFormik as initFormikCloudMsTeams } from '../../../forms/instance/platforms/cloudTeams/utils'
import { initFormik as initFormikDiscord } from '../../../forms/instance/platforms/discord/utils'
import { initFormik as initFormikMattermost } from '../../../forms/instance/platforms/mattermost/utils'
import { initFormik as initFormikES } from '../../../forms/instance/platforms/elasticsearch/utils'
import { initFormik as initFormikWebhook } from '../../../forms/instance/platforms/webhook/utils'
import { initFormik as initFormikPagerDuty } from '../../../forms/instance/platforms/pagerDuty/utils'
import { PagerDutyInstanceForm } from '../../../forms/instance/platforms/pagerDuty/pagerDutyInstance'

export type HandlePlatformFormSubmit = (type: PlatformsType, values: PlatformsFormValuesWithChannels) => void

type RenderPlatformSettingsArg = {
  formik: FormikProps<FormikValues>
  platformType: PlatformsType
}
export const renderPlatformSettingsForm = ({ formik, platformType }: RenderPlatformSettingsArg): ReactNode => {
  switch (platformType) {
    case PlatformsType.CLOUD_SLACK:
      return <CloudSlackUpdateContent formik={formik as unknown as FormikProps<CloudSlackFormValues>} />
    case PlatformsType.SOCKET_SLACK:
      return <SocketSlackInstanceForm formik={formik as unknown as FormikProps<SocketSlackFormValues>} />
    case PlatformsType.DISCORD:
      return <DiscordInstanceForm formik={formik as unknown as FormikProps<DiscordFormValues>} />
    case PlatformsType.MATTERMOST:
      return <MattermostInstanceForm formik={formik as unknown as FormikProps<MattermostFormValues>} />
    case PlatformsType.CLOUD_TEAMS:
      return <CloudTeamsUpdateContent formik={formik as unknown as FormikProps<CloudTeamsFormValues>} />
    case PlatformsType.WEBHOOK:
      return <WebhookInstanceForm formik={formik as unknown as FormikProps<WebhookFormValues>} />
    case PlatformsType.PAGER_DUTY:
      return <PagerDutyInstanceForm formik={formik as unknown as FormikProps<PagerDutyFormValues>} />
    case PlatformsType.ELASTIC_SEARCH:
      return <ElasticsearchInstanceForm formik={formik as unknown as FormikProps<ElasticSearchFormValues>} />
  }
}

type InitFormikForPlatformArgs = {
  type: PlatformsType
  values?: PlatformsValues
  onSubmit: HandlePlatformFormSubmit
}
export const initFormikForPlatform = ({ type, values, onSubmit }: InitFormikForPlatformArgs) => {
  switch (type) {
    case PlatformsType.CLOUD_SLACK:
      return initFormikCloudSlack({ values: values as CloudSlack, onSubmit })
    case PlatformsType.SOCKET_SLACK:
      return initFormikSocketSlack({ values: values as SocketSlack, onSubmit })
    case PlatformsType.CLOUD_TEAMS:
      return initFormikCloudMsTeams({ values: values as CloudMsTeams, onSubmit })
    case PlatformsType.DISCORD:
      return initFormikDiscord({ values: values as Discord, onSubmit })
    case PlatformsType.MATTERMOST:
      return initFormikMattermost({ values: values as Mattermost, onSubmit })
    case PlatformsType.ELASTIC_SEARCH:
      return initFormikES({ values: values as Elasticsearch, onSubmit })
    case PlatformsType.WEBHOOK:
      return initFormikWebhook({ values: values as Webhook, onSubmit })
    case PlatformsType.PAGER_DUTY:
      return initFormikPagerDuty({ values: values as PagerDuty, onSubmit })
  }
}

export const mapPlatformTypeToDocs = {
  [PlatformsType.CLOUD_SLACK]: 'https://docs.botkube.io/installation/slack/cloud-slack/',
  [PlatformsType.SOCKET_SLACK]: 'https://docs.botkube.io/installation/slack/socket-slack',
  [PlatformsType.CLOUD_TEAMS]: 'https://docs.botkube.io/installation/teams/',
  [PlatformsType.MATTERMOST]: 'https://docs.botkube.io/installation/mattermost/',
  [PlatformsType.DISCORD]: 'https://docs.botkube.io/installation/discord/',
  [PlatformsType.WEBHOOK]: 'https://docs.botkube.io/installation/webhook/',
  [PlatformsType.ELASTIC_SEARCH]: 'https://docs.botkube.io/installation/elasticsearch/',
  [PlatformsType.PAGER_DUTY]: 'https://docs.botkube.io/installation/pagerduty/',
}
