import styled from 'styled-components'
import { Form } from 'antd'

export const FormItemSC = styled(Form.Item)`
  .ant-form-item-explain-error {
    margin-bottom: 10px;
  }
  .ant-table-pagination.ant-pagination {
    margin-bottom: 0;
  }
`
