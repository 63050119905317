import { Form, Input, Row } from 'antd'
import { FC } from 'react'
import { FormikProps } from 'formik'
import { getValidationError, getValidationStatus } from '../../../../utils/formik/formik'
import { WebhookFormValues } from '../../../../pages/clusters/add/steps/platformsConfiguration/utils'
import { ContentType } from '../../../../services/contentful/types'
import { PlatformContentInstruction } from '../../../../pages/clusters/add/steps/platformsConfiguration/contents/PlatformContentInstruction'
import { ContentColumnRight } from '../../../../pages/clusters/add/steps/platformsConfiguration/contents/columns/ContentColumnRight'
import { PlatformEmbeddedDocs } from '../../../../pages/clusters/add/steps/platformsConfiguration/contents/PlatformEmbeddedDocs'
import { ContentColumnLeft } from '../../../../pages/clusters/add/steps/platformsConfiguration/contents/columns/ContentColumnLeft'

export type WebhookInstanceFormProps = {
  formik: FormikProps<WebhookFormValues>
}

export const WebhookInstanceForm: FC<WebhookInstanceFormProps> = ({ formik }) => {
  return (
    <Row>
      <ContentColumnLeft>
        <PlatformContentInstruction platformInstructionType={ContentType.WEBHOOK_PLATFORM_INSTRUCTION} />
        <Form layout={'vertical'}>
          <Form.Item
            label={'Display Name'}
            required={true}
            validateStatus={getValidationStatus(formik.errors.name, formik.touched.name)}
            help={getValidationError(formik.errors.name, formik.touched.name)}
          >
            <Input
              name={`name`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              placeholder={'My Teams Name'}
            />
          </Form.Item>
          <Form.Item
            label={'URL'}
            required={true}
            validateStatus={getValidationStatus(formik.errors.url, formik.touched.url)}
            help={getValidationError(formik.errors.url, formik.touched.url)}
          >
            <Input
              name={`url`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.url}
              placeholder={'https://my.server.com'}
            />
          </Form.Item>
        </Form>
      </ContentColumnLeft>
      <ContentColumnRight>
        <PlatformEmbeddedDocs platformInstructionType={ContentType.WEBHOOK_PLATFORM_INSTRUCTION} />
      </ContentColumnRight>
    </Row>
  )
}
