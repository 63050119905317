import { Maybe, PolicySubjectType, Rbac } from '../models/graphql'
import { RBACFormValues } from '../components/plugin/configuration/content/rbac/PluginRBAC'
import { v4 } from 'uuid'

export const getInitialRBACFormValues = (value?: Maybe<Rbac>): RBACFormValues => {
  return {
    id: value?.id ? value.id : v4(),
    group: {
      static: {
        values: value?.group?.static?.values ?? [],
      },
      type: value?.group?.type ?? PolicySubjectType.Empty,
      prefix: value?.group?.prefix,
    },
    user: {
      static: {
        value: value?.user?.static?.value ?? '',
      },
      type: value?.user?.type ?? PolicySubjectType.Empty,
      prefix: value?.user?.prefix,
    },
  }
}
