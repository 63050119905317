import { FC, useContext } from 'react'
import { FormikErrors, FormikProps } from 'formik'
import { Spin } from 'antd'
import { SocketSlackFormValues } from '../../../../pages/clusters/add/steps/platformsConfiguration/utils'
import { useConnectedSlackPlatformsQuery } from '../../../../models/graphql'
import { CloudSlackContentInner } from './CloudSlackContent'
import { AppCtx } from '../../../../providers/appCtx'
import { CloudSlackFormValues } from '../../../../pages/clusters/add/steps/platformsConfiguration/utils'
import { setWorkspaces } from '../../../../store/slices/slackWorkspace/slackWorkspace'
import { useAppDispatch } from '../../../../store/store'

export type CloudSlackInstanceFormProps = {
  formik: FormikProps<CloudSlackFormValues>
}

export const CloudSlackUpdateContent: FC<CloudSlackInstanceFormProps> = ({ formik }) => {
  const appCtx = useContext(AppCtx)
  const dispatch = useAppDispatch()
  const { data: connectedPlatform, isLoading: isSlackWorkspaceLoading } = useConnectedSlackPlatformsQuery(
    { id: appCtx.orgID },
    {
      refetchOnWindowFocus: 'always',
      onSuccess: data => {
        dispatch(setWorkspaces(data.organization?.connectedPlatforms?.slacks ?? []))
      },
    },
  )

  const isSubmitted = formik.submitCount > 0

  return (
    <Spin
      spinning={!connectedPlatform && isSlackWorkspaceLoading}
      tip={'Fetching Slack channels...'}
    >
      <CloudSlackContentInner
        handleChange={formik.handleChange}
        values={formik.values}
        errors={formik.errors as FormikErrors<SocketSlackFormValues> | undefined}
        touched={formik.touched}
        isSubmitted={isSubmitted}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        setFieldValue={formik.setFieldValue}
        connectedPlatforms={connectedPlatform?.organization?.connectedPlatforms?.slacks}
        isSlackWorkspaceLoading={isSlackWorkspaceLoading}
      />
    </Spin>
  )
}
