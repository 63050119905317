import { FC, useContext } from 'react'
import { PlatformsType } from '../../../../../../models/platform/platform'
import { SlackContent } from './SlackContent'
import { DiscordContent } from './DiscordContent'
import {
  CloudSlackFormValues,
  DiscordFormValues,
  FormikPlatformsConfig,
  MattermostFormValues,
  PagerDutyFormValues,
  SocketSlackFormValues,
  WebhookFormValues,
} from '../utils'
import { MattermostContent } from './MattermostContent'
import { FormikErrors } from 'formik'
import { CloudSlackContent } from '../../../../../../forms/instance/platforms/cloudSlack/CloudSlackContent'
import { WebhookContent } from './WebhookContent'
import { ElasticsearchContent } from './ElasticsearchContent'
import { CloudTeamsContent } from '../../../../../../forms/instance/platforms/cloudTeams/CloudTeamsContent'
import { AppCtx } from '../../../../../../providers/appCtx'
import { useConnectedSlackPlatformsQuery } from '../../../../../../models/graphql'
import { PagerDutyContent } from './PagerDutyContent'
import { useAppDispatch } from '../../../../../../store/store'
import { setWorkspaces } from '../../../../../../store/slices/slackWorkspace/slackWorkspace'

type Props = {
  platformType: PlatformsType
  formik: FormikPlatformsConfig
  idx: number
}
export const PlatformsContent: FC<Props> = props => {
  const { platformType, formik, idx } = props
  const isSubmitted = formik.submitCount > 0
  const appCtx = useContext(AppCtx)
  const dispatch = useAppDispatch()
  const { data: connectedPlatform, isLoading: isSlackWorkspaceLoading } = useConnectedSlackPlatformsQuery(
    { id: appCtx.orgID },
    {
      refetchOnWindowFocus: 'always',
      onSuccess: data => {
        dispatch(setWorkspaces(data.organization?.connectedPlatforms?.slacks ?? []))
      },
    },
  )

  switch (platformType) {
    case PlatformsType.CLOUD_SLACK:
      return (
        <CloudSlackContent
          handleChange={formik.handleChange}
          values={formik.values.cloudSlacks[idx]}
          errors={formik.errors.cloudSlacks?.[idx] as FormikErrors<CloudSlackFormValues> | undefined}
          touched={formik.touched.cloudSlacks?.[idx]}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          setFieldValue={formik.setFieldValue}
          fieldNamePrefix={`${PlatformsType.CLOUD_SLACK}.[${idx}].`}
          isSubmitted={isSubmitted}
          isSlackWorkspaceLoading={isSlackWorkspaceLoading}
          connectedPlatforms={connectedPlatform?.organization?.connectedPlatforms?.slacks}
        />
      )
    case PlatformsType.SOCKET_SLACK:
      return (
        <SlackContent
          handleChange={formik.handleChange}
          values={formik.values.socketSlacks[idx]}
          errors={formik.errors.socketSlacks?.[idx] as FormikErrors<SocketSlackFormValues> | undefined}
          touched={formik.touched.socketSlacks?.[idx]}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          setFieldValue={formik.setFieldValue}
          idx={idx}
          isSubmitted={isSubmitted}
        />
      )
    case PlatformsType.DISCORD:
      return (
        <DiscordContent
          handleChange={formik.handleChange}
          values={formik.values.discords[idx]}
          errors={formik.errors.discords?.[idx] as FormikErrors<DiscordFormValues> | undefined}
          touched={formik.touched.discords?.[idx]}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          setFieldValue={formik.setFieldValue}
          idx={idx}
          isSubmitted={isSubmitted}
        />
      )
    case PlatformsType.CLOUD_TEAMS:
      return (
        <CloudTeamsContent
          handleChange={formik.handleChange}
          values={formik.values.cloudMsTeams[idx]}
          errors={formik.errors.cloudMsTeams?.[idx] as FormikErrors<CloudSlackFormValues> | undefined}
          touched={formik.touched.cloudMsTeams?.[idx]}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          setFieldValue={formik.setFieldValue}
          fieldNamePrefix={`${PlatformsType.CLOUD_TEAMS}.[${idx}].`}
          isSubmitted={isSubmitted}
        />
      )
    case PlatformsType.MATTERMOST:
      return (
        <MattermostContent
          handleChange={formik.handleChange}
          values={formik.values.mattermosts[idx]}
          errors={formik.errors.mattermosts?.[idx] as FormikErrors<MattermostFormValues> | undefined}
          touched={formik.touched.mattermosts?.[idx]}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          setFieldValue={formik.setFieldValue}
          isSubmitted={isSubmitted}
          idx={idx}
        />
      )
    case PlatformsType.WEBHOOK:
      return (
        <WebhookContent
          handleChange={formik.handleChange}
          values={formik.values.webhooks[idx]}
          errors={formik.errors.webhooks?.[idx] as FormikErrors<WebhookFormValues> | undefined}
          touched={formik.touched.webhooks?.[idx]}
          isSubmitted={isSubmitted}
          idx={idx}
        />
      )
    case PlatformsType.PAGER_DUTY:
      return (
        <PagerDutyContent
          handleChange={formik.handleChange}
          values={formik.values.pagerDuty[idx]}
          errors={formik.errors.pagerDuty?.[idx] as FormikErrors<PagerDutyFormValues> | undefined}
          touched={formik.touched.pagerDuty?.[idx]}
          isSubmitted={isSubmitted}
          idx={idx}
        />
      )
    case PlatformsType.ELASTIC_SEARCH:
      return (
        <ElasticsearchContent
          handleChange={formik.handleChange}
          values={formik.values.elasticsearches[idx]}
          errors={formik.errors.elasticsearches?.[idx] as FormikErrors<WebhookFormValues> | undefined}
          touched={formik.touched.elasticsearches?.[idx]}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          setFieldValue={formik.setFieldValue}
          isSubmitted={isSubmitted}
          idx={idx}
        />
      )
    default:
      return null
  }
}
