import styled from 'styled-components'

export const PlatformsListSC = styled.div`
  .ant-card {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    .ant-card-body {
      padding: 1px 0 0 0;
    }
  }
`

export const AddNewPlatformWrapperSC = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: column;
  align-items: center;
  margin: 32px 0;

  .connect {
    height: 68px;
  }
`

export const PlatformIconsSC = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 32px;
  flex-wrap: wrap;
`
