import styled from 'styled-components'
import { Card, Tag } from 'antd'

export const TagSC = styled(Tag)`
  margin-left: 8px;
  position: relative;
  top: -1px;
`

export const CardSC = styled(Card)`
  height: 100%;

  .ant-card-head-title {
    display: flex;
    align-items: center;
  }
`

export const PriceSC = styled.p`
  margin-bottom: 16px;
`

export const ReasonToUpgradeIcon = styled.img`
  margin-right: 8px;
  height: 18px;
`
