import React, { FC, useEffect, useMemo } from 'react'
import { setDeploymentHealth, setDeploymentUpdateBlockOutdated } from '../../../store/slices/deployment/deployment'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { useDeploymentInfoQuery } from '../../../models/graphql'
import { AlertSC, DeploymentInfoSC } from './styles'
import { Container } from '../../../layouts/container/Container'
import { getDescription, getIconType, getTitle, isAlertShown } from './utils'
import { ExtraActions } from './ExtraActions'

const QUERY_REFETCH_INTERVAL_MS = 5000

type Props = {
  fetchDeployment: () => Promise<unknown>
  onUpgradeDeployment: () => void
}
export const DeploymentInfo: FC<Props> = ({ onUpgradeDeployment, fetchDeployment }) => {
  const deployment = useAppSelector(state => state.deployment.data)
  const isDeploymentClean = useAppSelector(state => state.deployment.isClean)

  const dispatch = useAppDispatch()

  const { data } = useDeploymentInfoQuery(
    { id: deployment?.id ?? '' },
    {
      enabled: !!deployment,
      refetchInterval: QUERY_REFETCH_INTERVAL_MS,
      refetchOnWindowFocus: 'always',
      onSuccess: data => {
        if (data.deployment?.heartbeat?.health) {
          dispatch(setDeploymentHealth(data.deployment.heartbeat.health))
        }
      },
    },
  )

  const isOutdated = useMemo(
    () => data?.deployment?.resourceVersion !== deployment?.resourceVersion,
    [data?.deployment, deployment],
  )

  useEffect(() => {
    if (isOutdated) {
      dispatch(
        setDeploymentUpdateBlockOutdated({ isBlocked: true, reason: 'Outdated instance, please refresh the page' }),
      )
    } else {
      dispatch(setDeploymentUpdateBlockOutdated({ isBlocked: false }))
    }
    if (isDeploymentClean && isOutdated) {
      void fetchDeployment()
    }
  }, [isOutdated, isDeploymentClean, data?.deployment, dispatch, deployment, fetchDeployment])

  if (
    !data?.deployment ||
    !isAlertShown({
      isOutdated,
      isDeploymentClean,
      statusPhase: data.deployment.status.phase,
      upgrade: data.deployment.status.upgrade,
    })
  ) {
    return null
  }

  const iconType = getIconType({
    isOutdated,
    isDeploymentClean,
    statusPhase: data.deployment.status.phase,
    statusMessage: data.deployment.status.message,
    upgrade: data.deployment.status.upgrade,
  })
  const title = getTitle({
    isOutdated,
    isDeploymentClean,
    statusPhase: data.deployment.status.phase,
    statusMessage: data.deployment.status.message,
    upgrade: data.deployment.status.upgrade,
  })
  const description = getDescription({
    isOutdated,
    isDeploymentClean,
    statusPhase: data.deployment.status.phase,
    statusMessage: data.deployment.status.message,
    upgrade: data.deployment.status.upgrade,
  })

  if (!title && !description) {
    return null
  }

  return (
    <DeploymentInfoSC iconType={iconType}>
      <Container>
        <AlertSC
          message={title}
          description={description}
          type={iconType}
          banner={true}
          action={
            <ExtraActions
              isDeploymentClean={isDeploymentClean}
              isOutdated={isOutdated}
              fetchDeployment={fetchDeployment}
              needsUpgrade={data.deployment.status.upgrade?.needsUpgrade}
              onUpgradeDeployment={onUpgradeDeployment}
            />
          }
        />
      </Container>
    </DeploymentInfoSC>
  )
}
