import { FC, ReactNode, useContext } from 'react'
import { Alert, Flex, Pagination } from 'antd'
import { useNavigate } from 'react-router-dom'

import { MainLayout } from '../../layouts/main/MainLayout'
import { DeploymentsGroup } from '../../components/deployment/group/DeploymentsGroup'
import { useDeploymentCountQuotaQuery, useHomePageQuery, useUpdateCurrentUserMutation } from '../../models/graphql'
import { Spinner } from '../login/styles'
import { CenteredContentSC, OnboardingArrowSC, OnboardingDescSC, OnboardingTopSC } from './styles'
import { paths } from '../../router/paths'
import { AppCtx } from '../../providers/appCtx'
import { usePageTitle } from '../../utils/customeHooks/usePageTitle'
import { ContentType, HomeSkeleton } from '../../services/contentful/types'
import { useContent } from '../../utils/customeHooks/useContent'
import { ReactComponent as OnboardingIllustration1 } from '../../assets/onboarding1.svg'
import { ReactComponent as OnboardingIllustration2 } from '../../assets/onboarding2.svg'
import { ReactComponent as OnboardingIllustration3 } from '../../assets/onboarding3.svg'
import { AddCardDeployment } from '../../components/addCard/AddCardDeployment'
import { useAppSelector } from '../../store/store'
import { PlanUpgradeMessage } from '../../components/subscription/PlanUpgradeMessage'
import { usePagination } from '../../utils/customeHooks/usePagination'
import { defaultPagination } from '../../utils/table/pagination'
import { FuseAlert } from '../fuse/FuseAlert'

export const HomePage: FC = () => {
  usePageTitle('Dashboard')
  const navigate = useNavigate()
  const { mutate: updateCurrentUser } = useUpdateCurrentUserMutation()
  const { limit, offset, setOffset, setLimit } = usePagination()
  const deploymentUpdateBlock = useAppSelector(state => state.deployment.updateBlock)
  const { data, isLoading } = useHomePageQuery(
    { offset, limit },
    {
      onSuccess: data => {
        if (!data.currentUser.firstLoginPageVisitedIn) {
          updateCurrentUser({ input: { firstLoginPageVisitedIn: true } })
          navigate(paths.FIRST_LOGIN)
        }
      },
    },
  )
  const appCtx = useContext(AppCtx)
  const content = useContent<HomeSkeleton>(ContentType.HOME_PAGE)
  const { data: quotaData, isLoading: quotaIsLoading } = useDeploymentCountQuotaQuery({ orgID: appCtx.orgID })

  const isNoDeploymentsLimit = quotaData?.organization?.quota?.deploymentCount === -1
  const deploymentsLeft = (quotaData?.organization?.quota?.deploymentCount ?? 0) - (data?.deployments.data.length ?? 0)
  const canCreateDeployments = isNoDeploymentsLimit || deploymentsLeft > 0
  const addButtonEnabled = !deploymentUpdateBlock.org.isBlocked && canCreateDeployments
  const quotaMessage = getQuotaMessage(
    appCtx.orgID,
    data?.deployments.data.length ?? 0,
    quotaData?.organization?.quota?.deploymentCount,
    quotaData?.organization?.subscription.isDefaultPlan,
    quotaData?.organization?.subscription.trialConsumed,
  )

  if (data?.deployments.data.length === 0 && canCreateDeployments) {
    return (
      <MainLayout>
        <Spinner spinning={quotaIsLoading}>
          {quotaMessage && (
            <Alert
              className={'mb-20'}
              message={quotaMessage}
              type='info'
              showIcon
            />
          )}
        </Spinner>
        <FuseAlert />
        <CenteredContentSC>
          <OnboardingTopSC>
            <OnboardingDescSC>
              <OnboardingIllustration1 />
              <h3>
                Select your platform
                <OnboardingArrowSC />
              </h3>
              <p>Select and configure your preferred collaboration platform.</p>
            </OnboardingDescSC>
            <OnboardingDescSC>
              <OnboardingIllustration2 />
              <h3>
                Add your plugins
                <OnboardingArrowSC />
              </h3>
              <p>Extend Botkube with plugins and enable any tool for ChatOps and automation.</p>
            </OnboardingDescSC>
            <OnboardingDescSC>
              <OnboardingIllustration3 />
              <h3>Install Botkube</h3>
              <p>Install Botkube to seamlessly take action on your clusters with ease and efficiency.</p>
            </OnboardingDescSC>
          </OnboardingTopSC>
          <div>
            <h2>{content?.fields.pageTitleWithoutInstances}</h2>
            <p>{content?.fields.pageDescriptionWithoutInstances}</p>
            <AddCardDeployment
              disabled={deploymentUpdateBlock.org.isBlocked}
              init={true}
            />
          </div>
        </CenteredContentSC>
      </MainLayout>
    )
  }

  return (
    <MainLayout>
      <FuseAlert />
      <h2>{content?.fields.pageTitle}</h2>
      <p>{content?.fields.pageDescription}</p>
      <Spinner spinning={quotaIsLoading}>
        {quotaMessage && (
          <Alert
            className={'mb-20'}
            message={quotaMessage}
            type='info'
            showIcon
          />
        )}
      </Spinner>
      <DeploymentsGroup
        className={'mb-20'}
        deployments={data?.deployments.data ?? []}
        loading={isLoading}
        addButtonEnabled={addButtonEnabled}
      />
      <Flex justify={'right'}>
        <Pagination
          {...defaultPagination({
            total: data?.deployments.totalCount,
            limit: data?.deployments.pageInfo.limit,
            setOffset,
            setLimit,
            hideOnSinglePage: true,
          })}
        />
      </Flex>
    </MainLayout>
  )
}

function getQuotaMessage(
  orgID: string,
  deploymentCount: number,
  deployQuota?: number | null,
  isDefaultPlan?: boolean | null,
  trialConsumed?: boolean,
): ReactNode {
  if (deployQuota === undefined || deployQuota === null || deployQuota === -1 || deploymentCount === 0) {
    return null
  }

  const quotaMessage = (
    <>There is a limit of {deployQuota === 1 ? '1 instance' : `${deployQuota} instances`} for your organization.</>
  )
  if (!isDefaultPlan) {
    return quotaMessage
  }

  return (
    <>
      {quotaMessage}{' '}
      <PlanUpgradeMessage
        orgID={orgID}
        trialConsumed={trialConsumed}
      />
    </>
  )
}
