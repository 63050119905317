import { FC, useState } from 'react'
import { Alert, Button, Col, Row } from 'antd'
import { CaretRightOutlined } from '@ant-design/icons'
import moment from 'moment/moment'
import { useParams } from 'react-router-dom'
import { ConnectLabel } from './tabs/ConnectLabel'
import { ConnectTabs } from './tabs/ConnectTabs'
import { SetStep } from '../../../../../models/handlers/handlers'
import { DeploymentPage } from '../../../utils'
import { DeploymentStatusPhase, InstallationType, useDeploymentPageQuery } from '../../../../../models/graphql'
import { DEPLOYMENT_ADD_STEP } from '../../../../../router/paths'
import { DeploymentAddStep } from '../../DeploymentAddPage'
import { WizardStepFooter } from '../footer/WizardStepFooter'

type Props = {
  deployment: DeploymentPage
  setStep: SetStep
}

const gutter = {
  xxl: 48,
  xl: 24,
  lg: 24,
}
const col1 = {
  xxl: 6,
  xl: 6,
  lg: 6,
}

const col2 = {
  xxl: 18,
  xl: 18,
  lg: 18,
}

export const ConnectStep: FC<Props> = ({ deployment, setStep }) => {
  const { data } = useDeploymentPageQuery(
    { id: deployment.id },
    {
      refetchInterval: data => (data?.deployment?.status.phase === DeploymentStatusPhase.Connected ? 0 : 3000),
    },
  )
  const params = useParams()
  const [installationType, setInstallationType] = useState(InstallationType.Cli)
  const isDeploymentConnected =
    !!data?.deployment?.status.phase && [DeploymentStatusPhase.Connected].includes(data.deployment.status.phase)
  const hasGitOpsInstructions = !!data?.deployment?.installUpgradeInstructions?.some(
    instruction => instruction.installationType === InstallationType.GitOps,
  )

  if (params.step === DEPLOYMENT_ADD_STEP.CONNECT && isDeploymentConnected) {
    return (
      <Alert
        message={
          <>
            <b>Status</b>: Agent connected. Finish setting up your platforms and plugins.
          </>
        }
        description={
          <>
            <b>Last transition time</b>:{' '}
            {data.deployment && moment(data.deployment.status.lastTransitionTime as Date).fromNow()}
          </>
        }
        action={
          <Button
            type={'primary'}
            icon={<CaretRightOutlined />}
            onClick={() => setStep(DeploymentAddStep.PLATFORMS_CONFIG)}
          >
            Finish setup
          </Button>
        }
        showIcon
        type={'success'}
      />
    )
  }

  return (
    <>
      <Row gutter={gutter}>
        <Col {...col1}>
          <ConnectLabel
            title={'Botkube CLI'}
            description={
              'Deploy Botkube using our command line tool designed for a ease of use. Easiest way to migrate to Botkube Cloud for users of the open-source version.'
            }
            onClick={() => setInstallationType(InstallationType.Cli)}
            selected={installationType === InstallationType.Cli}
            recommended={true}
          />
          {hasGitOpsInstructions && (
            <ConnectLabel
              title={'GitOps'}
              description={'Deploy Botkube using FluxCD or ArgoCD'}
              onClick={() => setInstallationType(InstallationType.GitOps)}
              selected={installationType === InstallationType.GitOps}
            />
          )}
        </Col>
        <Col {...col2}>
          <ConnectTabs
            deployment={deployment}
            installationType={installationType}
          />
        </Col>
      </Row>
      <WizardStepFooter
        helpMessage={
          <>
            Connect a cluster to proceed.{' '}
            <a
              href='https://docs.botkube.io/operation/common-problems/'
              target='_blank'
              rel='noreferrer'
            >
              Need help
            </a>
          </>
        }
      >
        <Button
          id={'cluster-connected'}
          disabled={!isDeploymentConnected}
          loading={!isDeploymentConnected}
          type={isDeploymentConnected ? 'primary' : 'default'}
          icon={<CaretRightOutlined />}
          onClick={() => setStep(DeploymentAddStep.PLATFORMS_CONFIG)}
          size={'large'}
        >
          {isDeploymentConnected ? 'Cluster Connected, Next Step' : 'Waiting for cluster to connect'}
        </Button>
      </WizardStepFooter>
    </>
  )
}
