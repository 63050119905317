import { FC, useState } from 'react'
import { Button, message, Result, Space, Typography } from 'antd'
import { UserLogoutHeaderLayout } from '../../layouts/userLogoutHeader/UserLogoutHeaderLayout'
import { ReactComponent as BotkubeMail } from '../../assets/icons/botkube-email.svg'
import { useResendEmailVerificationMutation } from '../../models/graphql'
import { usePageTitle } from '../../utils/customeHooks/usePageTitle'

const { Text } = Typography
export type UnauthenticatedProps = {
  email?: string
}

const RESEND_ERR_MESSAGE = 'Failed to send email verification message. Try again later.'
export const RequireEmailVerification: FC<UnauthenticatedProps> = ({ email }) => {
  usePageTitle('Require Email Verification')
  const { mutateAsync, isLoading } = useResendEmailVerificationMutation()
  const [isEmailResent, setIsEmailResent] = useState(false)
  const resendEmailVerification = async () => {
    try {
      const result = await mutateAsync({})
      if (!result.resendEmailVerification) {
        void message.error(RESEND_ERR_MESSAGE)
        return
      }
      void message.success('Email verification message sent successfully.')
      setIsEmailResent(true)
    } catch (err: unknown) {
      void message.error(`${RESEND_ERR_MESSAGE} Reason: ${(err as Error).toString()}`)
    }
  }
  return (
    <UserLogoutHeaderLayout contentClass={'center'}>
      <Result
        icon={<BotkubeMail />}
        title='Verify your email'
        extra={
          <>
            <p>
              {email ? (
                <Text>
                  We just sent an email to <b>{email}</b>, click the link in the message to verify your identity.
                </Text>
              ) : (
                <Text>
                  Verification email was sent to the address that you used during sign-up. Click the link in the message
                  to verify your identity.
                </Text>
              )}
              <br />
            </p>
            <p>
              If you do not see it, you may need to <b>check your spam folder.</b>
            </p>
            <Space>
              <Button href='mailto:frontdesk@kubeshop.io'>Contact Support</Button>
              <Button
                type={'primary'}
                disabled={isEmailResent}
                loading={isLoading}
                onClick={() => void resendEmailVerification()}
              >
                Resend message
              </Button>
            </Space>
          </>
        }
      />
    </UserLogoutHeaderLayout>
  )
}
