import styled from 'styled-components'
import { breakpoints } from '../../../styles/globalStyles'
import { ReactComponent as SlackIcon } from '../../../assets/icons/slack-full-size.svg'
import { ReactComponent as TeamsIcon } from '../../../assets/icons/teams.svg'

const iconStyles = `
 width: 94px;
  height: 94px;

  @media (max-width: ${breakpoints.mdMin}px) {
    width: 64px;
    height: 64px;
  }

  @media (max-width: ${breakpoints.xsMin}px) {
    width: 48px;
    height: 48px;
  }
`

export const SlackIconSC = styled(SlackIcon)`
  ${iconStyles}
`
export const TeamsIconSC = styled(TeamsIcon)`
  ${iconStyles}
`

export const CenteredContentSC = styled.div`
  margin: 0;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`
