import React, { FC } from 'react'
import { usePageTitle } from '../../utils/customeHooks/usePageTitle'
import { useContent } from '../../utils/customeHooks/useContent'
import { AIAssistantSkeleton, ContentType } from '../../services/contentful/types'
import { CustomDocsUpload } from './CustomDocsUpload'
import Markdown from 'markdown-to-jsx'
import { FlexibleLayout } from '../../layouts/flexible/FlexibleLayout'
import { Container } from '../../layouts/container/Container'
import { PageHeader } from '../../components/pageHeader/PageHeader'

export const AIAssistantPage: FC = () => {
  usePageTitle('AI Assistant')

  const content = useContent<AIAssistantSkeleton>(ContentType.AI_ASSISTANT_PAGE)

  return (
    <FlexibleLayout>
      <PageHeader
        title={content?.fields.pageTitle ?? 'AI Assistant'}
        description={
          content?.fields.aiAssistantDescription && <Markdown>{content.fields.aiAssistantDescription}</Markdown>
        }
      />
      <Container className='mt-20'>
        <CustomDocsUpload content={content?.fields} />
      </Container>
    </FlexibleLayout>
  )
}
