import { Form, Input } from 'antd'
import { FC, useState } from 'react'
import { PlatformsType } from '../../../models/platform/platform'
import { FormikErrors, FormikHandlers, FormikHelpers, FormikTouched } from 'formik'
import { getValidationError, getValidationStatus } from '../../../utils/formik/formik'
import { DiscordFormValues } from '../../../pages/clusters/add/steps/platformsConfiguration/utils'
import { InputTag } from '../../../components/inputTag/InputTag'

type Props = {
  values: DiscordFormValues
  handleChange: FormikHandlers['handleChange']
  setFieldValue: FormikHelpers<DiscordFormValues>['setFieldValue']
  errors?: FormikErrors<DiscordFormValues>
  touched?: FormikTouched<DiscordFormValues>
  idx: number
  isSubmitted: boolean
}

export const DiscordForm: FC<Props> = props => {
  const { errors, touched, values, idx, handleChange, setFieldValue, isSubmitted } = props
  const [tokenVisible, setTokenVisible] = useState(false)
  const handleChannels = (channels: string[]) => {
    setFieldValue(`${PlatformsType.DISCORD}.[${idx}].channelNames`, channels)
  }

  return (
    <>
      <Form.Item
        label={'Display Name'}
        required={true}
        validateStatus={getValidationStatus(errors?.name, touched?.name ?? isSubmitted)}
        help={getValidationError(errors?.name, touched?.name ?? isSubmitted)}
      >
        <Input
          name={`${PlatformsType.DISCORD}.[${idx}].name`}
          onChange={handleChange}
          value={values.name}
          placeholder={'My Discord Name'}
        />
      </Form.Item>
      <Form.Item
        label={'BotID'}
        required={true}
        validateStatus={getValidationStatus(errors?.botId, touched?.botId ?? isSubmitted)}
        help={getValidationError(errors?.botId, touched?.botId ?? isSubmitted)}
      >
        <Input
          name={`${PlatformsType.DISCORD}.[${idx}].botId`}
          onChange={handleChange}
          value={values.botId}
          placeholder={'1004652664149254188'}
        />
      </Form.Item>
      <Form.Item
        label={'Token'}
        required={true}
        validateStatus={getValidationStatus(errors?.token, touched?.token ?? isSubmitted)}
        help={getValidationError(errors?.token, touched?.token ?? isSubmitted)}
      >
        <Input.Password
          visibilityToggle={{ visible: tokenVisible, onVisibleChange: setTokenVisible }}
          name={`${PlatformsType.DISCORD}.[${idx}].token`}
          onChange={handleChange}
          value={values.token}
          placeholder={'MTAwNDY1MjY2NDE0OTI1NDE3NQ.G6CfuQ.rLKeSGQxGBzVEMsrclA6MHgLy9uuujdjdye'}
        />
      </Form.Item>
      <Form.Item
        label={'Channel ID'}
        required={true}
        validateStatus={getValidationStatus(errors?.channelNames as string, touched?.channelNames ?? isSubmitted)}
        help={getValidationError(errors?.channelNames as string, touched?.channelNames ?? isSubmitted)}
      >
        <InputTag
          values={values.channelNames}
          placeholder={'New channel'}
          onChange={handleChannels}
        />
      </Form.Item>
    </>
  )
}
