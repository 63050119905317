import { FC } from 'react'
import { MenuInfo } from 'rc-menu/lib/interface'
import { menuItems } from './utils'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMenuComponentQuery } from '../../models/graphql'
import { MenuBase } from './MenuBase'
import { useAuth0 } from '@auth0/auth0-react'
import { browserStoreService, StorageKey } from '../../services/storage'
import { paths } from '../../router/paths'
import { useViewport } from '../../utils/customeHooks/useViewport'
import { breakpoints } from '../../styles/globalStyles'

export const MenuMain: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { width } = useViewport()
  const { data } = useMenuComponentQuery()
  const { user = {} } = useAuth0()

  const onMenuChange = ({ key }: MenuInfo) => {
    if (key.startsWith('/')) {
      navigate(key)
    }
  }

  const onOrganizationSwitch = (orgId: string) => {
    browserStoreService.set(StorageKey.ORG_ID, orgId)
    window.location.href = paths.HOME
  }

  const navigateForRootElem = width > breakpoints.lgMin ? (path: string) => navigate(path) : undefined

  return (
    <MenuBase
      items={menuItems({
        user,
        width,
        organizations: data?.organizations ?? [],
        onOrganizationSwitch,
        redirect: navigateForRootElem,
      })}
      selectedKeys={[location.pathname]}
      onClick={onMenuChange}
    />
  )
}
