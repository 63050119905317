import { FC } from 'react'
import { Organization } from '../../../pages/organization/utils'
import { useFormik } from 'formik'
import { initFormik } from './utils'
import { Button, Form, Input, Radio } from 'antd'
import { getValidationError, getValidationStatus } from '../../../utils/formik/formik'
import { FormSC } from './styles'
import { OrganizationDefaultView } from '../../../models/graphql'

export type OrganizationSettingsFormValues = {
  displayName: string
  defaultView?: OrganizationDefaultView | null
}

export type OrganizationSettingsFormProps = {
  organization: Organization
  loading?: boolean
  onSubmit: (values: OrganizationSettingsFormValues) => Promise<void>
  disabled?: boolean
}
export const OrganizationSettingsForm: FC<OrganizationSettingsFormProps> = props => {
  const { loading, disabled } = props
  const formik = useFormik(initFormik(props))

  return (
    <FormSC
      layout={'horizontal'}
      disabled={disabled}
    >
      <Form.Item
        label={'Name'}
        required={true}
        validateStatus={getValidationStatus(formik.errors.displayName?.toString(), formik.touched.displayName)}
        help={getValidationError(formik.errors.displayName?.toString(), formik.touched.displayName)}
      >
        <Input
          name={'displayName'}
          placeholder={'Organization Name'}
          value={formik.values.displayName}
          onChange={formik.handleChange}
        />
      </Form.Item>
      <Form.Item
        label={'Default Homepage View'}
        validateStatus={getValidationStatus(formik.errors.defaultView?.toString(), formik.touched.defaultView)}
        help={getValidationError(formik.errors.defaultView?.toString(), formik.touched.defaultView)}
      >
        <Radio.Group
          name='defaultView'
          value={formik.values.defaultView}
          onChange={formik.handleChange}
        >
          <Radio.Button value={null}>No selection</Radio.Button>
          <Radio.Button value='FUSE_CLI'>Fuse CLI</Radio.Button>
          <Radio.Button value='CHAT_PLATFORM'>Chat Platform</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Button
        onClick={() => void formik.submitForm()}
        type={'primary'}
        disabled={!formik.dirty}
        loading={loading}
      >
        Update
      </Button>
    </FormSC>
  )
}
