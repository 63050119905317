import { FC, useEffect } from 'react'

import { ReactComponent as NotFoundImg } from '../../assets/not-found.svg'
import { MainLayout } from '../../layouts/main/MainLayout'
import { PageSC, IllustrationWrapperSC, WrapperIlustrationSC, WrapperTextSC } from './styles'
import { Button } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { paths } from '../../router/paths'

export const Page404: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const wrongSlashRegEx = new RegExp('/{2,}', 'g')
  const hasManySlashes = wrongSlashRegEx.test(location.pathname)

  useEffect(() => {
    if (hasManySlashes) {
      const cleanPath = location.pathname.replace(wrongSlashRegEx, '/')
      navigate(cleanPath)
    }
  })

  if (hasManySlashes) {
    return null
  }

  return (
    <MainLayout>
      <PageSC>
        <WrapperIlustrationSC>
          <h2>404</h2>
          <IllustrationWrapperSC>
            <NotFoundImg />
          </IllustrationWrapperSC>
        </WrapperIlustrationSC>
        <WrapperTextSC>
          <h2>Page not found</h2>
          <p>Oops, this page doesn&apos;t exist.</p>
          <Button
            type={'primary'}
            onClick={() => navigate(paths.HOME)}
          >
            Return to homepage
          </Button>
        </WrapperTextSC>
      </PageSC>
    </MainLayout>
  )
}
