import React from 'react'
import ReactDOM from 'react-dom/client'
import { Auth0Provider } from '@auth0/auth0-react'
import { PostHogProvider } from 'posthog-js/react'
import { Provider } from 'react-redux'
import { notification, ConfigProvider } from 'antd'

import { QueryClientProvider } from './providers/queryClient'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { loadRuntimeConfig } from './config/runtime'
import { initSentry } from './utils/sentry'
import { store } from './store/store'

const {
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_AUTH_AUDIENCE,
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_PUBLIC_POSTHOG_KEY,
  REACT_APP_PUBLIC_POSTHOG_HOST,
  REACT_APP_SENTRY_DSN,
} = loadRuntimeConfig()

initSentry(REACT_APP_SENTRY_DSN)
notification.config({
  placement: 'bottomRight',
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorBorderSecondary: '#ccc',
        },
      }}
    >
      <Provider store={store}>
        <PostHogProvider
          apiKey={REACT_APP_PUBLIC_POSTHOG_KEY}
          options={{
            api_host: REACT_APP_PUBLIC_POSTHOG_HOST,
            opt_out_capturing_by_default: true,
          }}
        >
          <Auth0Provider
            domain={REACT_APP_AUTH_DOMAIN}
            clientId={REACT_APP_AUTH_CLIENT_ID}
            authorizationParams={{
              audience: REACT_APP_AUTH_AUDIENCE,
              redirect_uri: window.location.origin,
            }}
          >
            <QueryClientProvider>
              <App />
            </QueryClientProvider>
          </Auth0Provider>
        </PostHogProvider>
      </Provider>
    </ConfigProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
