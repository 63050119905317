import { FC, ReactNode } from 'react'
import { CodeBlockSC } from './styles'
import { CopyOutlined } from '@ant-design/icons'
import copy from 'copy-to-clipboard'
import { message } from 'antd'

type Props = {
  children?: ReactNode
  className?: string
  light?: boolean
  id?: string
}
export const CodeBlock: FC<Props> = props => {
  const { children, className, light, id } = props

  const handleCopyToClipboard = () => {
    if (typeof children === 'string') {
      copy(children)
      void message.success('Copied to clipboard')
    }
  }

  return (
    <CodeBlockSC
      id={id}
      keyboard
      $light={!!light}
      className={className}
    >
      {children} <CopyOutlined onClick={handleCopyToClipboard} />
    </CodeBlockSC>
  )
}
