import { FC, ReactNode } from 'react'
import { QuestionCircleFilled, CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'

import { useAppSelector } from '../../../store/store'
import { selectPlatformHealth } from '../../../store/slices/deployment/selectors'
import { PlatformsType } from '../../../models/platform/platform'
import { BadgeIconWrapperSC, PlatformHealthBadgeSC } from './styles'
import { WrapWithOptionalTooltip } from '../../tooltip/WrapWithOptionalTooltip'

type Props = {
  id: string
  type: PlatformsType
  children: ReactNode
}
export type HealthStatus = 'healthy' | 'unhealthy' | 'unknown' | string

export const PlatformHealthBadge: FC<Props> = ({ children, id, type }) => {
  const platformHealth = useAppSelector(state => selectPlatformHealth(state, id, type))

  const renderIcon = (healthStatus: HealthStatus) => {
    let Icon: ReactNode
    switch (healthStatus.toLowerCase()) {
      case 'healthy':
        Icon = <CheckCircleFilled />
        break
      case 'unhealthy':
        Icon = <CloseCircleFilled />
        break
      case 'unknown':
      default:
        Icon = <QuestionCircleFilled />
    }

    return <WrapWithOptionalTooltip tooltip={`Status: ${healthStatus.toLowerCase()}`}>{Icon}</WrapWithOptionalTooltip>
  }

  return (
    <PlatformHealthBadgeSC>
      {children}
      {platformHealth?.status ? (
        <BadgeIconWrapperSC healthStatus={platformHealth.status}>
          {renderIcon(platformHealth.status)}
        </BadgeIconWrapperSC>
      ) : (
        <BadgeIconWrapperSC healthStatus={'unknown'}>{renderIcon('unknown')}</BadgeIconWrapperSC>
      )}
    </PlatformHealthBadgeSC>
  )
}
