import { FormikConfig } from 'formik'
import * as yup from 'yup'
import { AnySchema } from 'yup'

import { ValidationMsg } from '../../../../../utils/validation'
import { PluginOverviewFormProps, PluginGeneralFormValues } from './PluginGeneralForm'
import { isSinkPlatform } from '../../../../../models/platform/platform'
import { PluginConfigurationProps } from '../../PluginConfiguration'

export const initPluginGeneralFormValues = (pluginConfig: PluginConfigurationProps): PluginGeneralFormValues => {
  return {
    enabled: pluginConfig.pluginValue.enabled,
    displayName: pluginConfig.pluginValue.displayName,
    connections: Object.values(pluginConfig.pluginValue.bindings ?? {}).map(binding => ({
      channels: binding.channels,
      platformId: binding.platformId,
      bound: isSinkPlatform(binding.platform) ? true : undefined,
    })),
  }
}

export const initFormik = (props: PluginOverviewFormProps): FormikConfig<PluginGeneralFormValues> => {
  return {
    enableReinitialize: false,
    initialValues: initPluginGeneralFormValues(props.pluginConfig),
    validationSchema: yup.object().shape<Record<keyof PluginGeneralFormValues, AnySchema>>({
      displayName: yup.string().required(ValidationMsg.REQUIRED),
      enabled: yup.boolean().required(ValidationMsg.REQUIRED),
      connections: yup.array().of(
        yup.object().shape({
          channels: yup.array().of(yup.string()),
          platformId: yup.string(),
        }),
      ),
    }),
    onSubmit: () => {
      //We do not make submit here, but we are updating parent state on each form value change
    },
  }
}

export const initStateSelectedConnections = (pluginConfig: PluginConfigurationProps, selectedPlatformId?: string) => {
  if (!selectedPlatformId) {
    return {
      platformType: pluginConfig.connections[0].platformType,
      platformId: pluginConfig.connections[0].platformId,
    }
  }
  const connection = pluginConfig.connections.find(conn => conn.platformId === selectedPlatformId)

  return (
    connection && {
      platformType: connection.platformType,
      platformId: connection.platformId,
    }
  )
}
