import { TablePaginationConfig } from 'antd'

export const defaultPageSize = 20

const getPageSizeOptions = () => ['20', '50', '100']

type DefaultPaginationProps = {
  total?: number
  limit?: number
  setOffset?: (offset: number) => void
  setLimit?: (limit: number) => void
  showSizeChanger?: boolean
  hideOnSinglePage?: boolean
}

export const defaultPagination = (props: DefaultPaginationProps): TablePaginationConfig => {
  const limit = props.limit ?? defaultPageSize
  return {
    showSizeChanger: props.showSizeChanger ?? true,
    hideOnSinglePage: props.hideOnSinglePage ?? false,
    pageSize: limit,
    pageSizeOptions: getPageSizeOptions(),
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    total: props.total,
    onChange: (page, pageSize) => {
      props.setOffset ? props.setOffset((page - 1) * limit) : null
      props.setLimit ? props.setLimit(pageSize) : null
    },
  }
}
