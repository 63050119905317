import { FC, Fragment } from 'react'
import { Alert, Button, Divider, Typography } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import Markdown from 'markdown-to-jsx'
import { loadRuntimeConfig } from '../../../../config/runtime'
import { useContent } from '../../../../utils/customeHooks/useContent'
import { ContentType, TeamsCloudPlatformInstructionSkeleton } from '../../../../services/contentful/types'

const { Title } = Typography

const { REACT_APP_TEAMS_APP_URL } = loadRuntimeConfig()

export const CloudTeamContentInstructions: FC = () => {
  const content =
    useContent<TeamsCloudPlatformInstructionSkeleton[]>(ContentType.TEAMS_CLOUD_PLATFORM_INSTRUCTION, true) ?? []
  const instructions = content.sort((itemA, itemB) => itemA.fields.order - itemB.fields.order)

  return (
    <>
      <Typography.Title level={5}>1. Connect your Microsoft Teams team</Typography.Title>
      {instructions.map((instruction, idx) => (
        <Fragment key={idx}>
          <Title level={5}>{instruction.fields.title}</Title>
          {instruction.fields.warningDescription && (
            <Alert
              showIcon={true}
              type={'warning'}
              message={instruction.fields.warningDescription}
              className={'mb-20'}
            />
          )}
          {instruction.fields.description && (
            <p>
              <Markdown>{instruction.fields.description}</Markdown>
            </p>
          )}
          {instruction.fields.attachDownloadMsTeamsApp && (
            <Button
              type={'primary'}
              size={'large'}
              href={REACT_APP_TEAMS_APP_URL}
              target={'blank'}
              rel='noreferrer'
              icon={<DownloadOutlined />}
              className={'mb-20'}
            >
              Install Botkube App for Microsoft Teams
            </Button>
          )}
          {instructions.length - 1 > idx && <Divider />}
        </Fragment>
      ))}
    </>
  )
}
