import styled from 'styled-components'
import { Card } from 'antd'

export const ProductCardWrapper = styled.div<{ isFeatured?: boolean }>`
  background: ${props =>
    props.isFeatured ? 'linear-gradient(135deg, rgb(31 102 234), rgb(239 142 93));' : 'rgb(217, 217, 217)'};
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 3px;
`

export const ProductCardBg = styled(Card)`
  border-radius: 6px;
  background: #fff;
  border: 0;
  display: block;
  overflow: hidden;

  h1 {
    margin: 0;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 10px;
  }
`

export const Image = styled.img`
  width: 100%;
  height: auto;
`
