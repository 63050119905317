import React, { FC } from 'react'
import { Button, Result } from 'antd'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { WithoutMenuLayout } from '../../../layouts/withoutMenu/withoutMenuLayout'
import { paths } from '../../../router/paths'

const CONNECTION_ERROR_REASON_PARAM = 'error_reason'
const CONNECTION_ERROR_DESCRIPTION_PARAM = 'error_description'

export const SlackConnectionError: FC = () => {
  const [searchParams] = useSearchParams()
  const errReason = searchParams.get(CONNECTION_ERROR_REASON_PARAM) ?? ''
  const errDesc = searchParams.get(CONNECTION_ERROR_DESCRIPTION_PARAM) ?? ''
  const navigate = useNavigate()

  return (
    <WithoutMenuLayout>
      <Result
        style={{ margin: '34px 0 0' }}
        status='error'
        title={errReason}
        subTitle={
          <>
            {errDesc}
            <br />
            Please return to Slack to restart the process of connecting your <code>Botkube</code> app.
          </>
        }
        extra={
          <Button
            type='primary'
            key='home'
            onClick={() => navigate(paths.HOME)}
          >
            Return to dashboard
          </Button>
        }
      />
    </WithoutMenuLayout>
  )
}
