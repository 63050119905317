import { createClient, Entry, EntryCollection } from 'contentful'
import { AssetContentful, ContentSkeleton, ContentType, FieldContentful } from './types'
import { loadRuntimeConfig } from '../../config/runtime'
import { defaultContent } from './default'

export class CMSContent {
  private client

  constructor() {
    const { REACT_APP_CONTENTFUL_SPACE, REACT_APP_CONTENTFUL_ACCESS_TOKEN, REACT_APP_CONTENTFUL_ENV } =
      loadRuntimeConfig()
    this.client = createClient({
      space: REACT_APP_CONTENTFUL_SPACE,
      accessToken: REACT_APP_CONTENTFUL_ACCESS_TOKEN,
      environment: REACT_APP_CONTENTFUL_ENV,
    })
  }

  public async getAll() {
    let content
    try {
      content = await this.client.getEntries()
    } catch (err) {
      console.warn('while getting content from contentful', err)
      content = defaultContent
    }

    return content
  }

  public static getContent<T>(contentType: ContentType, entries: EntryCollection<ContentSkeleton>, multi?: boolean) {
    const content = entries.items.filter(item => item.sys.contentType.sys.id === contentType)
    if (content.length === 0) {
      console.error(`Can't find content for ${contentType}`)
    }
    this.attachAssets(content, entries)

    return multi ? (content as T | undefined) : (content[0] as T | undefined)
  }

  private static attachAssets(
    content: Entry<ContentSkeleton>[] | undefined,
    entries: EntryCollection<ContentSkeleton> | undefined,
  ) {
    content?.forEach(item => {
      Object.values<FieldContentful>(item.fields).forEach(field => {
        if (field.sys?.type !== 'Asset') {
          return
        }

        const asset = entries?.includes?.Asset?.find((asset: AssetContentful) => asset.sys.id === field.sys?.id) as
          | AssetContentful
          | undefined
        field.url = asset?.fields.file.url
      })
    })
  }
}

export const cmsContentService = new CMSContent()
