import {
  ChannelBindingsById,
  ChannelBindingsByName,
  ChannelBindingsByNameAndId,
  CloudMsTeams,
  CloudSlack,
  Discord,
  Elasticsearch,
  Mattermost,
  PagerDuty,
  SocketSlack,
  Webhook,
} from '../models/graphql'

export const instanceOfChannelBindingsByName = (object: unknown): object is ChannelBindingsByName => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return object && 'name' in object
}

export const instanceOfChannelBindingsById = (object: unknown): object is ChannelBindingsById => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return object && 'id' in object
}

export const instanceOfChannelBindingsByNameAndId = (object: unknown): object is ChannelBindingsByNameAndId => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return object && 'name' in object && 'channelId' in object
}

export const instanceOfPlatformWithChannels = (
  object: unknown,
): object is SocketSlack | Mattermost | Discord | CloudMsTeams | CloudSlack => {
  // @ts-expect-error
  return object && 'channels' in object
}

export const instanceOfCloudMSTeams = (object: unknown): object is CloudMsTeams => {
  // @ts-expect-error
  return object && 'channels' in object && 'aadGroupId' in object
}

export const instanceOfCloudSlack = (object: unknown): object is CloudSlack => {
  // @ts-expect-error
  return object && 'channels' in object && 'teamId' in object
}

export const instanceOfWebhook = (object: unknown): object is Webhook => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return object && 'bindings' in object && !('executors' in object.bindings)
}

export const instanceOfElasticsearch = (object: unknown): object is Elasticsearch => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return object && 'indices' in object
}

export const instanceOfPagerDuty = (object: unknown): object is PagerDuty => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return object && 'integrationKey' in object && 'bindings' in object
}

export const instanceOfSinkPlatform = (object: unknown): object is Webhook | Elasticsearch | PagerDuty => {
  return instanceOfWebhook(object) || instanceOfElasticsearch(object) || instanceOfPagerDuty(object)
}
