import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { RouterProvider } from 'react-router-dom'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { usePostHog } from 'posthog-js/react'
import Cookie from 'js-cookie'
import { notification, Spin } from 'antd'
import { useAuth0 } from '@auth0/auth0-react'

import { GlobalStyle } from './styles/globalStyles'
import { getRoutesForAuthorizedUser, getRoutesForUnauthorizedUser } from './router/routes'
import { RequireEmailVerification } from './pages/login/RequireEmailVerification'
import { shouldEnableHubspotToken } from './config/runtime'
import { useAppQuery, useHubspotMutation } from './models/graphql'
import {
  getCurrentSelectedOrganization,
  getUserOrganization,
  isOrganizationIdBelongsUserOrganizations,
} from './utils/organizations'
import { browserStoreService, StorageKey } from './services/storage'
import { AppCtx } from './providers/appCtx'
import { enableHubspot, setHubSpotConversationSettings, setHubSpotIdentify } from './utils/hubspot'
import { CookieBanner } from './components/cookieBanner/CookieBanner'
import { CookieName } from './models/cookie'
import { enableGTM } from './utils/gtm'
import { useLocationSearchParams } from './utils/customeHooks/useLocationSearchParams'
import { cmsContentService } from './services/contentful/contentful'
import { EntryCollection } from 'contentful'
import { ContentSkeleton } from './services/contentful/types'
import './webworkers'
import { useAppDispatch } from './store/store'
import { setDeploymentUpdateBlockOrg } from './store/slices/deployment/deployment'

const enableHubspotToken = shouldEnableHubspotToken()

export const App: FC = () => {
  const dispatch = useAppDispatch()
  const { user, isAuthenticated, isLoading: isLoadingUser, error } = useAuth0()
  const posthog = usePostHog()
  const searchParams = useLocationSearchParams()
  const [retryOnError, setRetryOnError] = useState(0)
  const [hubspotScriptLoaded, setHubspotScriptLoaded] = useState(false)
  const [hubspotIdentificationToken, setHubspotIdentificationToken] = useState('')
  const [isOrgIDSet, setIsOrgID] = useState(false)
  const [content, setContent] = useState<EntryCollection<ContentSkeleton>>()
  const headerRef = useRef<HTMLDivElement>(null)
  const { data, refetch } = useAppQuery(undefined, {
    enabled: isAuthenticated && user?.email_verified,
    refetchOnWindowFocus: true,
    retry: 3,
    onError: () => {
      browserStoreService.removeItem(StorageKey.ORG_ID)
      if (retryOnError < 3) {
        setRetryOnError(retryOnError + 1)
        void refetch()
      }
    },
  })
  const { mutate: generateHubspotToken } = useHubspotMutation({
    onSuccess: data => setHubspotIdentificationToken(data.generateHubspotIdentificationToken.token),
  })

  const handleConsentAccept = useCallback(() => {
    posthog.opt_in_capturing()
    enableHubspot(hubspotScriptLoaded, () => {
      setHubspotScriptLoaded(true)
    })
    enableGTM()
  }, [posthog, hubspotScriptLoaded])

  useEffect(() => {
    const getContent = async () => {
      const content = await cmsContentService.getAll()
      // @ts-expect-error
      setContent(content)
    }

    void getContent().catch(err => {
      console.error('while getting contentful content', err)
      notification.error({ message: 'Error during getting content' })
    })
  }, [])

  useEffect(() => {
    if (searchParams.organizationId) {
      browserStoreService.set(StorageKey.ORG_ID, searchParams.organizationId)
    }
  }, [searchParams])

  useEffect(() => {
    if (Cookie.get(CookieName.CONSENT) === '1') {
      handleConsentAccept()
    }
    if (!data?.organizations || !user) {
      return
    }

    posthog.identify(user.email, {
      name: user.name,
      email: user.email,
    })

    const orgId = browserStoreService.get(StorageKey.ORG_ID)
    if (isOrganizationIdBelongsUserOrganizations(data.organizations, orgId)) {
      setIsOrgID(true)
      return
    }

    const org = getUserOrganization(data.organizations, user)
    if (org) {
      browserStoreService.set(StorageKey.ORG_ID, org.id)
      setIsOrgID(true)
      return
    }

    console.warn(`Can't find user: ${user.email ?? ''} organization, switch to default one`)
    if (data.organizations.length >= 0) {
      const org = data.organizations[0]
      browserStoreService.set(StorageKey.ORG_ID, org.id)
      setIsOrgID(true)
      return
    }
  }, [data?.organizations, user, posthog, handleConsentAccept])

  useEffect(() => {
    if (hubspotScriptLoaded && user?.email && enableHubspotToken) {
      generateHubspotToken({ input: { email: user.email } })
    }
  }, [hubspotScriptLoaded, generateHubspotToken, user])

  useEffect(() => {
    if (user?.email && hubspotScriptLoaded) {
      setHubSpotIdentify(user.email)
    }
    if (user?.email && hubspotIdentificationToken) {
      setHubSpotConversationSettings(user.email, hubspotIdentificationToken)
    }
  }, [user, hubspotIdentificationToken, hubspotScriptLoaded])

  useEffect(() => {
    if (!user || !data?.organizations) {
      return
    }
    const currentOrganization = getCurrentSelectedOrganization(data.organizations, user)
    if (currentOrganization?.updateOperations?.blocked) {
      dispatch(
        setDeploymentUpdateBlockOrg({
          isBlocked: true,
          reason: currentOrganization.updateOperations.reasons?.join(','),
        }),
      )

      return
    }
    dispatch(setDeploymentUpdateBlockOrg({ isBlocked: false }))
  }, [data?.organizations, user, dispatch])

  if (isLoadingUser) {
    return (
      <>
        <GlobalStyle />
        <Spin
          tip={'Loading user data...'}
          fullscreen={true}
        />
      </>
    )
  }

  if (error || !isAuthenticated) {
    return (
      <AppCtx.Provider
        value={{
          orgID: '',
          orgUpdateOps: null,
          connectedSlacks: [],
          connectedMSTeams: [],
          headerRef,
          content,
        }}
      >
        <GlobalStyle />
        <RouterProvider router={getRoutesForUnauthorizedUser} />
      </AppCtx.Provider>
    )
  }

  if (!user?.email_verified) {
    return (
      <>
        <GlobalStyle />
        <RequireEmailVerification email={user?.email} />
      </>
    )
  }

  if (!isOrgIDSet) {
    return (
      <>
        <GlobalStyle />
        <Spin
          tip={'Loading current organization...'}
          fullscreen={true}
        />
      </>
    )
  }

  const currentOrganization = getCurrentSelectedOrganization(data?.organizations ?? [], user)

  return (
    <AppCtx.Provider
      value={{
        orgID: currentOrganization?.id ?? '',
        connectedSlacks: currentOrganization?.connectedPlatforms?.slacks ?? [],
        connectedMSTeams: currentOrganization?.connectedPlatforms?.teamsOrganizations ?? [],
        orgUpdateOps: currentOrganization?.updateOperations ?? null,
        headerRef,
        content,
      }}
    >
      <GlobalStyle />
      <RouterProvider router={getRoutesForAuthorizedUser()} />
      <ReactQueryDevtools initialIsOpen={false} />
      <CookieBanner onAccept={handleConsentAccept} />
    </AppCtx.Provider>
  )
}

export default App
