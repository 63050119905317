const TAG_MAX_LENGTH = 20

export function printTagHeadline(tag: string) {
  return tag.length > TAG_MAX_LENGTH ? `${tag.slice(0, TAG_MAX_LENGTH)}...` : tag
}

export function isLongTag(tag: string) {
  return tag.length > TAG_MAX_LENGTH
}

export const normalizeChannelName = (input: string, prefix?: string) => {
  if (!prefix || !input.startsWith(prefix)) {
    return input
  }

  return input.slice(prefix.length)
}
