import styled from 'styled-components'
import { Button, Radio } from 'antd'
import { CheckOutlined, MinusOutlined } from '@ant-design/icons'

export const RadioGroupSC = styled(Radio.Group)`
  font-size: inherit;
`

export const FeaturesListSC = styled.ul`
  padding-left: 24px;
  margin: 0 0;
`
export const GreenCheckOutlinedSC = styled(CheckOutlined)`
  padding-right: 8px;

  svg {
    filter: invert(42%) sepia(92%) saturate(2375%) hue-rotate(71deg) brightness(98%) contrast(90%);
  }
`

export const MinusOutlinedSC = styled(MinusOutlined)`
  padding-right: 8px;
`

export const UpgradeButtonSC = styled(Button)`
  margin-left: 24px;
`
