import { FC } from 'react'
import { Button, Space, Tooltip, Popconfirm } from 'antd'

type Props = {
  handlePrevStep?: () => void
  handleNextStep?: () => void
  onCancel?: () => void
  nextButtonLabel?: string
  nextButtonTooltipText?: string
  disableNextButton?: boolean
  isLoading?: boolean
}

export const PrevNextButtons: FC<Props> = props => {
  const {
    handlePrevStep,
    handleNextStep,
    onCancel,
    nextButtonLabel,
    nextButtonTooltipText,
    isLoading,
    disableNextButton,
  } = props

  let nextButton
  if (handleNextStep) {
    nextButton = (
      <Button
        type={'primary'}
        onClick={handleNextStep}
        loading={isLoading}
        disabled={disableNextButton}
        size={'large'}
      >
        {nextButtonLabel ? nextButtonLabel : 'Next'}
      </Button>
    )
  }
  if (nextButtonTooltipText) {
    nextButton = <Tooltip title={nextButtonTooltipText}>{nextButton}</Tooltip>
  }

  return (
    <Space>
      {onCancel && (
        <Popconfirm
          title={'Do you really want to cancel?'}
          placement={'topLeft'}
          onConfirm={onCancel}
          okText={'Yes'}
          cancelText={'No'}
        >
          <Button
            type={'link'}
            className={'mr-20'}
            danger
            size={'large'}
          >
            Cancel
          </Button>
        </Popconfirm>
      )}
      {handlePrevStep && (
        <Button
          onClick={handlePrevStep}
          size={'large'}
        >
          Previous
        </Button>
      )}
      {nextButton}
    </Space>
  )
}
