import React, { useContext } from 'react'
import { MainLayout } from '../../../layouts/main/MainLayout'
import { Button, Divider, message, Result, Spin } from 'antd'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAddPlatformToOrganizationMutation, useGetSlackPlatformDetailsQuery } from '../../../models/graphql'
import { CenteredContentSC, SlackIconSC } from './styles'
import { paths } from '../../../router/paths'
import { AppCtx } from '../../../providers/appCtx'

const CONNECTION_STATE_SEARCH_PARAM = 'state'
const CLOSE_TAB_SEARCH_PARAM = 'close-tab'

const WINDOW_CLOSE_TIMEOUT = 3000
const AUTO_CLOSE_TAB_VALUE = 'auto'

export const SlackConnectionPage: React.FC = () => {
  const [searchParams] = useSearchParams()
  const state = searchParams.get(CONNECTION_STATE_SEARCH_PARAM) ?? ''
  const closeTab = searchParams.get(CLOSE_TAB_SEARCH_PARAM) ?? ''
  const navigate = useNavigate()

  const appCtx = useContext(AppCtx)

  const { data, isLoading, isError, error } = useGetSlackPlatformDetailsQuery(
    { token: state },
    {
      enabled: state !== '',
    },
  )
  const slackName = data?.connectedPlatforms.slack.name ?? 'Unknown'

  const { mutateAsync, isLoading: isAddPlatformLoading, isSuccess } = useAddPlatformToOrganizationMutation()

  if (state === '') {
    return (
      <MainLayout>
        <Result
          style={{ margin: '34px 0 0' }}
          status='error'
          title='Connection state is missing'
          subTitle={
            <>
              Please return to Slack to restart the process of connecting your <code>Botkube</code> app.
            </>
          }
        />
      </MainLayout>
    )
  }

  if (isLoading) {
    return (
      <MainLayout>
        <Spin
          tip={'Fetching Slack details...'}
          fullscreen={true}
        />
      </MainLayout>
    )
  }

  if (isError) {
    const err = error as Error
    return (
      <MainLayout>
        <Result
          style={{ margin: '34px 0 0' }}
          status='error'
          title={'Connection failed'}
          subTitle={err.message}
        />
      </MainLayout>
    )
  }

  const isAlreadyConnected = data.connectedPlatforms.slack.connectedOrganizations?.some(val => val.id === appCtx.orgID)
  if (isAlreadyConnected) {
    return (
      <MainLayout>
        <Result
          status='success'
          title='Organization Already Connected!'
          subTitle={
            <>
              The {slackName} is already connected to this organization. Switch organization if you want to connect it
              to a different one.
            </>
          }
          extra={
            <Button
              type='primary'
              key='home'
              onClick={() => navigate(paths.HOME)}
            >
              Return to dashboard
            </Button>
          }
        />
      </MainLayout>
    )
  }

  const submitFn = async () => {
    // errors are handled globally
    await mutateAsync({
      input: {
        organizationId: appCtx.orgID,
        slack: {
          token: state,
        },
      },
    })
    let msg = `Successfully connected with “${slackName}” Slack workspace.`
    if (closeTab === AUTO_CLOSE_TAB_VALUE) {
      msg += ' This window will close automatically in a moment.'
    }
    void message.success(msg)
    if (closeTab === AUTO_CLOSE_TAB_VALUE) {
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!window) {
          return
        }
        window.opener = null
        window.open('', '_self')
        window.close()
      }, WINDOW_CLOSE_TIMEOUT)
      return
    }

    navigate(paths.HOME)
  }

  return (
    <MainLayout>
      <CenteredContentSC>
        <SlackIconSC />
        <h1>Connect {slackName}</h1>
        Monitor your Kubernetes on {slackName} by connecting it to an existing or to a new Botkube Instance
        <br />
        <Button
          id={'slack-workspace-connect'}
          style={{ marginTop: '1em' }}
          type={'primary'}
          onClick={() => void submitFn()}
          loading={isAddPlatformLoading}
          disabled={isSuccess}
        >
          Connect
        </Button>
        <Divider />
      </CenteredContentSC>
      ,
    </MainLayout>
  )
}
