import { FC, useContext } from 'react'
import { AlertSC, LinkSC } from './styles'
import { AppCtx, AppCtxValue } from '../../providers/appCtx'
import { paths } from '../../router/paths'
import { loadRuntimeConfig } from '../../config/runtime'

export const SlackReinstallRequiredAlert: FC = () => {
  const appCtx = useContext(AppCtx)

  const reinstallLinks = getReinstallLinks(appCtx.connectedSlacks)
  if (reinstallLinks.length === 0) {
    return null
  }

  return (
    <AlertSC
      message={
        <>
          <b>🎉 A new version of the Botkube app for Slack is available!</b>
          <br />
          To use the latest features, reinstall the app to {reinstallLinks.map((link, i) => [i > 0 && ', ', link])}.
        </>
      }
    />
  )
}

const getReinstallLinks = (slacks: AppCtxValue['connectedSlacks']) => {
  const { REACT_APP_SLACK_ROUTER_BASE_URL } = loadRuntimeConfig()
  const redirect = `${location.protocol}//${location.host}${paths.REINSTALLED_SLACK_WORKSPACE}`

  return slacks
    .filter(s => s.isReinstallRequired)
    .map((slack, index) => {
      const CLOUD_SLACK_APP_INSTALL_URL = `${REACT_APP_SLACK_ROUTER_BASE_URL}/routers/slack/v1/install?state=custom_redirect:${redirect}&team=${slack.teamId}`
      return (
        <LinkSC
          key={index}
          target='_blank'
          rel='noreferrer'
          href={CLOUD_SLACK_APP_INSTALL_URL}
        >
          {slack.name}
        </LinkSC>
      )
    })
}
