import styled from 'styled-components'
import { ReactComponent as OnboardingIllustration } from '../../assets/onboarding.svg'
import { ReactComponent as OnboardingArrow } from '../../assets/onboarding-arrow.svg'
import { breakpoints } from '../../styles/globalStyles'

export const CenteredContentSC = styled.div`
  background: #fff;
  padding: 30px;
  text-align: center;
  border-radius: 8px;

  @media (min-width: ${breakpoints.lgMin}px) {
    padding: 80px;
  }
`
export const IllustrationSC = styled(OnboardingIllustration)`
  margin: 40px 0;
`

export const OnboardingTopSC = styled.div`
  display: block;
  margin-bottom: 80px;

  @media (min-width: ${breakpoints.lgMin}px) {
    display: flex;
    justify-content: center;
  }
`

export const OnboardingDescSC = styled.div`
  margin: 0 0 40px;

  @media (min-width: ${breakpoints.lgMin}px) {
    width: 200px;
    margin: 0 60px;
  }

  h3 {
    position: relative;
  }
`

export const OnboardingBottomSC = styled.div`
  margin: 0 auto;
`

export const OnboardingArrowSC = styled(OnboardingArrow)`
  display: none;

  @media (min-width: ${breakpoints.lgMin}px) {
    display: block;
    position: absolute;
    right: -100px;
    top: 0;
  }
`
