import styled from 'styled-components'
import { Card } from 'antd'

export const PluginUsedInPlatformsSC = styled.div``

export const PluginUsedInPlatformSC = styled.div`
  display: flex;
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0;
  }

  .ant-tag {
    margin-right: 0;
    border-radius: 0;
    border-left: none;
    border-right: 1px solid #d9d9d9;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;

    &:first-child {
      border-radius: 4px 0 0 4px;
      border-left: 1px solid #d9d9d9;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }

    &.without-channels {
      border-radius: 4px;
    }

    svg {
      margin: 0 0 -2px 0;
    }
  }
`

export const PluginsSC = styled(Card)`
  .ant-card-body {
    padding: 1px 0 0 0;
  }
`
