import { FC, MouseEventHandler } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { usePostHog } from 'posthog-js/react'
import { LogoutButtonSC } from './styles'

export const LogoutLink: FC = () => {
  const { logout } = useAuth0()
  const posthog = usePostHog()
  const onClick: MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault()
    e.stopPropagation()
    posthog.reset()

    void logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })
  }

  return (
    <LogoutButtonSC
      type='link'
      onClick={onClick}
    >
      Log out
    </LogoutButtonSC>
  )
}
