import { FC, useEffect, useRef, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Space, Tooltip, InputRef } from 'antd'
import { AddTagSC, InputTagSC, TagSC } from './styles'
import { isLongTag, normalizeChannelName, printTagHeadline } from './utils'

type Props = {
  values: string[]
  placeholder?: string
  onChange: (tags: string[]) => void
  prefix?: string
}

export const InputTag: FC<Props> = props => {
  const { prefix, values, placeholder, onChange } = props
  const [inputVisible, setInputVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [editInputIndex, setEditInputIndex] = useState(-1)
  const [editInputValue, setEditInputValue] = useState('')
  const inputRef = useRef<InputRef>(null)
  const editInputRef = useRef<InputRef>(null)

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus()
    }
  }, [inputVisible])

  useEffect(() => {
    editInputRef.current?.focus()
  }, [inputValue])

  const handleRemoveTag = (removedTag: string) => {
    const newTags = values.filter(tag => tag !== removedTag)
    onChange(newTags)
  }

  const handleInputConfirm = () => {
    const targetInputValue = normalizeChannelName(inputValue, prefix)
    if (targetInputValue && !values.includes(targetInputValue)) {
      onChange([...values, targetInputValue])
    }
    setInputVisible(false)
    setInputValue('')
  }

  const handleEditInputConfirm = () => {
    const newTags = [...values]
    const targetInputValue = normalizeChannelName(editInputValue, prefix)
    if (targetInputValue && !values.includes(targetInputValue)) {
      newTags[editInputIndex] = targetInputValue
      onChange(newTags)
    }
    setEditInputIndex(-1)
    setInputValue('')
  }

  return (
    <Space
      size={[0, 8]}
      wrap
    >
      <Space
        size={[0, 8]}
        wrap
      >
        {values.map((tag, index) => {
          if (editInputIndex === index) {
            return (
              <InputTagSC
                prefix={prefix}
                ref={editInputRef}
                key={tag}
                size='small'
                value={editInputValue}
                onChange={e => setEditInputValue(e.target.value)}
                onBlur={handleEditInputConfirm}
                onPressEnter={handleEditInputConfirm}
              />
            )
          }
          const tagElem = (
            <TagSC
              key={tag}
              closable={true}
              style={{ userSelect: 'none' }}
              onClose={() => handleRemoveTag(tag)}
            >
              <span
                onDoubleClick={e => {
                  setEditInputIndex(index)
                  setEditInputValue(tag)
                  e.preventDefault()
                }}
              >
                {prefix && prefix}
                {printTagHeadline(tag)}
              </span>
            </TagSC>
          )
          return isLongTag(tag) ? (
            <Tooltip
              title={tag}
              key={tag}
            >
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          )
        })}
      </Space>
      {inputVisible ? (
        <InputTagSC
          ref={inputRef}
          prefix={prefix}
          type='text'
          size='small'
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      ) : (
        <AddTagSC onClick={() => setInputVisible(true)}>
          <PlusOutlined /> {placeholder ? placeholder : 'New Tag'}
        </AddTagSC>
      )}
    </Space>
  )
}
