let gtmEnabled = false
export const enableGTM = () => {
  if (!gtmEnabled) {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = `${window.location.origin}/gtm.js`

    document.head.appendChild(script)
    gtmEnabled = true
  }
}
