import { FC, memo } from 'react'
import { Row } from 'antd'
import { PagerDutyFormValues } from '../utils'
import { FormikErrors, FormikHandlers, FormikTouched } from 'formik'
import { PlatformContentInstruction } from './PlatformContentInstruction'
import { ContentType } from '../../../../../../services/contentful/types'
import { PlatformEmbeddedDocs } from './PlatformEmbeddedDocs'
import { ContentColumnLeft } from './columns/ContentColumnLeft'
import { ContentColumnRight } from './columns/ContentColumnRight'
import { PagerDutyForm } from '../../../../../../forms/deploymentWizard/platforms/pagerDuty'

export type PagerDutyContentProps = {
  values: PagerDutyFormValues
  errors?: FormikErrors<PagerDutyFormValues>
  touched?: FormikTouched<PagerDutyFormValues>
  handleChange: FormikHandlers['handleChange']
  idx: number
  isSubmitted: boolean
}

export const PagerDutyContentInner: FC<PagerDutyContentProps> = props => {
  const { values, errors, touched, handleChange, idx, isSubmitted } = props
  const platformInstructionType = ContentType.PAGER_DUTY_PLATFORM_INSTRUCTION
  return (
    <section>
      <Row>
        <ContentColumnLeft>
          <PlatformContentInstruction platformInstructionType={platformInstructionType} />
          <PagerDutyForm
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            idx={idx}
            isSubmitted={isSubmitted}
          />
        </ContentColumnLeft>
        <ContentColumnRight>
          <PlatformEmbeddedDocs platformInstructionType={platformInstructionType} />
        </ContentColumnRight>
      </Row>
    </section>
  )
}

export const PagerDutyContent = memo(PagerDutyContentInner)
