import { FormikConfig } from 'formik'
import * as yup from 'yup'
import { AnySchema } from 'yup'
import { ValidationMsg } from '../../../utils/validation'
import { OrganizationSettingsFormProps, OrganizationSettingsFormValues } from './OrganizationSettings'

export const initFormik = (props: OrganizationSettingsFormProps): FormikConfig<OrganizationSettingsFormValues> => {
  return {
    enableReinitialize: true,
    initialValues: {
      displayName: props.organization?.displayName ?? '',
    },
    validationSchema: yup.object().shape<Record<keyof OrganizationSettingsFormValues, AnySchema>>({
      displayName: yup.string().max(80).required(ValidationMsg.REQUIRED),
    }),
    onSubmit: values => {
      props.onSubmit(values)
    },
  }
}
