import React, { FC, useContext, useState } from 'react'
import { Button, Dropdown, Typography } from 'antd'
import { DeleteOutlined, DoubleRightOutlined, EditOutlined, MenuOutlined, RedoOutlined } from '@ant-design/icons'
import { useSearchParams } from 'react-router-dom'

import {
  DeploymentDetailsHeaderSC,
  DeploymentHeaderContentSC,
  DeploymentSubHeaderRightSC,
  DeploymentSubHeaderSC,
} from './styles'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { AppCtx } from '../../../providers/appCtx'
import { setDeploymentName } from '../../../store/slices/deployment/deployment'
import { DeploymentInfo } from '../info/DeploymentInfo'
import { DeploymentStatus } from '../status/DeploymentStatus'
import { DeploymentUpgradeAlert } from '../upgradeAlert/DeploymentUpgradeAlert'
import { DEPLOYMENT_CONNECT_MODAL_SEARCH_PARAM } from '../../../router/paths'
import { ConnectionInstructions } from '../connection/ConnectionInstructions'
import { DeleteDeployment } from '../../../pages/clusters/delete/DeleteDeployment'
import { DeploymentApiKey } from '../../../pages/clusters/regenerateApiKey/DeploymentApiKey'
import { AddPlatform } from '../../platform/add/AddPlatform'

type Props = {
  fetchDeployment: () => Promise<unknown>
}

export const DeploymentDetailsHeader: FC<Props> = ({ fetchDeployment }) => {
  const deployment = useAppSelector(state => state.deployment.data)
  const updateBlock = useAppSelector(state => state.deployment.updateBlock)
  const dispatch = useAppDispatch()
  const appCtx = useContext(AppCtx)
  const [searchParams] = useSearchParams()

  const [isTitleEditable, setTitleEditable] = useState(false)
  const [isInstallCmdModalOpen, setInstallCmdModalOpen] = useState(
    () => searchParams.get(DEPLOYMENT_CONNECT_MODAL_SEARCH_PARAM) === 'true',
  )
  const [isDeleteDeploymentModalOpen, setDeleteDeploymentModalOpen] = useState(false)
  const [isDeploymentAPIKeyModalOpen, setDeploymentAPIKeyModalOpen] = useState(false)

  if (!deployment) {
    return null
  }

  return (
    <DeploymentDetailsHeaderSC additionalPadding={appCtx.headerRef?.current?.offsetHeight ?? 0}>
      <DeploymentUpgradeAlert onUpgradeLinkClick={setInstallCmdModalOpen} />
      <DeploymentInfo
        fetchDeployment={fetchDeployment}
        onUpgradeDeployment={() => setInstallCmdModalOpen(true)}
      />
      <DeploymentHeaderContentSC>
        <DeploymentSubHeaderSC>
          <Typography.Title
            level={1}
            editable={
              isTitleEditable
                ? {
                    onChange: name => {
                      dispatch(setDeploymentName(name))
                      setTitleEditable(false)
                    },
                    editing: true,
                  }
                : false
            }
          >
            {deployment.name}
          </Typography.Title>
          <DeploymentStatus />
        </DeploymentSubHeaderSC>
        <DeploymentSubHeaderSC>
          <DeploymentSubHeaderRightSC>
            <AddPlatform />
            <Dropdown
              menu={{
                items: [
                  {
                    icon: <DoubleRightOutlined />,
                    key: 'reinstall',
                    label: 'Re-install or upgrade agent',
                    disabled: updateBlock.org.isBlocked,
                    onClick: () => setInstallCmdModalOpen(true),
                  },
                  {
                    icon: <EditOutlined />,
                    key: 'rename',
                    label: 'Rename Instance',
                    disabled: updateBlock.isBlocked,
                    onClick: () => setTitleEditable(true),
                  },
                  {
                    icon: <RedoOutlined />,
                    key: 'regenerate',
                    label: 'Regenerate API Key',
                    disabled: updateBlock.isBlocked,
                    onClick: () => setDeploymentAPIKeyModalOpen(true),
                  },
                  {
                    type: 'divider',
                  },
                  {
                    icon: <DeleteOutlined />,
                    key: 'delete',
                    label: 'Delete Instance',
                    danger: true,
                    onClick: () => setDeleteDeploymentModalOpen(true),
                  },
                ],
              }}
            >
              <Button icon={<MenuOutlined />} />
            </Dropdown>
          </DeploymentSubHeaderRightSC>
        </DeploymentSubHeaderSC>
      </DeploymentHeaderContentSC>
      <ConnectionInstructions
        installUpgradeInstructions={deployment.installUpgradeInstructions}
        isOpened={isInstallCmdModalOpen}
        setOpened={setInstallCmdModalOpen}
        refetchDeployment={fetchDeployment}
      />
      <DeleteDeployment
        deployment={deployment}
        isOpened={isDeleteDeploymentModalOpen}
        setOpened={setDeleteDeploymentModalOpen}
      />
      <DeploymentApiKey
        onSuccess={() => {
          setInstallCmdModalOpen(true)
        }}
        isOpened={isDeploymentAPIKeyModalOpen}
        setOpened={setDeploymentAPIKeyModalOpen}
      />
    </DeploymentDetailsHeaderSC>
  )
}
