import { FC, useContext, useState } from 'react'
import { Modal, Radio, Space, Tooltip } from 'antd'
import { PlatformsType } from '../../models/platform/platform'
import { FeaturesListSC, GreenCheckOutlinedSC, MinusOutlinedSC, RadioGroupSC, UpgradeButtonSC } from './styles'
import { RadioChangeEvent } from 'antd/lib/radio/interface'
import { paths } from '../../router/paths'
import { AppCtx } from '../../providers/appCtx'
import { useIsDefaultOrgPlanQuery } from '../../models/graphql'
import { Spinner } from '../login/styles'
import { useContent } from '../../utils/customeHooks/useContent'
import { ContentType, SlackPlatformTypeSkeleton } from '../../services/contentful/types'

export type SlackPlatformTypeModalProps = {
  open: boolean
  onCancel: () => void
  onOk: (selectedType: PlatformsType) => void
}

const prepareData = (content: SlackPlatformTypeSkeleton[]) => {
  const cloudSlack = content.find(item => item.fields.subscriptionRequired)
  const customSlack = content.find(item => !item.fields.subscriptionRequired)

  return [
    {
      type: PlatformsType.CLOUD_SLACK,
      title: cloudSlack?.fields.title,
      features: cloudSlack?.fields.features ?? [],
      featureIcon: GreenCheckOutlinedSC,
      subscriptionRequired: false,
    },
    {
      type: PlatformsType.SOCKET_SLACK,
      title: customSlack?.fields.title,
      features: customSlack?.fields.features ?? [],
      featureIcon: MinusOutlinedSC,
      subscriptionRequired: false,
    },
  ]
}

export const SlackPlatformTypeModal: FC<SlackPlatformTypeModalProps> = ({ open, onCancel, onOk }) => {
  const appCtx = useContext(AppCtx)
  const content = useContent<SlackPlatformTypeSkeleton[]>(ContentType.SLACK_PLATFORM_TYPE, true)
  const [selectedType, setSelectedType] = useState<PlatformsType>(PlatformsType.CLOUD_SLACK)
  const { data: orgData, isLoading } = useIsDefaultOrgPlanQuery(
    { id: appCtx.orgID },
    {
      refetchOnWindowFocus: 'always',
    },
  )
  const data = prepareData(content ?? [])

  const userOnDefaultPlan = orgData?.organization?.subscription.isDefaultPlan ?? true
  const upgradeButtonLink = paths.SUBSCRIPTION_PLANS.replace(':id', appCtx.orgID)
  return (
    <Spinner spinning={isLoading}>
      <Modal
        title='Choose Slack platform type'
        open={open}
        onCancel={onCancel}
        okText={'Select'}
        onOk={() => onOk(selectedType)}
        okButtonProps={{
          disabled: data.find(item => item.type === selectedType)?.subscriptionRequired && userOnDefaultPlan,
        }}
        maskClosable={false}
      >
        <RadioGroupSC
          value={selectedType}
          onChange={(e: RadioChangeEvent) => setSelectedType(e.target.value as PlatformsType)}
        >
          <Space direction='vertical'>
            {data.map((item, idx) => {
              const planDisabled = item.subscriptionRequired && userOnDefaultPlan
              const upgradeTooltipMsg = planDisabled ? `To select this integration type, upgrade your plan.` : ''
              return (
                <div key={item.title ?? idx}>
                  <Tooltip title={upgradeTooltipMsg}>
                    <Radio
                      key={item.type}
                      value={item.type}
                      disabled={planDisabled}
                    >
                      {item.title}
                    </Radio>
                  </Tooltip>
                  <FeaturesListSC>
                    {item.features.map(feature => (
                      <li key={feature}>
                        <item.featureIcon />
                        {feature}
                      </li>
                    ))}
                  </FeaturesListSC>
                  {planDisabled && (
                    <UpgradeButtonSC
                      type='link'
                      href={upgradeButtonLink}
                      target={'_blank'}
                      rel='noreferrer'
                    >
                      Upgrade your plan to proceed
                    </UpgradeButtonSC>
                  )}
                </div>
              )
            })}
          </Space>
        </RadioGroupSC>
      </Modal>
    </Spinner>
  )
}
