import styled from 'styled-components'
import { Card, CardProps } from 'antd'
import { red, gray, green } from '@ant-design/colors'

interface CardSCProps extends CardProps {
  invalid?: boolean
  warn?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CardSC = styled(({ invalid, warn, ...props }: CardSCProps) => <Card {...props} />)<CardSCProps>`
  .ant-card-head-title {
    display: flex;
    align-items: center;

    svg {
      fill: ${props => (props.warn ? gray.primary : green.primary)};
    }

    .ant-tag {
      text-transform: capitalize;
    }
  }

  height: 100%;
  border-color: ${props => (props.invalid ? red.primary : undefined)};

  .ant-card-body {
    height: calc(100% - 58px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 0;
    font-size: 13px;

    :before,
    :after {
      display: none;
    }
  }
`

export const CardBodySC = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const CardDescSC = styled.div``

export const CardChannelsSC = styled.div`
  padding: 16px 0;
`

export const CardFooterSC = styled.div`
  border-top: 1px solid #f0f0f0;
  padding: 16px 24px 0;
`
