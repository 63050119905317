import { FC, useMemo } from 'react'
import { Col, Row, Typography } from 'antd'
import { CardSC, ReasonToUpgradeIcon, TagSC } from './styles'
import { SubscriptionFeatureSkeleton } from '../../services/contentful/types'

type Props = {
  content: SubscriptionFeatureSkeleton[]
}
export const ReasonsToUpgrade: FC<Props> = props => {
  const { content } = props
  const comingSoonTag = <TagSC color={'processing'}>Coming soon</TagSC>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = useMemo(() => {
    return content.filter(item => item.fields.showInReasonToUpgrade).sort((a, b) => a.fields.order - b.fields.order)
  }, [content])

  return (
    <Row
      gutter={[16, 16]}
      className={'mb-40'}
    >
      {data.map(item => {
        const extra = item.fields.comingSoon ? comingSoonTag : null
        return (
          <Col
            key={item.fields.title}
            className='gutter-row'
            xs={24}
            sm={24}
            md={8}
          >
            <CardSC
              title={
                <>
                  {item.fields.icon.url && (
                    <ReasonToUpgradeIcon
                      src={item.fields.icon.url}
                      alt={item.fields.title}
                    />
                  )}
                  <Typography.Text ellipsis={{ tooltip: item.fields.title }}>{item.fields.title}</Typography.Text>
                </>
              }
              extra={extra}
            >
              {item.fields.description}
            </CardSC>
          </Col>
        )
      })}
    </Row>
  )
}
