import { FC, useState } from 'react'
import { PlatformsType, PlatformsValues } from '../../../models/platform/platform'
import { Button, Space } from 'antd'
import { mapPlatformTypeToIcon } from '../../../utils/platforms'
import { PlusOutlined, SettingOutlined } from '@ant-design/icons'
import { PlatformSettings } from '../settings/PlatformSettings'
import { PluginDeploymentGroup } from '../../plugin/deploymentGroup/pluginDeploymentGroup'
import { getChannelsFromPlatformValues } from '../../../utils/platforms'
import { useMSTeamsChannels } from '../../../utils/customeHooks/useMSTeamsChannels'
import { PlatformSC } from './styles'
import { AddPlugin } from '../../plugin/add/AddPlugin'
import { useAppSelector } from '../../../store/store'
import { ReasonForBlockUpdateTooltip } from '../../tooltip/ReasonForBlockUpdateTooltip'
import { PlatformHealthBadge } from '../health/PlatformHealthBadge'
import { PlatformHealthAlert } from '../health/PlatformHealthAlert'

type Props = {
  values: PlatformsValues
  type: PlatformsType
}
export const Platform: FC<Props> = ({ values, type }) => {
  const isDeploymentUpdateBlocked = useAppSelector(state => state.deployment.updateBlock.isBlocked)
  const [isPlatformSettingsOpened, setPlatformSettingsOpened] = useState(false)
  const [addPluginToPlatform, setAddPluginToPlatform] = useState<{ id: string; type: PlatformsType } | undefined>()
  const { msTeamsChannels } = useMSTeamsChannels()

  const channels = getChannelsFromPlatformValues(values, msTeamsChannels)

  return (
    <>
      <PlatformSC
        title={
          <>
            <PlatformHealthBadge
              id={values.id}
              type={type}
            >
              {mapPlatformTypeToIcon(type)}
            </PlatformHealthBadge>{' '}
            {values.name}
          </>
        }
        extra={
          <Space>
            <ReasonForBlockUpdateTooltip>
              <Button
                icon={<PlusOutlined />}
                onClick={() => setAddPluginToPlatform({ id: values.id, type: type })}
                disabled={isDeploymentUpdateBlocked}
              >
                Add plugin
              </Button>
            </ReasonForBlockUpdateTooltip>
            <Button
              icon={<SettingOutlined />}
              onClick={() => setPlatformSettingsOpened(true)}
            />
          </Space>
        }
        bordered={false}
      >
        <PlatformHealthAlert
          id={values.id}
          type={type}
        />
        <PluginDeploymentGroup
          platformType={type}
          platformId={values.id}
          availableChannels={channels}
        />
      </PlatformSC>
      <PlatformSettings
        isOpened={isPlatformSettingsOpened}
        onClose={() => setPlatformSettingsOpened(false)}
        platformType={type}
        values={values}
      />
      {addPluginToPlatform && (
        <AddPlugin
          isOpened={!!addPluginToPlatform}
          onClose={() => setAddPluginToPlatform(undefined)}
          platformId={addPluginToPlatform.id}
          platformType={addPluginToPlatform.type}
        />
      )}
    </>
  )
}
