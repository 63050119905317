import { FC, useCallback, useMemo, KeyboardEvent, MouseEvent, useState } from 'react'
import { Form, Space, Dropdown } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import deepClone from 'deep-clone'
import { v4 } from 'uuid'

import { SetStep } from '../../../../../models/handlers/handlers'
import { PrevNextButtons } from '../../../../../components/prevNextButtons/PrevNextButtons'
import { DeploymentAddStep } from '../../DeploymentAddPage'
import { PlatformsType, PlatformsTypeNames } from '../../../../../models/platform/platform'
import { useFormik } from 'formik'
import { PlatformsContent } from './contents/PlatformsContent'
import { addPlatformMenu, initFormik, platformDefaultValue, PlatformsFormValues } from './utils'
import { mapPlatformTypeToIcon } from '../../../../../utils/platforms'
import { isError } from '../../../../../utils/formik/formik'
import { EmptyContentBotSC, TabsSC, TabWrapperSC } from './styles'
import { WizardFormValues } from '../../DeploymentAddPage'
import { SlackPlatformTypeModal } from '../../../../platforms/SlackPlatformTypeModal'
import { usePostHog } from 'posthog-js/react'
import { FeatureFlag } from '../../../../../utils/posthog'
import { WizardStepFooter } from '../footer/WizardStepFooter'

export type PlatformConfigurationStepProps = {
  wizardValues: WizardFormValues
  setStep: SetStep
  isLoading: boolean
  onSubmit: (values: PlatformsFormValues) => void
}

export const PlatformsConfigurationStep: FC<PlatformConfigurationStepProps> = props => {
  const { setStep, isLoading } = props
  const [slackModalVisible, setSlackModalVisible] = useState(false)
  const posthog = usePostHog()
  const formik = useFormik<PlatformsFormValues>(initFormik(props))
  const platforms = useMemo(() => {
    const platformsBuff: { platform: PlatformsType; name: string; idx: number }[] = []
    Object.entries(formik.values).forEach(([platform, platformsValues]) => {
      platformsValues.forEach((platformVal, idx) => {
        platformsBuff.push({ platform: platform as PlatformsType, name: platformVal.name, idx })
      })
    })

    return platformsBuff
  }, [formik.values])
  const isSubmitted = formik.submitCount > 0

  const handleAddNewPlatform = useCallback(
    (platform: PlatformsType) => {
      const platformName = platform as keyof PlatformsFormValues
      const values = { ...formik.values }
      if (Array.isArray(values[platformName])) {
        const defaultPlatformValues = platformDefaultValue[platform]
        defaultPlatformValues.id = v4()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        values[platformName] = [...values[platformName], defaultPlatformValues]
      } else {
        values[platformName] = []
      }
      void formik.setValues(values)
    },
    [formik],
  )

  const handleRemovePlatform = (targetKey: MouseEvent | KeyboardEvent | string, action: 'add' | 'remove') => {
    if (action === 'remove' && typeof targetKey === 'string') {
      const [platform, idx] = targetKey.split('_')
      const platformName = platform as PlatformsType
      const values = { ...formik.values }
      values[platformName].splice(+idx, 1)
      void formik.setValues(deepClone(values))
    }
  }

  const onSlackModalClose = (platformType: PlatformsType) => {
    setSlackModalVisible(false)
    handleAddNewPlatform(platformType)
  }

  return (
    <>
      <Form layout={'vertical'}>
        <TabsSC
          type='editable-card'
          onEdit={handleRemovePlatform}
          addIcon={
            <Dropdown
              overlayStyle={{ minWidth: '145px' }}
              menu={{
                items: addPlatformMenu({
                  onClick: handleAddNewPlatform,
                  onSlackClick: () => setSlackModalVisible(true),
                  displaySocketSlack: posthog.isFeatureEnabled(FeatureFlag.DISPLAY_SOCKET_SLACK),
                }),
              }}
            >
              <Space>
                Add platform <CaretDownOutlined />
              </Space>
            </Dropdown>
          }
          items={platforms.map(platform => ({
            label: (
              <TabWrapperSC
                error={isError(
                  !!formik.errors[platform.platform]?.[platform.idx],
                  !!formik.touched[platform.platform]?.[platform.idx] || isSubmitted,
                )}
              >
                {mapPlatformTypeToIcon(platform.platform, { height: '12px' })}
                {platform.name ? platform.name : PlatformsTypeNames[platform.platform]}
              </TabWrapperSC>
            ),
            key: `${platform.platform}_${platform.idx}`,
            children: (
              <PlatformsContent
                platformType={platform.platform}
                formik={formik}
                idx={platform.idx}
              />
            ),
          }))}
        />
        <SlackPlatformTypeModal
          open={slackModalVisible}
          onCancel={() => setSlackModalVisible(false)}
          onOk={onSlackModalClose}
        />
        {platforms.length === 0 && <EmptyContentBotSC />}
      </Form>
      <WizardStepFooter helpMessage={'Add at least one platform to proceed'}>
        <PrevNextButtons
          isLoading={isLoading}
          handlePrevStep={() => setStep(DeploymentAddStep.CONNECTION)}
          handleNextStep={() => void formik.submitForm()}
          disableNextButton={Object.values(formik.values).every(platformValues => platformValues.length === 0)}
        />
      </WizardStepFooter>
    </>
  )
}
