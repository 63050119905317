import { FC } from 'react'
import { Button, Checkbox, Space } from 'antd'
import { PlatformsType } from '../../../models/platform/platform'
import { Tag, TagType } from '../../tag/Tag'
import { CardBodySC, CardChannelsSC, CardDescSC, CardFooterSC, CardSC } from '../styles'
import { PluginValue } from '../../../pages/clusters/add/steps/plugins/PluginsStep'
import { getBindingChannels, PluginTemplate } from './utils'
import { SpanSC } from './styles'
import { generateConfigName } from '../../../utils/plugins'
import { WarnBox } from '../../warnBox/WarnBox'
import { isPlatformWithChannels, MSTeamsCloudChannel, PlatformsChannels } from '../../../utils/platforms'
import { PluginType } from '../../../models/graphql'

type Props = {
  plugin?: PluginValue
  msTeamsChannels: MSTeamsCloudChannel[]
  pluginTemplate: PluginTemplate
  platform: PlatformsType
  platformIdx: number
  platformId: string
  platformsChannels: PlatformsChannels
  onCheckboxChange: (pluginData: PluginValue, isChecked: boolean) => void
  onConfig?: (pluginValue: PluginValue, pluginTemplate: PluginTemplate) => void
}

export const PluginWizardCard: FC<Props> = props => {
  const {
    onCheckboxChange,
    pluginTemplate,
    platform,
    platformIdx,
    platformId,
    platformsChannels,
    onConfig,
    plugin,
    msTeamsChannels,
  } = props
  const isEnabled = plugin?.bindings?.[platformId] && plugin.enabled
  const isInvalid = Boolean(plugin?.enabled && plugin.invalid)
  const notBound = isEnabled && plugin.bindings?.[platformId]?.channels.length === 0

  return (
    <CardSC
      invalid={isInvalid}
      title={
        <>
          <Checkbox
            name={'plugins'}
            onChange={evt =>
              onCheckboxChange(
                {
                  configurationName: plugin ? plugin.configurationName : generateConfigName(pluginTemplate.name),
                  configuration: plugin?.configuration ?? '{}',
                  enabled: !!plugin?.enabled,
                  name: pluginTemplate.name,
                  type: pluginTemplate.type,
                  displayName: pluginTemplate.title,
                  bindings: {
                    ...plugin?.bindings,
                    [platformId]: {
                      channels: plugin?.bindings?.[platformId]
                        ? plugin.bindings[platformId].channels
                        : getBindingChannels(platform, platformsChannels[platform]?.[platformIdx]),
                      platform: platform,
                      platformId,
                      platformIdx: platformIdx,
                    },
                  },
                  rbac: plugin?.rbac,
                },
                evt.target.checked,
              )
            }
            checked={isEnabled}
          >
            <SpanSC invalid={isInvalid}>{plugin?.displayName ?? pluginTemplate.title}</SpanSC>
          </Checkbox>
          <Tag type={pluginTemplate.type === PluginType.Executor ? TagType.FORTH : TagType.SECONDARY}>
            {pluginTemplate.type.toLowerCase()}
          </Tag>
        </>
      }
    >
      <CardBodySC>
        {pluginTemplate.description && (
          // TODO(https://github.com/kubeshop/botkube-cloud/issues/622): We should revisit the approach once the plugin indexes are customizable
          <CardDescSC dangerouslySetInnerHTML={{ __html: pluginTemplate.description }} />
        )}

        <CardChannelsSC>
          {isPlatformWithChannels(platform) && (
            <>
              <div className={'mb-5'}>
                {isEnabled && <b>Channels:</b>}
                {notBound && <WarnBox message={'The plugin is not bound to any platform.'} />}
              </div>
              <Space wrap={true}>
                {isEnabled
                  ? plugin.bindings?.[platformId].channels.map(channel => {
                      let channelDisplayName = channel
                      if (platform === PlatformsType.CLOUD_TEAMS) {
                        channelDisplayName = msTeamsChannels.find(ch => ch.id === channel)?.name ?? channel
                      }
                      if (platform === PlatformsType.CLOUD_SLACK) {
                        channelDisplayName =
                          platformsChannels[platform]?.[platformIdx].meta?.slackChannels.find(
                            slCh => slCh.id === channel,
                          )?.name ?? channel
                      }

                      return (
                        <Tag
                          key={channel}
                          ellipsis={{ enable: true, width: '300px' }}
                        >
                          {platform !== PlatformsType.DISCORD && '#'}
                          {channelDisplayName}
                        </Tag>
                      )
                    }) ?? ''
                  : ''}
              </Space>
            </>
          )}
        </CardChannelsSC>
      </CardBodySC>
      <CardFooterSC>
        <Button
          type={'link'}
          onClick={() => {
            if (plugin && onConfig) {
              onConfig(plugin, pluginTemplate)
            }
          }}
          disabled={!onConfig || !plugin || !isEnabled}
        >
          Configure
        </Button>
      </CardFooterSC>
    </CardSC>
  )
}
