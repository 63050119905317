import { loadRuntimeConfig } from '../../config/runtime'
import { useAuth0 } from '@auth0/auth0-react'
import { browserStoreService, StorageKey } from '../storage'
import { notification } from 'antd'

type GraphQLResponse<TData> = {
  errors?: { message: string }[]
  status: number
  data: TData & {
    errors?: {
      message: string
      path?: string[]
    }[]
  }
}

export const useFetchData = <TData, TVariables>(query: string) => {
  const { getAccessTokenSilently } = useAuth0()

  return async (variables?: TVariables) => {
    const token = await getAccessTokenSilently()
    const { REACT_APP_BACKEND_BASE_URL } = loadRuntimeConfig()

    try {
      const res = await fetch(`${REACT_APP_BACKEND_BASE_URL}/graphql`, {
        method: 'POST',
        headers: {
          'X-Botkube-Organization-Id': browserStoreService.get(StorageKey.ORG_ID) ?? '',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ query, variables }),
      })

      const json = (await res.json()) as GraphQLResponse<TData>
      if (json.status === 401) {
        browserStoreService.removeItem(StorageKey.ORG_ID)
      }
      if (json.errors && json.errors.length > 0) {
        console.error(json.errors)

        let errMessage
        if (json.errors.length === 1) {
          errMessage = `Error: ${errorMessageForError(json.errors[0].message)}`
        } else {
          errMessage = `${json.errors.length} errors:`
          for (const err of json.errors) {
            errMessage = `${errMessage}- ${errorMessageForError(err.message)}\n`
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!json.data) {
          throw new Error(errMessage)
        }
        notification.error({ message: errMessage })
      }
      const data = json.data
      if (json.errors) {
        data.errors = json.errors
      }

      return data
    } catch (err: unknown) {
      const errMessage = (err as Error).message
      notification.error({ message: errMessage })

      throw Error(errMessage)
    }
  }
}

function errorMessageForError(input: string): string {
  if (input in knownBackendErrors) {
    return knownBackendErrors[input]
  }

  return input
}

const knownBackendErrors: Record<string, string> = {
  'while finding user for appending it by email: record not found':
    'User not found. Make sure you have entered an email address of an already registered account.',
}
