import React, { FC, useContext, useState } from 'react'

import { Button, Col, Flex, Row, Tag, Tooltip } from 'antd'
import { FolderOutlined, StarOutlined, UploadOutlined } from '@ant-design/icons'
import { AiCustomDocument, useAiCustomDocsQuery } from '../../models/graphql'
import { AppCtx } from '../../providers/appCtx'
import Markdown from 'markdown-to-jsx'
import { CardSC, ColSC, TooltipWrapperSC } from './styles'
import { TableRowSelection } from 'antd/lib/table/interface'
import { Link } from 'react-router-dom'
import { paths } from '../../router/paths'
import { UploadModal } from './UploadModal'
import { DeleteDocsButton } from './DeleteDocs'
import { CustomDocsTable, DataType } from './DocsTable'

const PAGE_LIMIT = 100

type CustomDocsUploadProps = {
  content?: {
    documentsTitle: string
    documentsDescription?: string
  }
}

export const CustomDocsUpload: FC<CustomDocsUploadProps> = ({ content }) => {
  const { orgID } = useContext(AppCtx)

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [isUploadModalOpen, setUploadModalOpen] = useState(false)

  const [docsList, setDocsList] = useState<AiCustomDocument[]>([])
  const [after, setAfter] = useState<string | null>(null)
  const [isTableLoading, setTableLoading] = useState(false)
  const { data, isLoading, refetch } = useAiCustomDocsQuery(
    {
      limit: PAGE_LIMIT,
      after,
      orgID,
    },
    {
      onSuccess: data => {
        const items = [...docsList, ...(data.organization?.aiAssistant.uploadedAIDocs?.data ?? [])]
        setDocsList(Array.from(new Set(items).values()))
        setTableLoading(false)
      },
      refetchOnWindowFocus: false,
    },
  )
  const fetchMore = () => {
    setAfter(data?.organization?.aiAssistant.uploadedAIDocs?.pageInfo.lastID ?? null)
  }
  const resetTable = () => {
    setAfter(null)
    setDocsList([])
    setSelectedRowKeys([])
    setTableLoading(true)
    void refetch()
  }

  const isDefaultPlan = data?.organization?.subscription.isDefaultPlan ?? false

  const tableData = docsList.map(doc => {
    return {
      key: doc.fileID,
      fileName: doc.fileName,
      bytes: doc.bytes,
      createdAt: doc.createdAt as string,
    }
  })
  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys)
    },
  }

  const uploadButton = (
    <Button
      type='primary'
      icon={<UploadOutlined />}
      disabled={isDefaultPlan}
      onClick={() => setUploadModalOpen(true)}
    >
      Upload file(s)
    </Button>
  )

  return (
    <>
      <CardSC
        title={
          <>
            <FolderOutlined /> {content?.documentsTitle ?? 'Documents'}
            {isDefaultPlan && (
              <TooltipWrapperSC>
                <Tooltip title={'Upgrade your Botkube Cloud plan to use the feature.'}>
                  <Link to={paths.SUBSCRIPTION_PLANS}>
                    <Tag icon={<StarOutlined />}>Plan upgrade required</Tag>
                  </Link>
                </Tooltip>
              </TooltipWrapperSC>
            )}
          </>
        }
        extra={
          <Flex
            gap='small'
            wrap
          >
            <DeleteDocsButton
              selectedRowKeys={selectedRowKeys}
              onDeleteFinish={resetTable}
              onUploadStart={() => setTableLoading(true)}
            />

            {isDefaultPlan ? (
              <Tooltip title={'Upgrade your Botkube Cloud plan to use the feature.'}>{uploadButton}</Tooltip>
            ) : (
              uploadButton
            )}
          </Flex>
        }
      >
        <Row>
          <ColSC lg={8}>{content?.documentsDescription && <Markdown>{content.documentsDescription}</Markdown>}</ColSC>
          <Col lg={16}>
            <CustomDocsTable
              isLoading={isTableLoading || isLoading}
              data={tableData}
              rowSelection={rowSelection}
              totalCount={data?.organization?.aiAssistant.uploadedAIDocs?.totalCount}
              fetchMore={fetchMore}
              canFetchMore={data?.organization?.aiAssistant.uploadedAIDocs?.pageInfo.hasNextPage}
            />
          </Col>
        </Row>
      </CardSC>
      <UploadModal
        isOpen={isUploadModalOpen}
        customDocsReadyToUpload={data?.organization?.aiAssistant.customDocsReadyToUpload}
        onEnableDocsSuccess={() => void refetch()}
        onUploadFinish={resetTable}
        closeModal={() => setUploadModalOpen(false)}
      />
    </>
  )
}
