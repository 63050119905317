import { FormikConfig } from 'formik'
import * as yup from 'yup'
import { AnySchema } from 'yup'
import { ValidationMsg } from '../../../../utils/validation'
import { PlatformsType } from '../../../../models/platform/platform'
import { SocketSlack } from '../../../../models/graphql'
import { HandlePlatformFormSubmit } from '../../../../components/platform/settings/utils'
import { SocketSlackFormValues } from '../../../../pages/clusters/add/steps/platformsConfiguration/utils'

type Props = {
  values?: SocketSlack
  onSubmit: HandlePlatformFormSubmit
}
export const initFormik = (props: Props): FormikConfig<SocketSlackFormValues> => {
  return {
    enableReinitialize: true,
    initialValues: {
      id: props.values?.id ?? '',
      name: props.values?.name ?? '',
      appToken: props.values?.appToken ?? '',
      botToken: props.values?.botToken ?? '',
      channelNames: props.values?.channels.map(channel => channel.name) ?? [],
    },
    validationSchema: yup.object().shape<Record<keyof SocketSlackFormValues, AnySchema>>({
      id: yup.string(),
      name: yup.string().required(ValidationMsg.REQUIRED),
      appToken: yup
        .string()
        .matches(/^xapp-.*/, 'App token must start from `xapp-` prefix')
        .required(ValidationMsg.REQUIRED),
      botToken: yup
        .string()
        .matches(/^xoxb-.*/, 'Bot token must start from `xoxb-` prefix')
        .required(ValidationMsg.REQUIRED),
      channelNames: yup.array().of(yup.string()).min(1, ValidationMsg.MIN_ONE),
    }),
    onSubmit: values => {
      props.onSubmit(PlatformsType.SOCKET_SLACK, values)
    },
  }
}
