import { FC, ReactNode, useContext } from 'react'
import { Layout } from 'antd'
import { Container } from '../container/Container'
import { Header } from '../../components/header/Header'
import { Footer } from '../../components/footer/Footer'
import { UpdateBlockedAlert } from '../../components/orgUpdateBlocked/UpdateBlockedAlert'
import { SlackReinstallRequiredAlert } from '../../components/slackReinstallRequired/SlackReinstallRequiredAlert'
import { AppCtx } from '../../providers/appCtx'

const { Header: ADHeader, Content: ADContent } = Layout

type Props = {
  children?: ReactNode
}
export const MainLayout: FC<Props> = props => {
  const appCtx = useContext(AppCtx)
  return (
    <Layout>
      <ADHeader ref={appCtx.headerRef}>
        <UpdateBlockedAlert />
        <SlackReinstallRequiredAlert />
        <Container>
          <Header />
        </Container>
      </ADHeader>
      <ADContent>
        <Container>{props.children}</Container>
      </ADContent>
      <Footer />
    </Layout>
  )
}
