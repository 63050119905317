import React, { ReactNode, useContext } from 'react'
import { HeaderSC } from './styles'
import { ReactComponent as BotkubeLogo } from '../../assets/botkube-logo.svg'
import { MenuMain } from '../menu/MenuMain'
import { Link } from 'react-router-dom'
import { paths } from '../../router/paths'
import { AppCtx } from '../../providers/appCtx'
import { viewToPathMap } from '../../pages/home/home'

type Props = {
  children?: ReactNode
  disableHomePageLink?: boolean
}
export const Header: React.FC<Props> = ({ children, disableHomePageLink }) => {
  let homeLink = paths.HOME
  const appCtx = useContext(AppCtx)
  if (appCtx.defaultView) {
    homeLink = viewToPathMap[appCtx.defaultView]
  }

  const logo = <BotkubeLogo className={'logo'} />
  return (
    <HeaderSC>
      {disableHomePageLink ? (
        logo
      ) : (
        <Link
          to={homeLink}
          className={'logo-link'}
        >
          {logo}
        </Link>
      )}
      {children ? children : <MenuMain />}
    </HeaderSC>
  )
}
