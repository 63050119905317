import { DeploymentStatusPhase, Maybe } from '../../../models/graphql'
import { AlertProps } from 'antd'
import { ReactNode } from 'react'
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons'

type DeploymentStatusArgs = {
  status?: { phase: DeploymentStatusPhase }
  draft?: Maybe<boolean>
}
export const mapStatusToType = ({ status, draft }: DeploymentStatusArgs): AlertProps['type'] => {
  if (draft) {
    return 'warning'
  }
  switch (status?.phase) {
    case DeploymentStatusPhase.Creating:
      return 'info'
    case DeploymentStatusPhase.Connected:
      return 'success'
    case DeploymentStatusPhase.Failed:
      return 'error'
    case DeploymentStatusPhase.Disconnected:
      return 'warning'
    case DeploymentStatusPhase.Deleted:
      return 'warning'
    default:
      return 'info'
  }
}

export const mapStatusToTagColor = ({
  status,
  draft,
}: DeploymentStatusArgs): 'success' | 'processing' | 'error' | 'warning' | 'default' => {
  if (draft) {
    return 'warning'
  }
  switch (status?.phase) {
    case DeploymentStatusPhase.Creating:
    case DeploymentStatusPhase.Connecting:
      return 'processing'
    case DeploymentStatusPhase.Connected:
      return 'success'
    case DeploymentStatusPhase.Failed:
      return 'error'
    case DeploymentStatusPhase.Disconnected:
      return 'warning'
    case DeploymentStatusPhase.Deleted:
      return 'warning'
    default:
      return 'processing'
  }
}

export const mapStatusToIcon = ({ status, draft }: DeploymentStatusArgs): ReactNode => {
  if (draft) {
    return <ExclamationCircleOutlined />
  }
  switch (status?.phase) {
    case DeploymentStatusPhase.Creating:
    case DeploymentStatusPhase.Connecting:
    case DeploymentStatusPhase.Updating:
      return <SyncOutlined />
    case DeploymentStatusPhase.Connected:
      return <CheckCircleOutlined />
    case DeploymentStatusPhase.Failed:
      return <CloseCircleOutlined />
    case DeploymentStatusPhase.Disconnected:
      return <MinusCircleOutlined />
    case DeploymentStatusPhase.Deleted:
      return <ExclamationCircleOutlined />
    default:
      return <ExclamationCircleOutlined />
  }
}

export const mapStatusToMsg = ({ status, draft }: DeploymentStatusArgs): string => {
  if (draft) {
    return 'Draft'
  }

  if (!status) {
    return ''
  }

  switch (status.phase) {
    case DeploymentStatusPhase.Creating:
      return 'Creating'
    case DeploymentStatusPhase.Updating:
      return 'Updating'
    case DeploymentStatusPhase.Connecting:
      return 'Connecting'
    case DeploymentStatusPhase.Connected:
      return 'Connected'
    case DeploymentStatusPhase.Failed:
      return 'Failed'
    case DeploymentStatusPhase.Disconnected:
      return 'Disconnected'
    case DeploymentStatusPhase.Deleted:
      return 'Deleted'
    default:
      return status.phase
  }
}
