import React from 'react'
import { ContainerSC } from './styles'

type Props = {
  className?: string
  children?: React.ReactNode
}
export const Container: React.FC<Props> = props => (
  <ContainerSC className={`container ${props.className ?? ''}`}>{props.children}</ContainerSC>
)
