import { Button, Space, Typography } from 'antd'

import { WarnBox } from '../../warnBox/WarnBox'
import { Tag, TagType } from '../../tag/Tag'
import { Action } from '../../../store/slices/deployment/model'
import { ReasonForBlockUpdateTooltip } from '../../tooltip/ReasonForBlockUpdateTooltip'

type DefActionColumnsArg = {
  onEdit: (id: string) => void
  onDelete: (id: string) => void
  disabled?: boolean
}
export const defActionsColumns = ({ onEdit, onDelete, disabled }: DefActionColumnsArg) => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Display Name',
      dataIndex: 'displayName',
    },
    {
      title: 'Trigger Source',
      dataIndex: 'sources',
      render: (sources: string[]) =>
        sources.length > 0 ? sources.join(', ') : <WarnBox message={`Action doesn't have trigger source`} />,
    },
    {
      title: 'Executor',
      dataIndex: 'executors',
      render: (executors: string[]) =>
        executors.length > 0 ? executors.join(', ') : <WarnBox message={`Action doesn't have executor`} />,
    },
    {
      title: 'Command',
      dataIndex: 'command',
      render: (command: string) => (
        <Typography.Text
          style={{ width: 200 }}
          ellipsis={{ tooltip: command }}
        >
          {command}
        </Typography.Text>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'enabled',
      render: (enabled: boolean) =>
        enabled ? <Tag type={TagType.ENABLE}>Enabled</Tag> : <Tag type={TagType.FORTH}>Disabled</Tag>,
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      render: (id: string) => (
        <Space>
          <ReasonForBlockUpdateTooltip>
            <Button
              type={'link'}
              disabled={disabled}
              onClick={() => onEdit(id)}
            >
              Edit
            </Button>
          </ReasonForBlockUpdateTooltip>
          <ReasonForBlockUpdateTooltip>
            <Button
              type={'link'}
              danger={true}
              disabled={disabled}
              onClick={() => onDelete(id)}
            >
              Delete
            </Button>
          </ReasonForBlockUpdateTooltip>
        </Space>
      ),
    },
  ]
}

export const prepareActionData = (action: Action[], plugins: { name: string; displayName: string }[]) =>
  action.map(action => ({
    ...action,
    key: action.id,
    sources: action.bindings.sources
      .map(sourceName => plugins.find(plugin => plugin.name === sourceName)?.displayName)
      .filter(source => !!source),
    executors: action.bindings.executors
      .map(executorName => plugins.find(plugin => plugin.name === executorName)?.displayName)
      .filter(executor => !!executor),
  }))
