import React, { FC } from 'react'
import { useRegenerateDeploymentApiKeyMutation } from '../../../models/graphql'
import { useAppSelector } from '../../../store/store'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { ModalSC } from './styles'

type Props = {
  isOpened: boolean
  setOpened: (isOpen: boolean) => void
  onSuccess?: () => void
}

export const DeploymentApiKey: FC<Props> = ({ setOpened, isOpened, onSuccess }) => {
  const deployment = useAppSelector(state => state.deployment.data)

  const { mutate: regenerateDeploymentApiKey, isLoading: isLoadingApiKey } = useRegenerateDeploymentApiKeyMutation({
    onSuccess: () => {
      setOpened(false)
      if (typeof onSuccess === 'function') {
        onSuccess()
      }
    },
  })

  if (!deployment) {
    return null
  }

  return (
    <ModalSC
      width={700}
      title={
        <>
          <ExclamationCircleOutlined />
          Regenerate API Key
        </>
      }
      onOk={() => regenerateDeploymentApiKey({ id: deployment.id })}
      okText={'Regenerate API Key'}
      okButtonProps={{ danger: true }}
      cancelText={'Cancel'}
      confirmLoading={isLoadingApiKey}
      open={isOpened}
      onCancel={() => setOpened(false)}
    >
      Generating a new API key will invalidate the previous one, causing the Botkube agent using it to lose connection
      to Botkube Cloud. To reconnect, re-install the agent with the new API key.
    </ModalSC>
  )
}
