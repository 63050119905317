import React from 'react'
import { MainLayout } from '../../../layouts/main/MainLayout'
import { useAuth0 } from '@auth0/auth0-react'
import { CodeBlock } from '../../../components/codeBlock/CodeBlock'
import './styles.css'
import { useSearchParams } from 'react-router-dom'
import { Result } from 'antd'
import { IllustrationSC } from '../../chat-platform/styles'

const redirectWithToken = async (getAccessTokenSilently: () => Promise<string>, redirectUrl: string) => {
  const token = await getAccessTokenSilently()
  window.location.replace(`${redirectUrl}?token=${token}`)
}

const REDIRECT_URL_QUERY_PARAM = 'redirect_url'
const SUCCESS_STATE_QUERY_PARAM = 'success'
export const LoginCLIPage: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0()
  const [searchParams] = useSearchParams()
  const redirectUrl = searchParams.get(REDIRECT_URL_QUERY_PARAM)
  const success = searchParams.get(SUCCESS_STATE_QUERY_PARAM)

  if (success === 'true') {
    return (
      <MainLayout>
        <Result
          icon={<IllustrationSC />}
          status='success'
          title='Successfully Connected Botkube CLI!'
          subTitle='The authentication flow has completed successfully. You may close this window.'
        />
      </MainLayout>
    )
  }

  if (!redirectUrl) {
    return (
      <MainLayout>
        <Result
          style={{ marginTop: '200px' }}
          status='warning'
          title={
            <>
              There redirect URL is missing. Close the website and re-run:
              <CodeBlock className={'login-cmd'}>botkube login</CodeBlock>
            </>
          }
        />
      </MainLayout>
    )
  }

  void redirectWithToken(getAccessTokenSilently, redirectUrl)

  return (
    <MainLayout>
      <Result
        style={{ marginTop: '200px' }}
        title={
          <>
            Redirecting to <code>{redirectUrl}</code>...
          </>
        }
      />
    </MainLayout>
  )
}

export default LoginCLIPage
