import styled from 'styled-components'
import { Drawer } from 'antd'

export const DrawerSC = styled(Drawer)`
  .ant-drawer-title {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`
