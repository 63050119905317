import { useContext, useMemo } from 'react'
import { AppCtx, ConnectedTeamsOrganization } from '../../providers/appCtx'
import { MSTeamsCloudChannel } from '../platforms'

type Teams = NonNullable<ConnectedTeamsOrganization['teams']>

type TeamsChannels = {
  channel: NonNullable<Teams[number]['channels']>[number]
  aadGroupId: string
}[]

export const useMSTeamsChannels = (aadGroupId?: string) => {
  const appCtx = useContext(AppCtx)

  const msTeamsChannels = useMemo((): MSTeamsCloudChannel[] => {
    const channels = appCtx.connectedMSTeams
      .reduce<Teams>((teams, org) => {
        if (org.teams) {
          teams.push(...org.teams)
        }
        return teams
      }, [])
      .reduce<TeamsChannels>((channels, team) => {
        team.channels?.forEach(channel => {
          channels.push({
            channel: channel,
            aadGroupId: team.aadGroupId,
          })
        })

        return channels
      }, [])
      .map(teamChannel => ({
        id: teamChannel.channel.conversationId,
        name: teamChannel.channel.name,
        description: teamChannel.channel.description,
        aadGroupId: teamChannel.aadGroupId,
      }))

    return aadGroupId ? channels.filter(channel => channel.aadGroupId === aadGroupId) : channels
  }, [appCtx.connectedMSTeams, aadGroupId])

  return { msTeamsChannels }
}
