import { FC, useContext, useState } from 'react'
import { usePageTitle } from '../../utils/customeHooks/usePageTitle'
import { useNavigate } from 'react-router-dom'
import {
  OrganizationDefaultView,
  useHomePageQuery,
  useUpdateCurrentUserMutation,
  useUpdateOrganizationMutation,
} from '../../models/graphql'
import { paths } from '../../router/paths'
import { FlexibleLayout } from '../../layouts/flexible/FlexibleLayout'
import { PageHeader } from '../../components/pageHeader/PageHeader'
import { Container } from '../../layouts/container/Container'
import { Checkbox, Col, Row } from 'antd'
import { ProductCard } from './ProductCard'
import ChatPlatformImagePath from '../../assets/chat-platform.png'
import FuseImagePath from '../../assets/fuse.png'

import { ReactComponent as ChatIcon } from '../../assets/icons/chat-icon.svg'
import { ReactComponent as TerminalIcon } from '../../assets/icons/terminal-icon.svg'
import { AppCtx } from '../../providers/appCtx'
import { Spinner } from '../login/styles'

export const viewToPathMap = {
  [OrganizationDefaultView.ChatPlatform]: paths.CHAT_PLATFORM_HOME,
  [OrganizationDefaultView.FuseCli]: paths.FUSE,
}

const fuseFeatures = [
  { headline: 'Quick Setup', description: 'Get up and running in seconds with minimal configuration' },
  {
    headline: 'Reduce Context Switching',
    description: 'Gain in-depth expertise and tailored assistance directly in your terminal',
  },
  {
    headline: 'Natural Language Commands',
    description: 'Use simple, conversational commands to interact with your infrastructure',
  },
  {
    headline: 'Seamless Tool Integration',
    description: 'Integrate with your locally installed tools, with full control over the process',
  },
]

const chatPlatformFeatures = [
  {
    headline: 'Real-Time Collaboration',
    description: 'Receive alerts and troubleshoot with your team in Slack or Microsoft Teams',
  },
  { headline: 'Familiar Interface', description: 'Get clear, rich messages for quick understanding' },
  {
    headline: 'K8s Agent Setup',
    description: 'Requires an agent installed in your Kubernetes cluster for full features',
  },
  {
    headline: 'Admin Access Needed',
    description: 'Requires admin permissions to set up in Slack, Teams or other chat platform',
  },
]

export const HomePage: FC = () => {
  usePageTitle('Dashboard')
  const navigate = useNavigate()
  const appCtx = useContext(AppCtx)
  const [defaultViewState, setDefaultViewState] = useState(true)

  const { mutate: updateCurrentUser } = useUpdateCurrentUserMutation()
  const { mutateAsync: updateOrg, isLoading: isUpdatingOrg } = useUpdateOrganizationMutation()
  const { data, isLoading } = useHomePageQuery(
    { orgID: appCtx.orgID },
    {
      onSuccess: data => {
        if (!data.currentUser.firstLoginPageVisitedIn) {
          updateCurrentUser({ input: { firstLoginPageVisitedIn: true } })
          navigate(paths.FIRST_LOGIN)
          return
        }
      },
    },
  )

  if (appCtx.defaultView) {
    navigate(viewToPathMap[appCtx.defaultView])
    return <Spinner spinning />
  }

  const navigateWithSettingDefault = async (viewName: OrganizationDefaultView) => {
    if (defaultViewState) {
      await updateOrg({
        id: appCtx.orgID,
        input: { displayName: data?.organization?.displayName ?? '', defaultView: viewName },
      })
    }
    if (appCtx.refetchAppQuery) {
      await appCtx.refetchAppQuery()
    }

    navigate(viewToPathMap[viewName])
  }

  return (
    <FlexibleLayout>
      <Spinner spinning={isLoading || isUpdatingOrg}>
        <PageHeader
          title={'Welcome to Botkube Dashboard.'}
          description='Manage all Botkube installations in a single place.'
        />
        <Container className={'mt-20'}>
          <Row
            gutter={[16, 16]}
            className={'mb-40'}
          >
            <ProductCard
              title={'Fuse'}
              subheader={'Ultimate DevOps Copilot, available directly from your terminal.'}
              isFeatured
              imagePath={FuseImagePath}
              icon={<TerminalIcon />}
              features={fuseFeatures}
              btnOnClick={() => void navigateWithSettingDefault(OrganizationDefaultView.FuseCli)}
            />
            <ProductCard
              title={'Chat Platform'}
              subheader={'AI-Powered Kubernetes Troubleshooting in your favorite chat platform.'}
              imagePath={ChatPlatformImagePath}
              icon={<ChatIcon />}
              features={chatPlatformFeatures}
              btnOnClick={() => void navigateWithSettingDefault(OrganizationDefaultView.ChatPlatform)}
            />
            <Col
              xs={24}
              style={{ textAlign: 'center' }}
            >
              <Checkbox
                checked={defaultViewState}
                onChange={() => setDefaultViewState(!defaultViewState)}
              >
                <h2>Set as a default homepage</h2>
              </Checkbox>
            </Col>
          </Row>
        </Container>
      </Spinner>
    </FlexibleLayout>
  )
}
