import { DeploymentInfoQuery, DeploymentPageQuery, PluginType } from '../../../models/graphql'

export type Deployment = NonNullable<DeploymentPageQuery['deployment']>

export type DeploymentPlugin = NonNullable<Deployment['plugins']>[number]

export type Action = Deployment['actions'][number]

export type DeploymentUpdateReport = {
  platforms?: Record<
    string,
    {
      isValid: boolean
      reason?: string
    }
  >
  plugins?: Record<
    string,
    {
      isValid: boolean
    }
  >
}

export type DeploymentUpdateBlock = {
  outdated: {
    isBlocked: boolean
    reason?: string
  }
  needUpgrade: {
    isBlocked: boolean
    reason?: string
  }
  org: {
    isBlocked: boolean
    reason?: string
  }
  isBlocked: boolean
}

export type DeploymentHealth = NonNullable<
  NonNullable<NonNullable<DeploymentInfoQuery['deployment']>['heartbeat']>['health']
>
export const DEPLOYMENT_HEALTH_CLOUD_TEAMS_KEY = 'cloud-ms-teams'

export type HandleFormValidationReport = (platformId: string, isValid: boolean, reason?: string) => void

export type HandlePluginValidationReport = (pluginId: string, isValid: boolean) => void

export type AddPluginValues = {
  id: string
  name: string
  displayName: string
  type: PluginType
  invalid: boolean
  configuration: string
  configurationName: string
  enabled: boolean
}
