import styled from 'styled-components'

export const PageSC = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 200px);
`

export const WrapperIlustrationSC = styled.div`
  position: relative;

  h2 {
    position: relative;
    font-size: 11.25rem;
    line-height: 1;
    font-weight: 700;
    margin: 0;
    color: #cccccc;
  }
`

export const WrapperTextSC = styled.div`
  text-align: center;
  margin-top: 80px;
`

export const IllustrationWrapperSC = styled.div`
  position: absolute;
  left: 50%;
  top: 115px;
  z-index: 2;
  transform: translateX(-50%);
`
