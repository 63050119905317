import React, { FC, useState } from 'react'
import { addPlatformMenu } from '../../../pages/clusters/add/steps/platformsConfiguration/utils'
import { FeatureFlag } from '../../../utils/posthog'
import { Button, Dropdown, Tooltip } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { PlatformsType } from '../../../models/platform/platform'
import { ButtonType } from 'antd/es/button'
import { usePostHog } from 'posthog-js/react'
import { PlatformSettings } from '../settings/PlatformSettings'
import { SlackPlatformTypeModal } from '../../../pages/platforms/SlackPlatformTypeModal'
import { addPlatformToDeployment } from '../../../store/slices/deployment/deployment'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { provideReasonsForBlockedUpdate } from '../../../utils/deployment'

type Props = {
  addButtonType?: ButtonType
  addButtonLabel?: string
}

export const AddPlatform: FC<Props> = ({ addButtonLabel, addButtonType }) => {
  const posthog = usePostHog()
  const dispatch = useAppDispatch()
  const isDeploymentBlocked = useAppSelector(state => state.deployment.updateBlock.isBlocked)
  const deploymentUpdateBlock = useAppSelector(state => state.deployment.updateBlock)
  const deploymentUpdateValidReport = useAppSelector(state => state.deployment.updateValidReport)
  const [platformTypeToAdd, setPlatformTypeToAdd] = useState<PlatformsType | undefined>()
  const [slackModalVisible, setSlackModalVisible] = useState(false)

  const handleAddNewPlatform = (platformType: PlatformsType) => {
    setPlatformTypeToAdd(platformType)
  }

  const onSlackModalClose = (platformType: PlatformsType) => {
    setSlackModalVisible(false)
    dispatch(addPlatformToDeployment({ platformType }))
  }

  return (
    <>
      <Dropdown
        menu={{
          items: addPlatformMenu({
            onClick: handleAddNewPlatform,
            onSlackClick: () => setSlackModalVisible(true),
            displaySocketSlack: posthog.isFeatureEnabled(FeatureFlag.DISPLAY_SOCKET_SLACK),
          }),
        }}
        disabled={isDeploymentBlocked}
      >
        <Tooltip
          title={
            isDeploymentBlocked
              ? provideReasonsForBlockedUpdate(deploymentUpdateBlock, deploymentUpdateValidReport)
              : null
          }
        >
          <Button
            icon={<PlusOutlined />}
            type={addButtonType}
            disabled={isDeploymentBlocked}
          >
            {addButtonLabel ? addButtonLabel : 'Add platform'}
          </Button>
        </Tooltip>
      </Dropdown>
      {slackModalVisible && (
        <SlackPlatformTypeModal
          open={slackModalVisible}
          onCancel={() => setSlackModalVisible(false)}
          onOk={onSlackModalClose}
        />
      )}
      {platformTypeToAdd && (
        <PlatformSettings
          platformType={platformTypeToAdd}
          isOpened={!!platformTypeToAdd}
          onClose={() => setPlatformTypeToAdd(undefined)}
        />
      )}
    </>
  )
}
