import { FC, memo, useContext } from 'react'
import { Row } from 'antd'
import { CloudTeamsFormValues } from '../../../../pages/clusters/add/steps/platformsConfiguration/utils'
import { FormikErrors, FormikHandlers, FormikHelpers, FormikTouched } from 'formik'
import { CloudTeamsForm } from './CloudTeamsForm'
import { ConnectedTeamsPlatformsQuery, useConnectedTeamsPlatformsQuery } from '../../../../models/graphql'
import { AppCtx } from '../../../../providers/appCtx'
import { Spinner } from '../../../../pages/login/styles'
import { CloudTeamContentInstructions } from './CloudTeamsInstructions'
import { ContentColumnLeft } from '../../../../pages/clusters/add/steps/platformsConfiguration/contents/columns/ContentColumnLeft'
import { ContentColumnRight } from '../../../../pages/clusters/add/steps/platformsConfiguration/contents/columns/ContentColumnRight'
import { PlatformEmbeddedDocs } from '../../../../pages/clusters/add/steps/platformsConfiguration/contents/PlatformEmbeddedDocs'
import { ContentType } from '../../../../services/contentful/types'

export type TeamsContentProps = {
  values: CloudTeamsFormValues
  errors?: FormikErrors<CloudTeamsFormValues>
  touched?: FormikTouched<CloudTeamsFormValues>
  handleChange: FormikHandlers['handleChange']
  setFieldValue: FormikHelpers<CloudTeamsFormValues>['setFieldValue']
  isSubmitted: boolean
  handleAutoSubmit?: () => void
  disabled?: boolean
  fieldNamePrefix?: string
}

export type ConnectedTeamsOrganizations = NonNullable<
  NonNullable<ConnectedTeamsPlatformsQuery['organization']>['connectedPlatforms']
>['teamsOrganizations']

export const CloudTeamsContentInner: FC<TeamsContentProps> = props => {
  const {
    fieldNamePrefix,
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    isSubmitted,
    handleAutoSubmit,
    disabled,
  } = props
  const appCtx = useContext(AppCtx)
  const { data, isLoading } = useConnectedTeamsPlatformsQuery(
    { id: appCtx.orgID },
    {
      refetchOnWindowFocus: 'always',
    },
  )

  const platformInstructionType = ContentType.TEAMS_CLOUD_PLATFORM_INSTRUCTION

  return (
    <section>
      <Row>
        <ContentColumnLeft>
          <CloudTeamContentInstructions />
          <Spinner spinning={isLoading}>
            <CloudTeamsForm
              connectedTeamsOrgs={data?.organization?.connectedPlatforms?.teamsOrganizations}
              fieldNamePrefix={fieldNamePrefix}
              values={values}
              errors={errors}
              touched={touched}
              disabled={disabled}
              handleAutoSubmit={handleAutoSubmit}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              isSubmitted={isSubmitted}
            />
          </Spinner>
        </ContentColumnLeft>
        <ContentColumnRight>
          <PlatformEmbeddedDocs platformInstructionType={platformInstructionType} />
        </ContentColumnRight>
      </Row>
    </section>
  )
}

export const CloudTeamsContent = memo(CloudTeamsContentInner)
