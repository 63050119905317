import { FC } from 'react'
import { WarningOutlined } from '@ant-design/icons'
import { WarnBoxSC } from './styles'

type Props = {
  message: string
}
export const WarnBox: FC<Props> = props => {
  const { message } = props
  return (
    <WarnBoxSC>
      <WarningOutlined /> {message}
    </WarnBoxSC>
  )
}
