import { Form, Input, Switch } from 'antd'
import { FC } from 'react'
import { PlatformsType } from '../../../models/platform/platform'
import { FormikErrors, FormikHandlers, FormikHelpers, FormikTouched } from 'formik'
import { getValidationError, getValidationStatus } from '../../../utils/formik/formik'
import { ElasticSearchFormValues } from '../../../pages/clusters/add/steps/platformsConfiguration/utils'

type Props = {
  values: ElasticSearchFormValues
  handleChange: FormikHandlers['handleChange']
  errors?: FormikErrors<ElasticSearchFormValues>
  touched?: FormikTouched<ElasticSearchFormValues>
  setFieldValue: FormikHelpers<ElasticSearchFormValues>['setFieldValue']
  idx: number
  isSubmitted: boolean
}

export const ElasticsearchForm: FC<Props> = props => {
  const { errors, touched, values, setFieldValue, idx, handleChange, isSubmitted } = props

  return (
    <>
      <Form.Item
        label={'Display Name'}
        required={true}
        validateStatus={getValidationStatus(errors?.name, touched?.name ?? isSubmitted)}
        help={getValidationError(errors?.name, touched?.name ?? isSubmitted)}
      >
        <Input
          name={`${PlatformsType.ELASTIC_SEARCH}.[${idx}].name`}
          onChange={handleChange}
          value={values.name}
          placeholder={'My Elasticsearch Name'}
        />
      </Form.Item>
      <Form.Item
        label={'Username'}
        required={true}
        validateStatus={getValidationStatus(errors?.username, touched?.username ?? isSubmitted)}
        help={getValidationError(errors?.username, touched?.username ?? isSubmitted)}
      >
        <Input
          name={`${PlatformsType.ELASTIC_SEARCH}.[${idx}].username`}
          onChange={handleChange}
          value={values.username}
          placeholder={'username123'}
        />
      </Form.Item>
      <Form.Item
        label={'Password'}
        required={true}
        validateStatus={getValidationStatus(errors?.password, touched?.password ?? isSubmitted)}
        help={getValidationError(errors?.password, touched?.password ?? isSubmitted)}
      >
        <Input.Password
          name={`${PlatformsType.ELASTIC_SEARCH}.[${idx}].password`}
          onChange={handleChange}
          value={values.password}
        />
      </Form.Item>
      <Form.Item
        label={'Server'}
        required={true}
        validateStatus={getValidationStatus(errors?.server, touched?.server ?? isSubmitted)}
        help={getValidationError(errors?.server, touched?.server ?? isSubmitted)}
      >
        <Input
          name={`${PlatformsType.ELASTIC_SEARCH}.[${idx}].server`}
          onChange={handleChange}
          value={values.server}
          placeholder={'https://elasticsearch-master.default:9200'}
        />
      </Form.Item>
      <Form.Item
        label={'Skip TLS Verify'}
        validateStatus={getValidationStatus(errors?.skipTlsVerify, touched?.skipTlsVerify ?? isSubmitted)}
        help={getValidationError(errors?.skipTlsVerify, touched?.skipTlsVerify ?? isSubmitted)}
      >
        <Switch
          onChange={value => setFieldValue(`${PlatformsType.ELASTIC_SEARCH}.[${idx}].skipTlsVerify`, value)}
          checked={values.skipTlsVerify}
        />
      </Form.Item>
      <Form.Item
        label={'Index name'}
        required={true}
        validateStatus={getValidationStatus(errors?.indexName, touched?.indexName ?? isSubmitted)}
        help={getValidationError(errors?.indexName, touched?.indexName ?? isSubmitted)}
      >
        <Input
          name={`${PlatformsType.ELASTIC_SEARCH}.[${idx}].indexName`}
          onChange={handleChange}
          value={values.indexName}
          placeholder={'botkube-index-name'}
        />
      </Form.Item>
    </>
  )
}
