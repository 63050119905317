import { FC, useState } from 'react'
import { MainLayout } from '../../../layouts/main/MainLayout'
import { Button, Table } from 'antd'
import { getData, setColumns } from './utils'
import { ClusterRecommendations } from '../../../components/deployment/recommendations/ClusterRecommendations'
import { NotificationSettings } from '../../../components/modal/notificationSettings/NotificationSettings'
import { usePageTitle } from '../../../utils/customeHooks/usePageTitle'

export const ClusterEventsPage: FC = () => {
  usePageTitle('Cluster Events')
  const [isNotificationSettingsOpen, setNotificationSettingsOpen] = useState(false)

  return (
    <MainLayout>
      <h1>Kubernetes Events</h1>
      <p>
        You can monitor or integrate your Kubernetes clusters with as many of the supported platforms as you find
        necessary.
      </p>
      <Button
        type={'primary'}
        className={'mb-20'}
        onClick={() => setNotificationSettingsOpen(true)}
      >
        Edit
      </Button>
      <Table
        rowSelection={{ type: 'checkbox' }}
        dataSource={getData()}
        columns={setColumns()}
      />
      <h2>Proactive recommendations</h2>
      <p>Send recommendations notifications when:</p>
      <ClusterRecommendations />
      <NotificationSettings
        open={isNotificationSettingsOpen}
        onCancel={() => setNotificationSettingsOpen(false)}
        onOk={() => setNotificationSettingsOpen(false)}
        width={'80%'}
      />
    </MainLayout>
  )
}
