import React, { FC, useEffect, useMemo } from 'react'
import { gte as semverGte, valid as semverValid } from 'semver'
import { Link } from 'react-router-dom'
import { Alert } from 'antd'

import { useAppDispatch, useAppSelector } from '../../../store/store'
import { paths } from '../../../router/paths'
import { Deployment } from '../../../store/slices/deployment/model'
import { setDeploymentUpdateBlockNeedUpgrade } from '../../../store/slices/deployment/deployment'

type DeploymentUpgradeAlert = {
  onUpgradeLinkClick: (isOpened: boolean) => void
}

export const DeploymentUpgradeAlert: FC<DeploymentUpgradeAlert> = ({ onUpgradeLinkClick }) => {
  const dispatch = useAppDispatch()
  const deployment = useAppSelector(state => state.deployment.data)
  const hideAlert = useMemo(() => {
    return (
      !deployment ||
      !deployment.status.upgrade?.needsUpgrade ||
      (semverValid(deployment.status.botkubeVersion) && semverGte(deployment.status.botkubeVersion ?? '', '1.9.0'))
    )
  }, [deployment])

  useEffect(() => {
    if (hideAlert) {
      dispatch(setDeploymentUpdateBlockNeedUpgrade({ isBlocked: false }))
      return
    }
    dispatch(setDeploymentUpdateBlockNeedUpgrade({ isBlocked: true, reason: 'Upgrade Botkube Agent' }))
  }, [hideAlert, dispatch])

  if (hideAlert || !deployment) {
    return null
  }

  return (
    <Alert
      type={'info'}
      banner={true}
      message={`🎉 Botkube ${deployment.status.upgrade?.targetBotkubeVersion ?? ''} available!`}
      description={
        <DeploymentUpgradeMessage
          deployment={deployment}
          onUpgradeLinkClick={() => onUpgradeLinkClick(true)}
        />
      }
    />
  )
}

type Props = {
  deployment: Deployment
  onUpgradeLinkClick: () => void
}
const DeploymentUpgradeMessage: FC<Props> = ({ deployment, onUpgradeLinkClick }) => {
  return (
    <>
      <p>
        🤖 Botkube Bot just got a lot smarter! Just ask anything after upgrading using the <code>ai</code> command, e.g.{' '}
        <code>@Botkube ai what pods are there in kube-system namespace?</code>
      </p>
      <Link
        key={deployment.id + '/upgrade'}
        to={paths.DEPLOYMENT_CONNECT.replace(':id', deployment.id)}
        onClick={onUpgradeLinkClick}
      >
        Upgrade your Botkube Agent now
      </Link>
    </>
  )
}
