import { FC, useMemo, useState } from 'react'
import { Alert, Col, Form, Row, Space, Tabs, Typography } from 'antd'
import * as yup from 'yup'
import { AnySchema } from 'yup'
import { colProps, gutter } from '../../../../../styles/globalStyles'
import { PrevNextButtons } from '../../../../../components/prevNextButtons/PrevNextButtons'
import { DeploymentAddStep, WizardFormValues } from '../../DeploymentAddPage'
import {
  GetPluginTemplatesQuery,
  Maybe,
  PluginTemplate,
  PluginType,
  Rbac,
  useGetPluginTemplatesQuery,
} from '../../../../../models/graphql'
import { SkeletonGroup } from '../../../../../components/skeleton/group/SkeletonGroup'
import { FormikConfig, useFormik } from 'formik'
import { isSinkPlatform, PlatformsType } from '../../../../../models/platform/platform'
import { PluginWizardCard } from '../../../../../components/plugin/wizardCard/PluginWizardCard'
import { getPlatformsChannels, mapPlatformTypeToIcon } from '../../../../../utils/platforms'
import {
  PluginConfiguration,
  PluginConfigurationProps,
} from '../../../../../components/plugin/configuration/PluginConfiguration'
import deepClone from 'deep-clone'
import {
  bindPluginWithPlatforms,
  getAvailableChannels,
  getDefaultsFromSchema,
  getPluginsInPlatform,
  isSchemaValid,
} from '../../../../../utils/plugins'
import { PluginConfigData, validatePluginSelection } from '../../../../../components/plugin/configuration/utils'
import { RJSFSchema } from '@rjsf/utils'
import { TabWrapperSC } from '../platformsConfiguration/styles'
import { RBACFormValues } from '../../../../../components/plugin/configuration/content/rbac/PluginRBAC'
import { useMSTeamsChannels } from '../../../../../utils/customeHooks/useMSTeamsChannels'
import { PlatformsFormsValues } from '../platformsConfiguration/utils'
import { WizardStepFooter } from '../footer/WizardStepFooter'
import { createEnabledDefaultPlugins } from './utils'
import { PluginGeneralFormValues } from '../../../../../components/plugin/configuration/content/overview/PluginGeneralForm'

export type PluginTemplateInPluginStep = GetPluginTemplatesQuery['pluginTemplates']['data'][number]

export type PlatformsInPluginStep = {
  platform: PlatformsType
  platformId: string
  name: string
  idx: number
  details: PlatformsFormsValues
}

export type PluginBindings = {
  platform: PlatformsType
  platformId: string
  platformIdx: number | string
  channels: string[]
}

export type PluginValue = {
  id?: string
  configurationName: string
  configuration: string
  enabled: boolean
  name: string
  type: PluginType
  bindings?: Record<string, PluginBindings> //key - platformId
  displayName: string
  invalid?: boolean
  rbac?: Maybe<Rbac>
}

export type PluginsFormValues = {
  plugins: PluginValue[]
}

type PluginItem = {
  template: PluginTemplate
  value?: PluginValue
}

type Props = {
  wizardValues: WizardFormValues
  setStep: (step: number) => void
  onSubmit: (values: PluginsFormValues) => void
  isLoading: boolean
}

const initForm = (props: Props, enabledDefaultPlugins: PluginValue[]): FormikConfig<PluginsFormValues> => {
  const {
    wizardValues: { pluginsStep },
  } = props

  return {
    enableReinitialize: true,
    initialValues: {
      plugins: pluginsStep?.plugins.length ? pluginsStep.plugins : enabledDefaultPlugins,
    },
    validationSchema: yup.object().shape<Record<keyof PluginsFormValues, AnySchema>>({
      plugins: yup.array().min(1, 'At least 1 plugin needs to be enabled'),
    }),
    onSubmit: values => {
      props.onSubmit(values)
    },
  }
}

export const PluginsStep: FC<Props> = props => {
  const { setStep, wizardValues, isLoading } = props
  const [selectedPlugin, setSelectedPlugin] = useState<PluginConfigurationProps | null>(null)
  const { data, isLoading: isPluginTemplateLoading } = useGetPluginTemplatesQuery()
  const { msTeamsChannels } = useMSTeamsChannels()

  const platformsChannels = useMemo(() => {
    if (wizardValues.platformsStep) {
      return getPlatformsChannels(wizardValues.platformsStep)
    }

    return {}
  }, [wizardValues.platformsStep])
  const platforms = useMemo(() => {
    const platformsBuff: PlatformsInPluginStep[] = []
    if (wizardValues.platformsStep) {
      Object.entries(wizardValues.platformsStep).forEach(([platform, platformsValues]) => {
        platformsValues.forEach((platformVal, idx) => {
          platformsBuff.push({
            platform: platform as PlatformsType,
            platformId: platformVal.id,
            name: platformVal.name,
            details: platformVal,
            idx,
          })
        })
      })
    }

    return platformsBuff
  }, [wizardValues.platformsStep])
  const enabledDefaultPlugins = useMemo(() => {
    const pluginsRecommended =
      data?.pluginTemplates.data.filter(pluginTemplateItem => pluginTemplateItem.recommended) ?? []

    return createEnabledDefaultPlugins(platforms, pluginsRecommended, platformsChannels)
  }, [data, platforms, platformsChannels])

  const formik = useFormik(initForm(props, enabledDefaultPlugins))

  const handleTogglePlugin = (pluginData: PluginValue, pluginTemplate: PluginTemplate, isChecked: boolean) => {
    const currentPluginIdx = formik.values.plugins.findIndex(
      plugin => plugin.configurationName === pluginData.configurationName,
    )
    const currentPlugin = currentPluginIdx > -1 ? formik.values.plugins[currentPluginIdx] : undefined
    const pluginValue = bindPluginWithPlatforms({ currentPlugin, pluginData, isChecked })

    if (currentPlugin) {
      formik.values.plugins.splice(currentPluginIdx, 1, pluginValue)
    } else {
      // plugin wasn't enabled yet: set default values and validate the schema
      const schema = pluginTemplate.schema as RJSFSchema
      const defaultVal = getDefaultsFromSchema(schema) ?? {}
      pluginValue.configuration = JSON.stringify(defaultVal)
      pluginValue.invalid = !isSchemaValid(schema, defaultVal)

      formik.values.plugins.push(pluginValue)
    }

    void formik.setValues(deepClone(formik.values))
  }

  const handleOnConfigClick = (
    pluginValue: PluginValue,
    pluginTemplate: PluginTemplate,
    platform: PlatformsInPluginStep,
  ) => {
    setSelectedPlugin({
      pluginValue: pluginValue,
      pluginTemplate: pluginTemplate,
      connections: [
        {
          platformId: platform.platformId,
          platformType: platform.platform,
          availableChannels: getAvailableChannels(
            platformsChannels[platform.platform]?.[platform.idx] ?? { channels: [] },
            platform.platform,
            msTeamsChannels,
          ),
        },
      ],
    })
  }

  const handleConfigPlugin = (
    configData: PluginConfigData,
    generalSettings: PluginGeneralFormValues,
    plugin: PluginValue,
    rbac?: RBACFormValues,
  ) => {
    const pluginForUpdateIndex = formik.values.plugins.findIndex(
      item => item.configurationName === plugin.configurationName,
    )

    setSelectedPlugin(null)
    if (pluginForUpdateIndex == -1) {
      return
    }

    const pluginForUpdate = formik.values.plugins[pluginForUpdateIndex]
    pluginForUpdate.configuration = JSON.stringify(configData)
    pluginForUpdate.displayName = generalSettings.displayName
    pluginForUpdate.enabled = generalSettings.enabled
    if (pluginForUpdate.bindings) {
      generalSettings.connections.forEach(connect => {
        if (pluginForUpdate.bindings?.[connect.platformId]?.channels) {
          pluginForUpdate.bindings[connect.platformId].channels = connect.channels
        }
      })
    }
    pluginForUpdate.rbac = rbac
    pluginForUpdate.invalid = false

    formik.values.plugins.splice(pluginForUpdateIndex, 1, pluginForUpdate)
    void formik.setValues(deepClone(formik.values))
  }

  const { valid: shouldEnableNextBtn, reason: nextBtnTooltipText } = validatePluginSelection(
    platforms,
    formik.values.plugins,
  )

  return (
    <>
      <Form>
        <Tabs
          className={'hide-active-state'}
          items={platforms.map(platform => {
            const pluginsInPlatform = getPluginsInPlatform(formik.values.plugins, platform)
            const plugins: PluginItem[] = (data?.pluginTemplates.data ?? [])
              .filter(pluginTemplate =>
                isSinkPlatform(platform.platform) ? pluginTemplate.type === PluginType.Source : true,
              )
              .map(pluginTemplate => {
                const pluginVal = pluginsInPlatform.find(plugin => plugin.name === pluginTemplate.name)
                return {
                  template: pluginTemplate,
                  value: pluginVal,
                }
              })
            const isTabError = plugins.some(plugin => plugin.value?.enabled && plugin.value.invalid)

            return {
              label: (
                <TabWrapperSC error={isTabError}>
                  {mapPlatformTypeToIcon(platform.platform, { height: '12px' })}
                  {platform.name}
                </TabWrapperSC>
              ),
              key: `${platform.platform}_${platform.idx}`,
              children: (
                <>
                  {plugins.some(pluginItem => pluginItem.template.recommended) && (
                    <Space
                      direction='vertical'
                      className={'mb-40'}
                      style={{ width: '100%' }}
                    >
                      <Typography.Title level={3}>Recommended Plugins</Typography.Title>
                      <Alert
                        type={'info'}
                        className={'mb-20'}
                        message={
                          isSinkPlatform(platform.platform)
                            ? 'If this is your first time using Botkube, we recommend to start with this plugin. Kubernetes will get you important events from your cluster. You can change their configuration later.'
                            : 'If this is your first time using Botkube, we recommend to start with these plugins. Kubernetes will get you important events from your cluster, and kubectl will enable you to run commands from your chat platform. You can change their configuration later.'
                        }
                      />
                      <Row gutter={gutter}>
                        <SkeletonGroup
                          loading={isPluginTemplateLoading}
                          size={4}
                          horizontal
                          horizontalSize={6}
                        />
                        {!isPluginTemplateLoading &&
                          plugins
                            .filter(plugin => plugin.template.recommended)
                            .map(plugin => {
                              return (
                                <Col
                                  key={plugin.template.name}
                                  {...colProps}
                                >
                                  <Form.Item
                                    name={'plugins'}
                                    className={'align-height'}
                                  >
                                    <PluginWizardCard
                                      msTeamsChannels={msTeamsChannels}
                                      plugin={plugin.value}
                                      pluginTemplate={plugin.template}
                                      platform={platform.platform}
                                      platformIdx={platform.idx}
                                      platformId={platform.platformId}
                                      platformsChannels={platformsChannels}
                                      onCheckboxChange={(pluginData: PluginValue, isChecked: boolean) => {
                                        handleTogglePlugin(pluginData, plugin.template, isChecked)
                                      }}
                                      onConfig={(pluginValue, pluginTemplate) =>
                                        handleOnConfigClick(pluginValue, pluginTemplate, platform)
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                              )
                            })}
                      </Row>
                    </Space>
                  )}
                  <Space
                    direction={'vertical'}
                    style={{ width: '100%' }}
                  >
                    <Typography.Title level={3}>Plugins</Typography.Title>
                    <Row gutter={gutter}>
                      <SkeletonGroup
                        loading={isPluginTemplateLoading}
                        size={4}
                        horizontal
                        horizontalSize={6}
                      />
                      {!isPluginTemplateLoading &&
                        plugins
                          .filter(plugin => !plugin.template.recommended)
                          .map(plugin => {
                            return (
                              <Col
                                key={plugin.template.name}
                                {...colProps}
                              >
                                <Form.Item
                                  name={'plugins'}
                                  className={'align-height'}
                                >
                                  <PluginWizardCard
                                    msTeamsChannels={msTeamsChannels}
                                    plugin={plugin.value}
                                    pluginTemplate={plugin.template}
                                    platform={platform.platform}
                                    platformIdx={platform.idx}
                                    platformId={platform.platformId}
                                    platformsChannels={platformsChannels}
                                    onCheckboxChange={(pluginData: PluginValue, isChecked: boolean) => {
                                      handleTogglePlugin(pluginData, plugin.template, isChecked)
                                    }}
                                    onConfig={(pluginValue, pluginTemplate) =>
                                      handleOnConfigClick(pluginValue, pluginTemplate, platform)
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            )
                          })}
                    </Row>
                  </Space>
                </>
              ),
            }
          })}
        />
        {selectedPlugin && (
          <PluginConfiguration
            pluginConfig={selectedPlugin}
            onCancel={() => setSelectedPlugin(null)}
            onSubmit={handleConfigPlugin}
            blockPlatformSettings={true}
          />
        )}
      </Form>
      <WizardStepFooter helpMessage={'Add at least one plugin per each platform to proceed'}>
        <PrevNextButtons
          isLoading={isLoading}
          handlePrevStep={() => setStep(DeploymentAddStep.PLATFORMS_CONFIG)}
          handleNextStep={() => void formik.submitForm()}
          nextButtonTooltipText={nextBtnTooltipText}
          disableNextButton={!shouldEnableNextBtn}
        />
      </WizardStepFooter>
    </>
  )
}
