import { PlatformInstructionSkeleton, PlatformInstructionTypes } from '../../../../../../services/contentful/types'
import { FC } from 'react'
import { useContent } from '../../../../../../utils/customeHooks/useContent'
import { DocsEmbed } from './Docs'

export type PlatformEmbeddedDocsProps = {
  platformInstructionType: PlatformInstructionTypes
}

export const PlatformEmbeddedDocs: FC<PlatformEmbeddedDocsProps> = ({ platformInstructionType }) => {
  const content = useContent<PlatformInstructionSkeleton[]>(platformInstructionType, true) ?? []
  const instructions = content.sort((itemA, itemB) => itemA.fields.order - itemB.fields.order)
  const embedURL = instructions.length > 0 ? instructions[0].fields.documentationUrl : undefined

  if (!embedURL) {
    return null
  }

  return (
    <DocsEmbed
      url={embedURL}
      removeEmbedParamOnOpenNewTab={true}
    />
  )
}
