import deepClone from 'deep-clone'
import { v4 } from 'uuid'

import { DeploymentUpdateInput, PolicySubjectType } from '../models/graphql'
import { Deployment, DeploymentUpdateBlock, DeploymentUpdateReport } from '../store/slices/deployment/model'

export const createDeploymentUpdateInput = (deployment: Deployment): DeploymentUpdateInput => {
  const clonedDeployment = deepClone<Deployment>(deployment)

  // TODO: fix https://github.com/dotansimha/graphql-code-generator/issues/6023

  return {
    name: clonedDeployment.name,
    platforms: clonedDeployment.platforms,
    plugins: [
      {
        groups: clonedDeployment.plugins.map(plugin => ({
          id: plugin.id,
          name: plugin.name,
          type: plugin.type,
          displayName: plugin.displayName,
          enabled: plugin.enabled,
          configurations: [
            {
              name: plugin.configurationName,
              configuration: plugin.configuration,
              rbac: {
                id: plugin.rbac?.id ?? v4(),
                group: {
                  prefix: plugin.rbac?.group?.prefix !== '' ? plugin.rbac?.group?.prefix : null,
                  type: plugin.rbac?.group?.type ?? PolicySubjectType.Empty,
                  static: plugin.rbac?.group?.type === PolicySubjectType.Static ? plugin.rbac.group.static : null,
                },
                user: {
                  prefix: plugin.rbac?.user?.prefix !== '' ? plugin.rbac?.user?.prefix : null,
                  type: plugin.rbac?.user?.type ?? PolicySubjectType.Empty,
                  static: plugin.rbac?.user?.type === PolicySubjectType.Static ? plugin.rbac.user.static : null,
                },
              },
            },
          ],
        })),
      },
    ],
    actions: deployment.actions,
    resourceVersion: clonedDeployment.resourceVersion,
    draft: clonedDeployment.draft,
  }
}

export const provideReasonsForBlockedUpdate = (
  deploymentUpdateBlock: DeploymentUpdateBlock,
  deploymentUpdateValidReport?: DeploymentUpdateReport | null,
) => {
  const validationReportErrors = Object.entries(deploymentUpdateValidReport?.platforms ?? {})
    .map(([platformName, report]) => {
      return report.isValid ? null : `${platformName}: ${report.reason ?? ''}`
    })
    .filter(error => !!error)
    .join(', ')
  const deploymentUpdateBlockReasons = deploymentUpdateBlock.isBlocked
    ? [
        deploymentUpdateBlock.outdated.reason,
        deploymentUpdateBlock.needUpgrade.reason,
        deploymentUpdateBlock.org.reason,
      ]
        .filter(reason => reason)
        .join(', ')
    : ''

  return deploymentUpdateBlockReasons || validationReportErrors
    ? `${deploymentUpdateBlockReasons}${validationReportErrors ? ' ' + validationReportErrors : ''}`
    : ''
}

export const hasDeploymentCloudSlack = (deployment?: Deployment | null) => {
  const cloudSlackPlatforms = deployment?.platforms.cloudSlacks
  return Array.isArray(cloudSlackPlatforms) && cloudSlackPlatforms.length > 0
}
