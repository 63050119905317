import styled from 'styled-components'
import { Modal } from 'antd'

export const ModalSC = styled(Modal)`
  .ant-modal-title {
    display: flex;
    gap: 8px;
    align-items: center;

    .anticon {
      color: #faad14;
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
`
