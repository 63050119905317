import React, { FC } from 'react'
import { MenuBase } from './MenuBase'
import { OrgMenuItem } from './OrgMenuItem'
import { LogoutLink } from './LogoutLink'

export const MenuUserLogout: FC = () => {
  return (
    <MenuBase
      items={[
        {
          label: <OrgMenuItem />,
          key: 'account-submenu',
          children: [
            {
              label: <LogoutLink />,
              key: 'logout',
            },
          ],
        },
      ]}
    />
  )
}
