import { CSSProperties, ReactNode } from 'react'
import { v4 } from 'uuid'

import { ReactComponent as SlackIcon } from '../assets/icons/slack.svg'
import { ReactComponent as DiscordIcon } from '../assets/icons/discord.svg'
import { ReactComponent as MattermostIcon } from '../assets/icons/mattermost.svg'
import { ReactComponent as TeamsIcon } from '../assets/icons/teams.svg'
import { ReactComponent as ElasticsearchIcon } from '../assets/icons/elasticsearch.svg'
import { ReactComponent as WebhookIcon } from '../assets/icons/webhook.svg'
import { ReactComponent as PagerDutyIcon } from '../assets/icons/pagerduty.svg'
import { PlatformsType, PlatformsValues } from '../models/platform/platform'
import {
  BotPlatform,
  ChannelBindingsById,
  ChannelBindingsByName,
  CloudMsTeamsUpdateInput,
  CloudSlackUpdateInput,
  DiscordUpdateInput,
  ElasticsearchUpdateInput,
  MattermostUpdateInput,
  PagerDutyUpdateInput,
  PlatformsCreateInput,
  PlatformsUpdateInput,
  PluginType,
  SocketSlackUpdateInput,
  WebhookUpdateInput,
} from '../models/graphql'
import {
  instanceOfChannelBindingsById,
  instanceOfChannelBindingsByName,
  instanceOfCloudMSTeams,
  instanceOfCloudSlack,
  instanceOfPlatformWithChannels,
} from './ts-guards'
import { PluginsFormValues, PluginValue } from '../pages/clusters/add/steps/plugins/PluginsStep'
import {
  ChannelNameWithId,
  CloudSlackFormValues,
  CloudTeamsFormValues,
  DiscordFormValues,
  ElasticSearchFormValues,
  MattermostFormValues,
  PagerDutyFormValues,
  PlatformsFormValues,
  SocketSlackFormValues,
  WebhookFormValues,
} from '../pages/clusters/add/steps/platformsConfiguration/utils'
import { getPluginsConfigurationNamesByPluginType, getPluginsInPlatform } from './plugins'
import { DeploymentPage } from '../pages/clusters/utils'
import { ConnectedTeamsOrganizations } from '../forms/instance/platforms/cloudTeams/CloudTeamsContent'
import { TeamInConnectedTeams } from '../forms/instance/platforms/cloudTeams/utils'
import { PluginConfigurationAvailableChannel } from '../components/plugin/configuration/PluginConfiguration'

export type MSTeamsCloudChannel = {
  id: string
  name: string
  description: string
  aadGroupId: string
}

export const mapPlatformTypeToIcon = (type: PlatformsType, style?: CSSProperties): ReactNode => {
  switch (type) {
    case PlatformsType.CLOUD_SLACK:
    case PlatformsType.SOCKET_SLACK:
      return (
        <SlackIcon
          key={type}
          style={style}
        />
      )
    case PlatformsType.DISCORD:
      return (
        <DiscordIcon
          key={type}
          style={style}
        />
      )
    case PlatformsType.MATTERMOST:
      return (
        <MattermostIcon
          key={type}
          style={style}
        />
      )
    case PlatformsType.CLOUD_TEAMS:
      return (
        <TeamsIcon
          key={type}
          style={style}
        />
      )
    case PlatformsType.ELASTIC_SEARCH:
      return (
        <ElasticsearchIcon
          key={type}
          style={style}
        />
      )
    case PlatformsType.WEBHOOK:
      return (
        <WebhookIcon
          key={type}
          style={style}
        />
      )
    case PlatformsType.PAGER_DUTY:
      return (
        <PagerDutyIcon
          key={type}
          style={style}
        />
      )
    default:
      return null
  }
}

export const getPlatformsFormValuesFromDeployment = (deployment?: DeploymentPage): PlatformsFormValues | undefined => {
  if (!deployment) {
    return
  }

  return {
    [PlatformsType.CLOUD_SLACK]:
      deployment.platforms.cloudSlacks?.map(value => ({
        id: value.id,
        name: value.name,
        channelNamesWithId: value.channels.map(channel => ({
          id: channel.channelId,
          name: channel.name,
        })),
        teamId: value.teamId,
      })) ?? [],
    [PlatformsType.SOCKET_SLACK]:
      deployment.platforms.socketSlacks?.map(value => ({
        id: value.id,
        name: value.name,
        channelNames: value.channels.map(channel => channel.name),
        appToken: value.appToken,
        botToken: value.botToken,
      })) ?? [],
    [PlatformsType.DISCORD]:
      deployment.platforms.discords?.map(value => ({
        id: value.id,
        name: value.name,
        channelNames: value.channels.map(channel => channel.id),
        botId: value.botId,
        token: value.token,
      })) ?? [],
    [PlatformsType.CLOUD_TEAMS]:
      deployment.platforms.cloudMsTeams?.map(value => ({
        id: value.id,
        name: value.name,
        aadGroupId: value.aadGroupId,
        attachmentStorage: {
          useDefaultLocation: value.attachmentStorage.sharePointSiteName === '',
          sharePointSiteName: value.attachmentStorage.sharePointSiteName,
        },
        channelNames: value.channels.map(channel => channel.id),
      })) ?? [],
    [PlatformsType.MATTERMOST]:
      deployment.platforms.mattermosts?.map(value => ({
        id: value.id,
        name: value.name,
        botName: value.botName,
        token: value.token,
        channelNames: value.channels.map(channel => channel.name),
        team: value.team,
        url: value.url,
      })) ?? [],
    [PlatformsType.WEBHOOK]:
      deployment.platforms.webhooks?.map(value => ({
        id: value.id,
        name: value.name,
        url: value.url,
      })) ?? [],
    [PlatformsType.PAGER_DUTY]:
      deployment.platforms.pagerDuty?.map(value => ({
        id: value.id,
        name: value.name,
        integrationKey: value.integrationKey,
      })) ?? [],
    [PlatformsType.ELASTIC_SEARCH]:
      deployment.platforms.elasticsearches?.map(value => ({
        id: value.id,
        name: value.name,
        username: value.username,
        password: value.password,
        server: value.server,
        skipTlsVerify: value.skipTlsVerify,
        indexName: value.indices[0]?.name ?? '',
      })) ?? [],
  }
}

// TODO make channels as {id: string, name: string}[], always for all platforms
export type PlatformChannelsConfig = {
  channels: string[]
  meta?: {
    slackChannels: ChannelNameWithId[]
  }
}
export type PlatformsChannels = Partial<Record<PlatformsType, PlatformChannelsConfig[]>>
export const getPlatformsChannels = (platforms: PlatformsFormValues): PlatformsChannels => {
  const channels: PlatformsChannels = {}

  Object.entries(platforms)
    .filter(([, platformValues]) => !!platformValues.length)
    .forEach(([platformIndexName, platformValues]) => {
      platformValues.forEach(platformValue => {
        if (!Array.isArray(channels[platformIndexName as PlatformsType])) {
          channels[platformIndexName as PlatformsType] = []
        }
        if ('channelNamesWithId' in platformValue) {
          const meta = { slackChannels: platformValue.channelNamesWithId }
          const channelsNames = platformValue.channelNamesWithId.map(chWithId => chWithId.name)
          channels[platformIndexName as PlatformsType]?.push({
            channels: channelsNames,
            meta,
          })
          return
        }
        if ('channelNames' in platformValue) {
          channels[platformIndexName as PlatformsType]?.push({
            channels: platformValue.channelNames,
          })
          return
        }
        channels[platformIndexName as PlatformsType]?.push({ channels: [] })
      })
    })

  return channels
}

export const getChannelsFromPlatformValues = (
  platformValue: unknown,
  msTeamsChannels?: MSTeamsCloudChannel[],
): PluginConfigurationAvailableChannel[] => {
  const channels: PluginConfigurationAvailableChannel[] = []
  if (instanceOfCloudSlack(platformValue)) {
    platformValue.channels.forEach(channel => {
      channels.push({ label: channel.name, value: channel.channelId })
    })
  } else if (instanceOfCloudMSTeams(platformValue)) {
    platformValue.channels.forEach(channel => {
      const msTeamsChannel = msTeamsChannels?.find(msCh => msCh.id === channel.id)
      if (!msTeamsChannel && msTeamsChannels?.length) {
        console.error(`while getting available channels, cannot find channel with id ${channel.id}`)
      }
      channels.push({ label: msTeamsChannel?.name ?? '', value: channel.id })
    })
  } else if (instanceOfPlatformWithChannels(platformValue)) {
    platformValue.channels.forEach(channel => {
      if (instanceOfChannelBindingsByName(channel)) {
        channels.push({ label: channel.name, value: channel.name })
      }
      if (instanceOfChannelBindingsById(channel)) {
        channels.push({ label: channel.id, value: channel.id })
      }
    })
  }

  return channels
}

export const setCloudSlackUpdateInput = (
  values: CloudSlackFormValues,
  plugins: PluginValue[],
): CloudSlackUpdateInput => {
  return {
    name: values.name,
    teamId: values.teamId,
    channels: values.channelNamesWithId.map(channel => {
      return {
        channelId: channel.id,
        name: channel.name,
        bindings: {
          sources: getPluginsConfigurationNamesByPluginType(plugins, PluginType.Source, channel.id),
          executors: getPluginsConfigurationNamesByPluginType(plugins, PluginType.Executor, channel.id),
        },
      }
    }),
  }
}

export const setSocketSlackUpdateInput = (
  values: SocketSlackFormValues,
  plugins: PluginValue[],
): SocketSlackUpdateInput => {
  return {
    name: values.name,
    appToken: values.appToken,
    botToken: values.botToken,
    channels: values.channelNames.map(channel => {
      return {
        name: channel,
        bindings: {
          sources: getPluginsConfigurationNamesByPluginType(plugins, PluginType.Source, channel),
          executors: getPluginsConfigurationNamesByPluginType(plugins, PluginType.Executor, channel),
        },
      }
    }),
  }
}

export const setDiscordUpdateInput = (values: DiscordFormValues, plugins: PluginValue[]): DiscordUpdateInput => {
  return {
    name: values.name,
    botId: values.botId,
    token: values.token,
    channels: values.channelNames.map(channel => {
      return {
        id: channel,
        bindings: {
          sources: getPluginsConfigurationNamesByPluginType(plugins, PluginType.Source, channel),
          executors: getPluginsConfigurationNamesByPluginType(plugins, PluginType.Executor, channel),
        },
      }
    }),
  }
}

export const setMattermostUpdateInput = (
  values: MattermostFormValues,
  plugins: PluginValue[],
): MattermostUpdateInput => {
  return {
    name: values.name,
    botName: values.botName,
    team: values.team,
    url: values.url,
    token: values.token,
    channels: values.channelNames.map(channel => {
      return {
        name: channel,
        bindings: {
          sources: getPluginsConfigurationNamesByPluginType(plugins, PluginType.Source, channel),
          executors: getPluginsConfigurationNamesByPluginType(plugins, PluginType.Executor, channel),
        },
      }
    }),
  }
}

export const setCloudMsTeamsUpdateInput = (
  values: CloudTeamsFormValues,
  plugins: PluginValue[],
): CloudMsTeamsUpdateInput => {
  return {
    name: values.name,
    aadGroupId: values.aadGroupId,
    attachmentStorage: {
      sharePointSiteName: values.attachmentStorage.sharePointSiteName,
    },
    channels: values.channelNames.map(channel => {
      return {
        id: channel,
        bindings: {
          sources: getPluginsConfigurationNamesByPluginType(plugins, PluginType.Source, channel),
          executors: getPluginsConfigurationNamesByPluginType(plugins, PluginType.Executor, channel),
        },
      }
    }),
  }
}

export const setWebhookUpdateInput = (values: WebhookFormValues, plugins: PluginValue[]): WebhookUpdateInput => {
  return {
    name: values.name,
    url: values.url,
    bindings: {
      sources: getPluginsConfigurationNamesByPluginType(plugins, PluginType.Source),
    },
  }
}

export const setPagerDutyUpdateInput = (values: PagerDutyFormValues, plugins: PluginValue[]): PagerDutyUpdateInput => {
  return {
    name: values.name,
    integrationKey: values.integrationKey,
    bindings: {
      sources: getPluginsConfigurationNamesByPluginType(plugins, PluginType.Source),
    },
  }
}

export const setElasticsearchUpdateInput = (
  values: ElasticSearchFormValues,
  plugins: PluginValue[],
): ElasticsearchUpdateInput => {
  return {
    name: values.name,
    username: values.username,
    password: values.password,
    server: values.server,
    skipTlsVerify: values.skipTlsVerify,
    indices: [
      {
        name: values.indexName,
        type: 'botkube-event',
        replicas: 0,
        shards: 1,
        bindings: {
          sources: getPluginsConfigurationNamesByPluginType(plugins, PluginType.Source),
        },
      },
    ],
  }
}

export const setPlatformsUpdateInput = (
  plugins: PluginsFormValues,
  platforms: PlatformsFormValues,
): PlatformsUpdateInput => {
  const platformsUpdateInput: PlatformsUpdateInput = {}

  Object.keys(platforms).forEach(platformName => {
    platformsUpdateInput[platformName as keyof PlatformsUpdateInput] = []

    platforms[platformName as PlatformsType].forEach((platformVal, platformIdx) => {
      const pluginsInPlatform = getPluginsInPlatform(plugins.plugins, {
        platform: platformName as PlatformsType,
        platformId: platformVal.id,
        idx: platformIdx,
        details: platformVal,
      }).filter(plugin => plugin.enabled)

      let platformUpdateInputValues
      switch (platformName as PlatformsType) {
        case PlatformsType.CLOUD_SLACK:
          platformUpdateInputValues = setCloudSlackUpdateInput(platformVal as CloudSlackFormValues, pluginsInPlatform)
          break
        case PlatformsType.SOCKET_SLACK:
          platformUpdateInputValues = setSocketSlackUpdateInput(platformVal as SocketSlackFormValues, pluginsInPlatform)
          break
        case PlatformsType.DISCORD:
          platformUpdateInputValues = setDiscordUpdateInput(platformVal as DiscordFormValues, pluginsInPlatform)
          break
        case PlatformsType.MATTERMOST:
          platformUpdateInputValues = setMattermostUpdateInput(platformVal as MattermostFormValues, pluginsInPlatform)
          break
        case PlatformsType.CLOUD_TEAMS:
          platformUpdateInputValues = setCloudMsTeamsUpdateInput(platformVal as CloudTeamsFormValues, pluginsInPlatform)
          break
        case PlatformsType.WEBHOOK:
          platformUpdateInputValues = setWebhookUpdateInput(platformVal as WebhookFormValues, pluginsInPlatform)
          break
        case PlatformsType.PAGER_DUTY:
          platformUpdateInputValues = setPagerDutyUpdateInput(platformVal as PagerDutyFormValues, pluginsInPlatform)
          break
        case PlatformsType.ELASTIC_SEARCH:
          platformUpdateInputValues = setElasticsearchUpdateInput(
            platformVal as ElasticSearchFormValues,
            pluginsInPlatform,
          )
          break
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      platformsUpdateInput[platformName as keyof PlatformsCreateInput].push(platformUpdateInputValues)
    })
  })

  return platformsUpdateInput
}

export const mapBotPlatformToIcon = (type: BotPlatform, style?: CSSProperties): ReactNode => {
  switch (type) {
    case BotPlatform.Slack:
      return <SlackIcon style={style} />
    case BotPlatform.Discord:
      return <DiscordIcon style={style} />
    case BotPlatform.Mattermost:
      return <MattermostIcon style={style} />
    case BotPlatform.MsTeams:
      return <TeamsIcon style={style} />
    default:
      return null
  }
}

export const mapBotPlatformToName = (type: BotPlatform) => {
  switch (type) {
    case BotPlatform.Slack:
      return 'Slack'
    case BotPlatform.Discord:
      return 'Discord'
    case BotPlatform.Mattermost:
      return 'Mattermost'
    case BotPlatform.MsTeams:
      return 'Teams'
  }

  return null
}

export const isPlatformWithChannels = (platformType: PlatformsType) => {
  return (
    platformType === PlatformsType.SOCKET_SLACK ||
    platformType === PlatformsType.CLOUD_SLACK ||
    platformType === PlatformsType.CLOUD_TEAMS ||
    platformType === PlatformsType.DISCORD ||
    platformType === PlatformsType.MATTERMOST
  )
}

export const getTeamsFromOrganization = (connectedTeams: ConnectedTeamsOrganizations): TeamInConnectedTeams[] => {
  if (!connectedTeams) {
    return []
  }
  return connectedTeams.reduce<TeamInConnectedTeams[]>((teams, teamOrg) => {
    if (teamOrg.teams) {
      teams.push(...teamOrg.teams)
    }

    return teams
  }, [])
}

export const getPlatformFromDeployment = (deployment: DeploymentPage, platformId: string) => {
  let platform
  for (const platformsValues of Object.values(deployment.platforms)) {
    if (!Array.isArray(platformsValues)) {
      continue
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/ban-ts-comment
    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
    platform = platformsValues.find(platformValues => platformValues.id === platformId)
    if (platform) {
      break
    }
  }

  return platform as PlatformsValues | undefined
}

export const addNewChannelDefaultValues = (
  platformType: PlatformsType,
  channel: string,
): ChannelBindingsByName | ChannelBindingsById => {
  switch (platformType) {
    case PlatformsType.CLOUD_SLACK:
    case PlatformsType.SOCKET_SLACK:
    case PlatformsType.MATTERMOST:
      return {
        name: channel,
        bindings: {
          executors: [],
          sources: [],
        },
      }
    case PlatformsType.DISCORD:
    case PlatformsType.CLOUD_TEAMS:
      return {
        id: channel,
        bindings: {
          executors: [],
          sources: [],
        },
      }
    default:
      throw new Error(`while adding a new channel, cannot handle platform: ${platformType}`)
  }
}

const cloudSlackDefaultValues: Omit<
  Exclude<DeploymentPage['platforms']['cloudSlacks'], null | undefined>[number],
  'botToken'
> = {
  id: '',
  name: '',
  teamId: '',
  channels: [],
}
const socketSlackDefaultValues: Exclude<DeploymentPage['platforms']['socketSlacks'], null | undefined>[number] = {
  id: '',
  name: '',
  botToken: '',
  appToken: '',
  channels: [],
}
const discordDefaultValues: Exclude<DeploymentPage['platforms']['discords'], null | undefined>[number] = {
  id: '',
  name: '',
  token: '',
  botId: '',
  channels: [],
}
const mattermostDefaultValues: Exclude<DeploymentPage['platforms']['mattermosts'], null | undefined>[number] = {
  id: '',
  name: '',
  token: '',
  url: '',
  botName: '',
  team: '',
  channels: [],
}
const cloudTeamsDefaultValues: Exclude<DeploymentPage['platforms']['cloudMsTeams'], null | undefined>[number] = {
  id: '',
  name: '',
  aadGroupId: '',
  attachmentStorage: {
    sharePointSiteName: '',
  },
  channels: [],
}
const webhookDefaultValues: Exclude<DeploymentPage['platforms']['webhooks'], null | undefined>[number] = {
  id: '',
  name: '',
  url: '',
  bindings: {
    sources: [],
  },
}
const pagerDutyDefaultValues: Exclude<DeploymentPage['platforms']['pagerDuty'], null | undefined>[number] = {
  id: '',
  name: '',
  integrationKey: '',
  bindings: {
    sources: [],
  },
}
const elasticsearchDefaultValues: Exclude<DeploymentPage['platforms']['elasticsearches'], null | undefined>[number] = {
  id: '',
  name: '',
  server: '',
  username: '',
  password: '',
  awsSigningRegion: '',
  awsSigningRoleArn: '',
  skipTlsVerify: false,
  indices: [
    {
      name: '',
      type: 'botkube-event',
      replicas: 0,
      shards: 1,
      bindings: {
        sources: null,
      },
    },
  ],
}

type DeploymentPlatformDefaultValues = {
  [PlatformsType.CLOUD_SLACK]: Omit<
    Exclude<DeploymentPage['platforms']['cloudSlacks'], null | undefined>[number],
    'botToken'
  >
  [PlatformsType.SOCKET_SLACK]: Exclude<DeploymentPage['platforms']['socketSlacks'], null | undefined>[number]
  [PlatformsType.DISCORD]: Exclude<DeploymentPage['platforms']['discords'], null | undefined>[number]
  [PlatformsType.MATTERMOST]: Exclude<DeploymentPage['platforms']['mattermosts'], null | undefined>[number]
  [PlatformsType.CLOUD_TEAMS]: Exclude<DeploymentPage['platforms']['cloudMsTeams'], null | undefined>[number]
  [PlatformsType.WEBHOOK]: Exclude<DeploymentPage['platforms']['webhooks'], null | undefined>[number]
  [PlatformsType.ELASTIC_SEARCH]: Exclude<DeploymentPage['platforms']['elasticsearches'], null | undefined>[number]
  [PlatformsType.PAGER_DUTY]: Exclude<DeploymentPage['platforms']['pagerDuty'], null | undefined>[number]
}

const deploymentPlatformDefaultValues: DeploymentPlatformDefaultValues = {
  [PlatformsType.CLOUD_SLACK]: cloudSlackDefaultValues,
  [PlatformsType.SOCKET_SLACK]: socketSlackDefaultValues,
  [PlatformsType.DISCORD]: discordDefaultValues,
  [PlatformsType.MATTERMOST]: mattermostDefaultValues,
  [PlatformsType.CLOUD_TEAMS]: cloudTeamsDefaultValues,
  [PlatformsType.WEBHOOK]: webhookDefaultValues,
  [PlatformsType.ELASTIC_SEARCH]: elasticsearchDefaultValues,
  [PlatformsType.PAGER_DUTY]: pagerDutyDefaultValues,
}

export const getDefaultValues = (platform: PlatformsType) => {
  const defaultValues = deploymentPlatformDefaultValues[platform]
  defaultValues.id = v4()
  defaultValues.name = platform.toString()
  return defaultValues
}
