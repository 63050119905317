import { OrganizationPageQuery } from '../../models/graphql'
import { Button, Popconfirm } from 'antd'
import { Tag, TagType } from '../../components/tag/Tag'
import { PlatformsType, PlatformsTypeNames } from '../../models/platform/platform'
import { getTeamsFromOrganization, mapPlatformTypeToIcon } from '../../utils/platforms'
import { ConnectedTeamsOrganizations } from '../../forms/instance/platforms/cloudTeams/CloudTeamsContent'

type GetUserTableColumnsArgs = {
  ownerId: string
  appUserId?: string
  onRemove: (memberId: string) => void
}
export type Organization = OrganizationPageQuery['organization']
export type OrganizationMember = NonNullable<OrganizationPageQuery['organization']>['members'][number]

export const getMembersTableColumns = ({ onRemove, ownerId, appUserId }: GetUserTableColumnsArgs) => {
  return [
    {
      title: 'Email address',
      dataIndex: 'email',
      sorter: (a: OrganizationMember, b: OrganizationMember) => a.email.localeCompare(b.email),
      render: (email: string, member: OrganizationMember) => (
        <>
          {email} {member.id === appUserId && <Tag type={TagType.SECONDARY}>You</Tag>}{' '}
          {member.id === ownerId && <Tag type={TagType.PRIMARY}>Owner</Tag>}
        </>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      render: (id: string) => (
        <Popconfirm
          title={'Do you really want to remove the member?'}
          placement={'topRight'}
          onConfirm={() => onRemove(id)}
          okText={'Remove'}
          okButtonProps={{ danger: true }}
          cancelText={'Cancel'}
          disabled={id === ownerId}
        >
          <Button
            type={'link'}
            danger={true}
            disabled={id === ownerId}
          >
            Remove
          </Button>
        </Popconfirm>
      ),
    },
  ]
}

export const getMembersTableData = (members: NonNullable<OrganizationPageQuery['organization']>['members']) => {
  return members
}

const EMPTY_VALUE_PLACEHOLDER = '—'

export const getPlatformsTableColumns = (
  handleDisconnect: (platformType: PlatformsType, slackId: string) => Promise<void>,
) => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a: ConnectedPlatformEntry, b: ConnectedPlatformEntry) => a.name.localeCompare(b.name),
    },
    {
      title: 'URL',
      dataIndex: 'url',
      sorter: (a: ConnectedPlatformEntry, b: ConnectedPlatformEntry) => a.url.localeCompare(b.url),
      render: (url: string) => {
        if (url) {
          return url
        }

        return EMPTY_VALUE_PLACEHOLDER
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (type: PlatformsType) => {
        return (
          <>
            {mapPlatformTypeToIcon(type, { height: '12px' })} {PlatformsTypeNames[type]}
          </>
        )
      },
      sorter: (a: ConnectedPlatformEntry, b: ConnectedPlatformEntry) =>
        PlatformsTypeNames[a.type].localeCompare(PlatformsTypeNames[b.type]),
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      render: (id: string, platform: ConnectedPlatformEntry) => {
        return (
          <Popconfirm
            title={'Do you really want to disconnect the platform?'}
            placement={'topRight'}
            onConfirm={() => void handleDisconnect(platform.type, id)}
            okText={'Disconnect'}
            okButtonProps={{ danger: true }}
            cancelText={'Cancel'}
          >
            <Button
              type={'link'}
              danger={true}
            >
              Disconnect
            </Button>
          </Popconfirm>
        )
      },
    },
  ]
}

export type ConnectedPlatformEntry = {
  id: string
  name: string
  url: string
  type: PlatformsType
}

export const getPlatformsTableData = (
  data: NonNullable<OrganizationPageQuery['organization']>['connectedPlatforms'],
): ConnectedPlatformEntry[] => {
  const connectedPlatforms = []

  if (data?.slacks) {
    for (const slack of data.slacks) {
      connectedPlatforms.push({
        id: slack.id,
        name: slack.name,
        url: slack.url,
        type: PlatformsType.CLOUD_SLACK,
      })
    }
  }
  if (data?.teamsOrganizations) {
    const teams = getTeamsFromOrganization(data.teamsOrganizations as ConnectedTeamsOrganizations)
    for (const team of teams) {
      connectedPlatforms.push({
        id: team.id,
        name: team.name ?? '',
        url: '',
        type: PlatformsType.CLOUD_TEAMS,
      })
    }
  }

  return connectedPlatforms
}

const ORG_DISPLAY_NAME_MAX_LENGTH = 30

export const shortenOrgDisplayName = (input?: string, length?: number): string => {
  if (!input) {
    return ''
  }

  const targetLength = length ?? ORG_DISPLAY_NAME_MAX_LENGTH
  if (input.length > targetLength) {
    return input.substring(0, targetLength) + '…'
  }

  return input
}
