export enum StorageKey {
  ORG_ID = 'botkube-orgId',
}

export enum BrowserStorageType {
  LOCAL = 'local',
  SESSION = 'session',
}

export class BrowserStorage {
  private store: Storage = localStorage

  constructor(type: BrowserStorageType) {
    this.switch(type)
  }

  public switch(type: BrowserStorageType) {
    switch (type) {
      case BrowserStorageType.LOCAL:
        this.store = localStorage
        return this
      case BrowserStorageType.SESSION:
        this.store = sessionStorage
        return this
    }
  }
  public set(key: string, value: string) {
    this.store.setItem(key, value)
    return this
  }

  public get(key: string) {
    return this.store.getItem(key)
  }

  public removeItem(key: string) {
    this.store.removeItem(key)
    return this
  }
}

export const browserStoreService = new BrowserStorage(BrowserStorageType.LOCAL)
