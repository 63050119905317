import { ColumnsType } from 'antd/lib/table'
import { TeamChannel } from './CloudTeamsChannelsTableChannels'

export const getColumns = (): ColumnsType<TeamChannel> => {
  return [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Name',
    },
    {
      key: 'description',
      dataIndex: 'description',
      title: 'Description',
      ellipsis: true,
    },
  ]
}

export const getData = (channels: TeamChannel[]): TeamChannel[] => {
  return channels
}
