import styled from 'styled-components'
import { Alert, Card, CardProps, Tag } from 'antd'

const CARD_ACTIONS_HEIGHT = '46px'

type DeploymentSC = CardProps & {
  displayManagementActions?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const DeploymentSC = styled(({ displayManagementActions, ...props }: DeploymentSC) => <Card {...props} />)`
  ${({ displayManagementActions }) => displayManagementActions && `padding-bottom: ${CARD_ACTIONS_HEIGHT};`}
  height: 100%;

  .ant-card-head-title {
    a {
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .ant-card-actions {
    padding: 0 24px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    > li {
      text-align: left;
      padding: 0;
      width: auto !important;

      > span a:not(.ant-btn) {
        color: #1890ff;
      }

      :not(:last-child) {
        border: 0;
      }
    }

    > li + li {
      margin-left: 12px;
    }
  }
`

export const AlertSC = styled(Alert)`
  margin-bottom: 20px;
`

export const TagSC = styled(Tag)`
  color: #2f54eb;
  background-color: #f0f5ff;
  border-color: #adc6ff;
  padding: 2px 6px;

  svg {
    margin: 0 3px -2px 0;
    filter: brightness(0) saturate(100%) invert(23%) sepia(69%) saturate(5053%) hue-rotate(229deg) brightness(99%)
      contrast(86%);
  }
`
