import { FormikConfig } from 'formik'
import * as yup from 'yup'
import { AnySchema } from 'yup'
import { ValidationMsg } from '../../../../utils/validation'
import { PlatformsType } from '../../../../models/platform/platform'
import { Discord } from '../../../../models/graphql'
import { HandlePlatformFormSubmit } from '../../../../components/platform/settings/utils'
import { DiscordFormValues } from '../../../../pages/clusters/add/steps/platformsConfiguration/utils'

type Props = {
  values?: Discord
  onSubmit: HandlePlatformFormSubmit
}
export const initFormik = (props: Props): FormikConfig<DiscordFormValues> => {
  return {
    enableReinitialize: true,
    initialValues: {
      id: props.values?.id ?? '',
      name: props.values?.name ?? '',
      botId: props.values?.botId ?? '',
      token: props.values?.token ?? '',
      channelNames: props.values?.channels.map(channel => channel.id) ?? [],
    },
    validationSchema: yup.object().shape<Record<keyof DiscordFormValues, AnySchema>>({
      id: yup.string(),
      name: yup.string().required(ValidationMsg.REQUIRED),
      botId: yup.string().required(ValidationMsg.REQUIRED),
      token: yup.string().required(ValidationMsg.REQUIRED),
      channelNames: yup.array().of(yup.string()).min(1, ValidationMsg.MIN_ONE).required(ValidationMsg.REQUIRED),
    }),
    onSubmit: values => {
      props.onSubmit(PlatformsType.DISCORD, values)
    },
  }
}
