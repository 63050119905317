import { FormikConfig } from 'formik'
import * as yup from 'yup'
import { AnySchema } from 'yup'
import { ValidationMsg } from '../../../../utils/validation'
import { PlatformsType } from '../../../../models/platform/platform'
import { Elasticsearch } from '../../../../models/graphql'
import { HandlePlatformFormSubmit } from '../../../../components/platform/settings/utils'
import { ElasticSearchFormValues } from '../../../../pages/clusters/add/steps/platformsConfiguration/utils'

type Props = {
  values?: Elasticsearch
  onSubmit: HandlePlatformFormSubmit
}
export const initFormik = (props: Props): FormikConfig<ElasticSearchFormValues> => {
  return {
    enableReinitialize: true,
    initialValues: {
      id: props.values?.id ?? '',
      name: props.values?.name ?? '',
      username: props.values?.username ?? '',
      password: props.values?.password ?? '',
      server: props.values?.server ?? '',
      skipTlsVerify: !!props.values?.skipTlsVerify,
      indexName: props.values?.indices[0]?.name ?? '',
    },
    validationSchema: yup.object().shape<Record<keyof ElasticSearchFormValues, AnySchema>>({
      id: yup.string(),
      name: yup.string().required(ValidationMsg.REQUIRED),
      username: yup.string().required(ValidationMsg.REQUIRED),
      password: yup.string().required(ValidationMsg.REQUIRED),
      server: yup.string().required(ValidationMsg.REQUIRED),
      skipTlsVerify: yup.boolean(),
      indexName: yup.string().required(ValidationMsg.REQUIRED),
    }),
    onSubmit: values => {
      props.onSubmit(PlatformsType.ELASTIC_SEARCH, values)
    },
  }
}
