import React, { FC, useContext, useState } from 'react'
import { Alert, Button, message, Modal, Upload, UploadFile, UploadProps } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import { OpenAI } from 'openai'
import { useAuth0 } from '@auth0/auth0-react'
import { loadRuntimeConfig } from '../../config/runtime'
import { AppCtx } from '../../providers/appCtx'
import { useEnableAiCustomDocsForOrganizationMutation } from '../../models/graphql'

type UploadModalProps = {
  isOpen: boolean
  onEnableDocsSuccess?: () => void
  closeModal: () => void
  customDocsReadyToUpload?: boolean
  onUploadFinish: () => void
  totalUploadedDocsCount: number
  totalDocsLimit: number
}

const MAGIC_VECTOR_STORE_ID = 'magic-vector-store-id' // // magic value used to inject an actual vector store ID for current organization

// Copied from https://platform.openai.com/docs/assistants/tools/file-search/supported-files
const SUPPORTED_FILE_EXTENSIONS = [
  'c',
  'cs',
  'cpp',
  'doc',
  'docx',
  'html',
  'java',
  'json',
  'md',
  'pdf',
  'php',
  'pptx',
  'py',
  'py',
  'rb',
  'tex',
  'txt',
  'css',
  'js',
  'sh',
  'ts',
]
const SUPPORTED_FILE_TYPES = [
  '', // sometimes the mime type is empty so we need to allow such cases
  'text/x-c',
  'text/x-csharp',
  'text/x-c++',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/html',
  'text/x-java',
  'application/json',
  'text/markdown',
  'application/pdf',
  'text/x-php',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'text/x-python',
  'text/x-script.python',
  'text/x-ruby',
  'text/x-tex',
  'text/plain',
  'text/css',
  'text/javascript',
  'application/x-sh',
  'application/typescript',
]

const { REACT_APP_BACKEND_BASE_URL } = loadRuntimeConfig()

const { Dragger } = Upload

export const UploadModal: FC<UploadModalProps> = ({
  isOpen,
  customDocsReadyToUpload,
  onEnableDocsSuccess,
  onUploadFinish,
  closeModal,
  totalDocsLimit,
  totalUploadedDocsCount,
}) => {
  const { orgID } = useContext(AppCtx)
  const { getAccessTokenSilently } = useAuth0()
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [isUploading, setUploading] = useState(false)
  const { mutateAsync: enableAiDocsAsync } = useEnableAiCustomDocsForOrganizationMutation({
    onSuccess: () => {
      if (!onEnableDocsSuccess) {
        return
      }

      onEnableDocsSuccess()
    },
  })

  const enableDocs = async () => {
    await enableAiDocsAsync({
      id: orgID,
    })
  }

  const handleUpload = async () => {
    setUploading(true)

    if (!customDocsReadyToUpload) {
      try {
        await enableDocs()
      } catch (err) {
        void message.error(`Couldn't enable AI custom docs upload: ${(err as Error).message}`)
        setUploading(false)
        return
      }
    }

    const token = await getAccessTokenSilently()
    const client = new OpenAI({
      baseURL: `${REACT_APP_BACKEND_BASE_URL}/ai`,
      apiKey: '', // empty as we use our proxy
      dangerouslyAllowBrowser: true,
      defaultHeaders: {
        Authorization: `Bearer ${token}`,
        'X-Botkube-Organization-Id': orgID,
      },
    })

    try {
      const files: File[] = fileList.map(file => {
        return file as unknown as File
      })
      const batch = await client.beta.vectorStores.fileBatches.uploadAndPoll(MAGIC_VECTOR_STORE_ID, { files: files })
      void message.success(
        `Upload completed successfully for ${batch.file_counts.completed} of ${batch.file_counts.total} file${
          batch.file_counts.total > 1 ? 's' : ''
        }.`,
      )
      setFileList([])
    } catch (err) {
      void message.error(`Batch upload failed with error: ${(err as Error).message}`)
    } finally {
      setUploading(false)
      closeModal()
      onUploadFinish()
    }
  }

  const uploadProps: UploadProps = {
    multiple: true,

    onRemove: file => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    },
    beforeUpload: (file, files) => {
      const fileExtension = file.name.split('.').pop() ?? ''
      if (!SUPPORTED_FILE_EXTENSIONS.includes(fileExtension)) {
        void message.error(`${file.name}: Unsupported file extension.`)
        return false
      }

      if (!SUPPORTED_FILE_TYPES.includes(file.type)) {
        void message.error(`${file.name}: Unsupported file type ${file.type}.`)
        return false
      }

      const filteredFiles = files
        .filter(file => SUPPORTED_FILE_TYPES.includes(file.type))
        .filter(file => SUPPORTED_FILE_EXTENSIONS.includes(file.name.split('.').pop() ?? ''))

      setFileList([...fileList, ...filteredFiles])
      return false
    },
    fileList,
  }

  const uploadLimitExceeded = totalDocsLimit - totalUploadedDocsCount - fileList.length < 0
  const maxFilesToUpload = Math.max(totalDocsLimit - totalUploadedDocsCount, 0)

  return (
    <Modal
      title='Upload file(s)'
      open={isOpen}
      onCancel={closeModal}
      closable={false}
      maskClosable={false}
      footer={[
        <Button
          key='back'
          onClick={closeModal}
          disabled={isUploading}
        >
          Close
        </Button>,
        <Button
          key={'upload'}
          type='primary'
          onClick={() => void handleUpload()}
          disabled={fileList.length === 0 || uploadLimitExceeded}
          loading={isUploading}
        >
          {isUploading ? 'Uploading' : 'Start Upload'}
        </Button>,
      ]}
    >
      {uploadLimitExceeded && (
        <Alert
          className={'mb-10'}
          message={`You can only upload ${maxFilesToUpload} more file${maxFilesToUpload > 1 ? 's' : ''}.`}
          type='error'
          showIcon
        />
      )}

      <Dragger {...uploadProps}>
        <p className='ant-upload-drag-icon'>
          <InboxOutlined />
        </p>
        <p className='ant-upload-text'>Click or drag text file(s) to this area to upload</p>
        <p className='ant-upload-hint'>Supported formats include: .doc, .docx, .md, .pdf, .txt</p>
      </Dragger>
    </Modal>
  )
}
