import { AppQuery, Maybe, OrganizationDefaultView, OrganizationUpdateOperations } from '../models/graphql'
import { createContext, MutableRefObject } from 'react'
import { EntryCollection } from 'contentful'
import { ContentSkeleton } from '../services/contentful/types'

export type ConnectedTeamsOrganization = NonNullable<
  NonNullable<NonNullable<AppQuery['organizations']>[number]['connectedPlatforms']>['teamsOrganizations']
>[number]

export type AppCtxValue = {
  orgID: string
  orgUpdateOps: Maybe<OrganizationUpdateOperations>
  headerRef?: MutableRefObject<HTMLDivElement | null>
  content?: EntryCollection<ContentSkeleton>
  connectedSlacks: { name: string; teamId: string; isReinstallRequired: boolean }[]
  connectedMSTeams: ConnectedTeamsOrganization[]
  defaultView: OrganizationDefaultView | null
  refetchAppQuery?: () => Promise<unknown>
}

export const AppCtx = createContext<AppCtxValue>({
  orgID: '',
  orgUpdateOps: null,
  connectedSlacks: [],
  connectedMSTeams: [],
  defaultView: null,
  refetchAppQuery: undefined,
})
AppCtx.displayName = 'AppCtx'
