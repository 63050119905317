import { FC } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { shortenOrgDisplayName } from '../../pages/organization/utils'
import { AvatarSC, CaretDownOutlinedSC, OrgDropdownSC, OrgDropdownWrapperSC, OrgNameSC } from './styles'
import { breakpoints } from '../../styles/globalStyles'
import { useViewport } from '../../utils/customeHooks/useViewport'

export type OrgMenuItemProps = {
  currentOrganizationName?: string
}
export const OrgMenuItem: FC<OrgMenuItemProps> = ({ currentOrganizationName }) => {
  const { user = {} } = useAuth0()
  const { width } = useViewport()
  const shortenedOrgName = shortenOrgDisplayName(currentOrganizationName ?? '')

  const icon = (
    <img
      src={user.picture}
      alt={user.name}
      referrerPolicy='no-referrer'
    />
  )

  return (
    <OrgDropdownWrapperSC>
      <OrgDropdownSC>
        <AvatarSC
          size={24}
          icon={icon}
        />{' '}
        {shortenedOrgName && <OrgNameSC>{shortenedOrgName}</OrgNameSC>}
        {width > breakpoints.lgMin ? <CaretDownOutlinedSC /> : null}
      </OrgDropdownSC>
    </OrgDropdownWrapperSC>
  )
}
