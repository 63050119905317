import styled from 'styled-components'

export const OSIconSC = styled.span`
  display: flex;
  align-items: center;
  fill-opacity: 0.65;

  .custom-icon {
    margin-right: 12px;
  }
`
