import { FC } from 'react'
import { useFormik } from 'formik'
import { initFormik } from './utils'
import { Button, Form } from 'antd'
import { InputTag } from '../../../components/inputTag/InputTag'
import { getValidationError, getValidationStatus } from '../../../utils/formik/formik'
import { ButtonWrapper } from './styles'

export type AddMemberFormValues = {
  emails: string[]
}
export type AddMemberFormProps = {
  onSubmit: (values: AddMemberFormValues) => void
  onCancel: () => void
}
export const AddMemberForm: FC<AddMemberFormProps> = props => {
  const { onCancel } = props
  const formik = useFormik(initFormik(props))

  const handleEmails = (emails: string[]) => {
    void formik.setFieldValue(`emails`, emails)
  }

  return (
    <Form>
      <p>Invite a user to this organization. The user has to be already registered on app.botkube.io.</p>
      <Form.Item
        label={'Emails'}
        required={true}
        validateStatus={getValidationStatus(formik.errors.emails?.toString(), formik.touched.emails)}
        help={getValidationError(formik.errors.emails?.toString(), formik.touched.emails)}
      >
        <InputTag
          placeholder={'Email Address'}
          values={formik.values.emails}
          onChange={handleEmails}
        />
      </Form.Item>
      <ButtonWrapper>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          onClick={() => void formik.submitForm()}
          type={'primary'}
        >
          Add
        </Button>
      </ButtonWrapper>
    </Form>
  )
}
