import { useCallback, useEffect, useState } from 'react'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'

type CallbackOnCheckAll = (checkedAll: boolean) => void
type CallbackOnCheck = (list: CheckboxValueType[]) => void

export const useCheckAll = (
  numberOfOptions: number,
  numberOfCheckedOptions: number,
  callbackOnCheckAll: CallbackOnCheckAll,
  callbackOnChange: CallbackOnCheck,
): [boolean, boolean, (event: CheckboxChangeEvent) => void, (list: CheckboxValueType[]) => void] => {
  const [indeterminate, setIndeterminate] = useState(false)
  const [checkAll, setCheckAll] = useState(false)
  useEffect(() => {
    setIndeterminate(numberOfCheckedOptions > 0 && numberOfCheckedOptions < numberOfOptions)
    setCheckAll(numberOfCheckedOptions === numberOfOptions)
  }, [numberOfOptions, numberOfCheckedOptions])

  const onCheckAllChange = useCallback(
    (event: CheckboxChangeEvent) => {
      const { checked } = event.target
      setIndeterminate(false)
      setCheckAll(checked)

      callbackOnCheckAll(checked)
    },
    [setIndeterminate, setCheckAll, callbackOnCheckAll],
  )
  const onChange = useCallback(
    (list: CheckboxValueType[]) => {
      setIndeterminate(!!list.length && list.length < numberOfOptions)
      setCheckAll(list.length === numberOfOptions)

      callbackOnChange(list)
    },
    [setIndeterminate, setCheckAll, numberOfOptions, callbackOnChange],
  )

  return [indeterminate, checkAll, onCheckAllChange, onChange]
}
