import { FC } from 'react'
import { Button, Space } from 'antd'
import { GithubOutlined, GoogleOutlined, AmazonOutlined } from '@ant-design/icons'
import { ReactComponent as MicrosoftIcon } from '../../assets/icons/microsoft.svg'
import { WithoutHeaderLayout } from '../../layouts/withoutHeader/WithoutHeaderLayout'
import { BotkubeLogo, LoginCard, LoginSection } from './styles'

// TODO: This page is currently not used in favor of a predefined Auth0 login page.
//  Migrate to this page once we configured a custom login page.
export const LoginPage: FC = () => {
  return (
    <WithoutHeaderLayout contentClass={'center'}>
      <LoginSection>
        <BotkubeLogo />
        <LoginCard>
          <p>Use your preferred identity to sign in (or sign up)</p>
          <Space wrap={true}>
            <Button
              icon={<GithubOutlined />}
              className={'github'}
            >
              GitHub
            </Button>
            <Button
              icon={<GoogleOutlined />}
              className={'google'}
            >
              Google
            </Button>
            <Button
              icon={<AmazonOutlined />}
              className={'amazon'}
            >
              Amazon
            </Button>
            <Button
              icon={<MicrosoftIcon />}
              className={'microsoft'}
            >
              Microsoft
            </Button>
          </Space>
        </LoginCard>
      </LoginSection>
    </WithoutHeaderLayout>
  )
}
