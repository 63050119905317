import { FC, useState } from 'react'
import { Card, Checkbox, Col, Row, Space, Typography } from 'antd'
import { PrevNextButtons } from '../../../../../components/prevNextButtons/PrevNextButtons'
import { DeploymentAddStep } from '../../DeploymentAddPage'
import { PlatformsType } from '../../../../../models/platform/platform'
import { mapPlatformTypeToIcon } from '../../../../../utils/platforms'
import { WizardFormValues } from '../../DeploymentAddPage'
import { colProps, gutter } from '../../../../../styles/globalStyles'
import { getPluginsInPlatform } from '../../../../../utils/plugins'
import { WizardStepFooter } from '../footer/WizardStepFooter'
import { IconSC } from './styles'

const { Text, Title, Paragraph, Link } = Typography

type Props = {
  isLoading: boolean
  wizardValues: WizardFormValues
  setStep: (step: number) => void
  onSubmit: (defaultAliases: boolean, defaultActions: boolean) => void
}

export const ReviewStep: FC<Props> = props => {
  const {
    setStep,
    isLoading,
    onSubmit,
    wizardValues: { connectionStep, platformsStep, pluginsStep },
  } = props
  const [defaultAliases, setDefaultAliases] = useState(true)
  const [defaultActions, setDefaultActions] = useState(false)

  if (!connectionStep || !pluginsStep || !platformsStep) {
    return null
  }

  return (
    <>
      <Title level={4}>Review Botkube defaults</Title>
      <Title level={5}>1. Command Aliases</Title>
      <Paragraph>
        Aliases are shortcuts for longer commands and are defined globally for the Botkube installation.{' '}
        <Link
          href={'https://docs.botkube.io/configuration/alias'}
          title={'Alias'}
          target={'_blank'}
        >
          Learn more
        </Link>
        <br />
        We’ve created some <Text strong={true}> default command aliases</Text> to help get you started.{' '}
      </Paragraph>
      <Checkbox
        className={'mb-20'}
        checked={defaultAliases}
        onChange={() => setDefaultAliases(!defaultAliases)}
      >
        Include default aliases
      </Checkbox>
      <Title level={5}>2. Actions</Title>
      <Paragraph>
        Actions allow you to automate your workflows by executing custom commands based on specific events.{' '}
        <Link
          href={'https://docs.botkube.io/configuration/action'}
          title={'Actions'}
          target={'_blank'}
        >
          Learn more
        </Link>
        <br />
        We’ve created some <Text strong={true}>default actions</Text> to help get you started.
      </Paragraph>
      <Checkbox
        className={'mb-40'}
        checked={defaultActions}
        onChange={() => setDefaultActions(!defaultActions)}
      >
        Include default actions
      </Checkbox>

      <Title level={4}>Review instance configuration</Title>
      <Paragraph>
        <Text strong={true}>Display Name</Text>: {connectionStep.name}
      </Paragraph>
      <Row gutter={gutter}>
        {Object.entries(platformsStep)
          .filter(([, values]) => !!values.length)
          .map(([platformName, values]) => {
            return values.map((value, idx) => {
              const pluginsInPlatform = getPluginsInPlatform(pluginsStep.plugins, {
                platform: platformName as PlatformsType,
                platformId: value.id,
                idx,
                details: value,
              }).filter(plugin => plugin.enabled)

              return (
                <Col
                  key={value.name}
                  {...colProps}
                >
                  <Card
                    className={'align-height'}
                    title={
                      <>
                        <IconSC>{mapPlatformTypeToIcon(platformName as PlatformsType)}</IconSC> {value.name}
                      </>
                    }
                  >
                    <Space align={'start'}>
                      <b>Plugins: </b>
                      <ul>
                        {pluginsInPlatform.map(plugin => (
                          <li key={plugin.configurationName}>{plugin.name}</li>
                        ))}
                      </ul>
                    </Space>
                  </Card>
                </Col>
              )
            })
          })}
      </Row>
      <WizardStepFooter>
        <PrevNextButtons
          handlePrevStep={() => setStep(DeploymentAddStep.PLUGINS)}
          handleNextStep={() => onSubmit(defaultAliases, defaultActions)}
          nextButtonLabel={'Deploy changes'}
          isLoading={isLoading}
        />
      </WizardStepFooter>
    </>
  )
}
