import deepClone from 'deep-clone'
import { isEqual } from 'lodash'

import { AddPluginValues, Deployment, DeploymentUpdateReport } from './model'
import {
  instanceOfChannelBindingsById,
  instanceOfChannelBindingsByName,
  instanceOfCloudSlack,
  instanceOfElasticsearch,
  instanceOfPagerDuty,
  instanceOfPlatformWithChannels,
  instanceOfSinkPlatform,
  instanceOfWebhook,
} from '../../../utils/ts-guards'
import { addNewChannelDefaultValues, getDefaultValues, getPlatformFromDeployment } from '../../../utils/platforms'
import { PlatformsType, PlatformsValues } from '../../../models/platform/platform'
import {
  ChannelBindingsByNameAndId,
  ElasticsearchIndex,
  ElasticsearchUpdateInput,
  PluginType,
} from '../../../models/graphql'
import {
  ChannelNameWithId,
  ElasticSearchFormValues,
  PlatformsFormValuesWithChannels,
} from '../../../pages/clusters/add/steps/platformsConfiguration/utils'
import { PluginConfigData } from '../../../components/plugin/configuration/utils'
import { PluginGeneralFormValues } from '../../../components/plugin/configuration/content/overview/PluginGeneralForm'
import { RBACFormValues } from '../../../components/plugin/configuration/content/rbac/PluginRBAC'
import { AddEditActionFormValues } from '../../../forms/instance/actions/AddEditAction'
import { SlackWorkspace } from '../slackWorkspace/model'

export const isDeploymentClean = (deploymentOrigin: Deployment, deployment: Deployment) =>
  isEqual(deploymentOrigin, deployment)

export const isDeploymentUpdateValid = (deploymentUpdateValidReport: DeploymentUpdateReport) =>
  Object.values(deploymentUpdateValidReport.platforms ?? {}).every(report => report.isValid) &&
  Object.values(deploymentUpdateValidReport.plugins ?? {}).every(report => report.isValid)

export const setDeploymentPlatformsValidReportUtils = (
  deploymentUpdateReport: DeploymentUpdateReport,
  platformId: string,
  isValid: boolean,
  reason?: string,
) => {
  return {
    ...deploymentUpdateReport,
    platforms: {
      ...deploymentUpdateReport.platforms,
      [platformId]: {
        ...deploymentUpdateReport.platforms?.[platformId],
        isValid,
        reason,
      },
    },
  }
}

export const setDeploymentPluginsValidReportUtils = (
  deploymentUpdateReport: DeploymentUpdateReport,
  pluginId: string,
  isValid: boolean,
) => {
  return {
    ...deploymentUpdateReport,
    plugins: {
      ...deploymentUpdateReport.plugins,
      [pluginId]: {
        ...deploymentUpdateReport.plugins?.[pluginId],
        isValid,
      },
    },
  }
}

export const deleteChannelInPlatform = (deployment: Deployment, platformId: string, channel: string): Deployment => {
  const platform = getPlatformFromDeployment(deployment, platformId)
  if (instanceOfPlatformWithChannels(platform)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call,@typescript-eslint/ban-ts-comment
    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    platform.channels = platform.channels.filter((channelItem: { name?: string; id?: string }) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      const channelName = channelItem.name ?? channelItem.id
      return channelName !== channel
    })
  }

  return deployment
}

export const addChannelsToPlatformUtils = (
  deployment: Deployment,
  platformType: PlatformsType,
  platformId: string,
  channels: string[],
): Deployment => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const platform = getPlatformFromDeployment(deployment, platformId)
  if (instanceOfPlatformWithChannels(platform)) {
    channels.forEach(channel => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call,@typescript-eslint/ban-ts-comment
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      platform.channels.push(addNewChannelDefaultValues(platformType, channel))
    })
  }

  return deployment
}

export const updateDeploymentPlatforms = (
  deployment: Deployment,
  type: PlatformsType,
  values: PlatformsFormValuesWithChannels,
): Deployment => {
  if (!Object.values(PlatformsType).includes(type)) {
    console.error(`while updateDeploymentPlatforms, type ${type} is not supported`)
    return {
      ...deployment,
    }
  }

  const updatedPlatformIdx = deployment.platforms[type]?.findIndex(platformValues => platformValues.id === values.id)
  if (updatedPlatformIdx === undefined || updatedPlatformIdx === -1) {
    console.error(`while updateDeploymentUpdateInput, finding platform index for id: ${values.id}`)
    return {
      ...deployment,
    }
  }

  const actualValues: Partial<PlatformsFormValuesWithChannels> = deepClone<PlatformsFormValuesWithChannels>(values)
  if ('channelNames' in actualValues) {
    delete actualValues.channelNames
  }

  if (type == PlatformsType.CLOUD_SLACK && 'channelNamesWithId' in actualValues) {
    delete actualValues.channelNamesWithId
  }
  if (type == PlatformsType.CLOUD_TEAMS && 'attachmentStorage' in actualValues) {
    // @ts-expect-error
    delete actualValues.attachmentStorage.useDefaultLocation
  }

  if (type === PlatformsType.ELASTIC_SEARCH) {
    // @ts-expect-error
    delete actualValues.indexName
    const index =
      deployment.platforms[type]?.[updatedPlatformIdx]?.indices[0] ??
      ({} as ElasticsearchUpdateInput['indices'][number])
    index.name = (values as ElasticSearchFormValues).indexName
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  deployment.platforms[type]?.splice(updatedPlatformIdx, 1, {
    ...deployment.platforms[type]?.[updatedPlatformIdx],
    ...actualValues,
  })
  if ('channelNames' in values) {
    deployment = updateChannelsInPlatform(deployment, type, values.id, values.channelNames)
  }
  if ('channelNamesWithId' in values) {
    deployment = updateChannelsWithIdInPlatform(deployment, type, values.id, values.channelNamesWithId)
  }

  return deployment
}

const updateChannelsInPlatform = (
  deployment: Deployment,
  platformType: PlatformsType,
  platformId: string,
  channelNames: string[],
): Deployment => {
  const platform = getPlatformFromDeployment(deployment, platformId)
  if (instanceOfPlatformWithChannels(platform)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call,@typescript-eslint/ban-ts-comment
    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    platform.channels = platform.channels.filter((channelItem: { name?: string; id?: string }) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      const channelName = channelItem.name ?? channelItem.id
      return channelNames.some(chName => chName === channelName)
    })
    const newChannels = channelNames.filter(
      providedChannelName =>
        !platform.channels.some((channelItem: { name?: string; id?: string }) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
          const channelName = channelItem.name ?? channelItem.id
          return channelName === providedChannelName
        }),
    )

    newChannels.forEach(channel => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call,@typescript-eslint/ban-ts-comment
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      platform.channels.push(addNewChannelDefaultValues(platformType, channel))
    })
  }

  return deployment
}

export const updateChannelsWithIdInPlatform = (
  deployment: Deployment,
  platformType: PlatformsType,
  platformId: string,
  channels: ChannelNameWithId[],
): Deployment => {
  const platform = getPlatformFromDeployment(deployment, platformId)
  if (instanceOfCloudSlack(platform)) {
    platform.channels = platform.channels.filter(platformChannel => {
      return channels.some(channel => channel.id === platformChannel.channelId)
    })
    const newChannels = channels.filter(
      providedChannel =>
        !platform.channels.some(platformChannel => {
          return platformChannel.channelId === providedChannel.id
        }),
    )

    newChannels.forEach(channel => {
      platform.channels.push(addNewChannelWithId(platformType, channel))
    })
  }

  return deployment
}

export const removePluginFromDeploymentUtil = (deployment: Deployment, pluginId: string) => {
  const pluginForRemove = deployment.plugins.find(plugin => plugin.id === pluginId)
  deployment.plugins = deployment.plugins.filter(plugin => plugin.id !== pluginId)
  if (!pluginForRemove) {
    return deployment
  }

  const platforms = Object.entries(deployment.platforms).reduce<PlatformsValues[]>((values, [, platformValues]) => {
    if (Array.isArray(platformValues)) {
      values.push(...(platformValues as PlatformsValues[]))
    }
    return values
  }, [])

  for (const platform of platforms) {
    if (instanceOfPlatformWithChannels(platform)) {
      platform.channels.forEach(channel => {
        if (pluginForRemove.type === PluginType.Source) {
          channel.bindings.sources = channel.bindings.sources?.filter(
            pluginConfigName => pluginConfigName !== pluginForRemove.configurationName,
          )
        } else {
          channel.bindings.executors = channel.bindings.executors?.filter(
            pluginConfigName => pluginConfigName !== pluginForRemove.configurationName,
          )
        }
      })
    }
    if (instanceOfElasticsearch(platform)) {
      platform.indices.forEach(index => {
        if (index.bindings) {
          index.bindings.sources = index.bindings.sources?.filter(
            pluginConfigName => pluginConfigName !== pluginForRemove.configurationName,
          )
        }
      })
    }
  }

  return deployment
}

export const addPluginToDeploymentUtil = (deployment: Deployment, plugin: AddPluginValues, platformId?: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const platform = platformId ? getPlatformFromDeployment(deployment, platformId) : undefined
  if (instanceOfPlatformWithChannels(platform)) {
    platform.channels.forEach(channel => {
      if (plugin.type === PluginType.Source) {
        if (!Array.isArray(channel.bindings.sources)) {
          channel.bindings.sources = []
        }
        channel.bindings.sources.push(plugin.configurationName)
      } else {
        if (!Array.isArray(channel.bindings.executors)) {
          channel.bindings.executors = []
        }
        channel.bindings.executors.push(plugin.configurationName)
      }
    })
  }
  if (instanceOfWebhook(platform)) {
    if (!Array.isArray(platform.bindings.sources)) {
      platform.bindings.sources = []
    }
    platform.bindings.sources.push(plugin.configurationName)
  }
  if (instanceOfElasticsearch(platform)) {
    if (Array.isArray(platform.indices) && platform.indices.length < 1) {
      console.error(
        `while adding plugin to deployment: ${deployment.id} in platform: ${platform.id}: the indices don't exist`,
      )
      return deployment
    }

    if (platform.indices[0].bindings && !Array.isArray(platform.indices[0].bindings.sources)) {
      platform.indices[0].bindings.sources = []
    }
    platform.indices[0].bindings?.sources?.push(plugin.configurationName)
  }
  deployment.plugins.push(plugin)
  return deployment
}

export const changePluginConfigurationUtil = (
  deployment: Deployment,
  pluginId: string,
  configData: PluginConfigData,
  generalSettings: PluginGeneralFormValues,
  rbac?: RBACFormValues,
) => {
  const plugin = deployment.plugins.find(pluginItem => pluginItem.id === pluginId)
  if (!plugin) {
    throw Error(`while changePluginConfiguration can't find pluginId ${pluginId}`)
  }
  plugin.configuration = JSON.stringify(configData)
  plugin.displayName = generalSettings.displayName
  plugin.enabled = generalSettings.enabled
  plugin.rbac = rbac
  generalSettings.connections.forEach(connect => {
    const platform = getPlatformFromDeployment(deployment, connect.platformId)
    if (!platform) {
      throw Error(`while changePluginConfiguration can't find platform ${connect.platformId}`)
    }
    const channelsBind = connect.channels

    if (instanceOfCloudSlack(platform)) {
      platform.channels.forEach(channel => {
        if (plugin.type === PluginType.Source) {
          if (!Array.isArray(channel.bindings.sources)) {
            channel.bindings.sources = []
          }
          channel.bindings.sources = channel.bindings.sources.filter(
            pluginConfigName => pluginConfigName !== plugin.configurationName,
          )
          if (channelsBind.includes(channel.channelId)) {
            channel.bindings.sources.push(plugin.configurationName)
          }
        } else {
          if (!Array.isArray(channel.bindings.executors)) {
            channel.bindings.executors = []
          }
          channel.bindings.executors = channel.bindings.executors.filter(
            pluginConfigName => pluginConfigName !== plugin.configurationName,
          )
          if (channelsBind.includes(channel.channelId)) {
            channel.bindings.executors.push(plugin.configurationName)
          }
        }
      })
    } else if (instanceOfPlatformWithChannels(platform)) {
      platform.channels.forEach(channel => {
        if (plugin.type === PluginType.Source) {
          if (!Array.isArray(channel.bindings.sources)) {
            channel.bindings.sources = []
          }
          channel.bindings.sources = channel.bindings.sources.filter(
            pluginConfigName => pluginConfigName !== plugin.configurationName,
          )
          if (instanceOfChannelBindingsByName(channel) && channelsBind.includes(channel.name)) {
            channel.bindings.sources.push(plugin.configurationName)
          }
          if (instanceOfChannelBindingsById(channel) && channelsBind.includes(channel.id)) {
            channel.bindings.sources.push(plugin.configurationName)
          }
        } else {
          if (!Array.isArray(channel.bindings.executors)) {
            channel.bindings.executors = []
          }
          channel.bindings.executors = channel.bindings.executors.filter(
            pluginConfigName => pluginConfigName !== plugin.configurationName,
          )
          if (instanceOfChannelBindingsByName(channel) && channelsBind.includes(channel.name)) {
            channel.bindings.executors.push(plugin.configurationName)
          }
          if (instanceOfChannelBindingsById(channel) && channelsBind.includes(channel.id)) {
            channel.bindings.executors.push(plugin.configurationName)
          }
        }
      })
    } else if (instanceOfSinkPlatform(platform)) {
      if (instanceOfWebhook(platform) || instanceOfPagerDuty(platform)) {
        platform.bindings.sources = platform.bindings.sources?.filter(source => source !== plugin.configurationName)
        if (connect.bound) {
          if (!platform.bindings.sources) {
            platform.bindings.sources = []
          }
          platform.bindings.sources.push(plugin.configurationName)
        }
      } else if (instanceOfElasticsearch(platform)) {
        platform.indices = platform.indices.reduce<ElasticsearchIndex[]>((indAcc, ind) => {
          if (ind.bindings?.sources) {
            ind.bindings.sources = ind.bindings.sources.filter(source => source !== plugin.configurationName)
          }
          indAcc.push(ind)

          return indAcc
        }, [])
        if (connect.bound) {
          const bindings = platform.indices[0].bindings ?? (platform.indices[0].bindings = {})
          const sources = bindings.sources ?? (bindings.sources = [])
          sources.push(plugin.configurationName)
        }
      }
    }
  })

  return deployment
}

export const addNewPlatformUtil = (
  deployment: Deployment,
  platform: PlatformsType,
  values?: PlatformsFormValuesWithChannels,
): { deployment: Deployment; platformId: string } => {
  const platformName = platform as keyof Deployment['platforms']
  if (!Array.isArray(deployment.platforms[platformName])) {
    deployment.platforms[platformName] = []
  }
  const defaultValues = getDefaultValues(platform)
  defaultValues.id = values?.id ?? defaultValues.id
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/ban-ts-comment
  // @ts-expect-error
  deployment.platforms[platformName]?.push(defaultValues)

  return { deployment, platformId: defaultValues.id }
}

export const removePlatformFromDeploymentUtil = (deployment: Deployment, platformId: string): Deployment => {
  Object.entries(deployment.platforms).forEach(([platformNameStr, platformsValues]) => {
    const platformName = platformNameStr as keyof Deployment['platforms']
    if (!Array.isArray(platformsValues)) {
      return
    }
    //@ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
    const filteredPlatforms = platformsValues.filter(platformVal => platformVal.id !== platformId)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
    deployment.platforms[platformName] = filteredPlatforms.length > 0 ? filteredPlatforms : null
  })

  return deployment
}

export const removeActionFromDeploymentUtil = (deployment: Deployment, actionId: string) => {
  deployment.actions = deployment.actions.filter(action => action.id !== actionId)

  return deployment
}

export const updateActionInDeploymentUtil = (deployment: Deployment, values: AddEditActionFormValues) => {
  const { sources, executors, ...rest } = values
  const actionInDepIdx = deployment.actions.findIndex(action => action.id === values.id)
  // Edit action
  if (actionInDepIdx !== -1) {
    deployment.actions[actionInDepIdx] = {
      ...rest,
      bindings: {
        sources: sources,
        executors: executors,
      },
    }

    return deployment
  }

  // Add action
  deployment.actions = [
    ...deployment.actions,
    {
      ...rest,
      bindings: {
        sources: sources,
        executors: executors,
      },
    },
  ]

  return deployment
}

export const updateDeploymentWithCloudSlackChannels = (deployment: Deployment, slackWorkspaces: SlackWorkspace[]) => {
  const clonedDeployment = deepClone(deployment)
  if (clonedDeployment.platforms.cloudSlacks?.length) {
    clonedDeployment.platforms.cloudSlacks.forEach(cloudSlack => {
      const slackWorkspace = slackWorkspaces.find(sw => sw.teamId === cloudSlack.teamId)
      if (!slackWorkspace) {
        return
      }
      cloudSlack.channels.forEach(channel => {
        if (channel.channelId) {
          return
        }
        const swCh = slackWorkspace.channels.find(swCh => {
          return !swCh.isAlias && swCh.name === channel.name
        })
        channel.channelId = swCh?.id ?? ''
      })
    })
  }

  return clonedDeployment
}

const addNewChannelWithId = (platformType: PlatformsType, channel: ChannelNameWithId): ChannelBindingsByNameAndId => {
  switch (platformType) {
    case PlatformsType.CLOUD_SLACK:
      return {
        name: channel.name,
        channelId: channel.id,
        bindings: {
          executors: [],
          sources: [],
        },
      }
    default:
      throw new Error(`while adding a new channel with id, cannot handle platform: ${platformType}`)
  }
}
