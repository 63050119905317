import { FC, useMemo } from 'react'
import { useAppSelector } from '../../../store/store'
import {
  getBindingsPluginToPlatformsForDeploymentPage,
  getPluginConfigurationConnections,
  PluginBindingsDeploymentPage,
} from '../../../utils/plugins'
import { PlatformsFormValuesWithChannels } from '../../../pages/clusters/add/steps/platformsConfiguration/utils'
import { getPlatformFromDeployment, mapPlatformTypeToIcon } from '../../../utils/platforms'
import { Tag } from 'antd'
import { PlatformsType } from '../../../models/platform/platform'
import { PluginUsedInPlatformSC, PluginUsedInPlatformsSC } from './styles'
import { PluginConfigurationConnection } from '../configuration/PluginConfiguration'
import { PluginType } from '../../../models/graphql'
import { useMSTeamsChannels } from '../../../utils/customeHooks/useMSTeamsChannels'

type Props = {
  pluginConfigName: string
  pluginType: PluginType
}
type PluginBindingsWithPlatform = PluginBindingsDeploymentPage & {
  platform: PlatformsFormValuesWithChannels
}
export const PluginUsedInPlatforms: FC<Props> = ({ pluginType, pluginConfigName }) => {
  const deployment = useAppSelector(state => state.deployment.data)
  const { msTeamsChannels } = useMSTeamsChannels()
  const pluginBindings = useMemo<PluginBindingsWithPlatform[]>(() => {
    if (deployment) {
      return getBindingsPluginToPlatformsForDeploymentPage(pluginConfigName, deployment.platforms).map(connect => ({
        ...connect,
        platform: getPlatformFromDeployment(deployment, connect.platformId) as PlatformsFormValuesWithChannels,
      }))
    }
    return []
  }, [deployment, pluginConfigName])
  const pluginConnections = useMemo<PluginConfigurationConnection[]>(() => {
    if (!deployment) {
      return []
    }
    return getPluginConfigurationConnections(deployment, pluginType, msTeamsChannels)
  }, [deployment, pluginType, msTeamsChannels])

  return (
    <PluginUsedInPlatformsSC>
      {pluginBindings.map(binding => {
        const channels = pluginBindings.find(bind => bind.platformId === binding.platformId)?.channels ?? []
        return (
          <PluginUsedInPlatformSC key={binding.platformId}>
            <Tag className={channels.length > 0 ? 'with-channels' : 'without-channels'}>
              {mapPlatformTypeToIcon(binding.platformType, { height: '12px' })} {binding.platform.name}
            </Tag>
            {channels.map(ch => (
              <Tag key={ch}>
                {[
                  PlatformsType.CLOUD_SLACK,
                  PlatformsType.CLOUD_TEAMS,
                  PlatformsType.SOCKET_SLACK,
                  PlatformsType.MATTERMOST,
                ].includes(binding.platformType)
                  ? '#'
                  : ''}
                {
                  pluginConnections
                    .find(conn => conn.platformId === binding.platformId)
                    ?.availableChannels.find(avCh => avCh.value === ch)?.label
                }
              </Tag>
            ))}
          </PluginUsedInPlatformSC>
        )
      })}
    </PluginUsedInPlatformsSC>
  )
}
