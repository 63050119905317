import { Button, Col, Progress, Row, Space, Statistic, Tooltip } from 'antd'
import moment from 'moment/moment'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { paths } from '../../router/paths'
import { OrganizationPageQuery, StripeCouponDuration } from '../../models/graphql'
import { DescriptionsSC, InfoCircleOutlinedSC, ProgressIndicatorSC, ProgressValueSC } from './styles'
import { colProps } from '../../styles/globalStyles'
import { BillingPortalLink } from '../../components/billingPortalLink/BillingPortalLink'

type PlanUsageItem = {
  name: string
  value?: number | null
  limit?: number | null
  fixedMessage?: string
  description?: string
}

export type CurrentBillingProps = {
  organization: NonNullable<OrganizationPageQuery['organization']>
}

type Coupon = NonNullable<NonNullable<OrganizationPageQuery['organization']>['subscription']['invoice']>['coupon']

export const CurrentBilling: FC<CurrentBillingProps> = ({ organization }) => {
  const navigate = useNavigate()
  const planDisplayName = organization.subscription.planDisplayName ?? organization.subscription.planName

  const upcomingInvoiceAmount = organization.subscription.invoice?.upcomingAmount ?? 0
  const endOfBillingCycleDate = organization.subscription.invoice?.endOfBillingCycleDate
  const endOfTrialDate = organization.subscription.invoice?.endOfTrialDate
  const coupon = organization.subscription.invoice?.coupon
  // For now, we only have the node price, once we will add more columns
  const unitPrice = parseFloat(organization.subscription.invoice?.items[0]?.priceUnitAmount ?? '0')

  const planUsageItems: PlanUsageItem[] = [
    {
      name: 'Instances Count',
      value: organization.usage?.deploymentCount,
      limit: organization.quota?.deploymentCount,
    },
    {
      name: 'Instances Node Count',
      value: organization.usage?.nodeCount,
      limit: organization.quota?.nodeCount,
      description: 'The sum of cluster nodes of all active Botkube instances.',
    },
    {
      name: 'Members Count',
      value: organization.usage?.memberCount,
      limit: organization.quota?.memberCount,
    },
    {
      name: 'Slack Platform Count',
      description:
        'The number of enabled integrations for Slack platform. The official Botkube Cloud app for Slack supports multiple Kubernetes clusters.',
      value: organization.usage?.cloudSlackUseCount,
      limit: organization.quota?.cloudSlackUseCount,
    },
    {
      name: 'Monthly AI Token Limit',
      description:
        'The number of tokens that can be used for AI requests per each calendar month. 1 token ~= 4 English characters, 1-2 sentence(s) ~= 30 tokens.',
      value: organization.usage?.aiMonthlyTokenCount,
      limit: organization.quota?.aiMonthlyTokenCount,
    },
    {
      name: 'AI knowledge documents',
      description: 'The maximum number of custom knowledge documents uploaded.',
      value: organization.usage?.aiCustomDocsCount,
      limit: organization.quota?.aiCustomDocsCount,
    },
    {
      name: 'Audit Log Data Retention',
      fixedMessage: moment.duration(organization.quota?.auditRetentionPeriod, 'hours').humanize(),
    },
  ]

  return (
    <>
      <h2 className={'mt-10'}>Active plan</h2>
      <Row>
        <Col {...colProps}>
          <Statistic
            title='Current plan'
            value={planDisplayName}
          />
        </Col>
        {endOfBillingCycleDate && (
          <>
            <Col {...colProps}>
              <Statistic
                title='Price per node per hour'
                value={unitPrice / 100}
                precision={4}
                suffix={'$'}
              />
            </Col>
            <Col {...colProps}>
              <Tooltip title={organization.subscription.invoice?.items[0]?.description}>
                <Statistic
                  title='Upcoming invoice amount'
                  value={upcomingInvoiceAmount / 100}
                  precision={2}
                  suffix={'$'}
                />
              </Tooltip>
            </Col>
            <Col {...colProps}>
              <Tooltip title={moment(endOfBillingCycleDate).toLocaleString()}>
                <Statistic
                  title='End of billing cycle'
                  value={moment(endOfBillingCycleDate).fromNow()}
                />
              </Tooltip>
            </Col>
          </>
        )}
        {endOfTrialDate && (
          <Col {...colProps}>
            <Tooltip title={moment(endOfTrialDate).toLocaleString()}>
              <Statistic
                title='End of trial period'
                value={moment(endOfTrialDate).fromNow()}
              />
            </Tooltip>
          </Col>
        )}
      </Row>
      {coupon && (
        <>
          <div style={{ margin: '24px 0' }}>
            <h2>Applied Discounts</h2>
            {getCouponDetails(coupon)}
          </div>
        </>
      )}
      <p className={'mt-20'}>
        Manage payment, current subscriptions and see the billing history in the Billing Portal:
      </p>
      <Space className='mb-40'>
        <BillingPortalLink billingHistoryAvailable={organization.billingHistoryAvailable} />
        <Button
          type={'link'}
          onClick={() => navigate(paths.SUBSCRIPTION_PLANS.replace(':id', organization.id))}
        >
          Update your subscription
        </Button>
      </Space>
      <h2 className={'mt-10'}>Plan usage and limits</h2>
      <p>See your current plan usage and limits:</p>
      <Space className={'mb-40'}>
        <DescriptionsSC
          column={1}
          bordered
        >
          {planUsageItems.map(item => getDescItem(item))}
        </DescriptionsSC>
      </Space>
    </>
  )
}

function getDescItem({ name, value = 0, limit = 0, fixedMessage, description }: PlanUsageItem) {
  let label = <>{name}</>
  if (description) {
    label = (
      <>
        {label}{' '}
        <Tooltip title={description}>
          <InfoCircleOutlinedSC />
        </Tooltip>
      </>
    )
  }

  if (fixedMessage) {
    return (
      <DescriptionsSC.Item
        key={name}
        label={label}
      >
        {fixedMessage}
      </DescriptionsSC.Item>
    )
  }

  let percentage
  let status: 'normal' | 'exception' | 'active' | 'success' = 'normal'
  if (limit === null || limit === -1) {
    percentage = 0
  } else {
    percentage = Math.round(((value ?? 0) / limit) * 100)
  }

  const limitFormatted = limit === -1 ? '∞' : limit

  if (percentage > 100) {
    percentage = 100
    status = 'exception'
  }

  return (
    <DescriptionsSC.Item
      key={name}
      label={label}
    >
      <ProgressIndicatorSC>
        <Progress
          status={status}
          size={'small'}
          showInfo={false}
          percent={percentage}
        />
      </ProgressIndicatorSC>
      <ProgressValueSC exceeded={status === 'exception'}>
        {value} / {limitFormatted}
      </ProgressValueSC>
    </DescriptionsSC.Item>
  )
}

function getCouponDetails(coupon: Coupon) {
  if (!coupon) {
    return null
  }

  const months = coupon.durationInMonths ?? 0
  const duration = () => {
    switch (coupon.duration) {
      case StripeCouponDuration.Forever:
        return 'Forever'
      case StripeCouponDuration.Once:
        return 'One-time'
      case StripeCouponDuration.Repeating:
        return `${months} month${months > 1 ? 's' : ''}`
      case StripeCouponDuration.Unknown:
        return 'Unknown'
    }
    return coupon.duration
  }

  return (
    <>
      <Row style={{ marginTop: '10px' }}>
        <Col {...colProps}>
          <Statistic
            title='Coupon Name'
            value={coupon.name}
          />
        </Col>
        {coupon.amountOff && (
          <Col {...colProps}>
            <Statistic
              title='Discount amount'
              value={coupon.amountOff / 100}
              precision={2}
              suffix={'$'}
            />
          </Col>
        )}
        {coupon.percentOff && (
          <Col {...colProps}>
            <Statistic
              title='Discount percentage'
              value={coupon.percentOff}
              precision={2}
              suffix={'%'}
            />
          </Col>
        )}
        <Col {...colProps}>
          <Statistic
            title='Duration'
            value={duration()}
          />
        </Col>
      </Row>
    </>
  )
}
