import styled from 'styled-components'
import { breakpoints } from '../../../styles/globalStyles'
import { ReactComponent as SlackIcon } from '../../../assets/icons/slack-full-size.svg'

export const SlackIconSC = styled(SlackIcon)`
  width: 94px;
  height: 94px;

  @media (max-width: ${breakpoints.mdMin}px) {
    width: 64px;
    height: 64px;
  }

  @media (max-width: ${breakpoints.xsMin}px) {
    width: 48px;
    height: 48px;
  }
`
