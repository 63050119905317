import { FC, Fragment } from 'react'
import { Button, Divider, Typography } from 'antd'
import { ReactComponent as SlackIcon } from '../../../../assets/icons/slack.svg'
import { loadRuntimeConfig } from '../../../../config/runtime'
import { useContent } from '../../../../utils/customeHooks/useContent'
import { ContentType, SlackCloudPlatformInstructionSkeleton } from '../../../../services/contentful/types'

const { Text, Title } = Typography

const { REACT_APP_SLACK_ROUTER_BASE_URL } = loadRuntimeConfig()
const CLOUD_SLACK_APP_INSTALL_URL = `${REACT_APP_SLACK_ROUTER_BASE_URL}/routers/slack/v1/install?state=origin:ui`

export const CloudSlackContentInstructions: FC = () => {
  const content =
    useContent<SlackCloudPlatformInstructionSkeleton[]>(ContentType.SLACK_CLOUD_PLATFORM_INSTRUCTION, true) ?? []
  const instructions = content.sort((itemA, itemB) => itemA.fields.order - itemB.fields.order)

  return (
    <>
      {instructions.map((instruction, idx) => (
        <Fragment key={idx}>
          <Title level={5}>{instruction.fields.title}</Title>
          <p>
            <Text>{instruction.fields.description}</Text>
          </p>
          {instruction.fields.attachAddToSlackButton && (
            <Button
              type={'default'}
              size={'large'}
              href={CLOUD_SLACK_APP_INSTALL_URL}
              target={'blank'}
              rel='noreferrer'
              icon={<SlackIcon style={{ height: '12px', marginRight: '5px' }} />}
            >
              Add to Slack
            </Button>
          )}
          {instructions.length - 1 > idx && <Divider />}
        </Fragment>
      ))}
    </>
  )
}
