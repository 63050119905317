import { FormikConfig } from 'formik'
import * as yup from 'yup'
import { AnySchema } from 'yup'
import { ValidationMsg } from '../../../../../utils/validation'
import { PluginRBACProps, RBACFormValues } from './PluginRBAC'
import { PolicySubjectType } from '../../../../../models/graphql'
import { getInitialRBACFormValues } from '../../../../../utils/plugins-rbac'

export const initFormik = ({ value, onSubmit }: PluginRBACProps): FormikConfig<RBACFormValues> => {
  return {
    enableReinitialize: true,
    initialValues: getInitialRBACFormValues(value),
    validationSchema: yup.object().shape<Record<keyof RBACFormValues, AnySchema>>({
      id: yup.string(),
      group: yup.object().shape({
        type: yup.string().oneOf(Object.values(PolicySubjectType)).required(ValidationMsg.REQUIRED),
        prefix: yup.string().nullable(),
        static: yup.object().when('type', {
          is: PolicySubjectType.Static,
          then: yup.object().shape({
            values: yup.array().of(yup.string().min(1)).min(1, 'At least one group must be selected'),
          }),
        }),
      }),
      user: yup.object().shape({
        type: yup.string().oneOf(Object.values(PolicySubjectType)).required(ValidationMsg.REQUIRED),
        prefix: yup.string().nullable(),
        static: yup.object().when('type', {
          is: PolicySubjectType.Static,
          then: yup.object().shape({
            value: yup.string().min(1).required(ValidationMsg.REQUIRED),
          }),
        }),
      }),
    }),
    validateOnChange: true,
    onSubmit: values => {
      onSubmit(values)
    },
  }
}
