import { FC, Fragment } from 'react'
import { Button, Divider, Typography } from 'antd'
import { useContent } from '../../../../../../utils/customeHooks/useContent'
import { PlatformInstructionSkeleton, PlatformInstructionTypes } from '../../../../../../services/contentful/types'
import Markdown from 'markdown-to-jsx'

const { Title } = Typography

type Props = {
  platformInstructionType: PlatformInstructionTypes
}

export const PlatformContentInstruction: FC<Props> = props => {
  const { platformInstructionType } = props
  const content = useContent<PlatformInstructionSkeleton[]>(platformInstructionType, true) ?? []
  const instructions = content.sort((itemA, itemB) => itemA.fields.order - itemB.fields.order)

  return (
    <>
      {instructions.map((instruction, idx) => (
        <Fragment key={idx}>
          <Title level={5}>{instruction.fields.title}</Title>
          <p>
            <Markdown>{instruction.fields.description}</Markdown>
          </p>
          {instruction.fields.buttonLabel && instruction.fields.buttonLink && (
            <p>
              <Button
                type={'primary'}
                href={instruction.fields.buttonLink}
                target={'blank'}
                rel='noreferrer'
              >
                {instruction.fields.buttonLabel}
              </Button>
            </p>
          )}
          {instructions.length - 1 > idx && <Divider />}
        </Fragment>
      ))}
    </>
  )
}
