import { FC, useState } from 'react'
import { Checkbox, Switch } from 'antd'

import { BindPluginSC } from './styles'
import { PlatformsType } from '../../../../../models/platform/platform'
import { PluginConfigurationConnection } from '../../PluginConfiguration'
import { CloudSlackChannelsTable } from '../../../../../forms/instance/platforms/cloudSlack/CloudSlackChannelsTable'
import { useAppSelector } from '../../../../../store/store'
import { selectSlackWorkspace } from '../../../../../store/slices/slackWorkspace/selectors'
import { ChannelData } from '../../../../../forms/instance/platforms/cloudSlack/channels'
import {
  CloudTeamsChannelsTableChannels,
  TeamChannel,
} from '../../../../../forms/instance/platforms/cloudTeams/channels/CloudTeamsChannelsTableChannels'
import { useMSTeamsChannels } from '../../../../../utils/customeHooks/useMSTeamsChannels'
import { selectPlatform } from '../../../../../store/slices/deployment/selectors'
import { instanceOfCloudMSTeams } from '../../../../../utils/ts-guards'

type Props = {
  channels: string[]
  isBound?: boolean
  connection: PluginConfigurationConnection
  onChange: (platformType: PlatformsType, channels: string[], bound: boolean) => void
  onBlur: () => Promise<void>
}
export const PluginGeneralFormChannels: FC<Props> = ({ channels, connection, isBound, onChange, onBlur }) => {
  const slackWorkspace = useAppSelector(state => selectSlackWorkspace(state, connection.platformId))
  const platform = useAppSelector(state => selectPlatform(state, connection.platformId))
  const { msTeamsChannels } = useMSTeamsChannels()
  const [selectedChannels, setSelectedChannels] = useState(channels)

  const renderChannels = (platformType: PlatformsType) => {
    switch (platformType) {
      case PlatformsType.CLOUD_SLACK:
        return (
          <CloudSlackChannelsTable
            data={
              slackWorkspace?.channels.reduce<ChannelData[]>((accChannels, swCh) => {
                if (connection.availableChannels.some(avCh => avCh.value === swCh.id)) {
                  accChannels.push({
                    key: swCh.id,
                    type: swCh.isPrivate ? 'private' : 'public',
                    name: swCh.name,
                    purpose: swCh.purpose,
                    isAlias: swCh.isAlias,
                    isBoundToChannel: channels.includes(swCh.id),
                  })
                }
                return accChannels
              }, []) ?? []
            }
            selectedChannels={channels.map(ch => ({
              id: ch,
              name: slackWorkspace?.channels.find(swCh => swCh.id === ch)?.name ?? '',
            }))}
            onChannelSelect={channelsWithId => {
              onChange(
                platformType,
                channelsWithId.map(chWithId => chWithId.id),
                false,
              )
            }}
          />
        )
      case PlatformsType.CLOUD_TEAMS:
        return (
          <CloudTeamsChannelsTableChannels
            channels={
              instanceOfCloudMSTeams(platform)
                ? msTeamsChannels
                    .filter(ch => ch.aadGroupId === platform.aadGroupId)
                    .reduce<TeamChannel[]>((accCh, ch) => {
                      if (connection.availableChannels.some(avCh => avCh.value === ch.id)) {
                        accCh.push({
                          name: ch.name,
                          conversationId: ch.id,
                          description: ch.description,
                        })
                      }
                      return accCh
                    }, [])
                : []
            }
            selectedChannels={channels}
            onChange={selectedChannels => {
              onChange(platformType, selectedChannels, false)
            }}
          />
        )
      case PlatformsType.DISCORD:
      case PlatformsType.MATTERMOST:
      case PlatformsType.SOCKET_SLACK:
        return connection.availableChannels.map(channel => (
          <div
            key={channel.value}
            className={'mb-10'}
          >
            <Checkbox
              value={channel.value}
              checked={selectedChannels.includes(channel.value)}
              onChange={evt => {
                let newSelectedChannels: string[]
                if (evt.target.checked) {
                  newSelectedChannels = [...selectedChannels, evt.target.value as string]
                } else {
                  newSelectedChannels = selectedChannels.filter(chVal => chVal !== evt.target.value)
                }
                setSelectedChannels(newSelectedChannels)
                onChange(connection.platformType, newSelectedChannels, false)
              }}
            >
              {channel.label}
            </Checkbox>
          </div>
        ))
      case PlatformsType.WEBHOOK:
      case PlatformsType.ELASTIC_SEARCH:
      case PlatformsType.PAGER_DUTY:
        return (
          <BindPluginSC>
            <Switch
              checked={isBound}
              onChange={checked => onChange(platformType, [], checked)}
            />{' '}
            Bind / unbind plugin to platform
          </BindPluginSC>
        )
    }
  }

  return <div onBlur={() => void onBlur()}>{renderChannels(connection.platformType)}</div>
}
