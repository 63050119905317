import { FC } from 'react'

import { useAppSelector } from '../../../store/store'
import { PlatformsType, PlatformsValues } from '../../../models/platform/platform'
import { Platform } from '../single/Platform'
import { AddNewPlatformWrapperSC, PlatformIconsSC, PlatformsListSC } from './styles'
import { AddPlatform } from '../add/AddPlatform'
import { ReactComponent as ConnectIcon } from '../../../assets/connect.svg'
import { mapPlatformTypeToIcon } from '../../../utils/platforms'

export const PlatformsList: FC = () => {
  const deployment = useAppSelector(state => state.deployment.data)

  if (!deployment) {
    return null
  }

  return (
    <>
      <PlatformsListSC>
        {Object.entries(deployment.platforms).map(([platformNameStr, platformValues]) => {
          const platformType = platformNameStr as PlatformsType
          if (Array.isArray(platformValues)) {
            return platformValues.map(platformVal => {
              return (
                <Platform
                  key={platformVal.id}
                  values={platformVal as PlatformsValues}
                  type={platformType}
                />
              )
            })
          }

          return null
        })}
      </PlatformsListSC>
      <AddNewPlatformWrapperSC>
        <ConnectIcon className={'connect'} />
        <AddPlatform
          addButtonType={'primary'}
          addButtonLabel={'Add new platform'}
        />
        <PlatformIconsSC>
          {[
            PlatformsType.CLOUD_SLACK,
            PlatformsType.CLOUD_TEAMS,
            PlatformsType.DISCORD,
            PlatformsType.MATTERMOST,
            PlatformsType.PAGER_DUTY,
            PlatformsType.WEBHOOK,
            PlatformsType.ELASTIC_SEARCH,
          ].map(type => mapPlatformTypeToIcon(type, { height: '32px' }))}
        </PlatformIconsSC>
      </AddNewPlatformWrapperSC>
    </>
  )
}
