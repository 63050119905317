import React, { ReactNode } from 'react'
import { AlertSC, DeploymentSC, TagSC } from './styles'
import { CardProps, Space, TagProps } from 'antd'
import { Tag } from '../../tag/Tag'
import { PlatformsType } from '../../../models/platform/platform'
import { DeploymentStatusPhase, Maybe } from '../../../models/graphql'
import { mapStatusToMsg, mapStatusToType } from '../status/utils'
import { Link } from 'react-router-dom'
import { DEPLOYMENT_ADD_STEP, paths } from '../../../router/paths'
import { mapPlatformTypeToIcon } from '../../../utils/platforms'
import { useAppSelector } from '../../../store/store'

type Props = CardProps & {
  displayManagementActions?: boolean
  deployment: {
    id: string
    name: string
    plugins: {
      id: string
      name: string
    }[]
    platforms: Partial<Record<PlatformsType, ({ name?: string | null } | null)[] | null>>
    status?: { phase: DeploymentStatusPhase; upgrade?: { needsUpgrade: boolean; targetBotkubeVersion: string } | null }
    draft?: Maybe<boolean>
  }
}

export const DeploymentCard: React.FC<Props> = props => {
  const { displayManagementActions, title, deployment } = props
  const isUpgradeBlocked = useAppSelector(state => state.deployment.updateBlock.org.isBlocked)

  let deployStatus
  if (deployment.status) {
    let color: TagProps['color'] = mapStatusToType(deployment)
    switch (
      color // accessibility-related overrides
    ) {
      case 'warning':
        color = 'volcano'
        break
      case 'info':
        color = 'processing'
        break
    }
    deployStatus = {
      color,
      text: mapStatusToMsg(deployment),
    }
  }

  const needsUpgrade = deployment.status?.upgrade?.needsUpgrade
  let upgradeAlert
  if (needsUpgrade) {
    const targetVersion = deployment.status?.upgrade?.targetBotkubeVersion
      ? `Botkube ${deployment.status.upgrade.targetBotkubeVersion}`
      : 'New version'
    upgradeAlert = (
      <AlertSC
        showIcon
        type={'warning'}
        message={`${targetVersion} available.`}
      />
    )
  }

  const actions = []
  if (displayManagementActions) {
    actions.push(
      <Link
        key={deployment.id + '/manage'}
        to={
          deployment.draft
            ? paths.DEPLOYMENT_ADD_RETURN.replace(':id', deployment.id).replace(':step', DEPLOYMENT_ADD_STEP.CONNECT)
            : paths.DEPLOYMENT.replace(':id', deployment.id)
        }
      >
        {deployment.draft ? 'Setup' : 'Manage'}
      </Link>,
    )

    if (needsUpgrade && !isUpgradeBlocked) {
      actions.push(
        <Link
          key={deployment.id + '/upgrade'}
          to={paths.DEPLOYMENT_CONNECT.replace(':id', deployment.id)}
        >
          Upgrade
        </Link>,
      )
    }
  }

  const platforms: { name: string; icon: ReactNode }[] = []
  for (const [platform, values] of Object.entries(deployment.platforms)) {
    if (!Array.isArray(values) || !values.length) {
      continue
    }
    const platformIcon = mapPlatformTypeToIcon(platform as PlatformsType, { height: '12px' })

    for (const platformInstance of values) {
      if (!platformInstance?.name) {
        continue
      }

      platforms.push({ name: platformInstance.name, icon: platformIcon })
    }
  }

  return (
    <DeploymentSC
      displayManagementActions={displayManagementActions}
      title={
        title ?? (
          <>
            <Link
              key={deployment.id + '/manage'}
              to={
                deployment.draft
                  ? paths.DEPLOYMENT_ADD_RETURN.replace(':id', deployment.id).replace(
                      ':step',
                      DEPLOYMENT_ADD_STEP.CONNECT,
                    )
                  : paths.DEPLOYMENT.replace(':id', deployment.id)
              }
            >
              {deployment.name}
            </Link>
          </>
        )
      }
      extra={
        deployStatus ? (
          <Tag
            color={deployStatus.color}
            style={{ marginRight: 0 }}
          >
            {deployStatus.text}
          </Tag>
        ) : undefined
      }
      actions={actions}
    >
      {upgradeAlert}
      <Space
        size={'small'}
        wrap
      >
        {platforms.map((platform, i) => (
          <TagSC
            key={i}
            icon={platform.icon}
          >
            {platform.name}
          </TagSC>
        ))}
      </Space>
    </DeploymentSC>
  )
}
