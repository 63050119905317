interface ExtendedWindow extends Window {
  env?: RuntimeConfig
}

declare const window: ExtendedWindow

export interface RuntimeConfig {
  REACT_APP_BACKEND_BASE_URL: string
  REACT_APP_AUTH_DOMAIN: string
  REACT_APP_AUTH_CLIENT_ID: string
  REACT_APP_AUTH_AUDIENCE: string
  REACT_APP_SLACK_ROUTER_BASE_URL: string
  REACT_APP_TEAMS_APP_URL: string
  REACT_APP_PUBLIC_POSTHOG_KEY: string
  REACT_APP_PUBLIC_POSTHOG_HOST: string
  REACT_APP_SENTRY_DSN: string
  REACT_APP_HUBSPOT_TOKEN_ENABLED: string
  REACT_APP_CONTENTFUL_SPACE: string
  REACT_APP_CONTENTFUL_ACCESS_TOKEN: string
  REACT_APP_CONTENTFUL_ENV: string
  REACT_APP_DISPLAY_PRIVATE_CHANNEL_ALIAS: string
}

const defaultRuntimeConfig: RuntimeConfig = {
  REACT_APP_BACKEND_BASE_URL: 'http://localhost:8080',
  REACT_APP_SLACK_ROUTER_BASE_URL: 'http://localhost:9090',
  REACT_APP_AUTH_DOMAIN: 'botkube-dev.eu.auth0.com',
  REACT_APP_AUTH_CLIENT_ID: 'XxpHlah1VquJEPWHQOup6Mw0Ef7aLWt9',
  REACT_APP_AUTH_AUDIENCE: 'https://api-dev.botkube.io',
  REACT_APP_TEAMS_APP_URL: 'https://github.com/kubeshop/botkube-cloud/tree/main/assets/ms-teams',
  REACT_APP_PUBLIC_POSTHOG_KEY: '',
  REACT_APP_PUBLIC_POSTHOG_HOST: 'https://app.posthog.com',
  REACT_APP_SENTRY_DSN: '',
  REACT_APP_HUBSPOT_TOKEN_ENABLED: 'false',
  REACT_APP_CONTENTFUL_SPACE: 'sajwifddcyhh',
  REACT_APP_CONTENTFUL_ACCESS_TOKEN: 'jnlY9-knRVb5yVI9GghZXpp9bJdphHRO-SxXhgj8PmI',
  REACT_APP_CONTENTFUL_ENV: 'develop',
  REACT_APP_DISPLAY_PRIVATE_CHANNEL_ALIAS: 'true',
}
export function loadRuntimeConfig(): RuntimeConfig {
  return {
    ...defaultRuntimeConfig,
    ...process.env,
    ...window.env,
  }
}

export function shouldEnableHubspotToken(): boolean {
  return loadRuntimeConfig().REACT_APP_HUBSPOT_TOKEN_ENABLED === 'true'
}

export function shouldEnablePrivateChannelAlias(): boolean {
  return loadRuntimeConfig().REACT_APP_DISPLAY_PRIVATE_CHANNEL_ALIAS === 'true'
}
