import styled from 'styled-components'
import { Drawer, Button } from 'antd'
import { breakpoints } from '../../../styles/globalStyles'

export const DrawerSC = styled(Drawer)`
  .ant-drawer-title {
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      font-size: 20px;
      margin: 5px 0 0 5px;
      flex-shrink: 0;
    }
  }
`

export const DocumentationLinkSC = styled(Button)`
  @media (min-width: ${breakpoints.lgMin}px) {
    margin-right: 20px;
  }
`
