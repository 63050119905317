import React, { FC, useState } from 'react'
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, message, Modal, Typography } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { useDeletePlatformMutation, useDeploymentPageQuery } from '../../../models/graphql'
import { Platforms2PlatformType, PlatformsType } from '../../../models/platform/platform'
import {
  removePlatformFromDeployment,
  setDeploymentOrigin,
  setDeploymentPlatformsValidReport,
} from '../../../store/slices/deployment/deployment'

type Props = {
  platformId: string
  platformType: PlatformsType
  onDelete?: () => void
}
export const DeletePlatform: FC<Props> = ({ onDelete, platformId, platformType }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const deploymentOrigin = useAppSelector(state => state.deployment.original)
  const deployment = useAppSelector(state => state.deployment.data)
  const dispatch = useAppDispatch()

  const { refetch } = useDeploymentPageQuery(
    { id: deployment?.id ?? '' },
    {
      enabled: false,
      onSuccess: data => {
        dispatch((dispatch, getState) => {
          if (!data.deployment) {
            return
          }
          const { slackWorkspace } = getState()
          dispatch(setDeploymentOrigin({ deployment: data.deployment, slackWorkspaces: slackWorkspace.workspaces }))
        })
      },
    },
  )
  const { mutate: deletePlatform, isLoading: isLoadingDeletePlatform } = useDeletePlatformMutation({
    onSuccess: () => {
      void message.success(`Successfully deleted platform.`)
      if (typeof onDelete === 'function') {
        onDelete()
      }
      return refetch()
    },
  })

  const onRemovePlatform = () => {
    if (!deployment) {
      return
    }
    if (!Array.isArray(deployment.platforms[platformType])) {
      return
    }
    const platforms = deployment.platforms[platformType] as { id?: string }[]
    const platformsInOrigin = (deploymentOrigin?.platforms[platformType] ?? []) as { id?: string }[]
    const matchingPlatform = platforms.find(platform => platform.id === platformId)
    const matchingPlatformInOrigin = platformsInOrigin.find(platform => platform.id === platformId)
    if (!matchingPlatform) {
      return
    }

    const platformName = Platforms2PlatformType[platformType]

    if (matchingPlatformInOrigin) {
      deletePlatform({
        input: {
          [platformName]: {
            ID: platformId,
          },
        },
      })
    }
    dispatch(removePlatformFromDeployment(platformId))
    dispatch(setDeploymentPlatformsValidReport({ platformId, isValid: true }))
  }

  return (
    <>
      <Typography.Title level={4}>Delete platform</Typography.Title>
      <Typography.Paragraph>
        This action cannot be undone. Once the platform is removed, it cannot be restored.
      </Typography.Paragraph>
      <Button
        ghost={true}
        danger={true}
        icon={<DeleteOutlined />}
        onClick={() => setIsDeleteModalOpen(true)}
      >
        Delete platform
      </Button>
      <Modal
        width={700}
        title={
          <>
            <ExclamationCircleOutlined /> Delete Platform
          </>
        }
        onOk={() => {
          onRemovePlatform()
          setIsDeleteModalOpen(false)
        }}
        confirmLoading={isLoadingDeletePlatform}
        open={isDeleteModalOpen}
        onCancel={() => setIsDeleteModalOpen(false)}
        okText={'Delete Platform'}
        okButtonProps={{ danger: true }}
      >
        <p>Are you sure you want to delete this platform?</p>
        <p>
          <strong>This action cannot be undone.</strong> Once the platform is removed, it cannot be restored.
        </p>
      </Modal>
    </>
  )
}
