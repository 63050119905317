import { Form, Input, Row } from 'antd'
import { FC, useState } from 'react'
import { FormikProps } from 'formik'
import { getValidationError, getValidationStatus } from '../../../../utils/formik/formik'
import { MattermostFormValues } from '../../../../pages/clusters/add/steps/platformsConfiguration/utils'
import { ContentType } from '../../../../services/contentful/types'
import { PlatformContentInstruction } from '../../../../pages/clusters/add/steps/platformsConfiguration/contents/PlatformContentInstruction'
import { ContentColumnRight } from '../../../../pages/clusters/add/steps/platformsConfiguration/contents/columns/ContentColumnRight'
import { PlatformEmbeddedDocs } from '../../../../pages/clusters/add/steps/platformsConfiguration/contents/PlatformEmbeddedDocs'
import { ContentColumnLeft } from '../../../../pages/clusters/add/steps/platformsConfiguration/contents/columns/ContentColumnLeft'
import { InputTag } from '../../../../components/inputTag/InputTag'

export type MattermostInstanceFormValues = Omit<MattermostFormValues, 'channelNames'>

export type MattermostInstanceFormProps = {
  formik: FormikProps<MattermostFormValues>
}

export const MattermostInstanceForm: FC<MattermostInstanceFormProps> = ({ formik }) => {
  const [tokenVisible, setTokenVisible] = useState(false)

  return (
    <Row>
      <ContentColumnLeft>
        <PlatformContentInstruction platformInstructionType={ContentType.MATTERMOST_PLATFORM_INSTRUCTION} />
        <Form layout={'vertical'}>
          <Form.Item
            label={'Display Name'}
            required={true}
            validateStatus={getValidationStatus(formik.errors.name, formik.touched.name)}
            help={getValidationError(formik.errors.name, formik.touched.name)}
          >
            <Input
              name={`name`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              placeholder={'My Mattermost Name'}
            />
          </Form.Item>
          <Form.Item
            label={'Bot Name'}
            required={true}
            validateStatus={getValidationStatus(formik.errors.botName, formik.touched.botName)}
            help={getValidationError(formik.errors.botName, formik.touched.botName)}
          >
            <Input
              name={`botName`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.botName}
              placeholder={'Botkube'}
            />
          </Form.Item>
          <Form.Item
            label={'Personal Access Token'}
            required={true}
            validateStatus={getValidationStatus(formik.errors.token, formik.touched.token)}
            help={getValidationError(formik.errors.token, formik.touched.token)}
          >
            <Input.Password
              visibilityToggle={{ visible: tokenVisible, onVisibleChange: setTokenVisible }}
              name={`token`}
              type={'password'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.token}
              placeholder={'wmaa4eqi4ffq8f75nnc111zqre'}
            />
          </Form.Item>
          <Form.Item
            label={'Team Name'}
            required={true}
            validateStatus={getValidationStatus(formik.errors.team, formik.touched.team)}
            help={getValidationError(formik.errors.team, formik.touched.team)}
          >
            <Input
              name={`team`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.team}
              placeholder={'devops-team'}
            />
          </Form.Item>
          <Form.Item
            label={'Server URL'}
            required={true}
            validateStatus={getValidationStatus(formik.errors.url, formik.touched.url)}
            help={getValidationError(formik.errors.url, formik.touched.url)}
          >
            <Input
              name={`url`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.url}
              placeholder={'https://kube.cloud.mattermost.com/'}
            />
          </Form.Item>
          <Form.Item
            label={'Channel Name'}
            required={true}
            validateStatus={getValidationStatus(formik.errors.channelNames as string, formik.touched.channelNames)}
            help={getValidationError(formik.errors.channelNames as string, formik.touched.channelNames)}
          >
            <InputTag
              prefix={'#'}
              values={formik.values.channelNames}
              placeholder={'New channel'}
              onChange={channels => formik.setFieldValue(`channelNames`, channels)}
            />
          </Form.Item>
        </Form>
      </ContentColumnLeft>
      <ContentColumnRight>
        <PlatformEmbeddedDocs platformInstructionType={ContentType.MATTERMOST_PLATFORM_INSTRUCTION} />
      </ContentColumnRight>
    </Row>
  )
}
