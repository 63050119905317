import { Form, Input, Row } from 'antd'
import { FC, useState } from 'react'
import { FormikProps } from 'formik'
import { getValidationError, getValidationStatus } from '../../../../utils/formik/formik'
import { PagerDutyFormValues } from '../../../../pages/clusters/add/steps/platformsConfiguration/utils'
import { ContentType } from '../../../../services/contentful/types'
import { PlatformContentInstruction } from '../../../../pages/clusters/add/steps/platformsConfiguration/contents/PlatformContentInstruction'
import { ContentColumnRight } from '../../../../pages/clusters/add/steps/platformsConfiguration/contents/columns/ContentColumnRight'
import { PlatformEmbeddedDocs } from '../../../../pages/clusters/add/steps/platformsConfiguration/contents/PlatformEmbeddedDocs'
import { ContentColumnLeft } from '../../../../pages/clusters/add/steps/platformsConfiguration/contents/columns/ContentColumnLeft'

export type PagerDutyInstanceFormProps = {
  formik: FormikProps<PagerDutyFormValues>
}

export const PagerDutyInstanceForm: FC<PagerDutyInstanceFormProps> = ({ formik }) => {
  const [integrationKeyVisible, setIntegrationKeyVisible] = useState(false)
  const platformInstructionType = ContentType.PAGER_DUTY_PLATFORM_INSTRUCTION

  return (
    <Row>
      <ContentColumnLeft>
        <PlatformContentInstruction platformInstructionType={platformInstructionType} />
        <Form layout={'vertical'}>
          <Form.Item
            label={'Display Name'}
            required={true}
            validateStatus={getValidationStatus(formik.errors.name, formik.touched.name)}
            help={getValidationError(formik.errors.name, formik.touched.name)}
          >
            <Input
              name={`name`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              placeholder={'My PagerDuty integration'}
            />
          </Form.Item>
          <Form.Item
            label={'Integration Key'}
            required={true}
            validateStatus={getValidationStatus(formik.errors.integrationKey, formik.touched.integrationKey)}
            help={getValidationError(formik.errors.integrationKey, formik.touched.integrationKey)}
          >
            <Input.Password
              visibilityToggle={{
                visible: integrationKeyVisible,
                onVisibleChange: setIntegrationKeyVisible,
              }}
              name={`integrationKey`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.integrationKey}
            />
          </Form.Item>
        </Form>
      </ContentColumnLeft>
      <ContentColumnRight>
        <PlatformEmbeddedDocs platformInstructionType={platformInstructionType} />
      </ContentColumnRight>
    </Row>
  )
}
