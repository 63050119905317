import React, { FC, useState } from 'react'
import { Divider, Input, message, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { CodeBlock } from '../../../components/codeBlock/CodeBlock'
import { DeploymentPageQuery, useDeleteDeploymentMutation } from '../../../models/graphql'
import { useNavigate } from 'react-router-dom'
import { paths } from '../../../router/paths'

interface Props {
  deployment: NonNullable<DeploymentPageQuery['deployment']>
  isOpened: boolean
  setOpened: (isOpen: boolean) => void
}

export const DeleteDeployment: FC<Props> = ({ isOpened, setOpened, deployment }) => {
  const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false)
  const navigate = useNavigate()
  const { mutate: deleteDeploy, isLoading: isLoadingDeleteDeploy } = useDeleteDeploymentMutation({
    onSuccess: () => {
      navigate(paths.HOME)
      void message.success(`Successfully deleted instance ${deployment.name}.`)
    },

    onError: err => {
      const error = err as Error
      void message.error(`Failed to delete instance. Got error: ${error.name}: ${error.message}`)
    },
  })
  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsDeleteConfirmed(e.target.value.toLocaleLowerCase() === deployment.name.toLocaleLowerCase())
  }

  return (
    <Modal
      width={700}
      title={
        <>
          <ExclamationCircleOutlined /> Delete {deployment.name}
        </>
      }
      onOk={() => deleteDeploy({ id: deployment.id })}
      confirmLoading={isLoadingDeleteDeploy}
      open={isOpened}
      onCancel={() => setOpened(false)}
      okText={'Delete instance'}
      okButtonProps={{ danger: true, disabled: !isDeleteConfirmed }}
    >
      <p>
        If you have Botkube installed on your cluster, you <strong>must</strong> uninstall it first to clean up all
        Botkube resources.
        <>
          Use{' '}
          <a
            href={'https://docs.botkube.io/cli/getting-started'}
            target='_blank'
            rel={'noreferrer'}
          >
            Botkube CLI
          </a>{' '}
          you installed when connecting the instance, and run:
        </>
      </p>
      <CodeBlock>botkube uninstall</CodeBlock>
      <Divider />
      <p>
        Please type <b>{deployment.name}</b> below to confirm your intention.
      </p>
      <Input
        onChange={onChange}
        placeholder={'Instance Name'}
      />
    </Modal>
  )
}
