import { Tag, TagType } from '../../../components/tag/Tag'

export const getData = () => [
  {
    key: 1,
    resource: 'pods',
    triggers: ['Error', 'Create', 'Update'],
  },
  {
    key: 2,
    resource: 'statefulsets',
    triggers: ['Error', 'Create', 'Delete'],
  },
  {
    key: 3,
    resource: 'deployments',
    triggers: [],
  },
  {
    key: 4,
    resource: 'configmaps',
    triggers: ['Error', 'Create', 'Update', 'Delete'],
  },
  {
    key: 5,
    resource: 'secrets',
    triggers: ['Error', 'Update', 'Delete'],
  },
  {
    key: 6,
    resource: 'storage classes',
    triggers: ['Error', 'Create', 'Update', 'Delete'],
  },
]

export const setColumns = () => [
  {
    title: 'Resource',
    dataIndex: 'resource',
    key: 'resource',
  },
  {
    title: 'Event that triggers notification',
    dataIndex: 'triggers',
    key: 'triggers',
    render: (triggers: string[]) =>
      triggers.length > 0 ? (
        triggers.map(trigger => (
          <Tag
            key={trigger}
            type={TagType.SECONDARY}
          >
            {trigger}
          </Tag>
        ))
      ) : (
        <Tag type={TagType.DANGER}>Muted</Tag>
      ),
  },
]
