import { ContentSkeleton, ContentType } from '../../services/contentful/types'
import { useContext, useEffect, useState } from 'react'
import { EntryCollection } from 'contentful'
import { AppCtx } from '../../providers/appCtx'
import { CMSContent } from '../../services/contentful/contentful'

export const useContent = <T>(contentType: ContentType, multi = false) => {
  const appContext = useContext(AppCtx)
  const [content, setContent] = useState<EntryCollection<ContentSkeleton> | undefined>()

  useEffect(() => {
    if (!appContext.content) {
      return
    }

    const cmsContent = CMSContent.getContent(contentType, appContext.content, multi)
    // @ts-expect-error
    setContent(cmsContent)
  }, [appContext.content, contentType, multi])

  return content as T | undefined
}
