import { FormikConfig } from 'formik'
import { AddMemberFormProps, AddMemberFormValues } from './AddMember'
import * as yup from 'yup'
import { AnySchema } from 'yup'
import { ValidationMsg } from '../../../utils/validation'

export const initFormik = (props: AddMemberFormProps): FormikConfig<AddMemberFormValues> => {
  return {
    enableReinitialize: false,
    initialValues: {
      emails: [],
    },
    validationSchema: yup.object().shape<Record<keyof AddMemberFormValues, AnySchema>>({
      emails: yup
        .array()
        .of(yup.string().email(ValidationMsg.EMAIL))
        .min(1, ValidationMsg.MIN_ONE)
        .required(ValidationMsg.REQUIRED),
    }),
    onSubmit: values => {
      props.onSubmit(values)
    },
  }
}
