import styled from 'styled-components'
import { Form } from 'antd'

export const FormItemSC = styled(Form.Item)`
  .ant-form-item-label label {
    line-height: 1.2;
    font-weight: 600;
    font-size: 20px;
  }
`

export const BindPluginSC = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const PlatformTabLabelSC = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
