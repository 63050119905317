import styled from 'styled-components'

export const IconSC = styled.span`
  display: inline-block;

  svg {
    height: 16px;
    margin-right: 5px;
    position: relative;
    top: 2px;
  }
`
