import { FC, useEffect } from 'react'
import { Alert, Modal } from 'antd'
import { useSearchParams } from 'react-router-dom'
import { DEPLOYMENT_CONNECT_MODAL_SEARCH_PARAM } from '../../../router/paths'
import { DeploymentPage } from '../../../pages/clusters/utils'
import { InstallUpgradeInstructions } from './InstallUpgradeInstructions'

export type ConnectionInstructionsProps = {
  isOpened: boolean
  setOpened: (open: boolean) => void
  installUpgradeInstructions: DeploymentPage['installUpgradeInstructions']
  refetchDeployment: () => Promise<unknown>
}

export const ConnectionInstructions: FC<ConnectionInstructionsProps> = ({
  setOpened,
  isOpened,
  installUpgradeInstructions,
  refetchDeployment,
}) => {
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (isOpened) {
      void refetchDeployment()
    }
  }, [isOpened, refetchDeployment])

  const handleHelmCmdOpen = (isOpen: boolean) => {
    setOpened(isOpen)
    searchParams.set(DEPLOYMENT_CONNECT_MODAL_SEARCH_PARAM, String(isOpen))
    setSearchParams(searchParams)
  }

  return (
    <Modal
      width={800}
      title={'Connect Botkube instance'}
      open={isOpened}
      cancelButtonProps={{ style: { display: 'none' } }}
      onOk={() => handleHelmCmdOpen(false)}
      onCancel={() => handleHelmCmdOpen(false)}
    >
      {!installUpgradeInstructions?.length && (
        <Alert
          type={'error'}
          showIcon
          message={'Failed to get command for cluster connection. Reload the page and try again.'}
        />
      )}
      {installUpgradeInstructions && (
        <InstallUpgradeInstructions
          data={installUpgradeInstructions}
          action={'install or upgrade'}
        />
      )}
    </Modal>
  )
}
