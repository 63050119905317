import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import deepClone from 'deep-clone'

import { AddPluginValues, Deployment, DeploymentHealth, DeploymentUpdateBlock, DeploymentUpdateReport } from './model'
import {
  isDeploymentClean,
  isDeploymentUpdateValid,
  deleteChannelInPlatform,
  setDeploymentPlatformsValidReportUtils,
  addChannelsToPlatformUtils,
  updateDeploymentPlatforms,
  setDeploymentPluginsValidReportUtils,
  removePluginFromDeploymentUtil,
  addPluginToDeploymentUtil,
  changePluginConfigurationUtil,
  addNewPlatformUtil,
  removePlatformFromDeploymentUtil,
  removeActionFromDeploymentUtil,
  updateActionInDeploymentUtil,
  updateDeploymentWithCloudSlackChannels,
} from './utils'
import { PlatformsType } from '../../../models/platform/platform'
import { PlatformsFormValuesWithChannels } from '../../../pages/clusters/add/steps/platformsConfiguration/utils'
import { findUniquePluginConfigDisplayName } from '../../../utils/plugins'
import { PluginConfigData } from '../../../components/plugin/configuration/utils'
import { PluginGeneralFormValues } from '../../../components/plugin/configuration/content/overview/PluginGeneralForm'
import { RBACFormValues } from '../../../components/plugin/configuration/content/rbac/PluginRBAC'
import { AddEditActionFormValues } from '../../../forms/instance/actions/AddEditAction'
import { setWorkspaces } from '../slackWorkspace/slackWorkspace'
import { SlackWorkspace } from '../slackWorkspace/model'

export type DeploymentState = {
  data: Deployment | null
  original: Deployment | null //This one is from API and is used just for checking isDeploymentClean
  health: DeploymentHealth | null
  updateValidReport: DeploymentUpdateReport
  updateBlock: DeploymentUpdateBlock
  isClean: boolean
  isUpdateValid: boolean
}

const initialState: DeploymentState = {
  data: null,
  original: null,
  health: null,
  updateValidReport: {},
  updateBlock: {
    outdated: {
      isBlocked: false,
    },
    needUpgrade: {
      isBlocked: false,
    },
    org: {
      isBlocked: false,
    },
    isBlocked: false,
  },
  isClean: true,
  isUpdateValid: true,
}

export const deploymentSlice = createSlice({
  name: 'deployment',
  initialState,
  reducers: {
    setDeploymentOrigin: (
      state,
      action: PayloadAction<{ deployment: Deployment; slackWorkspaces: SlackWorkspace[] }>,
    ) => {
      state.original = updateDeploymentWithCloudSlackChannels(action.payload.deployment, action.payload.slackWorkspaces)
      state.data = updateDeploymentWithCloudSlackChannels(action.payload.deployment, action.payload.slackWorkspaces)
      state.isClean = true
    },
    setDeploymentName: (state, action: PayloadAction<string>) => {
      if (!state.data || !state.original) {
        return
      }
      state.data.name = action.payload
      state.isClean = isDeploymentClean(state.original, state.data)
    },
    removeChannelFromPlatform: (state, action: PayloadAction<{ platformId: string; channel: string }>) => {
      if (!state.data || !state.original) {
        return
      }
      state.data = deleteChannelInPlatform(state.data, action.payload.platformId, action.payload.channel)
      state.isClean = isDeploymentClean(state.original, state.data)
    },
    addChannelToPlatform: (
      state,
      action: PayloadAction<{ platformType: PlatformsType; platformId: string; channels: string[] }>,
    ) => {
      if (!state.data || !state.original) {
        return
      }
      state.data = addChannelsToPlatformUtils(
        state.data,
        action.payload.platformType,
        action.payload.platformId,
        action.payload.channels,
      )
      state.isClean = isDeploymentClean(state.original, state.data)
    },
    addPlatformToDeployment: (
      state,
      action: PayloadAction<{
        platformType: PlatformsType
        values?: PlatformsFormValuesWithChannels
        callback?: (platformId: string) => void
      }>,
    ) => {
      if (!state.data || !state.original) {
        return
      }
      const { deployment, platformId } = addNewPlatformUtil(
        state.data,
        action.payload.platformType,
        action.payload.values,
      )
      state.data = action.payload.values
        ? updateDeploymentPlatforms(state.data, action.payload.platformType, action.payload.values)
        : deployment
      state.isClean = isDeploymentClean(state.original, state.data)
      if (action.payload.callback) {
        action.payload.callback(platformId)
      }
    },
    removePlatformFromDeployment: (state, action: PayloadAction<string>) => {
      if (!state.data || !state.original) {
        return
      }
      state.data = removePlatformFromDeploymentUtil(state.data, action.payload)
      state.isClean = isDeploymentClean(state.original, state.data)
    },
    updatePlatformInDeployment: (
      state,
      action: PayloadAction<{
        type: PlatformsType
        values: PlatformsFormValuesWithChannels
      }>,
    ) => {
      if (!state.data || !state.original) {
        return
      }
      state.data = updateDeploymentPlatforms(state.data, action.payload.type, action.payload.values)
      state.isClean = isDeploymentClean(state.original, state.data)
    },
    removePluginFromDeployment: (state, action: PayloadAction<{ pluginId: string }>) => {
      if (!state.data || !state.original) {
        return
      }
      state.data = removePluginFromDeploymentUtil(state.data, action.payload.pluginId)
      state.isClean = isDeploymentClean(state.original, state.data)
      if (!state.updateValidReport.plugins) {
        return
      }
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete state.updateValidReport.plugins[action.payload.pluginId]
    },
    addPluginToDeployment: (
      state,
      action: PayloadAction<{
        values: AddPluginValues
        additionalValues: {
          pluginId: string
          config: PluginConfigData
          generalSettings: PluginGeneralFormValues
          rbac?: RBACFormValues
        }
        platformId?: string
      }>,
    ) => {
      if (!state.data || !state.original) {
        return
      }
      const clonedValues = deepClone<AddPluginValues>(action.payload.values)
      clonedValues.displayName = findUniquePluginConfigDisplayName(
        action.payload.values.displayName,
        state.data.plugins,
      )

      state.data = addPluginToDeploymentUtil(state.data, clonedValues, action.payload.platformId)
      state.data = changePluginConfigurationUtil(
        state.data,
        action.payload.additionalValues.pluginId,
        action.payload.additionalValues.config,
        action.payload.additionalValues.generalSettings,
        action.payload.additionalValues.rbac,
      )
      state.isClean = isDeploymentClean(state.original, state.data)
    },
    updatePluginConfiguration: (
      state,
      action: PayloadAction<{
        pluginId: string
        config: PluginConfigData
        generalSettings: PluginGeneralFormValues
        rbac?: RBACFormValues
      }>,
    ) => {
      if (!state.data || !state.original) {
        return
      }
      state.data = changePluginConfigurationUtil(
        state.data,
        action.payload.pluginId,
        action.payload.config,
        action.payload.generalSettings,
        action.payload.rbac,
      )
      state.isClean = isDeploymentClean(state.original, state.data)
    },
    removeActionFromDeployment: (state, action: PayloadAction<string>) => {
      if (!state.data || !state.original) {
        return
      }
      state.data = removeActionFromDeploymentUtil(state.data, action.payload)
      state.isClean = isDeploymentClean(state.original, state.data)
    },
    updateActionInDeployment: (
      state,
      action: PayloadAction<{ values: AddEditActionFormValues; callback?: () => void }>,
    ) => {
      if (!state.data || !state.original) {
        return
      }
      state.data = updateActionInDeploymentUtil(state.data, action.payload.values)
      if (typeof action.payload.callback === 'function') {
        action.payload.callback()
      }
      state.isClean = isDeploymentClean(state.original, state.data)
    },
    discardDeploymentChanges: state => {
      state.data = state.original
      state.isClean = true
    },
    setDeploymentPlatformsValidReport: (
      state,
      action: PayloadAction<{ platformId: string; isValid: boolean; reason?: string }>,
    ) => {
      state.updateValidReport = setDeploymentPlatformsValidReportUtils(
        state.updateValidReport,
        action.payload.platformId,
        action.payload.isValid,
        action.payload.reason,
      )
      state.isUpdateValid = isDeploymentUpdateValid(state.updateValidReport)
    },
    setDeploymentPluginsValidReport: (state, action: PayloadAction<{ pluginId: string; isValid: boolean }>) => {
      state.updateValidReport = setDeploymentPluginsValidReportUtils(
        state.updateValidReport,
        action.payload.pluginId,
        action.payload.isValid,
      )
      state.isUpdateValid = isDeploymentUpdateValid(state.updateValidReport)
    },
    setDeploymentUpdateBlockOutdated: (state, action: PayloadAction<{ isBlocked: boolean; reason?: string }>) => {
      state.updateBlock = {
        ...state.updateBlock,
        outdated: {
          isBlocked: action.payload.isBlocked,
          reason: action.payload.reason,
        },
      }
      state.updateBlock.isBlocked =
        state.updateBlock.outdated.isBlocked ||
        state.updateBlock.needUpgrade.isBlocked ||
        state.updateBlock.org.isBlocked
    },
    setDeploymentUpdateBlockNeedUpgrade: (state, action: PayloadAction<{ isBlocked: boolean; reason?: string }>) => {
      state.updateBlock = {
        ...state.updateBlock,
        needUpgrade: {
          isBlocked: action.payload.isBlocked,
          reason: action.payload.reason,
        },
      }
      state.updateBlock.isBlocked =
        state.updateBlock.outdated.isBlocked ||
        state.updateBlock.needUpgrade.isBlocked ||
        state.updateBlock.org.isBlocked
    },
    setDeploymentUpdateBlockOrg: (state, action: PayloadAction<{ isBlocked: boolean; reason?: string }>) => {
      state.updateBlock = {
        ...state.updateBlock,
        org: {
          isBlocked: action.payload.isBlocked,
          reason: action.payload.reason,
        },
      }
      state.updateBlock.isBlocked =
        state.updateBlock.outdated.isBlocked ||
        state.updateBlock.needUpgrade.isBlocked ||
        state.updateBlock.org.isBlocked
    },
    setDeploymentHealth: (state, action: PayloadAction<DeploymentHealth>) => {
      state.health = {
        ...action.payload,
        platforms: action.payload.platforms.map(platform => {
          return {
            key: platform?.key?.replace(
              /(-cloudTeams|-cloudSlack|-socketSlack|-discord|-mattermost|-webhook|-elasticsearch|-pagerDuty)/,
              '',
            ),
            value: platform?.value,
          }
        }),
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(setWorkspaces, (state, action) => {
      if (!state.original || !state.data) {
        return
      }
      state.original = updateDeploymentWithCloudSlackChannels(state.original, action.payload)
      state.data = updateDeploymentWithCloudSlackChannels(state.data, action.payload)
    })
  },
})

export const {
  setDeploymentOrigin,
  setDeploymentName,
  discardDeploymentChanges,
  setDeploymentHealth,
  setDeploymentUpdateBlockOutdated,
  setDeploymentUpdateBlockNeedUpgrade,
  setDeploymentUpdateBlockOrg,
  removeChannelFromPlatform,
  addChannelToPlatform,
  updatePlatformInDeployment,
  addPlatformToDeployment,
  removePlatformFromDeployment,
  removePluginFromDeployment,
  addPluginToDeployment,
  updatePluginConfiguration,
  removeActionFromDeployment,
  updateActionInDeployment,
  setDeploymentPlatformsValidReport,
  setDeploymentPluginsValidReport,
} = deploymentSlice.actions
