import { CaretDownOutlined } from '@ant-design/icons'
import { User } from '@auth0/auth0-react'
import { paths } from '../../router/paths'
import { MenuComponentQuery } from '../../models/graphql'
import { OrgMenuItem } from './OrgMenuItem'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { LogoutLink } from './LogoutLink'
import { getCurrentSelectedOrganization } from '../../utils/organizations'
import { shortenOrgDisplayName } from '../../pages/organization/utils'
import { SpinnerSC, TagSC } from './styles'
import { breakpoints } from '../../styles/globalStyles'

type MenuComponentData = MenuComponentQuery['deployments']['data'][number]
type Organizations = Exclude<MenuComponentQuery['organizations'], null | undefined>

type MenuItemArgs = {
  deployments: MenuComponentData[]
  organizations: Organizations
  user: User
  width: number
  onOrganizationSwitch: (orgId: string) => void
  showFuse?: boolean
}

export const menuItems = ({
  deployments,
  organizations,
  user,
  width,
  onOrganizationSwitch,
  showFuse,
}: MenuItemArgs): ItemType[] => {
  const currentOrganization = getCurrentSelectedOrganization(organizations, user) as Organizations[number] | undefined

  return [
    { label: 'Home', key: paths.HOME },
    {
      label: <>Instances {width > breakpoints.lgMin ? <CaretDownOutlined /> : null}</>,
      disabled: deployments.length === 0,
      key: paths.DEPLOYMENT,
      children: deployments.map(deployment => ({
        label: deployment.name,
        key: paths.DEPLOYMENT.replace(':id', deployment.id),
      })),
    },
    showFuse
      ? {
          label: (
            <>
              Fuse <TagSC>new</TagSC>
            </>
          ),
          key: paths.FUSE,
        }
      : null,
    { label: 'AI Assistant', key: paths.AI_ASSISTANT },
    { label: 'Aliases', key: paths.ALIASES },
    { label: 'Audit logs', key: paths.AUDIT_LOGS },
    {
      label: <OrgMenuItem currentOrganizationName={currentOrganization?.displayName} />,
      key: 'account-submenu',
      children: getChildrenForAccountSubmenu(organizations, onOrganizationSwitch, currentOrganization),
    },
  ]
}

const getChildrenForAccountSubmenu = (
  organizations: Organizations,
  onOrgSwitch: (orgId: string) => void,
  currentOrganization?: Organizations[number],
): ItemType[] => {
  const otherOrganizations = organizations.filter(orgItem => orgItem.id != currentOrganization?.id)

  if (!currentOrganization) {
    return [
      { label: <SpinnerSC size='small' />, key: 'spinner' },
      { type: 'divider' },
      { label: <LogoutLink />, key: 'logout' },
    ]
  }

  const children: ItemType[] = [
    {
      type: 'group',
      label: shortenOrgDisplayName(currentOrganization.displayName),
      children: [
        {
          label: `Manage organization`,
          key: paths.ORGANIZATION.replace(':id', currentOrganization.id),
        },
        {
          label: currentOrganization.subscription.isDefaultPlan ? 'Upgrade plan' : 'Manage subscription',
          key: paths.SUBSCRIPTION_PLANS.replace(':id', currentOrganization.id),
        },
      ],
    },
  ]
  if (otherOrganizations.length) {
    children.push({
      label: 'Switch organization',
      key: 'switch-organization',
      children: otherOrganizations.map(org => ({
        label: shortenOrgDisplayName(org.displayName),
        key: `other-organization-${org.id}`,
        onClick: () => onOrgSwitch(org.id),
      })),
    })
  }
  children.push({ type: 'divider' })
  children.push({ label: <LogoutLink />, key: 'logout' })
  return children
}
