import { FC, memo } from 'react'
import { Col, Row } from 'antd'
import { CloudSlackFormValues } from '../../../../pages/clusters/add/steps/platformsConfiguration/utils'
import { FormikErrors, FormikHandlers, FormikHelpers, FormikTouched } from 'formik'
import { CloudSlackForm } from './CloudSlackForm'
import { ConnectedSlackPlatformsQuery } from '../../../../models/graphql'
import { Spinner } from '../../../../pages/login/styles'
import { CloudSlackContentInstructions } from './CloudSlackInstructions'

export type SlackContentProps = {
  values: CloudSlackFormValues
  errors?: FormikErrors<CloudSlackFormValues>
  touched?: FormikTouched<CloudSlackFormValues>
  handleChange: FormikHandlers['handleChange']
  setFieldValue: FormikHelpers<CloudSlackFormValues>['setFieldValue']
  isSubmitted: boolean
  fieldNamePrefix?: string
  onBlur?: () => void
  disabled?: boolean
  isSlackWorkspaceLoading: boolean
  connectedPlatforms: Exclude<
    NonNullable<ConnectedSlackPlatformsQuery['organization']>['connectedPlatforms'],
    null | undefined
  >['slacks']
}

export const CloudSlackContentInner: FC<SlackContentProps> = props => {
  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    fieldNamePrefix,
    isSubmitted,
    onBlur,
    disabled,
    isSlackWorkspaceLoading,
    connectedPlatforms,
  } = props

  return (
    <section>
      <Row>
        <Col xl={9}>
          <CloudSlackContentInstructions />
        </Col>
      </Row>
      <Spinner spinning={isSlackWorkspaceLoading}>
        <CloudSlackForm
          connectedPlatforms={connectedPlatforms}
          fieldNamePrefix={fieldNamePrefix}
          values={values}
          errors={errors}
          touched={touched}
          disabled={disabled}
          onBlur={onBlur}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          isSubmitted={isSubmitted}
        />
      </Spinner>
    </section>
  )
}

export const CloudSlackContent = memo(CloudSlackContentInner)
