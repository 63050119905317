import React, { FC, ReactNode } from 'react'
import { provideReasonsForBlockedUpdate } from '../../utils/deployment'
import { WrapWithOptionalTooltip } from './WrapWithOptionalTooltip'
import { useAppSelector } from '../../store/store'

type Props = {
  children?: ReactNode
}
export const ReasonForBlockUpdateTooltip: FC<Props> = ({ children }) => {
  const deploymentUpdateBlock = useAppSelector(state => state.deployment.updateBlock)
  const deploymentUpdateValidReport = useAppSelector(state => state.deployment.updateValidReport)

  return (
    <WrapWithOptionalTooltip
      tooltip={provideReasonsForBlockedUpdate(deploymentUpdateBlock, deploymentUpdateValidReport)}
    >
      {children}
    </WrapWithOptionalTooltip>
  )
}
