import { FC } from 'react'
import { ConnectStep } from './connect/ConnectStep'
import { PlatformsConfigurationStep } from './platformsConfiguration/PlatformsConfigurationStep'
import { PluginsStep } from './plugins/PluginsStep'
import { ReviewStep } from './review/ReviewStep'
import { DeploymentAddStep, WizardFormValues } from '../DeploymentAddPage'
import { useUpdateDeploymentMutation } from '../../../../models/graphql'
import { useNavigate } from 'react-router-dom'
import { paths } from '../../../../router/paths'
import { DeploymentPage } from '../../utils'
import { updateDeploymentInsideWizard } from '../utils'

type Props = {
  wizardValues: WizardFormValues
  setWizardValues: (values: WizardFormValues) => void
  refetchDeployment: () => Promise<unknown>
  deployment: DeploymentPage
  step: DeploymentAddStep
  setStep: (step: number) => void
}

export const WizardSteps: FC<Props> = ({
  refetchDeployment,
  step,
  setStep,
  deployment,
  wizardValues,
  setWizardValues,
}) => {
  const navigate = useNavigate()
  const { mutate: updateDeployment, isLoading: deploymentMutationLoading } = useUpdateDeploymentMutation({
    onSuccess: () => refetchDeployment(),
  })

  switch (step) {
    case DeploymentAddStep.CONNECTION:
      return (
        <ConnectStep
          deployment={deployment}
          setStep={setStep}
        />
      )
    case DeploymentAddStep.PLATFORMS_CONFIG:
      return (
        <PlatformsConfigurationStep
          isLoading={deploymentMutationLoading}
          wizardValues={wizardValues}
          setStep={setStep}
          onSubmit={values => {
            updateDeploymentInsideWizard({
              deployment,
              wizardValues,
              platforms: values,
              plugins: wizardValues.pluginsStep,
              updateDeployment,
              onSuccess: () => {
                setWizardValues({ ...wizardValues, platformsStep: values })
                setStep(DeploymentAddStep.PLUGINS)
              },
            })
          }}
        />
      )
    case DeploymentAddStep.PLUGINS:
      return (
        <PluginsStep
          isLoading={deploymentMutationLoading}
          setStep={setStep}
          wizardValues={wizardValues}
          onSubmit={values => {
            updateDeploymentInsideWizard({
              deployment,
              wizardValues,
              platforms: wizardValues.platformsStep,
              plugins: values,
              updateDeployment,
              onSuccess: () => {
                setWizardValues({ ...wizardValues, pluginsStep: values })
                setStep(DeploymentAddStep.CONFIG)
              },
            })
          }}
        />
      )
    case DeploymentAddStep.CONFIG:
      return (
        <ReviewStep
          wizardValues={wizardValues}
          isLoading={deploymentMutationLoading}
          setStep={setStep}
          onSubmit={(defaultAliases, defaultActions) => {
            updateDeploymentInsideWizard({
              deployment,
              wizardValues,
              platforms: wizardValues.platformsStep,
              plugins: wizardValues.pluginsStep,
              draft: false,
              defaultActions,
              defaultAliases,
              updateDeployment,
              onSuccess: () => {
                navigate(paths.DEPLOYMENT.replace(':id', deployment.id))
              },
            })
          }}
        />
      )
    default:
      return null
  }
}
