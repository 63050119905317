import { UiSchema } from '@rjsf/utils'

export const defaultUISchema: UiSchema = {
  'ui:submitButtonOptions': {
    props: {
      disabled: false,
      className: 'ant-btn ant-btn-primary submit-cfg',
    },
    norender: false,
    submitText: 'Submit',
  },
}

enum PluginNames {
  KUBERNETES = 'kubernetes',
}
const uiFormDisabled: Record<string, boolean> = {
  [PluginNames.KUBERNETES]: true,
}

export const isUIFormDisabled = (pluginName: string) => {
  return Boolean(uiFormDisabled[pluginName.toLowerCase()])
}
