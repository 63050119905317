import { FormikConfig } from 'formik'
import * as yup from 'yup'
import { AnySchema } from 'yup'
import { ValidationMsg } from '../../../../utils/validation'
import { PlatformsType } from '../../../../models/platform/platform'
import { Mattermost } from '../../../../models/graphql'
import { HandlePlatformFormSubmit } from '../../../../components/platform/settings/utils'
import { MattermostFormValues } from '../../../../pages/clusters/add/steps/platformsConfiguration/utils'

type Props = {
  values?: Mattermost
  onSubmit: HandlePlatformFormSubmit
}
export const initFormik = (props: Props): FormikConfig<MattermostFormValues> => {
  return {
    enableReinitialize: true,
    initialValues: {
      id: props.values?.id ?? '',
      name: props.values?.name ?? '',
      botName: props.values?.botName ?? '',
      url: props.values?.url ?? '',
      team: props.values?.team ?? '',
      token: props.values?.token ?? '',
      channelNames: props.values?.channels.map(ch => ch.name) ?? [],
    },
    validationSchema: yup.object().shape<Record<keyof MattermostFormValues, AnySchema>>({
      id: yup.string(),
      name: yup.string().required(ValidationMsg.REQUIRED),
      botName: yup.string().required(ValidationMsg.REQUIRED),
      url: yup.string().required(ValidationMsg.REQUIRED),
      team: yup.string().required(ValidationMsg.REQUIRED),
      token: yup.string().required(ValidationMsg.REQUIRED),
      channelNames: yup.array().of(yup.string()).min(1, ValidationMsg.MIN_ONE).required(ValidationMsg.REQUIRED),
    }),
    onSubmit: values => {
      props.onSubmit(PlatformsType.MATTERMOST, values)
    },
  }
}
