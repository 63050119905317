import { createRef, FC } from 'react'
import validator from '@rjsf/validator-ajv8'
import Form, { IChangeEvent } from '@rjsf/core'
import { RJSFSchema } from '@rjsf/utils'
import { FormSC } from './styles'
import { defaultUISchema } from './utils'
import { PluginConfigData } from '../../utils'

type Props = {
  schema: RJSFSchema
  pluginConfigData: PluginConfigData
  onChange: (data: IChangeEvent) => void
}

export const UIForm: FC<Props> = ({ schema, pluginConfigData, onChange }) => {
  const formRef = createRef<Form>()

  const customUiSchema = (schema.uiSchema ?? {}) as Record<string, unknown>

  const uiSchema = {
    ...customUiSchema,
    ...defaultUISchema,
  }

  return (
    <FormSC
      ref={formRef}
      hideSubmitButton={true}
      onChange={onChange}
      liveValidate={true}
      schema={schema}
      uiSchema={uiSchema}
      formData={pluginConfigData}
      validator={validator}
      focusOnFirstError={true}
    />
  )
}
