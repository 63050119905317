import React, { useContext } from 'react'
import { Button, message, Popconfirm, Progress, Spin, Tooltip } from 'antd'
import { MachineTableSC } from './styles'
import { useDisconnectMachineMutation, useListMachinesQuery } from '../../models/graphql'
import { AppCtx } from '../../providers/appCtx'
import { InfoCircleOutlinedSC, ProgressIndicatorSC, ProgressValueSC } from '../organization/styles'
import { ColumnsType } from 'antd/lib/table'
import { FUSE_URL } from './config'

export type MachineEntry = {
  id: string
  fingerprint: string
  aiMonthlyTokenUsage: number | null | undefined
  aiMonthlyTokenLimit: number
}

const getMachineColumns = (
  handleDisconnect: (machineFingerprint: string) => Promise<void>,
): ColumnsType<MachineEntry> => {
  return [
    {
      title: (
        <>
          Machine fingerprint{' '}
          <Tooltip title={'Anonymous identifier of a host machine where Botkube Fuse CLI is used.'}>
            <InfoCircleOutlinedSC />
          </Tooltip>
        </>
      ),
      dataIndex: 'fingerprint',
      sorter: (a: MachineEntry, b: MachineEntry) => a.fingerprint.localeCompare(b.fingerprint),
      render: (_: string, machine: MachineEntry) => {
        return <code>{machine.fingerprint}</code>
      },
    },
    {
      title: (
        <>
          AI Token usage{' '}
          <Tooltip
            title={
              'The number of tokens that can be used for AI requests per each calendar month. 1 token ~= 4 English characters, 1-2 sentence(s) ~= 30 tokens.'
            }
          >
            <InfoCircleOutlinedSC />
          </Tooltip>
        </>
      ),
      render: (_: string, machine: MachineEntry) => {
        const value = machine.aiMonthlyTokenUsage
        const limit = machine.aiMonthlyTokenLimit

        let percentage
        let status: 'normal' | 'exception' | 'active' | 'success' = 'normal'
        if (limit === -1) {
          percentage = 0
        } else {
          percentage = Math.round(((value ?? 0) / limit) * 100)
        }

        const limitFormatted = limit === -1 ? '∞' : limit

        if (percentage > 100) {
          percentage = 100
          status = 'exception'
        }

        return (
          <>
            <ProgressIndicatorSC>
              <Progress
                status={status}
                size={'small'}
                showInfo={false}
                percent={percentage}
              />
            </ProgressIndicatorSC>
            <ProgressValueSC exceeded={status === 'exception'}>
              {value} / {limitFormatted}
            </ProgressValueSC>
          </>
        )
      },
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      render: (_: string, machine: MachineEntry) => {
        return (
          <Popconfirm
            title={'Do you really want to disconnect the machine?'}
            placement={'topRight'}
            onConfirm={() => void handleDisconnect(machine.fingerprint)}
            okText={'Disconnect'}
            okButtonProps={{ danger: true }}
            cancelText={'Cancel'}
          >
            <Button
              type={'link'}
              danger={true}
            >
              Disconnect
            </Button>
          </Popconfirm>
        )
      },
    },
  ]
}

export const ConnectedMachines = () => {
  const { orgID } = useContext(AppCtx)
  const { data, isLoading, refetch } = useListMachinesQuery()
  const { mutateAsync, isLoading: mutateIsLoading } = useDisconnectMachineMutation()

  const tableData = data?.machines?.map(machine => ({
    id: machine.id,
    fingerprint: machine.machineFingerprint,
    aiMonthlyTokenUsage: machine.aiMonthlyTokenUsage,
    aiMonthlyTokenLimit: machine.aiMonthlyTokenQuota,
  }))

  const handleDisconnect = async (machineFingerprint: string) => {
    await mutateAsync({
      input: {
        machineFingerprint,
        organizationId: orgID,
      },
    })
    void message.success('Machine successfully disconnected')
    void refetch()
  }

  return (
    <Spin
      spinning={isLoading || mutateIsLoading}
      tip='Loading'
    >
      <h2 style={{ marginTop: '50px' }}>Connected Machines</h2>
      <p>
        You can connect new <a href={FUSE_URL}>Fuse CLI</a> installations by running the <code>fuse login</code>{' '}
        command.
      </p>
      <MachineTableSC
        columns={getMachineColumns(handleDisconnect)}
        dataSource={tableData}
        rowKey={'id'}
      />
    </Spin>
  )
}
