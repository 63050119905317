import styled from 'styled-components'
import { Tag } from 'antd'

export const DeploymentStatusSC = styled.div`
  display: flex;
  align-items: center;
`

export const DeploymentStatusTagSC = styled(Tag)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  height: 32px;
  padding-left: 16px;
  padding-right: 16px;

  .anticon {
    margin-top: -2px;
  }
`
