import styled from 'styled-components'
import { Card } from 'antd'

export type AddCardSCProps = { disabled?: boolean }

const disabledColor = `rgba(0, 0, 0, 0.25);`

export const AddCardSC = styled(Card)<AddCardSCProps>`
  cursor: pointer;
  height: 100%;

  ${({ disabled }) =>
    disabled &&
    `
  cursor: not-allowed;
  color: ${disabledColor};
  border-color: #ccc;
  background: #f5f5f5;
  `}

  .ant-card-body {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 2rem;

    h6 {
      font-weight: 600;
      font-size: 1rem;

      ${({ disabled }) => disabled && `color: ${disabledColor};`}
    }

    p {
      font-size: 0.75rem;
    }

    svg {
      fill: #1890ff;

      ${({ disabled }) => disabled && `fill: ${disabledColor};`}
    }
  }
`

export const AddCardDeploymentSC = styled(AddCardSC)`
  cursor: pointer;

  .ant-card-body {
    display: block;
  }

  .ant-space-vertical {
    width: 100%;
  }

  a[href] {
    display: block;
  }

  h6,
  p,
  .ant-divider {
    margin: 0;
  }
`

export const VariantsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 20px;
`
