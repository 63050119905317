import styled from 'styled-components'
import { Typography } from 'antd'

type Props = {
  $light: boolean
}

export const CodeBlockSC = styled(Typography.Paragraph)<Props>`
  display: block;
  color: ${props => (props.$light ? 'rgba(0,0,0, 0.45)' : '#fff')};
  padding: 8px 36px 8px 12px;
  position: relative;
  white-space: pre-wrap;
  border-radius: 4px;
  background: ${props => (props.$light ? 'transparent' : '#000')};
  border: ${props => (props.$light ? '1px solid #D9D9D9' : 'none')};

  &.ant-typography {
    margin-bottom: 0;
  }
  &.mb-20 {
    margin-bottom: 20px;
  }

  kbd {
    margin: 0;
    padding: 0;
    background: transparent;
    border: none;
  }

  svg {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    font-size: 16px;
    cursor: pointer;
  }
`
