import { FC, ReactNode } from 'react'
import { ActionsSC, HelpTextSC, WizardStepFooterSC } from './styles'
import { Typography } from 'antd'

const { Text } = Typography

type Props = {
  children?: ReactNode
  helpMessage?: ReactNode
}

export const WizardStepFooter: FC<Props> = ({ helpMessage, children }) => {
  if (!helpMessage && !children) {
    return null
  }

  return (
    <WizardStepFooterSC>
      <ActionsSC>{children}</ActionsSC>
      {helpMessage && (
        <HelpTextSC>
          <Text type={'secondary'}>{helpMessage}</Text>
        </HelpTextSC>
      )}
    </WizardStepFooterSC>
  )
}
