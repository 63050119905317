import React, { FC, useCallback, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { Button, Flex, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { PlusOutlined, SettingOutlined } from '@ant-design/icons'
import { isSinkPlatform, PlatformsType } from '../../../models/platform/platform'
import { PluginType } from '../../../models/graphql'
import { PluginTag } from '../tag/PluginTag'
import { PluginUsedInPlatforms } from './PluginUsedInPlatforms'
import { PluginConfiguration } from '../configuration/PluginConfiguration'
import { PluginBindings } from '../../../pages/clusters/add/steps/plugins/PluginsStep'
import {
  getBindingsPluginToPlatformsForDeploymentPage,
  getPluginConfigurationConnections,
  getPluginsFromDeploymentForDeploymentPage,
  getPluginTemplate,
  PluginValueDeploymentPage,
} from '../../../utils/plugins'
import { useMSTeamsChannels } from '../../../utils/customeHooks/useMSTeamsChannels'
import { PluginConfigData } from '../configuration/utils'
import { PluginGeneralFormValues } from '../configuration/content/overview/PluginGeneralForm'
import { RBACFormValues } from '../configuration/content/rbac/PluginRBAC'
import { setDeploymentPluginsValidReport, updatePluginConfiguration } from '../../../store/slices/deployment/deployment'
import { getChannelsFromPlatformValues, getPlatformFromDeployment } from '../../../utils/platforms'
import { ReasonForBlockUpdateTooltip } from '../../tooltip/ReasonForBlockUpdateTooltip'
import { WrapWithOptionalTooltip } from '../../tooltip/WrapWithOptionalTooltip'
import { PluginsSC } from './styles'
import { AddPlugin } from '../add/AddPlugin'
import { PluginHealthIndicator } from '../health/PluginHealthIndicator'

type Props = {
  disableAdd?: boolean
  platformType?: PlatformsType
  platformId?: string
}
export const ExistingPluginsList: FC<Props> = ({ platformId, platformType, disableAdd }) => {
  const deployment = useAppSelector(state => state.deployment.data)
  const isDeploymentUpdateBlocked = useAppSelector(state => state.deployment.updateBlock.isBlocked)
  const pluginTemplates = useAppSelector(state => state.plugin.pluginTemplates)
  const [pluginSelected, setPluginSelected] = useState<PluginValueDeploymentPage | undefined>()
  const [isAddNewPluginOpened, setAddPluginOpened] = useState(false)
  const [platformIdForBinding, setPlatformIdForBinding] = useState<string | undefined>()
  const { msTeamsChannels } = useMSTeamsChannels()
  const dispatch = useAppDispatch()

  const pluginsInDeployment = useMemo<PluginValueDeploymentPage[]>(() => {
    if (!deployment || !pluginTemplates) {
      return []
    }
    return getPluginsFromDeploymentForDeploymentPage(deployment, pluginTemplates)
  }, [deployment, pluginTemplates])

  const findPluginTemplate = useCallback(
    (plugin: PluginValueDeploymentPage) => getPluginTemplate(pluginTemplates ?? [], plugin.name),
    [pluginTemplates],
  )

  const pluginTemplate = useMemo(() => {
    return pluginSelected ? findPluginTemplate(pluginSelected) : undefined
  }, [pluginSelected, findPluginTemplate])

  const handleConfigurationChange = (
    pluginId: string,
    config: PluginConfigData,
    generalSettings: PluginGeneralFormValues,
    rbac?: RBACFormValues,
  ) => {
    dispatch(setDeploymentPluginsValidReport({ pluginId, isValid: true })) // there's no way to submit invalid config
    dispatch(updatePluginConfiguration({ pluginId, config, generalSettings, rbac }))
    setPluginSelected(undefined)
    setPlatformIdForBinding(undefined)
  }

  const columns: ColumnsType<PluginValueDeploymentPage> = [
    {
      title: 'Plugin Name',
      dataIndex: 'displayName',
      render: (name: string, record) => (
        <PluginHealthIndicator
          enabled={record.enabled}
          name={name}
          configName={record.configurationName}
        />
      ),
    },
    {
      title: 'Type',
      dataIndex: 'name',
      render: (name: string, record) => (
        <PluginTag
          type={record.type}
          internalName={name}
        >
          {name}
        </PluginTag>
      ),
    },
    {
      key: 'usedIn',
      title: 'Used on platforms',
      width: '60%',
      render: (_, record) => (
        <PluginUsedInPlatforms
          pluginConfigName={record.configurationName}
          pluginType={record.type}
        />
      ),
    },
    {
      key: 'actions',
      title: '',
      render: (_, record) => {
        const isPluginInPlatform = Boolean(
          deployment &&
            getBindingsPluginToPlatformsForDeploymentPage(record.configurationName, deployment.platforms).some(
              bindings => bindings.platformId === platformId,
            ),
        )
        return (
          <Flex
            gap={'middle'}
            align={'center'}
            justify={'end'}
          >
            <ReasonForBlockUpdateTooltip>
              <Button
                id={record.configurationName}
                onClick={() => setPluginSelected(record)}
                icon={<SettingOutlined />}
                disabled={isDeploymentUpdateBlocked}
              />
            </ReasonForBlockUpdateTooltip>
            {!disableAdd && (
              <WrapWithOptionalTooltip tooltip={isPluginInPlatform ? 'Plugin already added to the platform' : ''}>
                <Button
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setPluginSelected(record)
                    setPlatformIdForBinding(platformId)
                  }}
                  disabled={isPluginInPlatform}
                >
                  Add plugin
                </Button>
              </WrapWithOptionalTooltip>
            )}
          </Flex>
        )
      },
    },
  ]

  if (!deployment) {
    return null
  }

  return (
    <>
      <PluginsSC
        title='Plugins'
        extra={
          <ReasonForBlockUpdateTooltip>
            <Button
              icon={<PlusOutlined />}
              disabled={isDeploymentUpdateBlocked}
              onClick={() => setAddPluginOpened(true)}
            >
              Add plugin
            </Button>
          </ReasonForBlockUpdateTooltip>
        }
        bordered={false}
      >
        <Table
          columns={columns}
          dataSource={
            platformType && isSinkPlatform(platformType)
              ? pluginsInDeployment.filter(plug => plug.type === PluginType.Source)
              : pluginsInDeployment
          }
          rowKey={'id'}
          pagination={false}
        />
      </PluginsSC>
      {pluginSelected && (
        <PluginConfiguration
          okText={'Update'}
          selectedPlatformId={platformIdForBinding}
          pluginConfig={{
            pluginTemplate: pluginTemplate,
            pluginValue: {
              ...pluginSelected,
              bindings: pluginSelected.bindings.reduce<Record<string, PluginBindings>>((bindingsRecord, binding) => {
                bindingsRecord[binding.platformId] = {
                  channels: binding.channels,
                  platform: binding.platformType,
                  platformId: binding.platformId,
                  platformIdx: binding.platformId,
                }
                //eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                if (platformIdForBinding && platformType && !bindingsRecord[platformIdForBinding]) {
                  bindingsRecord[platformIdForBinding] = {
                    channels: getChannelsFromPlatformValues(
                      getPlatformFromDeployment(deployment, platformIdForBinding),
                      msTeamsChannels,
                    ).map(ch => ch.value),
                    platform: platformType,
                    platformId: platformIdForBinding,
                    platformIdx: platformIdForBinding,
                  }
                }
                return bindingsRecord
              }, {}),
              enabled: pluginSelected.enabled,
            },
            connections: getPluginConfigurationConnections(deployment, pluginSelected.type, msTeamsChannels),
          }}
          onCancel={() => {
            setPluginSelected(undefined)
            setPlatformIdForBinding(undefined)
          }}
          onSubmit={(config, generalSettings, _, rbacData) =>
            handleConfigurationChange(pluginSelected.id, config, generalSettings, rbacData)
          }
        />
      )}
      <AddPlugin
        isOpened={isAddNewPluginOpened}
        onClose={() => setAddPluginOpened(false)}
        hideExisting={true}
      />
    </>
  )
}
