import styled from 'styled-components'
import JSForm from '@rjsf/antd'

export const FormSC = styled(JSForm)<{ hideSubmitButton?: boolean }>`
  /* 
  Styles for JSON schema headers
  These selectors aren't nice-looking, but in order to get rid of them
  we would need to fork the form fields and templates and modify code.
  That would be even uglier and harder to maintain.
  We picked the lesser evil.
  */

  /* h1 - object field label for root (whole form)  */

  fieldset#root > .ant-row > .ant-col.ant-form-item-label > label {
    margin-top: 0;
    font-size: 30px;
  }

  /* general styles for object field labels  */

  fieldset {
    padding: 0;
  }

  fieldset > .ant-row > .ant-col.ant-form-item-label > label {
    line-height: 1.4;
    margin-top: 0;
    font-weight: 600;
    height: auto;
    /* used for h2 (2nd level of nested object field) */
    font-size: 24px;
  }

  /* h3 - nested object fields (3rd level, 4th level and so on...)  */

  fieldset#root .field-object .field-object fieldset > .ant-row > .ant-col.ant-form-item-label > label {
    margin-top: 0;
    font-size: 18px;
  }

  .field-array,
  .field-object {
    /* Do not display duplicated descriptions for object + array fieldsets */

    > .ant-form-item > .ant-row > .ant-col > .ant-form-item-extra {
      display: none;
    }

    /* Style a bit the "Add" button */

    .ant-row-end {
      .ant-col {
        flex: 0 0 112px !important; /* there's just add button */
      }

      .ant-btn.array-item-add {
        margin-top: 24px;
      }
    }
  }

  /* 'non-orderable' is a custom class added as a part of UI Schema. */

  .field-array.non-orderable {
    .array-item-list > .ant-row > .ant-col:last-of-type {
      flex: 0 0 56px !important; /* just delete button */

      .ant-btn-group {
        > .ant-btn {
          width: 100% !important;
        }
      }
    }
  }

  /* 'obj-properties' is a custom class added as a part of UI Schema. */

  .field-object.obj-properties {
    .form-additional .ant-form-item-label {
      display: none;
    }

    .form-group.field-string > .ant-row > .ant-col:last-of-type {
      flex: 0 0 56px !important; /* just delete button */
    }
  }

  .ant-form-item {
    line-height: 1.5715;
    margin-top: 16px;
    margin-bottom: 0;
  }

  .ant-form-item-control-input {
    min-height: auto;
  }

  .ant-col .ant-btn-group,
  .ant-col > .ant-btn {
    margin-top: 16px;
  }

  /* form field label */

  .ant-form-item-extra {
    margin-top: 8px;
  }

  span#root__description {
    margin-bottom: 0;
    display: block;
  }

  /* custom class for submit button to match Ant UI modal styles */

  .submit-cfg {
    ${props => props.hideSubmitButton && 'display: none;'}
    ${props =>
      !props.hideSubmitButton &&
      `
    display: block;
    margin: 20px 0 0 auto;
    `}
  }
`
