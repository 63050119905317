import styled from 'styled-components'
import { Container } from '../container/Container'
import { Layout, Typography } from 'antd'
import { WIZARD_STEP_FOOTER_HEIGHT } from '../../pages/clusters/add/steps/footer/styles'

export const ContentSC = styled(Layout.Content)`
  padding: 0;
  background: #fff;
`

export const ContainerStepWrapperSC = styled.div`
  padding: 25px 0;
  background: #f5f5f5;
`

export const ContainerContentSC = styled(Container)`
  padding-bottom: ${WIZARD_STEP_FOOTER_HEIGHT}px;
`

export const TitleSC = styled(Typography.Title)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`
