import { Key, useCallback, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { Button, Input, InputNumber, InputRef, Space } from 'antd'
import { FilterDropdownProps } from 'antd/es/table/interface'
import { SearchOutlined } from '@ant-design/icons'

type Filters = {
  text: string
  value: string | number | boolean
}
type FilterMode = 'menu' | 'tree' | undefined

type RangeSearch = {
  from: number | null
  to: number | null
}

type FilterDropdownRangeProps = Omit<FilterDropdownProps, 'setSelectedKeys' | 'selectedKeys'> & {
  setSelectedKeys: (keys: RangeSearch[]) => void
  selectedKeys: RangeSearch[]
}

export const useFilterAntTable = () => {
  const [searchText, setSearchText] = useState<Key | undefined>('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef<InputRef>(null)

  const handleSearch = useCallback(
    (selectedKeys: Key[], confirm: () => void, dataIndex: string) => {
      confirm()
      setSearchText(selectedKeys[0])
      setSearchedColumn(dataIndex)
    },
    [setSearchText, setSearchedColumn],
  )

  const handleSearchRage = useCallback(
    (_selectedKeys: RangeSearch[], confirm: () => void, dataIndex: string) => {
      confirm()
      setSearchedColumn(dataIndex)
    },
    [setSearchedColumn],
  )

  const handleReset = useCallback(
    (confirm: FilterDropdownProps['confirm'], clearFilters?: () => void) => {
      if (typeof clearFilters === 'function') {
        clearFilters()
      }
      setSearchText('')
      confirm()
    },
    [setSearchText],
  )

  const getColumnSearchProps = function <RecordType>(
    dataIndex: string,
    title: string,
    onSearch?: (value: boolean | Key, record: RecordType) => boolean,
  ) {
    return {
      onFilterDropdownOpenChange: (visible: boolean) => {
        if (!visible) {
          return
        }
        setTimeout(() => searchInput.current?.select(), 100)
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              ref={searchInput}
              id={`${dataIndex}-${title}`}
              placeholder={`Search ${title}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                onClick={() => handleReset(confirm, clearFilters)}
                size='small'
                style={{ width: 90 }}
              >
                Clear
              </Button>
              <Button
                type='primary'
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size='small'
                style={{ width: 90 }}
              >
                Search
              </Button>
            </Space>
          </div>
        )
      },
      filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: onSearch
        ? onSearch
        : (value: boolean | Key, record: RecordType) =>
            // @ts-expect-error
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            record[dataIndex]
              ? // @ts-expect-error
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                record[dataIndex].toString().toLowerCase().includes(value.toString().toLowerCase())
              : '',
      render: (text: string) =>
        searchedColumn === dataIndex && searchText ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText.toString()]}
            autoEscape={true}
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    }
  }

  const getColumnSearchRangeProps = (dataIndex: string, title: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownRangeProps) => {
      return (
        <div style={{ padding: 8 }}>
          <div>
            <Space>
              <InputNumber<number>
                placeholder={`${title} od`}
                value={selectedKeys[0]?.from ?? null}
                onChange={value => setSelectedKeys([{ from: value, to: selectedKeys[0]?.to ?? null }])}
                onPressEnter={() => handleSearchRage(selectedKeys, confirm, dataIndex)}
                style={{ marginBottom: 8, display: 'block' }}
              />
              <InputNumber
                placeholder={`${title} do`}
                value={selectedKeys[0]?.to ?? null}
                onChange={value => setSelectedKeys([{ from: selectedKeys[0]?.from ?? null, to: value }])}
                onPressEnter={() => handleSearchRage(selectedKeys, confirm, dataIndex)}
                style={{ marginBottom: 8, display: 'block' }}
              />
            </Space>
          </div>
          <Space>
            <Button
              onClick={() => handleReset(confirm, clearFilters)}
              size='small'
              style={{ width: 90 }}
            >
              Clear
            </Button>
            <Button
              type='primary'
              onClick={() => handleSearchRage(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size='small'
              style={{ width: 90 }}
            >
              Search
            </Button>
          </Space>
        </div>
      )
    },
    filterIcon: (filtered: string) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (range: RangeSearch, record: Record<string, number>) => {
      if (range.from !== null && range.to !== null) {
        return record[dataIndex] && record[dataIndex] >= range.from && record[dataIndex] <= range.to
      } else if (range.from !== null) {
        return record[dataIndex] && record[dataIndex] >= range.from
      } else if (range.to !== null) {
        return record[dataIndex] && record[dataIndex] <= range.to
      }

      return true
    },
    render: (text: string) => text,
  })

  const getColumnFilterProps = function <RecordType>(
    dataIndex: string,
    filters: Filters[],
    onFilter?: (value: boolean | Key, record: RecordType) => boolean,
  ) {
    return {
      filters: filters,
      filterMode: 'menu' as FilterMode,
      filterSearch: true,
      onFilter: onFilter
        ? onFilter
        : (value: boolean | Key, record: RecordType): boolean => {
            // @ts-expect-error
            return record[dataIndex] === value
          },
    }
  }

  return {
    getColumnSearchProps,
    getColumnSearchRangeProps,
    getColumnFilterProps,
  }
}
