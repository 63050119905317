import { Form, Input, Row, Switch } from 'antd'
import { FC } from 'react'
import { ElasticSearchFormValues } from '../../../../pages/clusters/add/steps/platformsConfiguration/utils'
import { FormikProps } from 'formik'
import { getValidationError, getValidationStatus } from '../../../../utils/formik/formik'
import { PlatformContentInstruction } from '../../../../pages/clusters/add/steps/platformsConfiguration/contents/PlatformContentInstruction'
import { ContentType } from '../../../../services/contentful/types'
import { ContentColumnRight } from '../../../../pages/clusters/add/steps/platformsConfiguration/contents/columns/ContentColumnRight'
import { PlatformEmbeddedDocs } from '../../../../pages/clusters/add/steps/platformsConfiguration/contents/PlatformEmbeddedDocs'
import { ContentColumnLeft } from '../../../../pages/clusters/add/steps/platformsConfiguration/contents/columns/ContentColumnLeft'

export type ElasticsearchInstanceFormValues = ElasticSearchFormValues

export type ElasticsearchInstanceFormProps = {
  formik: FormikProps<ElasticSearchFormValues>
}

export const ElasticsearchInstanceForm: FC<ElasticsearchInstanceFormProps> = ({ formik }) => {
  return (
    <Row>
      <ContentColumnLeft>
        <PlatformContentInstruction platformInstructionType={ContentType.ELASTICSEARCH_PLATFORM_INSTRUCTION} />
        <Form layout={'vertical'}>
          <Form.Item
            label={'Display Name'}
            required={true}
            validateStatus={getValidationStatus(formik.errors.name, formik.touched.name)}
            help={getValidationError(formik.errors.name, formik.touched.name)}
          >
            <Input
              name={`name`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              placeholder={'My Teams Name'}
            />
          </Form.Item>
          <Form.Item
            label={'Username'}
            required={true}
            validateStatus={getValidationStatus(formik.errors.username, formik.touched.username)}
            help={getValidationError(formik.errors.username, formik.touched.username)}
          >
            <Input
              name={`username`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.username}
              placeholder={'username123'}
            />
          </Form.Item>
          <Form.Item
            label={'Password'}
            required={true}
            validateStatus={getValidationStatus(formik.errors.password, formik.touched.password)}
            help={getValidationError(formik.errors.password, formik.touched.password)}
          >
            <Input.Password
              name={`password`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
          </Form.Item>
          <Form.Item
            label={'Server'}
            required={true}
            validateStatus={getValidationStatus(formik.errors.server, formik.touched.server)}
            help={getValidationError(formik.errors.server, formik.touched.server)}
          >
            <Input
              name={`server`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.server}
              placeholder={'https://elasticsearch-master.default:9200'}
            />
          </Form.Item>
          <Form.Item
            label={'Skip TLS Verify'}
            validateStatus={getValidationStatus(formik.errors.skipTlsVerify, formik.touched.skipTlsVerify)}
            help={getValidationError(formik.errors.skipTlsVerify, formik.touched.skipTlsVerify)}
          >
            <Switch
              onChange={checked => {
                formik.setFieldValue('skipTlsVerify', checked)
              }}
              checked={formik.values.skipTlsVerify}
            />
          </Form.Item>
          <Form.Item
            label={'Index name'}
            required={true}
            validateStatus={getValidationStatus(formik.errors.indexName, formik.touched.indexName)}
            help={getValidationError(formik.errors.indexName, formik.touched.indexName)}
          >
            <Input
              name={`indexName`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.indexName}
              placeholder={'botkube-index-name'}
            />
          </Form.Item>
        </Form>
      </ContentColumnLeft>
      <ContentColumnRight>
        <PlatformEmbeddedDocs platformInstructionType={ContentType.ELASTICSEARCH_PLATFORM_INSTRUCTION} />
      </ContentColumnRight>
    </Row>
  )
}
