import { createSelector } from 'reselect'
import { selectPlatform } from '../deployment/selectors'
import { RootState } from '../../store'
import { instanceOfCloudSlack } from '../../../utils/ts-guards'

export const selectSlackWorkspace = (state: RootState, platformId: string) =>
  createSelector(
    [state => selectPlatform(state, platformId), (state: RootState) => state.slackWorkspace],
    (platform, slackWorkspace) => {
      if (!platform || !instanceOfCloudSlack(platform)) {
        return
      }

      return slackWorkspace.workspaces.find(workspace => workspace.teamId === platform.teamId)
    },
  )(state)
