import { Form, Input } from 'antd'
import { FC, useState } from 'react'
import { PlatformsType } from '../../../models/platform/platform'
import { FormikErrors, FormikHandlers, FormikHelpers, FormikTouched } from 'formik'
import { getValidationError, getValidationStatus } from '../../../utils/formik/formik'
import { MattermostFormValues } from '../../../pages/clusters/add/steps/platformsConfiguration/utils'
import { InputTag } from '../../../components/inputTag/InputTag'

type Props = {
  values: MattermostFormValues
  handleChange: FormikHandlers['handleChange']
  setFieldValue: FormikHelpers<MattermostFormValues>['setFieldValue']
  errors?: FormikErrors<MattermostFormValues>
  touched?: FormikTouched<MattermostFormValues>
  idx: number
  isSubmitted: boolean
}

export const MattermostForm: FC<Props> = props => {
  const { errors, touched, values, idx, handleChange, setFieldValue, isSubmitted } = props
  const [tokenVisible, setTokenVisible] = useState(false)
  const handleChannels = (channels: string[]) => {
    setFieldValue(`${PlatformsType.MATTERMOST}.[${idx}].channelNames`, channels)
  }

  return (
    <>
      <Form.Item
        label={'Display Name'}
        required={true}
        validateStatus={getValidationStatus(errors?.name, touched?.name ?? isSubmitted)}
        help={getValidationError(errors?.name, touched?.name ?? isSubmitted)}
      >
        <Input
          name={`${PlatformsType.MATTERMOST}.[${idx}].name`}
          onChange={handleChange}
          value={values.name}
          placeholder={'My Mattermost Name'}
        />
      </Form.Item>
      <Form.Item
        label={'Bot Name'}
        required={true}
        validateStatus={getValidationStatus(errors?.botName, touched?.botName ?? isSubmitted)}
        help={getValidationError(errors?.botName, touched?.botName ?? isSubmitted)}
      >
        <Input
          name={`${PlatformsType.MATTERMOST}.[${idx}].botName`}
          onChange={handleChange}
          value={values.botName}
          placeholder={'Botkube'}
        />
      </Form.Item>
      <Form.Item
        label={'Personal Access Token'}
        required={true}
        validateStatus={getValidationStatus(errors?.token, touched?.token ?? isSubmitted)}
        help={getValidationError(errors?.token, touched?.token ?? isSubmitted)}
      >
        <Input.Password
          visibilityToggle={{ visible: tokenVisible, onVisibleChange: setTokenVisible }}
          name={`${PlatformsType.MATTERMOST}.[${idx}].token`}
          onChange={handleChange}
          value={values.token}
          placeholder={'wmaa4eqi4ffq8f75nnc111zqre'}
        />
      </Form.Item>
      <Form.Item
        label={'Team Name'}
        required={true}
        validateStatus={getValidationStatus(errors?.team, touched?.team ?? isSubmitted)}
        help={getValidationError(errors?.team, touched?.team ?? isSubmitted)}
      >
        <Input
          name={`${PlatformsType.MATTERMOST}.[${idx}].team`}
          onChange={handleChange}
          value={values.team}
          placeholder={'devops-team'}
        />
      </Form.Item>
      <Form.Item
        label={'Server URL'}
        required={true}
        validateStatus={getValidationStatus(errors?.url, touched?.url ?? isSubmitted)}
        help={getValidationError(errors?.url, touched?.url ?? isSubmitted)}
      >
        <Input
          name={`${PlatformsType.MATTERMOST}.[${idx}].url`}
          onChange={handleChange}
          value={values.url}
          placeholder={'https://kube.cloud.mattermost.com/'}
        />
      </Form.Item>
      <Form.Item
        label={'Channel Name'}
        required={true}
        validateStatus={getValidationStatus(errors?.channelNames as string, touched?.channelNames ?? isSubmitted)}
        help={getValidationError(errors?.channelNames as string, touched?.channelNames ?? isSubmitted)}
      >
        <InputTag
          prefix={'#'}
          values={values.channelNames}
          placeholder={'New channel'}
          onChange={handleChannels}
        />
      </Form.Item>
    </>
  )
}
