import styled from 'styled-components'
import { breakpoints } from '../../../styles/globalStyles'
import { Container } from '../../../layouts/container/Container'

type DeploymentDetailsHeaderSCProps = {
  additionalPadding: number
}
export const DeploymentDetailsHeaderSC = styled.header<DeploymentDetailsHeaderSCProps>`
  position: sticky;
  top: ${props => props.additionalPadding}px;
  z-index: 99; // can't be higher than 1000 because of antd modal
  padding: 0 0 24px 0;
  background: #fff;
  border-bottom: 1px solid #d9d9d9;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02);
`

export const DeploymentHeaderContentSC = styled(Container)`
  display: block;
  justify-content: space-between;
  gap: 16px;
  padding-top: 24px;

  h1,
  .ant-typography-h1 {
    margin: 0 0 16px 0;

    .ant-typography {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${breakpoints.lgMin}px) {
    display: flex;

    h1 {
      margin: 0 0 12px 0;
    }
  }
`

export const DeploymentSubHeaderSC = styled.div`
  display: block;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: ${breakpoints.lgMin}px) {
    display: flex;
    margin-bottom: 0;
  }
`

export const DeploymentSubHeaderRightSC = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  .ant-dropdown-menu-item {
    svg {
      height: 14px;
    }
  }
`
