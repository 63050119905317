import { Layout } from 'antd'
import React, { FC, ReactNode } from 'react'
import { Container } from '../container/Container'
import { Footer } from '../../components/footer/Footer'
import { Header } from '../../components/header/Header'

const { Header: ADHeader, Content: ADContent } = Layout

type Props = {
  children?: ReactNode
  contentClass?: string
}
export const WithoutMenuLayout: FC<Props> = props => (
  <Layout>
    <ADHeader>
      <Container>
        <Header>{[]}</Header>
      </Container>
    </ADHeader>
    <ADContent>
      <Container className={props.contentClass}>{props.children}</Container>
    </ADContent>
    <Footer />
  </Layout>
)
