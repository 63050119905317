import React, { useContext } from 'react'
import { MainLayout } from '../../../layouts/main/MainLayout'
import { Button, Divider, message, Result, Spin } from 'antd'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAddPlatformToOrganizationMutation, useGetTeamsPlatformDetailsQuery } from '../../../models/graphql'
import { CenteredContentSC, TeamsIconSC } from './styles'
import { paths } from '../../../router/paths'
import { AppCtx } from '../../../providers/appCtx'
import { ResultStatusType } from 'antd/lib/result'

const CONNECTION_STATE_SEARCH_PARAM = 'state'

export const TeamsConnectionPage: React.FC = () => {
  const [searchParams] = useSearchParams()
  const state = searchParams.get(CONNECTION_STATE_SEARCH_PARAM) ?? ''
  const navigate = useNavigate()

  const appCtx = useContext(AppCtx)

  const { data, isLoading, isError, refetch, error } = useGetTeamsPlatformDetailsQuery(
    { token: state },
    {
      enabled: state !== '',
    },
  )

  const { mutateAsync, isLoading: isAddPlatformLoading, isSuccess } = useAddPlatformToOrganizationMutation()

  if (state === '') {
    return (
      <MainLayout>
        <Result
          style={{ margin: '34px 0 0' }}
          status='error'
          title='Connection state is missing'
          subTitle={<>Please return to Teams to restart the process of connecting your Botkube app.</>}
        />
      </MainLayout>
    )
  }

  if (isLoading) {
    return (
      <MainLayout>
        <Spin
          tip={'Fetching MS Teams details...'}
          fullscreen={true}
        />
      </MainLayout>
    )
  }

  if (isError) {
    const err = error as Error
    return (
      <MainLayout>
        <Result
          style={{ margin: '34px 0 0' }}
          status='error'
          title={'Connection failed. Please try again.'}
          subTitle={
            <>
              <p>{err.message}</p>
              <p>
                Please return to Teams and send a message with the{' '}
                <strong>
                  <code>@Botkube cloud connect</code>
                </strong>{' '}
                command to restart the permissions grant process.
              </p>
            </>
          }
        />
      </MainLayout>
    )
  }

  if (data.connectedPlatforms.teams.teams?.length !== 1) {
    return (
      <MainLayout>
        <Result
          style={{ margin: '34px 0 0' }}
          status='error'
          title={'Connection failed. Please try again.'}
          subTitle={'Could not find the Team you are trying to connect to.'}
        />
      </MainLayout>
    )
  }

  const currentTeam = data.connectedPlatforms.teams.teams[0]
  const teamName = currentTeam.name ?? ''
  const quotedOrEmptyTeamName = teamName !== '' ? ` "${teamName}"` : ''
  const isAlreadyConnected = currentTeam.connectedOrganizations?.some(val => val.id === appCtx.orgID)
  const consentRequired = !data.connectedPlatforms.teams.consentGiven

  if (isAlreadyConnected) {
    let title = 'All set up!'
    let status: ResultStatusType = 'success'
    let subTitle = (
      <>
        Your Team{quotedOrEmptyTeamName} is connected to this organization and all required permissions are granted. You
        can now use Botkube within your Team!
      </>
    )

    if (consentRequired) {
      title = 'Almost there!'
      status = 'info'
      subTitle = (
        <>
          Your Team {quotedOrEmptyTeamName} is connected to this organization. However, you need to grant Botkube bot
          access to your Team before you can use it.
          <br />
          Ask your Teams organization admin to grant access for Botkube bot.
        </>
      )
    }

    return (
      <MainLayout>
        <Result
          status={status}
          title={title}
          subTitle={subTitle}
          extra={
            <Button
              type='primary'
              key='home'
              onClick={() => navigate(paths.HOME)}
            >
              Return to dashboard
            </Button>
          }
        />
      </MainLayout>
    )
  }

  const submitFn = async () => {
    // errors are handled globally
    await mutateAsync({
      input: {
        organizationId: appCtx.orgID,
        teams: {
          token: state,
        },
      },
    })
    const msg = `Successfully connected with${quotedOrEmptyTeamName} Team.`
    void message.success(msg)
    await refetch()
  }

  return (
    <MainLayout>
      <CenteredContentSC>
        <TeamsIconSC />
        <h1>Connect{quotedOrEmptyTeamName} Team</h1>
        Use Botkube within your{quotedOrEmptyTeamName} Team by connecting it to an existing or to a new Botkube Instance
        <br />
        <Button
          style={{ marginTop: '1em' }}
          type={'primary'}
          onClick={() => void submitFn()}
          loading={isAddPlatformLoading}
          disabled={isSuccess}
        >
          Connect
        </Button>
        <Divider />
      </CenteredContentSC>
    </MainLayout>
  )
}
