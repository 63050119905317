import { DeploymentStatusPhase } from '../../../models/graphql'
import { Deployment } from '../../../store/slices/deployment/model'

type DeploymentInfoArg = {
  isOutdated: boolean
  isDeploymentClean: boolean
  statusPhase: DeploymentStatusPhase
  statusMessage?: string | null
  upgrade: Deployment['status']['upgrade']
}
export const getTitle = (args: DeploymentInfoArg) => {
  if (args.isOutdated && !args.isDeploymentClean) {
    return 'Outdated Version Detected'
  } else if (!args.isDeploymentClean) {
    return 'Unsaved changes'
  } else if (args.upgrade?.needsUpgrade) {
    return `Botkube ${args.upgrade.targetBotkubeVersion} available`
  } else if (args.statusPhase === DeploymentStatusPhase.Failed) {
    return 'Error'
  }
}

export const getDescription = (args: DeploymentInfoArg) => {
  if (args.isOutdated && !args.isDeploymentClean) {
    return `The instance you're editing has changed. Please copy your edits and refresh the page.`
  }
  if (!args.isDeploymentClean) {
    return ''
  }
  if (args.statusPhase === DeploymentStatusPhase.Failed) {
    return args.statusMessage
  }
}

export const getIconType = (args: DeploymentInfoArg): undefined | 'warning' | 'error' => {
  if (args.isOutdated || !args.isDeploymentClean || args.upgrade?.needsUpgrade) {
    return 'warning'
  }
  if (args.statusPhase === DeploymentStatusPhase.Failed) {
    return 'error'
  }
}

export const isAlertShown = (args: DeploymentInfoArg) => {
  return !!(
    args.isOutdated ||
    !args.isDeploymentClean ||
    args.statusPhase === DeploymentStatusPhase.Failed ||
    args.upgrade?.needsUpgrade
  )
}
