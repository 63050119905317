import React, { FC, useState } from 'react'
import { Button, Modal, Table } from 'antd'
import { useContent } from '../../../utils/customeHooks/useContent'
import { ContentType, InstanceSkeleton } from '../../../services/contentful/types'
import { breakpoints } from '../../../styles/globalStyles'
import { AddEditActionForm, AddEditActionFormValues } from '../../../forms/instance/actions/AddEditAction'
import { PluginType } from '../../../models/graphql'
import { defActionsColumns, prepareActionData } from './utils'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { useViewport } from '../../../utils/customeHooks/useViewport'
import { removeActionFromDeployment, updateActionInDeployment } from '../../../store/slices/deployment/deployment'
import { Action } from '../../../store/slices/deployment/model'
import { ReasonForBlockUpdateTooltip } from '../../tooltip/ReasonForBlockUpdateTooltip'
import { PlusOutlined } from '@ant-design/icons'
import { ActionsSC } from './styles'

export const ActionsList: FC = () => {
  const deployment = useAppSelector(state => state.deployment.data)
  const idDeploymentUpdateBlock = useAppSelector(state => state.deployment.updateBlock.isBlocked)

  const [isActionFormOpen, setActionFormOpen] = useState(false)
  const [selectedAction, setSelectedAction] = useState<Action | undefined>()

  const content = useContent<InstanceSkeleton>(ContentType.INSTANCE_PAGE)
  const { width } = useViewport()
  const dispatch = useAppDispatch()

  const handleActionRemove = (id: string) => {
    dispatch(removeActionFromDeployment(id))
  }

  const handleSubmitAction = (values: AddEditActionFormValues) => {
    dispatch(
      updateActionInDeployment({
        values,
        callback: () => {
          setSelectedAction(undefined)
          setActionFormOpen(false)
        },
      }),
    )
  }

  if (!deployment) {
    return null
  }

  return (
    <>
      <ActionsSC
        title={content?.fields.actionsSectionTitle}
        extra={
          <ReasonForBlockUpdateTooltip>
            <Button
              icon={<PlusOutlined />}
              disabled={idDeploymentUpdateBlock}
              onClick={() => setActionFormOpen(true)}
            >
              Add action
            </Button>
          </ReasonForBlockUpdateTooltip>
        }
        bordered={false}
      >
        <Table
          dataSource={prepareActionData(
            deployment.actions,
            deployment.plugins.map(plugin => ({
              name: plugin.configurationName,
              displayName: plugin.displayName,
            })),
          )}
          columns={defActionsColumns({
            onEdit: (id: string) => {
              setSelectedAction(deployment.actions.find(action => action.id === id))
              setActionFormOpen(true)
            },
            onDelete: handleActionRemove,
            disabled: idDeploymentUpdateBlock,
          })}
          pagination={false}
        />
      </ActionsSC>
      <Modal
        title={selectedAction ? `Edit Action: ${selectedAction.displayName}` : 'Add Action'}
        open={isActionFormOpen}
        width={width > breakpoints.lgMin ? '80%' : '100%'}
        onCancel={() => {
          setSelectedAction(undefined)
          setActionFormOpen(false)
        }}
        footer={false}
        destroyOnClose={true}
      >
        <AddEditActionForm
          action={selectedAction}
          sources={deployment.plugins
            .filter(plugin => plugin.type === PluginType.Source)
            .map(plugin => ({
              name: plugin.configurationName,
              displayName: plugin.displayName,
            }))}
          executors={deployment.plugins
            .filter(plugin => plugin.type === PluginType.Executor)
            .map(plugin => ({
              name: plugin.configurationName,
              displayName: plugin.displayName,
            }))}
          onCancel={() => {
            setSelectedAction(undefined)
            setActionFormOpen(false)
          }}
          onSubmit={handleSubmitAction}
          otherActionNames={deployment.actions
            .map(action => action.name)
            .filter(actionName => actionName !== selectedAction?.name)}
        />
      </Modal>
    </>
  )
}
