import { FC } from 'react'
import { PlatformChannelsBadgeSC } from './styles'
import { isSinkPlatform, PlatformsType } from '../../../models/platform/platform'

type Props = {
  platformType: PlatformsType
  isBound?: boolean
  availableChannels: number
  connectedChannels: number
}
export const BoundIndicator: FC<Props> = ({ platformType, connectedChannels, availableChannels, isBound }) => {
  const badgeText = isSinkPlatform(platformType)
    ? isBound
      ? 'On'
      : 'Off'
    : `${connectedChannels}/${availableChannels}`
  const bound = isSinkPlatform(platformType) ? !!isBound : connectedChannels > 0

  return <PlatformChannelsBadgeSC bound={bound}>{badgeText}</PlatformChannelsBadgeSC>
}
