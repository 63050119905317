import { FC } from 'react'

import { useAppSelector } from '../../../store/store'
import { selectPlatformHealth } from '../../../store/slices/deployment/selectors'
import { PlatformsType } from '../../../models/platform/platform'
import { PlatformHealthAlertSC } from './styles'

type Props = {
  id: string
  type: PlatformsType
}
export const PlatformHealthAlert: FC<Props> = ({ id, type }) => {
  const platformHealth = useAppSelector(state => selectPlatformHealth(state, id, type))

  if (!platformHealth?.reason) {
    return null
  }
  return (
    <PlatformHealthAlertSC
      banner={true}
      type={'error'}
      message={`${platformHealth.reason}${platformHealth.errorMsg ? ': ' + platformHealth.errorMsg : ''}`}
    />
  )
}
