import React, { ChangeEvent, FC, useState } from 'react'
import { Button, Input, Modal } from 'antd'
import { getChannelInviteURL } from './channels'

type CloudSlackChannelModalProps = {
  teamId: string
  isModalOpen: boolean
  setIsModalOpen: (isOpen: boolean) => void
}

export const CloudSlackChannelModal: FC<CloudSlackChannelModalProps> = ({ isModalOpen, setIsModalOpen, teamId }) => {
  const [channelName, setChannelName] = useState<string>('')

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    let channelName = e.target.value
    if (channelName.startsWith('#')) {
      channelName = channelName.substring(1)
    }
    setChannelName(channelName)
  }

  return (
    <Modal
      title='Invite Botkube Cloud bot'
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={<Button onClick={() => setIsModalOpen(false)}>OK</Button>}
    >
      <p>
        Provide your channel name and click on the button below to navigate to it. Then, invite Botkube Cloud bot to the
        channel.
      </p>
      <Input.Group compact>
        <Input
          value={channelName}
          placeholder={'Channel name'}
          onChange={onChange}
          style={{ width: 'calc(100% - 100px)' }}
        />
        <Button
          type={'primary'}
          disabled={!channelName}
          href={getChannelInviteURL(teamId, channelName)}
          target={'_blank'}
          rel='noreferrer'
        >
          Navigate
        </Button>
      </Input.Group>
    </Modal>
  )
}
