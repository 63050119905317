import { FC } from 'react'
import { Button, Form, Row, Select, Space, Switch } from 'antd'
import { ClusterRecommendationsSC } from './styles'

export const ClusterRecommendations: FC = () => {
  return (
    <ClusterRecommendationsSC>
      <Row>
        <Space>
          <Switch
            id={'recommendationLatestTag'}
            defaultChecked
          />
          <label htmlFor={'recommendationLatestTag'}>Pod containers that use `latest` tag for images.</label>
        </Space>
      </Row>
      <Row>
        <Space>
          <Switch
            id={'recommendationLabel'}
            defaultChecked
          />
          <label htmlFor={'recommendationLabel'}>Pod resources created without labels</label>
        </Space>
      </Row>
      <Row>
        <Space>
          <Switch
            id={'recommendationIngressService'}
            defaultChecked
          />
          <label htmlFor={'recommendationIngressService'}>
            Ingress resources with invalid backend service reference.
          </label>
        </Space>
      </Row>
      <Row>
        <Space>
          <Switch
            id={'recommendationIngressTTL'}
            defaultChecked
          />
          <label htmlFor={'recommendationIngressTTL'}>Ingress resources with invalid TLS secret reference.</label>
        </Space>
      </Row>
      <Row>
        <Form layout={'vertical'}>
          <Form.Item
            label='Allowed namespaces'
            required
            tooltip='Namespaces is required field'
          >
            <Select placeholder='Select'>
              <Select.Option key={'default'}>default</Select.Option>
              <Select.Option key={'botkube'}>botkube</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                type='primary'
                disabled={true}
              >
                Save
              </Button>
              <Button>Cancel</Button>
            </Space>
          </Form.Item>
        </Form>
      </Row>
    </ClusterRecommendationsSC>
  )
}
