import { Alert } from 'antd'
import styled from 'styled-components'

export const AlertSC = styled(Alert)`
  border: 0;
  background-color: #262f69;
  width: 100%;
  padding: 12px 16px;
  margin: 0 auto;
  text-align: center;

  .ant-alert-with-description,
  .ant-alert-message {
    color: #ffffff;
  }
`

export const LinkSC = styled.a`
  &,
  &:hover {
    color: #ffffff;
    text-decoration: underline;
  }
`
