import { Card, Col, Table } from 'antd'
import styled from 'styled-components'

export const CardSC = styled(Card)`
  .ant-card-body {
    padding: 0;
    overflow: hidden;
  }
`

export const ColSC = styled(Col)`
  padding: 24px;
  border-right: 1px solid #ccc;

  p {
    margin-top: 0;
  }
`

export const TableSC = styled(Table)`
  table {
    .ant-table-placeholder .ant-table-cell {
      border-bottom: 0;
      height: 300px;
    }
  }
`

export const TooltipWrapperSC = styled.div`
  display: inline-block;
  padding-left: 12px;
  position: relative;
  top: -1px;
`

export const LoadMoreWrapperSC = styled.div`
  width: 100%;
  p {
    display: inline-block;
    margin: 0 10px 0 0;
  }

  text-align: center;
`
