import styled from 'styled-components'
import { Typography } from 'antd'

export const PageHeaderContainerSC = styled.header`
  z-index: 99; // can't be higher than 1000 because of antd modal
  padding: 24px 0;
  background: #fff;
  border-bottom: 1px solid #d9d9d9;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02);
`
export const PageHeaderHeadlineSC = styled.h1`
  margin-top: 0;
`

export const PageHeaderDescriptionSC = styled(Typography.Paragraph)`
  &.ant-typography {
    /*needed to override the default styles */
    margin-bottom: 0;
  }
`
