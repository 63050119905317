import { Link } from 'react-router-dom'
import moment from 'moment'
import dayjs from 'dayjs'
import { LockOutlined } from '@ant-design/icons'
import { paths } from '../../router/paths'
import { AuditPageQuery, BotPlatform } from '../../models/graphql'
import { ColumnsBotType } from '../../models/antd/table'
import { mapBotPlatformToIcon, mapBotPlatformToName } from '../../utils/platforms'
import { Button, Tooltip } from 'antd'
import { PreSC } from './styles'

export type AuditItem = AuditPageQuery['auditEvents']['data'][number]

const TRIMMED_DETAILS_LENGTH = 50

// This is consistent with Ant UI defaults: https://4x.ant.design/components/date-picker/
// Later we should use ConfigProvider: https://4x.ant.design/components/date-picker/#Localization
export const DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

type TableItem = {
  createdAt: number
  user: string
  pluginName: string
  deploymentId: string
  origin?: TableItemOrigin
  details?: {
    label: string
    details: unknown
  } // event, command, api call
}

type TableItemOrigin = CommandOrigin | SourceEventOrigin | ApiCallOrigin
type CommandOrigin = {
  platform?: BotPlatform
  conversationGroup?: string
  channel: string
  isAlias: boolean
}

type SourceEventOrigin = {
  name: string
  displayName: string
}

type ApiCallOrigin = {
  user: string
  gqlType: 'QUERY' | 'MUTATION'
  gqlName: string
  requestBody: string
  responseBody: string
}

export const populateTableData = (input: AuditItem[]): TableItem[] => {
  return input.map(item => {
    let origin: TableItem['origin']
    let details: TableItem['details']
    let user = ''

    // source event
    if ('source' in item) {
      origin = { name: item.source.name, displayName: item.source.displayName }
      if (item.event) {
        details = {
          label: JSON.stringify(item.event, null, 2),
          details: item.event,
        }
      }
      // command execution event
    } else if ('botPlatform' in item && item.botPlatform) {
      origin = {
        platform: item.botPlatform,
        conversationGroup: item.conversationGroup ?? undefined,
        channel: item.channel,
        isAlias: item.isAlias,
      }
      details = {
        label: item.command,
        details: item.command,
      }
      user = item.platformUser ?? ''
      // API call event
    } else if ('gqlType' in item) {
      origin = {
        user: item.user,
        gqlType: item.gqlType,
        gqlName: item.gqlName,
        requestBody: item.requestBody,
        responseBody: item.responseBody,
      }
      details = {
        label: `${item.gqlType} ${item.gqlName}`,
        details: {
          requestBody: item.requestBody ? (JSON.parse(item.requestBody) as string) : {},
          responseBody: item.responseBody ? (JSON.parse(item.responseBody) as string) : {},
        },
      }
      user = item.user
    }

    if (!origin) {
      console.warn('while populating data for audit logs origin is undefined')
    }

    return {
      id: item.id,
      createdAt: dayjs(item.createdAt as string).valueOf(),
      user: user,
      deploymentId: item.deploymentId,
      pluginName: item.pluginName,
      origin,
      details,
    }
  })
}

const EMPTY_VALUE_PLACEHOLDER = '—'
const renderStringWithFallback = (value: string) => (value ? value : EMPTY_VALUE_PLACEHOLDER)

export const setColumns = (showDetailsModal: (value: string) => void): ColumnsBotType<TableItem> => [
  {
    title: 'Timestamp',
    dataIndex: 'createdAt',
    sorter: (a, b) => a.createdAt - b.createdAt,
    render: (timestamp: number) => moment(timestamp).format(DEFAULT_DATE_TIME_FORMAT),
  },
  {
    title: 'User',
    dataIndex: 'user',
    sorter: (a, b) => a.user.localeCompare(b.user),
    render: renderStringWithFallback,
  },
  {
    title: 'Instance',
    dataIndex: 'deploymentId',
    render: (deploymentId: string) => <Link to={paths.DEPLOYMENT.replace(':id', deploymentId)}>{deploymentId}</Link>,
  },
  {
    title: 'Plugin',
    dataIndex: 'pluginName',
    sorter: (a, b) => a.pluginName.localeCompare(b.pluginName),
    render: renderStringWithFallback,
  },
  {
    title: 'Origin',
    dataIndex: 'origin',
    render: (value: TableItemOrigin) => {
      if ('platform' in value && value.platform) {
        if (value.platform === BotPlatform.Unknown) {
          return EMPTY_VALUE_PLACEHOLDER
        }

        const channelName = value.channel ? `${value.isAlias ? '' : '#'}${value.channel}` : 'Unknown'
        let label = channelName
        if (value.conversationGroup) {
          label = `${value.conversationGroup}: ${label}`
        }
        return (
          <>
            <Tooltip title={mapBotPlatformToName(value.platform)}>
              {mapBotPlatformToIcon(value.platform, { height: '12px' })}
            </Tooltip>{' '}
            {value.isAlias && (
              <Tooltip title='This is a public alias of a private channel'>
                <LockOutlined />
              </Tooltip>
            )}
            {label}
          </>
        )
      }

      if ('name' in value) {
        return <>{value.displayName}</>
      }

      if ('gqlType' in value) {
        return 'GraphQL API'
      }

      return null
    },
  },
  {
    title: 'Details',
    dataIndex: 'details',
    render: (value: TableItem['details']) => {
      if (!value) {
        return EMPTY_VALUE_PLACEHOLDER
      }

      const labelWithoutEmptyLines = value.label.replace(/\n/g, '')
      const trimmedLabel =
        value.label.length > TRIMMED_DETAILS_LENGTH
          ? `${labelWithoutEmptyLines.substring(0, TRIMMED_DETAILS_LENGTH)}...`
          : labelWithoutEmptyLines

      const details = typeof value.details === 'string' ? value.details : JSON.stringify(value.details, null, 2)
      return (
        <Button
          type={'link'}
          onClick={() => showDetailsModal(details)}
        >
          <PreSC>{trimmedLabel}</PreSC>
        </Button>
      )
    },
  },
]
