import { Col, Form, Input, Row } from 'antd'
import { FC, useState } from 'react'
import { FormikProps } from 'formik'
import { getValidationError, getValidationStatus } from '../../../../utils/formik/formik'
import { SocketSlackFormValues } from '../../../../pages/clusters/add/steps/platformsConfiguration/utils'
import { PlatformEmbeddedDocs } from '../../../../pages/clusters/add/steps/platformsConfiguration/contents/PlatformEmbeddedDocs'
import { ContentType } from '../../../../services/contentful/types'
import { PlatformContentInstruction } from '../../../../pages/clusters/add/steps/platformsConfiguration/contents/PlatformContentInstruction'

export type SocketSlackInstanceFormValues = Omit<SocketSlackFormValues, 'channelNames'>

export type SocketSlackInstanceFormProps = {
  formik: FormikProps<SocketSlackFormValues>
}

export const SocketSlackInstanceForm: FC<SocketSlackInstanceFormProps> = ({ formik }) => {
  const [appTokenVisible, setAppTokenVisible] = useState(false)
  const [botTokenVisible, setBotTokenVisible] = useState(false)

  const platformInstructionType = ContentType.EMBEDDED_SLACK_PLATFORM_INSTRUCTION

  return (
    <Row>
      <Col
        lg={12}
        md={24}
        sm={24}
        xl={9}
      >
        <PlatformContentInstruction platformInstructionType={platformInstructionType} />
        <Form layout={'vertical'}>
          <Form.Item
            label={'Display Name'}
            required={true}
            validateStatus={getValidationStatus(formik.errors.name, formik.touched.name)}
            help={getValidationError(formik.errors.name, formik.touched.name)}
          >
            <Input
              name={`name`}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={'My Slack Name'}
            />
          </Form.Item>
          <Form.Item
            label={'App Token'}
            required={true}
            validateStatus={getValidationStatus(formik.errors.appToken, formik.touched.appToken)}
            help={getValidationError(formik.errors.appToken, formik.touched.appToken)}
          >
            <Input.Password
              visibilityToggle={{ visible: appTokenVisible, onVisibleChange: setAppTokenVisible }}
              name={`appToken`}
              value={formik.values.appToken}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={'xapp-MTAxNDU1NzA0MTAwNTY5OTIGcPZFv.FjwqnoIAi7BqJcVTj7vRmY99JdlgBeYrN-iPE'}
            />
          </Form.Item>
          <Form.Item
            label={'Bot Token'}
            required={true}
            validateStatus={getValidationStatus(formik.errors.botToken, formik.touched.botToken)}
            help={getValidationError(formik.errors.botToken, formik.touched.botToken)}
          >
            <Input.Password
              visibilityToggle={{ visible: botTokenVisible, onVisibleChange: setBotTokenVisible }}
              name={`botToken`}
              value={formik.values.botToken}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={'xoxb-MTAxNDU1NzA0MTAwNTY5OTIGcPZFv.FjwqnoIAi7BqJcVTj7vRmY99JdlgBeYrN-iPE'}
            />
          </Form.Item>
        </Form>
      </Col>
      <Col
        xl={{ span: 12, offset: 3 }}
        lg={{ span: 11, offset: 1 }}
        md={24}
        sm={24}
      >
        <PlatformEmbeddedDocs platformInstructionType={platformInstructionType} />
      </Col>
    </Row>
  )
}
