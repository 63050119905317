import { EmbedButtonWrapperSC, EmbedDocsSC, IconWrapperSC } from '../styles'
import { FC } from 'react'
import { Button } from 'antd'
import { ReactComponent as NewTabIcon } from '../../../../../../assets/icons/new-tab.svg'

export type DocsEmbedProps = {
  url: string
  removeEmbedParamOnOpenNewTab?: boolean
}

export const DOCS_EMBEDDED_QUERY_PARAM = '?embedded=true'

export const DocsEmbed: FC<DocsEmbedProps> = ({ url, removeEmbedParamOnOpenNewTab = false }) => {
  const newTabURL = removeEmbedParamOnOpenNewTab ? url.replace(DOCS_EMBEDDED_QUERY_PARAM, '') : url

  return (
    <>
      <EmbedButtonWrapperSC
        align={'end'}
        direction={'horizontal'}
      >
        <Button
          target={'_blank'}
          href={newTabURL}
          type={'primary'}
        >
          Open in new tab{' '}
          <IconWrapperSC>
            <NewTabIcon />
          </IconWrapperSC>
        </Button>
      </EmbedButtonWrapperSC>
      <EmbedDocsSC src={url} />
    </>
  )
}
