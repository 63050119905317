import { FC, ReactNode } from 'react'
import { PageHeaderContainerSC, PageHeaderDescriptionSC, PageHeaderHeadlineSC } from './styles'
import { Container } from '../../layouts/container/Container'
import { Col, Row } from 'antd'

export type PageHeaderProps = {
  title: string
  description?: ReactNode
  extra?: ReactNode
}

export const PageHeader: FC<PageHeaderProps> = ({ title, description, extra }) => {
  return (
    <PageHeaderContainerSC>
      <Container>
        <Row>
          <Col lg={13}>
            <PageHeaderHeadlineSC>{title}</PageHeaderHeadlineSC>
            {description && <PageHeaderDescriptionSC>{description}</PageHeaderDescriptionSC>}
            {extra && extra}
          </Col>
        </Row>
      </Container>
    </PageHeaderContainerSC>
  )
}
