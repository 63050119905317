import { FC } from 'react'
import { Organization } from '../../../pages/organization/utils'
import { useFormik } from 'formik'
import { initFormik } from './utils'
import { Button, Form, Input } from 'antd'
import { getValidationError, getValidationStatus } from '../../../utils/formik/formik'
import { FormSC } from './styles'

export type OrganizationSettingsFormValues = {
  displayName: string
}

export type OrganizationSettingsFormProps = {
  organization: Organization
  loading?: boolean
  onSubmit: (values: OrganizationSettingsFormValues) => void
  disabled?: boolean
}
export const OrganizationSettingsForm: FC<OrganizationSettingsFormProps> = props => {
  const { loading, disabled } = props
  const formik = useFormik(initFormik(props))

  return (
    <FormSC
      layout={'horizontal'}
      disabled={disabled}
    >
      <Form.Item
        label={'Name'}
        required={true}
        validateStatus={getValidationStatus(formik.errors.displayName?.toString(), formik.touched.displayName)}
        help={getValidationError(formik.errors.displayName?.toString(), formik.touched.displayName)}
      >
        <Input
          name={'displayName'}
          placeholder={'Organization Name'}
          value={formik.values.displayName}
          onChange={formik.handleChange}
        />
      </Form.Item>
      <Button
        onClick={() => void formik.submitForm()}
        type={'primary'}
        disabled={!formik.dirty}
        className={'ml-10'}
        loading={loading}
      >
        Update
      </Button>
    </FormSC>
  )
}
