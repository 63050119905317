import styled from 'styled-components'
import { Steps } from 'antd'
import { breakpoints } from '../../../styles/globalStyles'

export const StepsSC = styled(Steps)`
  &.ant-steps-horizontal:not(.ant-steps-label-vertical) {
    .ant-steps-item-description {
      @media (min-width: ${breakpoints.mdMin}px) {
        max-width: 100px;
      }
      @media (min-width: ${breakpoints.lgMin}px) {
        max-width: 140px;
      }
      @media (min-width: ${breakpoints.xlMin}px) {
        max-width: 200px;
      }
      @media (min-width: ${breakpoints.xxlMin}px) {
        max-width: 300px;
      }
    }
  }
`

export const ContentSC = styled.div`
  background: #fff;
  padding: 24px 0;
`
