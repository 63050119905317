import React, { FC, useContext } from 'react'
import { Button, message, Popconfirm } from 'antd'
import { useDeleteAiCustomDocumentForOrganizationMutation } from '../../models/graphql'
import { AppCtx } from '../../providers/appCtx'

type DeleteDocsProps = {
  selectedRowKeys: React.Key[]
  onDeleteFinish: () => void
  onUploadStart: () => void
}

export const DeleteDocsButton: FC<DeleteDocsProps> = ({ selectedRowKeys, onDeleteFinish, onUploadStart }) => {
  const { orgID } = useContext(AppCtx)

  const hasSelected = selectedRowKeys.length > 0
  const { mutateAsync: deleteAiDocsAsync, isLoading: isDeleting } = useDeleteAiCustomDocumentForOrganizationMutation()
  const deleteFiles = async () => {
    if (!hasSelected) {
      return
    }
    onUploadStart()

    for (const fileID of selectedRowKeys) {
      try {
        await deleteAiDocsAsync({
          orgID,
          fileID: fileID as string,
        })
      } catch (e) {
        console.error(e)
        void message.error(`Couldn't delete document: ${(e as Error).message}`)
        onDeleteFinish()
        return
      }
    }

    void message.success(`AI document${selectedRowKeys.length > 1 ? 's' : ''} deleted successfully`)
    onDeleteFinish()
  }

  return (
    <Popconfirm
      title={`Do you really want to remove the selected file${selectedRowKeys.length > 1 ? 's' : ''}?`}
      placement={'topRight'}
      onConfirm={() => void deleteFiles()}
      okText={'Remove'}
      okButtonProps={{ danger: true }}
      cancelText={'Cancel'}
    >
      <Button
        danger
        disabled={!hasSelected}
        loading={isDeleting}
      >
        Delete selected file(s)
      </Button>
    </Popconfirm>
  )
}
