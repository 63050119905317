import styled from 'styled-components'
import { Button, Card, Spin, Typography } from 'antd'
import { ReactComponent as Logo } from '../../assets/botkube-logo.svg'
import { ReactComponent as SignIn } from '../../assets/botkube-sign-in.svg'
const { Title } = Typography

export const LoginCard = styled(Card)`
  text-align: left;

  .github {
    background: #25292e;
    color: #fff;
  }

  .google {
    background: #5383ec;
    color: #fff;
  }

  .amazon {
    background: #eec95f;
    color: #000;
  }

  .microsoft {
    background: #25292e;
    color: #fff;

    svg {
      line-height: 1;
      height: 1em;
      margin-right: 0.5em;
      margin-bottom: -0.175em;
    }
  }
`

export const LoginSection = styled.section`
  text-align: center;
`

export const BotkubeLogo = styled(Logo)`
  margin-bottom: 20px;
`

export const WelcomeTitle = styled(Title)`
  margin-bottom: 10px;
`

export const BotkubeSignInIllustration = styled(SignIn)`
  margin-bottom: 20px;
`

export const SignInButton = styled(Button)`
  margin-top: 20px;
`

export const Spinner = styled(Spin)`
  display: block;
`

export const UnauthenticatedContent = styled.div`
  text-align: center;
`
