import React, { useContext } from 'react'
import { MainLayout } from '../../layouts/main/MainLayout'
import { Button, Divider, message, Result, Spin } from 'antd'
import { useSearchParams } from 'react-router-dom'
import { useConnectMachineToOrganizationMutation, useGetMachineByFingerprintQuery } from '../../models/graphql'
import { AppCtx } from '../../providers/appCtx'
import { CodeBlock } from '../../components/codeBlock/CodeBlock'
import { CodeOutlined } from '@ant-design/icons'
import { CenteredContentSC, IconWrapperSC } from './styles'

const REDIRECT_URL_QUERY_PARAM = 'redirect_url'
const MACHINE_ID_QUERY_PARAM = 'machine_fingerprint'
const SUCCESS_STATE_QUERY_PARAM = 'success'

export const MachineConnectionPage: React.FC = () => {
  const [searchParams] = useSearchParams()
  const redirectUrl = searchParams.get(REDIRECT_URL_QUERY_PARAM)
  const success = searchParams.get(SUCCESS_STATE_QUERY_PARAM)
  const machineFingerprint = searchParams.get(MACHINE_ID_QUERY_PARAM)
  const [isRedirecting, setRedirecting] = React.useState(false)

  const appCtx = useContext(AppCtx)

  const { mutateAsync, isLoading: isConnectLoading, isSuccess } = useConnectMachineToOrganizationMutation()
  const { data, isLoading, isError, error } = useGetMachineByFingerprintQuery({
    machineFingerprint: machineFingerprint ?? '',
  })

  const redirectingLayout = (
    <MainLayout>
      <Spin
        tip={'Redirecting back to Fuse CLI...'}
        fullscreen={true}
      />
    </MainLayout>
  )

  const redirect = (redirectUrl: string, apiKey: string) => {
    if (isRedirecting) {
      return
    }

    window.location.replace(`${redirectUrl}?api_key=${apiKey}`)
    setRedirecting(true)
  }

  if (isRedirecting) {
    return redirectingLayout
  }

  if (success === 'true') {
    return (
      <MainLayout>
        <Result
          status='success'
          title='Successfully connected Fuse CLI!'
          subTitle='The authentication flow has completed successfully. You can close this window.'
        />
      </MainLayout>
    )
  }

  if (!machineFingerprint || !redirectUrl) {
    return (
      <MainLayout>
        <Result
          style={{ marginTop: '200px' }}
          status='warning'
          title={
            <>
              The machine details are missing. Close the website and re-run:
              <CodeBlock className={'login-cmd'}>fuse login</CodeBlock>
            </>
          }
        />
      </MainLayout>
    )
  }

  if (isLoading) {
    return (
      <MainLayout>
        <Spin
          tip={'Fetching details...'}
          fullscreen={true}
        />
      </MainLayout>
    )
  }

  if (isError) {
    const err = error as Error
    return (
      <MainLayout>
        <Result
          style={{ margin: '34px 0 0' }}
          status='error'
          title={'Failed to fetch details'}
          subTitle={err.message}
        />
      </MainLayout>
    )
  }

  const apiKey = data.machine?.apiKey?.value ?? ''
  const isAlreadyConnected = data.machine?.id && apiKey
  if (isAlreadyConnected) {
    redirect(redirectUrl, apiKey)

    return redirectingLayout
  }

  const submitFn = async () => {
    // errors are handled globally
    const res = await mutateAsync({
      input: {
        organizationId: appCtx.orgID,
        machineFingerprint: machineFingerprint,
      },
    })
    const msg = `Successfully connected your machine to the organization. Sending response to Fuse CLI...`
    void message.success(msg)

    const apiKey = res.addMachineToOrganization.apiKey?.value ?? ''
    redirect(redirectUrl, apiKey)
  }

  return (
    <MainLayout>
      <CenteredContentSC>
        <IconWrapperSC>
          <CodeOutlined />
        </IconWrapperSC>
        <h1>Connect your machine to the organization</h1>
        To finalize Fuse CLI login, connect it to your Botkube Cloud organization. You will get <b>4x</b> monthly AI
        token quota for free!
        <br />
        <Button
          style={{ marginTop: '1em' }}
          type={'primary'}
          onClick={() => void submitFn()}
          loading={isConnectLoading}
          disabled={isSuccess}
        >
          {isSuccess ? 'Connecting...' : 'Connect'}
        </Button>
        <Divider />
      </CenteredContentSC>
      ,
    </MainLayout>
  )
}
