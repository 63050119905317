import { FC, ReactNode } from 'react'
import { PluginType } from '../../../models/graphql'
import { TagSC } from './styles'
import { mapPluginNameToIcon } from '../../../utils/plugins'

type Props = {
  type: PluginType
  internalName: string
  children?: ReactNode
}
export const PluginTag: FC<Props> = ({ internalName, children, type }) => {
  return (
    <TagSC type={type}>
      {mapPluginNameToIcon(internalName.toLowerCase())} {children}
    </TagSC>
  )
}
