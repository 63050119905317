import { Link } from 'react-router-dom'
import { paths } from '../../router/paths'
import { Button, Space } from 'antd'
import { AliasPageQuery } from '../../models/graphql'
import { ColumnsBotType } from '../../models/antd/table'

export type AliasPage = AliasPageQuery['aliases']['data'][number]

export const getData = (data: AliasPage[]) => data

type ColumnProps = {
  onEdit: (id: string) => void
  onDelete: (id: string) => void
}
export const setColumns = (props: ColumnProps): ColumnsBotType<AliasPage> => [
  {
    title: 'Display Name',
    dataIndex: 'displayName',
  },
  {
    title: 'Alias',
    dataIndex: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: 'Command',
    dataIndex: 'command',
    sorter: (a, b) => a.command.localeCompare(b.command),
  },
  {
    title: 'Instances',
    dataIndex: 'deployments',
    render: (deployments: AliasPage['deployments']) => (
      <Space>
        {deployments.map((deployment, idx) => (
          <Link
            key={idx}
            to={paths.DEPLOYMENT.replace(':id', deployment.id)}
          >{`${deployment.name}`}</Link>
        ))}
      </Space>
    ),
  },
  {
    title: 'Action',
    dataIndex: 'id',
    render: (aliasId: string) => (
      <Space>
        <Button
          type={'link'}
          onClick={() => props.onEdit(aliasId)}
        >
          Edit
        </Button>
        <Button
          type={'link'}
          onClick={() => props.onDelete(aliasId)}
        >
          Delete
        </Button>
      </Space>
    ),
  },
]
