import { Form, Input } from 'antd'
import { FC } from 'react'
import { PlatformsType } from '../../../models/platform/platform'
import { FormikErrors, FormikHandlers, FormikTouched } from 'formik'
import { getValidationError, getValidationStatus } from '../../../utils/formik/formik'
import { WebhookFormValues } from '../../../pages/clusters/add/steps/platformsConfiguration/utils'

type Props = {
  values: WebhookFormValues
  handleChange: FormikHandlers['handleChange']
  errors?: FormikErrors<WebhookFormValues>
  touched?: FormikTouched<WebhookFormValues>
  idx: number
  isSubmitted: boolean
}

export const WebhookForm: FC<Props> = props => {
  const { errors, touched, values, idx, handleChange, isSubmitted } = props

  return (
    <>
      <Form.Item
        label={'Display Name'}
        required={true}
        validateStatus={getValidationStatus(errors?.name, touched?.name ?? isSubmitted)}
        help={getValidationError(errors?.name, touched?.name ?? isSubmitted)}
      >
        <Input
          name={`${PlatformsType.WEBHOOK}.[${idx}].name`}
          onChange={handleChange}
          value={values.name}
          placeholder={'My Webhook Name'}
        />
      </Form.Item>
      <Form.Item
        label={'URL'}
        required={true}
        validateStatus={getValidationStatus(errors?.url, touched?.url ?? isSubmitted)}
        help={getValidationError(errors?.url, touched?.url ?? isSubmitted)}
      >
        <Input
          name={`${PlatformsType.WEBHOOK}.[${idx}].url`}
          onChange={handleChange}
          value={values.url}
          placeholder={'https://my.server.com/webhook'}
        />
      </Form.Item>
    </>
  )
}
