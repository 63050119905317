import { configureStore } from '@reduxjs/toolkit'
import { deploymentSlice } from './slices/deployment/deployment'
import { useDispatch, useSelector } from 'react-redux'
import { pluginSlice } from './slices/plugin/plugin'
import { slackWorkspaceSlice } from './slices/slackWorkspace/slackWorkspace'

export const store = configureStore({
  reducer: {
    deployment: deploymentSlice.reducer,
    plugin: pluginSlice.reducer,
    slackWorkspace: slackWorkspaceSlice.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these field paths in all actions
        ignoredActionPaths: ['payload.callback'],
      },
    }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
type AppDispatch = typeof store.dispatch

export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()
