import styled from 'styled-components'

export const ClusterRecommendationsSC = styled.section`
  .ant-row {
    margin-bottom: 10px;

    &:last-child {
      margin-top: 30px;
    }
  }
`
