import { FC, useContext } from 'react'
import { MainLayout } from '../../../layouts/main/MainLayout'
import { WithoutMenuLayout } from '../../../layouts/withoutMenu/withoutMenuLayout'
import { Button, Result } from 'antd'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { paths } from '../../../router/paths'
import { ResultStatusType } from 'antd/lib/result'
import { AppCtx } from '../../../providers/appCtx'
import * as DOMPurify from 'dompurify'

const SUCCESS = 'success'
const TEAM_AAD_GROUP_ID = 'teamAADGroupID'
const MSG_DETAILS = 'details'
const TMP_HREF_TARGET_ATTRIBUTE = 'data-temp-href-target'

export type Props = {
  authenticated: boolean
}

export const TeamsConsentPage: FC<Props> = ({ authenticated }) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const success = searchParams.get(SUCCESS) ?? ''
  const details = searchParams.get(MSG_DETAILS) ?? ''
  const appCtx = useContext(AppCtx)
  const teamAADGroupID = searchParams.get(TEAM_AAD_GROUP_ID) ?? ''

  const teamAADGroupIDs = appCtx.connectedMSTeams
    .map(org => org.teams)
    .flat()
    .map(team => team?.aadGroupId ?? null)
    .filter(id => id !== null)
  const teamAlreadyConnected = teamAADGroupIDs.includes(teamAADGroupID)

  const wrapWithLayout = (component: JSX.Element) => {
    if (authenticated) {
      return <MainLayout>{component}</MainLayout>
    }

    return <WithoutMenuLayout>{component}</WithoutMenuLayout>
  }

  if (success !== 'true') {
    const message = details.trim().length > 0 ? details : 'Oops! Something went wrong.'
    const msgHTML = sanitize(message)
    return wrapWithLayout(
      <Result
        style={{ margin: '34px 0 0' }}
        status='error'
        title='Permission grant failed'
        subTitle={
          <>
            <p dangerouslySetInnerHTML={{ __html: msgHTML }} />
            <p style={{ margin: 0 }}>
              Please return to Teams and send a message with the{' '}
              <strong>
                <code>@Botkube cloud connect</code>
              </strong>{' '}
              command to restart the permissions grant process.
            </p>
          </>
        }
      />,
    )
  }
  let title = 'Permission grant successful!'
  let subTitle = (
    <>You can now go back to Teams and connect the app to your Botkube organization, if you haven&apos;t already.</>
  )
  let status: ResultStatusType = 'info'

  if (teamAlreadyConnected) {
    title = 'All set up!'
    subTitle = (
      <>
        All required permissions are granted, and the bot is already connected to this Botkube Cloud organization.
        <br />
        You can now use Botkube within your Team!
      </>
    )
    status = 'success'
  }

  return wrapWithLayout(
    <Result
      status={status}
      title={title}
      subTitle={subTitle}
      extra={
        authenticated ? (
          <Button
            type='primary'
            key='home'
            onClick={() => navigate(paths.HOME)}
          >
            Return to dashboard
          </Button>
        ) : null
      }
    />,
  )
}

/**
 * Sanitizes the given input string by applying DOMPurify to remove any potentially unsafe HTML tags and attributes.
 * It allows only attribute <a> to have target="_blank".
 */
function sanitize(input: string) {
  DOMPurify.addHook('beforeSanitizeAttributes', function (node) {
    if (node.tagName === 'A') {
      if (!node.hasAttribute('target')) {
        return node.setAttribute('target', '_self')
      }
      return node.setAttribute(TMP_HREF_TARGET_ATTRIBUTE, node.getAttribute('target') ?? '')
    }
  })

  DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    const target = node.getAttribute(TMP_HREF_TARGET_ATTRIBUTE)
    if (node.tagName === 'A' && target) {
      if (target === '_blank') {
        node.setAttribute('rel', 'noopener')
        node.setAttribute('target', '_blank')
      }
    }
  })

  return DOMPurify.sanitize(input)
}
