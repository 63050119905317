import { FC, Key } from 'react'
import { Table } from 'antd'
import { getColumns, getData } from './utils'
import { TeamInConnectedTeams } from '../utils'
import { useViewport } from '../../../../../utils/customeHooks/useViewport'
import { breakpoints } from '../../../../../styles/globalStyles'

export type TeamChannel = NonNullable<TeamInConnectedTeams['channels']>[number]

type Props = {
  channels: TeamChannel[]
  selectedChannels: string[]
  onChange: (selectedChannels: string[]) => void
}
export const CloudTeamsChannelsTableChannels: FC<Props> = ({ selectedChannels, onChange, channels }) => {
  const { width } = useViewport()

  return (
    <Table
      rowKey={'conversationId'}
      rowSelection={{
        selectedRowKeys: selectedChannels,
        onChange: (selectedRows: Key[]) => onChange(selectedRows as string[]),
      }}
      columns={getColumns()}
      dataSource={getData(channels)}
      tableLayout={width < breakpoints.lgMin ? 'auto' : 'fixed'}
    />
  )
}
