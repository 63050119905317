export const DEPLOYMENT_CONNECT_MODAL_SEARCH_PARAM = 'connectCommand'

export enum DEPLOYMENT_ADD_STEP {
  CONNECT = 'connect',
}

export const externalPaths = {
  EULA: 'https://kubeshop.io/end-user-license-agreement',
  PRIVACY_POLICY: 'https://botkube.io/privacy-policy',
  TERMS_OF_SERVICE: 'https://botkube.io/terms-and-conditions',
}

export const paths = {
  LOGIN: '/login',
  CLI_CLOUD_LOGIN: '/cli/login',
  EMAIL_VERIFIED: '/email-verified',
  CONNECT_SLACK_WORKSPACE: '/organizations/connections/slack',
  CONNECT_TEAMS_TEAM: '/organizations/connections/teams',
  GIVE_CONSENT_TEAMS_TEAM: '/consents/teams',
  SUCCESSFUL_SLACK_WORKSPACE_CONNECTION: '/organizations/connections/slack/success',
  FAILED_SLACK_WORKSPACE_CONNECTION: '/organizations/connections/slack/failure',
  REINSTALLED_SLACK_WORKSPACE: '/organizations/connections/reinstalled-slack',
  HOME: '/',
  DEPLOYMENT: '/instances/:id',
  DEPLOYMENT_CONNECT: `/instances/:id?${DEPLOYMENT_CONNECT_MODAL_SEARCH_PARAM}=true`,
  DEPLOYMENT_ADD: '/instances/add/:id',
  DEPLOYMENT_ADD_RETURN: '/instances/add/:id/:step',
  DEPLOYMENT_ADD_BUILDER: '/instances/add-builder',
  DEPLOYMENT_EVENTS: '/instances/:id/k8s-events',
  AI_ASSISTANT: '/ai-assistant',
  ALIASES: '/aliases',
  AUDIT_LOGS: '/audit-logs',
  SUPPORT: '/support',
  ORGANIZATION: '/organization/:id',
  SUBSCRIPTION_PLANS: '/organization/:id/plans',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_OF_SERVICE: '/terms-of-service',
  EULA: '/eula',
  NOT_FOUND: '/not-found',
  FIRST_LOGIN: '/#first-login',
  CONNECT_MACHINE: '/organizations/connections/machine',
  FUSE: '/fuse',
}
