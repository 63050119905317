import { FC, useMemo } from 'react'
import { Col, Skeleton } from 'antd'

type Props = {
  size: number
  loading: boolean
  horizontal?: boolean
  horizontalSize?: number
}
export const SkeletonGroup: FC<Props> = props => {
  const { size, loading, horizontal, horizontalSize = 24 } = props
  const keys = useMemo(() => {
    const ids = []
    for (let i = 0; i < size; i++) {
      ids.push(i)
    }

    return ids
  }, [size])

  return (
    <>
      {horizontal &&
        loading &&
        keys.map(id => (
          <Col
            key={id}
            span={horizontalSize}
          >
            <Skeleton active={true} />
          </Col>
        ))}
      {!horizontal &&
        loading &&
        keys.map(id => (
          <Skeleton
            key={id}
            active={true}
          />
        ))}
    </>
  )
}
