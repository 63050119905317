import React from 'react'
import { MainLayout } from '../../../layouts/main/MainLayout'
import { SlackIconSC } from './styles'
import { Result } from 'antd'

const WINDOW_CLOSE_TIMEOUT = 3000
const CLOSE_MSG = 'This window will close automatically in a moment.'

export const CloudSlackReinstallPage: React.FC = () => {
  setTimeout(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!window) {
      return
    }
    window.opener = null
    window.open('', '_self')
    window.close()
  }, WINDOW_CLOSE_TIMEOUT)

  return (
    <MainLayout>
      <Result
        status='success'
        title='You successfully reinstalled Botkube application for Slack.'
        subTitle={CLOSE_MSG}
        icon={<SlackIconSC />}
      />
    </MainLayout>
  )
}
