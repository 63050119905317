import { FC, ReactNode } from 'react'
import { Layout } from 'antd'
import { Container } from '../container/Container'
import { Footer } from '../../components/footer/Footer'

const { Content: ADContent } = Layout

type Props = {
  children?: ReactNode
  contentClass?: string
}
export const WithoutHeaderLayout: FC<Props> = props => (
  <Layout>
    <ADContent className={'without-header'}>
      <Container className={props.contentClass}>{props.children}</Container>
    </ADContent>
    <Footer />
  </Layout>
)
