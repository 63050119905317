import React, { FC, useState } from 'react'
import { Button, message, Spin } from 'antd'
import { WrapWithOptionalTooltip } from '../tooltip/WrapWithOptionalTooltip'
import { useGenerateBillingPortalUrlMutation } from '../../models/graphql'

type Props = {
  billingHistoryAvailable: boolean
  isLink?: boolean
}
export const BillingPortalLink: FC<Props> = ({ billingHistoryAvailable, isLink }) => {
  const [isRedirect, setIsRedirect] = useState(false)
  const { mutateAsync: billingMutateAsync, isLoading: billingIsLoading } = useGenerateBillingPortalUrlMutation({
    onSuccess: result => {
      setIsRedirect(true)
      window.location.replace(result.generateBillingPortalURL)
    },
    onError: (err: unknown) => {
      void message.error((err as Error).toString())
    },
  })

  return (
    <>
      <Spin
        tip={'Redirecting to Stripe...'}
        fullscreen={true}
        spinning={isRedirect}
      />
      <WrapWithOptionalTooltip
        tooltip={
          !billingHistoryAvailable
            ? 'Billing Portal link is not available. Upgrade the plan and refresh the page.'
            : null
        }
      >
        <Button
          type={isLink ? 'link' : 'primary'}
          onClick={() => void billingMutateAsync({})}
          loading={billingIsLoading}
          disabled={!billingHistoryAvailable}
        >
          Go to Billing Portal
        </Button>
      </WrapWithOptionalTooltip>
    </>
  )
}
