import { FormikConfig, useFormik } from 'formik'
import { PlatformsType, PlatformsTypeNames } from '../../../../../models/platform/platform'
import * as yup from 'yup'
import { AnySchema } from 'yup'
import { ValidationMsg } from '../../../../../utils/validation'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { mapPlatformTypeToIcon } from '../../../../../utils/platforms'
import { PlatformConfigurationStepProps } from './PlatformsConfigurationStep'
import { PlatformButtonSC } from './styles'

export type PlatformsFormsValues =
  | CloudSlackFormValues
  | SocketSlackFormValues
  | DiscordFormValues
  | CloudTeamsFormValues
  | MattermostFormValues
  | WebhookFormValues
  | PagerDutyFormValues
  | ElasticSearchFormValues

export type CommonPlatformsFormValues = {
  id: string
  name: string
}

export type SocketSlackFormValues = CommonPlatformsFormValues & {
  channelNames: string[]
  appToken: string
  botToken: string
}

export type ChannelNameWithId = {
  id: string
  name: string
}

export type CloudSlackFormValues = CommonPlatformsFormValues & {
  channelNamesWithId: ChannelNameWithId[]
  teamId: string
}

export type DiscordFormValues = CommonPlatformsFormValues & {
  channelNames: string[]
  botId: string
  token: string
}

export type CloudTeamsFormValues = CommonPlatformsFormValues & {
  channelNames: string[]
  attachmentStorage: {
    useDefaultLocation: boolean
    sharePointSiteName: string
  }
  aadGroupId: string
}

export type MattermostFormValues = CommonPlatformsFormValues & {
  botName: string
  url: string
  token: string
  channelNames: string[]
  team: string
}

export type WebhookFormValues = CommonPlatformsFormValues & {
  url: string
}

export type PagerDutyFormValues = CommonPlatformsFormValues & {
  integrationKey: string
}

export type ElasticSearchFormValues = CommonPlatformsFormValues & {
  username: string
  password: string
  server: string
  skipTlsVerify: boolean
  indexName: string
}

export type PlatformsFormValues = {
  [PlatformsType.CLOUD_SLACK]: CloudSlackFormValues[]
  [PlatformsType.SOCKET_SLACK]: SocketSlackFormValues[]
  [PlatformsType.DISCORD]: DiscordFormValues[]
  [PlatformsType.CLOUD_TEAMS]: CloudTeamsFormValues[]
  [PlatformsType.MATTERMOST]: MattermostFormValues[]
  [PlatformsType.WEBHOOK]: WebhookFormValues[]
  [PlatformsType.PAGER_DUTY]: PagerDutyFormValues[]
  [PlatformsType.ELASTIC_SEARCH]: ElasticSearchFormValues[]
}

export type PlatformsFormValuesWithChannels =
  | CloudSlackFormValues
  | SocketSlackFormValues
  | CloudTeamsFormValues
  | DiscordFormValues
  | MattermostFormValues
  | ElasticSearchFormValues
  | WebhookFormValues
  | PagerDutyFormValues

export type FormikPlatformsConfig = ReturnType<typeof useFormik<PlatformsFormValues>>

const commonDefaultValues: CommonPlatformsFormValues = {
  id: '',
  name: '',
}

const cloudSlackDefaultValues: CloudSlackFormValues = {
  ...commonDefaultValues,
  channelNamesWithId: [],
  teamId: '',
}

const slackDefaultValues: SocketSlackFormValues = {
  ...commonDefaultValues,
  channelNames: [],
  appToken: '',
  botToken: '',
}

const discordDefaultValues: DiscordFormValues = {
  ...commonDefaultValues,
  channelNames: [],
  botId: '',
  token: '',
}

const mattermostDefaultValues: MattermostFormValues = {
  ...commonDefaultValues,
  botName: '',
  url: '',
  token: '',
  channelNames: [],
  team: '',
}

const cloudTeamsDefaultValues: CloudTeamsFormValues = {
  ...commonDefaultValues,
  attachmentStorage: {
    useDefaultLocation: true,
    sharePointSiteName: '',
  },
  channelNames: [],
  aadGroupId: '',
}

const webhookDefaultValues: WebhookFormValues = {
  ...commonDefaultValues,
  url: '',
}

const pagerDutyDefaultValues: PagerDutyFormValues = {
  ...commonDefaultValues,
  integrationKey: '',
}

const elasticSearchDefaultValues: ElasticSearchFormValues = {
  ...commonDefaultValues,
  password: '',
  server: '',
  skipTlsVerify: false,
  username: '',
  indexName: '',
}

export const platformDefaultValue: Record<PlatformsType, PlatformsFormsValues> = {
  [PlatformsType.CLOUD_SLACK]: cloudSlackDefaultValues,
  [PlatformsType.SOCKET_SLACK]: slackDefaultValues,
  [PlatformsType.DISCORD]: discordDefaultValues,
  [PlatformsType.MATTERMOST]: mattermostDefaultValues,
  [PlatformsType.CLOUD_TEAMS]: cloudTeamsDefaultValues,
  [PlatformsType.PAGER_DUTY]: pagerDutyDefaultValues,
  [PlatformsType.WEBHOOK]: webhookDefaultValues,
  [PlatformsType.ELASTIC_SEARCH]: elasticSearchDefaultValues,
}

export const initFormik = (props: PlatformConfigurationStepProps): FormikConfig<PlatformsFormValues> => {
  const {
    wizardValues: { platformsStep },
  } = props
  return {
    enableReinitialize: true,
    initialValues: {
      [PlatformsType.CLOUD_SLACK]: platformsStep?.[PlatformsType.CLOUD_SLACK] ?? [],
      [PlatformsType.SOCKET_SLACK]: platformsStep?.[PlatformsType.SOCKET_SLACK] ?? [],
      [PlatformsType.DISCORD]: platformsStep?.[PlatformsType.DISCORD] ?? [],
      [PlatformsType.CLOUD_TEAMS]: platformsStep?.[PlatformsType.CLOUD_TEAMS] ?? [],
      [PlatformsType.MATTERMOST]: platformsStep?.[PlatformsType.MATTERMOST] ?? [],
      [PlatformsType.WEBHOOK]: platformsStep?.[PlatformsType.WEBHOOK] ?? [],
      [PlatformsType.PAGER_DUTY]: platformsStep?.[PlatformsType.PAGER_DUTY] ?? [],
      [PlatformsType.ELASTIC_SEARCH]: platformsStep?.[PlatformsType.ELASTIC_SEARCH] ?? [],
    },
    onSubmit: values => {
      props.onSubmit(values)
    },
    validationSchema: yup.object().shape<Record<keyof PlatformsFormValues, AnySchema>>({
      [PlatformsType.CLOUD_SLACK]: yup.array().of(
        yup.object().shape<Record<keyof CloudSlackFormValues, AnySchema>>({
          id: yup.string(),
          name: yup.string().required(ValidationMsg.REQUIRED),
          teamId: yup.string().required(ValidationMsg.REQUIRED),
          channelNamesWithId: yup
            .array()
            .of(
              yup.object().shape<Record<keyof ChannelNameWithId, AnySchema>>({
                id: yup.string().required(ValidationMsg.REQUIRED),
                name: yup.string().required(ValidationMsg.REQUIRED),
              }),
            )
            .min(1, 'At least one channel must be selected')
            .required(ValidationMsg.REQUIRED),
        }),
      ),
      [PlatformsType.SOCKET_SLACK]: yup.array().of(
        yup.object().shape<Record<keyof SocketSlackFormValues, AnySchema>>({
          id: yup.string(),
          name: yup.string().required(ValidationMsg.REQUIRED),
          channelNames: yup.array().of(yup.string()).min(1, ValidationMsg.MIN_ONE).required(ValidationMsg.REQUIRED),
          appToken: yup
            .string()
            .matches(/^xapp-.*/, 'App token must start from `xapp-` prefix')
            .required(ValidationMsg.REQUIRED),
          botToken: yup
            .string()
            .matches(/^xoxb-.*/, 'Bot token must start from `xoxb-` prefix')
            .required(ValidationMsg.REQUIRED),
        }),
      ),
      [PlatformsType.DISCORD]: yup.array().of(
        yup.object().shape<Record<keyof DiscordFormValues, AnySchema>>({
          id: yup.string(),
          name: yup.string().required(ValidationMsg.REQUIRED),
          channelNames: yup.array().of(yup.string()).min(1, ValidationMsg.MIN_ONE).required(ValidationMsg.REQUIRED),
          botId: yup.string().required(ValidationMsg.REQUIRED),
          token: yup.string().required(ValidationMsg.REQUIRED),
        }),
      ),
      [PlatformsType.CLOUD_TEAMS]: yup.array().of(
        yup.object().shape<Record<keyof CloudTeamsFormValues, AnySchema>>({
          id: yup.string(),
          name: yup.string().required(ValidationMsg.REQUIRED),
          aadGroupId: yup.string().required(ValidationMsg.REQUIRED),
          attachmentStorage: yup.object().shape({
            sharePointSiteName: yup.string(),
          }),
          channelNames: yup
            .array()
            .of(yup.string())
            .min(1, 'At least one channel must be selected')
            .required(ValidationMsg.REQUIRED),
        }),
      ),
      [PlatformsType.MATTERMOST]: yup.array().of(
        yup.object().shape<Record<keyof MattermostFormValues, AnySchema>>({
          id: yup.string(),
          name: yup.string().required(ValidationMsg.REQUIRED),
          botName: yup.string().required(ValidationMsg.REQUIRED),
          url: yup.string().required(ValidationMsg.REQUIRED),
          token: yup.string().required(ValidationMsg.REQUIRED),
          channelNames: yup.array().of(yup.string()).min(1, ValidationMsg.MIN_ONE).required(ValidationMsg.REQUIRED),
          team: yup.string().required(ValidationMsg.REQUIRED),
        }),
      ),
      [PlatformsType.WEBHOOK]: yup.array().of(
        yup.object().shape<Record<keyof WebhookFormValues, AnySchema>>({
          id: yup.string(),
          name: yup.string().required(ValidationMsg.REQUIRED),
          url: yup.string().required(ValidationMsg.REQUIRED),
        }),
      ),
      [PlatformsType.PAGER_DUTY]: yup.array().of(
        yup.object().shape<Record<keyof PagerDutyFormValues, AnySchema>>({
          id: yup.string(),
          name: yup.string().required(ValidationMsg.REQUIRED),
          integrationKey: yup.string().required(ValidationMsg.REQUIRED),
        }),
      ),
      [PlatformsType.ELASTIC_SEARCH]: yup.array().of(
        yup.object().shape<Record<keyof ElasticSearchFormValues, AnySchema>>({
          id: yup.string(),
          name: yup.string().required(ValidationMsg.REQUIRED),
          username: yup.string().required(ValidationMsg.REQUIRED),
          password: yup.string().required(ValidationMsg.REQUIRED),
          server: yup.string().required(ValidationMsg.REQUIRED),
          skipTlsVerify: yup.boolean(),
          indexName: yup.string().required(ValidationMsg.REQUIRED),
        }),
      ),
    }),
  }
}

type AddPlatformMenuAttr = {
  onClick: (platform: PlatformsType) => void
  onSlackClick: () => void
  displaySocketSlack?: boolean
}

export const addPlatformMenu = (attr: AddPlatformMenuAttr): ItemType[] => {
  const items = []

  const platforms: PlatformsType[] = []
  if (attr.displaySocketSlack) {
    const platform = PlatformsType.CLOUD_SLACK
    items.push({
      key: 'unifiedSlack',
      label: (
        <>
          <PlatformButtonSC
            type={'link'}
            onClick={() => attr.onSlackClick()}
          >
            <span>{mapPlatformTypeToIcon(platform, { height: '12px' })}</span> {PlatformsTypeNames[platform]}
          </PlatformButtonSC>
        </>
      ),
    })
  } else {
    platforms.push(PlatformsType.CLOUD_SLACK)
  }

  platforms.push(
    PlatformsType.CLOUD_TEAMS,
    PlatformsType.DISCORD,
    PlatformsType.MATTERMOST,
    PlatformsType.PAGER_DUTY,
    PlatformsType.WEBHOOK,
    PlatformsType.ELASTIC_SEARCH,
  )

  for (const platform of platforms) {
    items.push({
      key: platform,
      label: (
        <PlatformButtonSC
          type={'link'}
          onClick={() => attr.onClick(platform)}
        >
          <span>{mapPlatformTypeToIcon(platform, { height: '12px' })}</span> {PlatformsTypeNames[platform]}
        </PlatformButtonSC>
      ),
    })
  }

  return items
}
