import { Col } from 'antd'
import { FC, ReactNode } from 'react'

type Props = {
  children: ReactNode
}
export const ContentColumnLeft: FC<Props> = ({ children }) => {
  return (
    <Col
      lg={12}
      md={24}
      sm={24}
      xl={9}
    >
      {children}
    </Col>
  )
}
