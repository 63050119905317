import { Link } from 'react-router-dom'
import { FC } from 'react'

import { paths } from '../../router/paths'

type Props = {
  orgID: string
  trialConsumed?: boolean
}
export const PlanUpgradeMessage: FC<Props> = ({ orgID, trialConsumed }) => {
  const subscriptionsPageLink = paths.SUBSCRIPTION_PLANS.replace(':id', orgID)
  if (!trialConsumed) {
    return (
      <>
        <Link to={subscriptionsPageLink}>Try Botkube Team</Link> free for 30 days to add additional instances.
      </>
    )
  }

  return (
    <>
      <Link to={subscriptionsPageLink}>Upgrade your plan</Link> to add additional instances.
    </>
  )
}
