import React, { FC } from 'react'
import { Spin } from 'antd'
import moment from 'moment'

import { useDeploymentStatusQuery } from '../../../models/graphql'
import { useAppSelector } from '../../../store/store'
import { mapStatusToIcon, mapStatusToMsg, mapStatusToTagColor } from './utils'
import { DeploymentStatusSC, DeploymentStatusTagSC } from './styles'
import { WrapWithOptionalTooltip } from '../../tooltip/WrapWithOptionalTooltip'
import { ClockCircleOutlined } from '@ant-design/icons'
import { Tag } from 'antd'

const QUERY_REFETCH_INTERVAL_MS = 5000

export const DeploymentStatus: FC = () => {
  const deployment = useAppSelector(state => state.deployment.data)

  const { data, isLoading } = useDeploymentStatusQuery(
    { id: deployment?.id ?? '' },
    {
      enabled: !!deployment,
      refetchInterval: QUERY_REFETCH_INTERVAL_MS,
      refetchOnWindowFocus: 'always',
    },
  )

  if (!data?.deployment) {
    return <Spin spinning={true} />
  }

  const lastHeartbeatTime = data.deployment.heartbeat && moment(data.deployment.heartbeat.updatedAt).toLocaleString()

  return (
    <Spin
      spinning={isLoading}
      tip='Loading instance status...'
    >
      <DeploymentStatusSC id={'deployment-status'}>
        <DeploymentStatusTagSC
          icon={mapStatusToIcon(data.deployment)}
          color={mapStatusToTagColor(data.deployment)}
        >
          {mapStatusToMsg(data.deployment)}
        </DeploymentStatusTagSC>{' '}
        <WrapWithOptionalTooltip tooltip={lastHeartbeatTime ?? ''}>
          Last seen:{' '}
          {data.deployment.heartbeat ? (
            moment(data.deployment.heartbeat.updatedAt as string).fromNow()
          ) : (
            <Tag
              icon={<ClockCircleOutlined />}
              color='default'
            >
              waiting
            </Tag>
          )}
        </WrapWithOptionalTooltip>
      </DeploymentStatusSC>
    </Spin>
  )
}
