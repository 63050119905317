import { FC } from 'react'
import { MenuMainSC, MenuNavSC, MobileMenuButtonSC } from './styles'
import { useToggle } from '../../utils/customeHooks/useToggle'
import { useViewport } from '../../utils/customeHooks/useViewport'
import { breakpoints } from '../../styles/globalStyles'
import { MenuProps as MenuPropsAntd } from 'antd/lib/menu'

export const MenuBase: FC<MenuPropsAntd> = props => {
  const [isMobileOpen, toggleMobileMenu] = useToggle()
  const { width } = useViewport()

  return (
    <MenuNavSC>
      <MobileMenuButtonSC onClick={toggleMobileMenu} />
      <MenuMainSC
        mode={width > breakpoints.lgMin ? 'horizontal' : 'inline'}
        triggerSubMenuAction={width > breakpoints.lgMin ? 'hover' : 'click'}
        isMobileOpen={width > breakpoints.lgMin ? false : isMobileOpen}
        {...props}
      />
    </MenuNavSC>
  )
}
