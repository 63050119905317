import { FC, useMemo, useState } from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons'
import validator from '@rjsf/validator-ajv8'
import { getPluginConfigurationData, PluginConfigData } from './utils'
import { PluginValue } from '../../../pages/clusters/add/steps/plugins/PluginsStep'
import { PluginTemplate } from '../../../models/graphql'
import { Divider, Space, TabsProps } from 'antd'
import { PluginRBAC, RBACFormValues } from './content/rbac/PluginRBAC'
import { Button, Tabs, Tooltip } from 'antd'
import { IChangeEvent } from '@rjsf/core'
import { TabWrapperSC } from '../../../pages/clusters/add/steps/platformsConfiguration/styles'
import { isUIFormDisabled } from './content/forms/utils'
import { DocumentationLinkSC, DrawerSC } from './styles'
import { getInitialRBACFormValues } from '../../../utils/plugins-rbac'
import { YAMLForm } from './content/forms/YAMLForm'
import { UIForm } from './content/forms/UIForm'
import { PluginGeneralForm, PluginGeneralFormValues } from './content/overview/PluginGeneralForm'
import { DeletePlugin } from '../delete/DeletePlugin'
import { PlatformsType } from '../../../models/platform/platform'
import { mapPluginNameToIcon } from '../../../utils/plugins'
import { useViewport } from '../../../utils/customeHooks/useViewport'
import { breakpoints } from '../../../styles/globalStyles'
import { initPluginGeneralFormValues } from './content/overview/utils'

export type PluginConfigurationConnection = {
  platformId: string
  platformType: PlatformsType
  availableChannels: PluginConfigurationAvailableChannel[]
}

export type PluginConfigurationAvailableChannel = {
  label: string
  value: string
}

export type PluginConfigurationProps = {
  pluginValue: PluginValue
  connections: PluginConfigurationConnection[]
  pluginTemplate?: PluginTemplate
}

type Props = {
  pluginConfig: PluginConfigurationProps
  onCancel: () => void
  onSubmit: (
    data: PluginConfigData,
    generalSettings: PluginGeneralFormValues,
    plugin: PluginValue,
    rbac?: RBACFormValues,
  ) => void
  okText?: string
  selectedPlatformId?: string
  blockPlatformSettings?: boolean
}

export const PluginConfiguration: FC<Props> = props => {
  const { blockPlatformSettings, selectedPlatformId, pluginConfig, onCancel, onSubmit, okText } = props
  const { width } = useViewport()
  const { schema, formDataConfig, rbacConfig } = useMemo(() => getPluginConfigurationData(pluginConfig), [pluginConfig])
  const [pluginConfigData, setPluginConfigData] = useState<PluginConfigData | undefined>(formDataConfig)
  const [pluginGeneralValues, setPluginGeneralValues] = useState<PluginGeneralFormValues | null>(() =>
    initPluginGeneralFormValues(pluginConfig),
  )
  const [rbacData, setRbacData] = useState<RBACFormValues>(getInitialRBACFormValues(rbacConfig))
  const [formsValid, setFormsValid] = useState({
    general: true,
    schema: schema ? validator.isValid(schema, formDataConfig, schema) : true,
    schemaYaml: true,
    permissions: true,
  })

  const submitForm = () => {
    if (pluginConfigData && pluginGeneralValues) {
      onSubmit(pluginConfigData, pluginGeneralValues, pluginConfig.pluginValue, rbacData)
    }
  }

  const onPluginConfigFormChange = (data: IChangeEvent) => {
    setFormsValid({ ...formsValid, schema: data.errors.length === 0 })
    setPluginConfigData(data.formData as PluginConfigData)
  }

  const onPluginConfigYamlChange = (data?: PluginConfigData) => {
    setPluginConfigData(data)
  }

  const isEmptySchema = Object.keys(schema ?? {}).length === 0

  const tabs: TabsProps['items'] = [
    {
      key: 'overview',
      label: <TabWrapperSC error={!formsValid.general}>Overview</TabWrapperSC>,
      children: (
        <PluginGeneralForm
          pluginConfig={pluginConfig}
          onChange={(values, errors) => {
            setPluginGeneralValues(values)
            setFormsValid({
              ...formsValid,
              general: !Object.keys(errors).length,
            })
          }}
          selectedPlatformId={selectedPlatformId}
          blockPlatformSettings={blockPlatformSettings}
        />
      ),
    },
    {
      key: 'yaml-form',
      label: <TabWrapperSC error={!formsValid.schemaYaml}>Configuration as Code</TabWrapperSC>,
      disabled: isEmptySchema,
      children:
        schema && pluginConfigData ? (
          <YAMLForm
            schema={schema}
            configuration={pluginConfigData}
            onChange={onPluginConfigYamlChange}
            onValidate={(errors, data) => {
              setFormsValid({
                ...formsValid,
                schema: validator.isValid(schema, data, schema),
                schemaYaml: !errors.length,
              })
            }}
          />
        ) : (
          <p>This plugin has no configuration</p>
        ),
    },
    {
      key: 'ui-form',
      label: (
        <Tooltip
          title={
            isUIFormDisabled(pluginConfig.pluginTemplate?.title ?? '')
              ? 'UI Form is not available for this plugin, as the JSON schema is too big.'
              : null
          }
        >
          <TabWrapperSC error={!formsValid.schema && !isUIFormDisabled(pluginConfig.pluginTemplate?.title ?? '')}>
            Configuration
          </TabWrapperSC>
        </Tooltip>
      ),
      disabled: isUIFormDisabled(pluginConfig.pluginTemplate?.title ?? '') || isEmptySchema,
      children:
        schema && pluginConfigData ? (
          <UIForm
            onChange={onPluginConfigFormChange}
            schema={schema}
            pluginConfigData={pluginConfigData}
          />
        ) : (
          <p>This plugin has no configuration</p>
        ),
    },
    {
      key: 'permissions',
      label: <TabWrapperSC error={!formsValid.permissions}>Permissions</TabWrapperSC>,
      children: (
        <PluginRBAC
          value={rbacConfig} // when undefined it
          onSubmit={values => setRbacData(values)}
          onChange={isValid => {
            if (formsValid.permissions === isValid) {
              return
            }
            setFormsValid({ ...formsValid, permissions: isValid })
          }}
        />
      ),
    },
  ]

  return (
    <DrawerSC
      title={
        <>
          {mapPluginNameToIcon(pluginConfig.pluginTemplate?.title ?? '')}
          {pluginConfig.pluginValue.displayName}
        </>
      }
      open={true}
      onClose={onCancel}
      closeIcon={null}
      width={width < breakpoints.lgMin ? '100%' : '85%'}
      extra={
        <Space>
          {pluginConfig.pluginTemplate?.documentationUrl && (
            <Tooltip title={`Read the plugin's documentation`}>
              <DocumentationLinkSC
                type={'link'}
                icon={<QuestionCircleOutlined />}
                href={pluginConfig.pluginTemplate.documentationUrl}
                target={'_blank'}
              >
                {width < breakpoints.lgMin ? '' : 'Documentation'}
              </DocumentationLinkSC>
            </Tooltip>
          )}
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            type='primary'
            onClick={submitForm}
            disabled={
              !(isUIFormDisabled(pluginConfig.pluginTemplate?.title ?? '')
                ? formsValid.general && formsValid.schemaYaml && formsValid.permissions
                : formsValid.general && formsValid.schemaYaml && formsValid.schema && formsValid.permissions)
            }
          >
            {okText ?? 'Submit'}
          </Button>
        </Space>
      }
    >
      <Tabs
        defaultActiveKey={'overview'}
        items={tabs}
      />
      {pluginConfig.pluginValue.id && (
        <>
          <Divider />
          <DeletePlugin
            pluginId={pluginConfig.pluginValue.id}
            onDelete={onCancel}
          />
        </>
      )}
    </DrawerSC>
  )
}
