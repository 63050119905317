import { Alert, Checkbox, Col, Form, Input, Select, Typography } from 'antd'
import { FC, useMemo } from 'react'
import { FormikErrors, FormikHandlers, FormikHelpers, FormikTouched } from 'formik'
import { getValidationError, getValidationStatus } from '../../../../utils/formik/formik'
import { CloudTeamsFormValues } from '../../../../pages/clusters/add/steps/platformsConfiguration/utils'

import { FormItemSC } from './styles'
import { ConnectedTeamsOrganizations } from './CloudTeamsContent'
import { getChannelsFromTeam, getTeamsOptionsForSelector, TeamInConnectedTeamsOption } from './utils'
import { CloudTeamsChannelsTableChannels } from './channels/CloudTeamsChannelsTableChannels'
import { getTeamsFromOrganization } from '../../../../utils/platforms'

const { Text } = Typography

type Props = {
  values: CloudTeamsFormValues
  handleChange: FormikHandlers['handleChange']
  setFieldValue: FormikHelpers<CloudTeamsFormValues>['setFieldValue']
  errors?: FormikErrors<CloudTeamsFormValues>
  touched?: FormikTouched<CloudTeamsFormValues>
  isSubmitted: boolean
  connectedTeamsOrgs?: ConnectedTeamsOrganizations
  handleAutoSubmit?: () => void
  disabled?: boolean
  fieldNamePrefix?: string
}

export const CloudTeamsForm: FC<Props> = props => {
  const {
    setFieldValue,
    errors,
    touched,
    values,
    handleChange,
    isSubmitted,
    connectedTeamsOrgs,
    handleAutoSubmit,
    disabled,
    fieldNamePrefix = '',
  } = props

  const teams = useMemo(() => {
    return getTeamsFromOrganization(connectedTeamsOrgs)
  }, [connectedTeamsOrgs])

  if (!connectedTeamsOrgs || !connectedTeamsOrgs.length) {
    return (
      <p>
        <Text
          strong={true}
          style={{ color: '#f5222d' }}
        >
          No connected MS Teams workspace found. Please connect your MS Teams workspace first, and return to the page.
        </Text>
      </p>
    )
  }

  const orgsWithConsent = connectedTeamsOrgs.filter(team => team.consentGiven && !team.isReConsentingRequired)
  const orgsWithoutConsent = connectedTeamsOrgs.filter(team => !team.consentGiven || team.isReConsentingRequired)

  const handleAadGroupSelect = (value: string, option: TeamInConnectedTeamsOption) => {
    setFieldValue(`${fieldNamePrefix}aadGroupId`, value)
    if (values.name === '') {
      setFieldValue(`${fieldNamePrefix}name`, option.label)
    }
  }

  return (
    <>
      {orgsWithoutConsent.length > 0 && (
        <Alert
          message='Missing consent'
          description={
            <>
              There {orgsWithoutConsent.length > 1 ? 'are' : 'is'} {orgsWithoutConsent.length} Microsoft Teams workspace
              {orgsWithoutConsent.length > 1 && 's'} connected without granted consent. Please return to Teams and send
              a message with the{' '}
              <strong>
                <code>@Botkube cloud connect</code>
              </strong>{' '}
              command to restart the permissions grant process.
            </>
          }
          type='warning'
          showIcon
          closable
          className='mb-20'
        />
      )}
      {orgsWithConsent.length > 0 && (
        <Col xl={24}>
          <Form layout={'vertical'}>
            <Form.Item
              label={'Select team'}
              required={true}
              validateStatus={getValidationStatus(errors?.aadGroupId, touched?.aadGroupId ?? isSubmitted)}
              help={getValidationError(errors?.aadGroupId, touched?.aadGroupId ?? isSubmitted)}
            >
              <Select
                value={values.aadGroupId}
                placeholder={'Select team'}
                disabled={disabled}
                showSearch
                onBlur={handleAutoSubmit}
                onChange={(value, option) => handleAadGroupSelect(value, option as TeamInConnectedTeamsOption)}
                options={getTeamsOptionsForSelector(orgsWithConsent)}
              />
            </Form.Item>
            <Form.Item
              label={'Display Name'}
              required={true}
              validateStatus={getValidationStatus(errors?.name, touched?.name ?? isSubmitted)}
              help={getValidationError(errors?.name, touched?.name ?? isSubmitted)}
            >
              <Input
                name={`${fieldNamePrefix}name`}
                disabled={disabled}
                onChange={handleChange}
                onBlur={handleAutoSubmit}
                value={values.name}
                placeholder={'My Team Name'}
              />
            </Form.Item>
            <Form.Item
              name={`${fieldNamePrefix}attachmentStorage.useDefaultLocation`}
              help={
                <>
                  If checked, all attachments will be stored in your team&apos;s SharePoint folder.{' '}
                  <a
                    href='https://support.microsoft.com/en-us/office/file-storage-in-microsoft-teams-df5cc0a5-d1bb-414c-8870-46c6eb76686a'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Learn more
                  </a>
                </>
              }
            >
              <Checkbox
                checked={values.attachmentStorage.useDefaultLocation}
                onChange={e => {
                  handleChange(e)
                  if (e.target.checked) {
                    setFieldValue(`${fieldNamePrefix}attachmentStorage.sharePointSiteName`, '')
                    if (typeof handleAutoSubmit === 'function') {
                      // workaround for https://github.com/jaredpalmer/formik/issues/529
                      setTimeout(() => {
                        // eslint-disable-next-line
                        handleAutoSubmit()
                      }, 50)
                    }
                  }
                }}
              >
                Use default attachment location
              </Checkbox>
            </Form.Item>

            {!values.attachmentStorage.useDefaultLocation && (
              <Form.Item
                label={'SharePoint site for attachment upload'}
                required={true}
                tooltip={
                  <a
                    href='https://learn.microsoft.com/en-us/sharepoint/teams-connected-sites'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Learn more about Teams and SharePoint integration
                  </a>
                }
                validateStatus={getValidationStatus(
                  errors?.attachmentStorage?.sharePointSiteName,
                  touched?.attachmentStorage?.sharePointSiteName ?? isSubmitted,
                )}
                help={getValidationError(
                  errors?.attachmentStorage?.sharePointSiteName,
                  touched?.attachmentStorage?.sharePointSiteName ?? isSubmitted,
                )}
              >
                <Input
                  name={`${fieldNamePrefix}attachmentStorage.sharePointSiteName`}
                  disabled={disabled}
                  onChange={handleChange}
                  onBlur={handleAutoSubmit}
                  value={values.attachmentStorage.sharePointSiteName}
                  placeholder={'Custom SharePoint site name'}
                />
              </Form.Item>
            )}

            <FormItemSC
              label={'Select channels'}
              required={true}
              validateStatus={getValidationStatus(errors?.channelNames as string | string[], isSubmitted)}
              help={getValidationError(errors?.channelNames as string | string[], isSubmitted)}
            >
              <CloudTeamsChannelsTableChannels
                channels={getChannelsFromTeam(teams, values.aadGroupId)}
                selectedChannels={values.channelNames}
                onChange={selectedChannels => {
                  setFieldValue(`${fieldNamePrefix}channelNames`, selectedChannels)
                  if (typeof handleAutoSubmit === 'function') {
                    // workaround for https://github.com/jaredpalmer/formik/issues/529
                    setTimeout(() => {
                      // eslint-disable-next-line
                      handleAutoSubmit()
                    }, 50)
                  }
                }}
              />
            </FormItemSC>
          </Form>
        </Col>
      )}
    </>
  )
}
