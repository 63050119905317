import { FC, ReactNode } from 'react'
import { Button, Col, Row, Typography } from 'antd'
import { ButtonSC } from '../fuse/styles'
import { Image, ProductCardBg, ProductCardWrapper } from './styles'
import Icon from '@ant-design/icons'

export type ProductCardProps = {
  icon: ReactNode
  title: string
  subheader: string
  features: { headline: string; description: string }[]
  imagePath: string
  isFeatured?: boolean
  btnOnClick: () => void
}

export const ProductCard: FC<ProductCardProps> = ({
  title,
  subheader,
  icon,
  imagePath,
  features,
  isFeatured,
  btnOnClick,
}) => {
  const btnLabel = 'Get started'

  return (
    <Col
      key={title}
      xs={24}
    >
      <ProductCardWrapper isFeatured={isFeatured}>
        <ProductCardBg>
          <Row gutter={[24, 24]}>
            <Col
              md={12}
              lg={14}
              xl={16}
              xxl={18}
            >
              <h1>
                <Icon>{icon}</Icon> {title}
              </h1>
              <h2>{subheader}</h2>
              <Typography.Paragraph>
                <ul>
                  {features.map(feature => (
                    <li key={feature.headline}>
                      <strong>{feature.headline}:</strong> {feature.description}
                    </li>
                  ))}
                </ul>
              </Typography.Paragraph>
              {isFeatured ? (
                <ButtonSC
                  size={'large'}
                  type='primary'
                  onClick={btnOnClick}
                >
                  {btnLabel}
                </ButtonSC>
              ) : (
                <Button
                  type={'primary'}
                  size={'large'}
                  onClick={btnOnClick}
                >
                  {btnLabel}
                </Button>
              )}
            </Col>
            <Col
              xs={24}
              md={12}
              lg={10}
              xl={8}
              xxl={6}
              style={{ textAlign: 'right' }}
            >
              <Image
                src={imagePath}
                alt={title}
              />
            </Col>
          </Row>
        </ProductCardBg>
      </ProductCardWrapper>
    </Col>
  )
}
