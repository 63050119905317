import { FC } from 'react'
import { FormikErrors, FormikProps } from 'formik'
import { CloudTeamsFormValues } from '../../../../pages/clusters/add/steps/platformsConfiguration/utils'
import { CloudTeamsContentInner } from './CloudTeamsContent'

export type CloudTeamsInstanceFormProps = {
  formik: FormikProps<CloudTeamsFormValues>
}

export const CloudTeamsUpdateContent: FC<CloudTeamsInstanceFormProps> = ({ formik }) => {
  return (
    <>
      <CloudTeamsContentInner
        handleChange={formik.handleChange}
        values={formik.values}
        errors={formik.errors as FormikErrors<CloudTeamsFormValues> | undefined}
        touched={formik.touched}
        isSubmitted={formik.submitCount > 0}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        setFieldValue={formik.setFieldValue}
      />
    </>
  )
}
