import { PluginConfigurationProps } from './PluginConfiguration'
import { RJSFSchema } from '@rjsf/utils'
import { getPluginsInPlatform } from '../../../utils/plugins'
import { PlatformsInPluginStep, PluginValue } from '../../../pages/clusters/add/steps/plugins/PluginsStep'
import { getEmptyRBACConfig } from './content/rbac/PluginRBAC'

export type PluginConfigData = unknown

export const parseConfigData = (configuration?: string) => {
  if (!configuration) {
    return {}
  }

  let data: PluginConfigData
  try {
    data = JSON.parse(configuration) as PluginConfigData
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('while parsing plugin configuration')
    data = {}
  }

  return data
}

export type ValidatePluginSelectionResult = {
  valid: boolean
  reason?: string
}

export const validatePluginSelection = (
  platforms: PlatformsInPluginStep[],
  pluginValues: PluginValue[],
): ValidatePluginSelectionResult => {
  for (const platform of platforms) {
    const pluginsInPlatform = getPluginsInPlatform(pluginValues, platform)
    if (pluginsInPlatform.every(plugin => !plugin.enabled)) {
      return { valid: false, reason: 'Each platform needs at least one plugin enabled.' }
    }

    if (pluginsInPlatform.some(plugin => plugin.enabled && plugin.invalid)) {
      return { valid: false, reason: 'There is an invalid plugin configuration.' }
    }
  }

  return { valid: true }
}

export const getPluginConfigurationData = (pluginConfig: PluginConfigurationProps) => {
  const pluginTemplate = pluginConfig.pluginTemplate

  return {
    schema: pluginTemplate?.schema as RJSFSchema | undefined,
    formDataConfig: parseConfigData(pluginConfig.pluginValue.configuration),
    rbacConfig: pluginConfig.pluginValue.rbac ?? getEmptyRBACConfig(),
  }
}
