import { FC } from 'react'
import { Button, Modal, ModalProps, Table } from 'antd'
import { getData, setColumns } from './utils'

export const NotificationSettings: FC<ModalProps> = props => {
  return (
    <Modal
      title={'Customise resource notifications'}
      okText={'Apply'}
      {...props}
    >
      <Button
        type={'primary'}
        className={'mb-20'}
      >
        Reset all
      </Button>
      <Table
        dataSource={getData()}
        columns={setColumns()}
      />
    </Modal>
  )
}
